import Rails from '@rails/ujs';

import {
  EditorWatchdog
} from 'ckeditor5'

import DecoupledEditor from './decoupled_editor';
import { configureTrackChangesAndSuggestions, configureReadOnly, configureComments } from '../fields/ckeditor';
/**
 * A specialized instance of editor watchdog. Contains all the default behavior used in the application.
 */
export default class CollaborationEditorWatchdog extends EditorWatchdog {

  constructor(Editor, watchdogConfig = {
    crashNumberLimit: 3,
    minimumNonErrorTimePeriod: 10000
  }) {
    super(Editor, watchdogConfig);

    const watchdog = this;

    this.on('error', (_, { error }) => {
      if (error.message.match("Document.+does not exist") || error.message.match("cloudservices-reconnection-error")) {
        // This error was caused by a missing cache
        // We need to reload the page here to ensure we have the latest content from the server
        // Otherwise, if we allow the editor to restart and the browser has old content, it will overwrite
        // the newer content on the first save.
        alert("We need to refresh your browser to update to the latest content for this section");
        document.getElementsByClassName("ckeditor-flush-link")[0].click();
        // TODO - We should just do an ajax request to our server to make sure we have the latest HTML in the DOM at this point
      }
    });

    // watchdog.on( 'restart', () => { console.log( 'Editor was restarted' ) } );

    const loaderElement = document.querySelector('.ck-collaboration .ck-loader');
    this.setCreator((element, config) => {
      // Customize autosave.

      // this data value is primarily used in the case that the CI bot is loading the editor so that the editor doesn't autosave.
      if (element.dataset.ckeditorautosavedisabled !== 'true') {
        config.autosave = Object.assign({}, {
          save(editor) {
            return saveData(editor.getData());
          },
          waitingTime: 3000
        }, config.autosave || {});
      }

      config.list = {
        properties: {
          startIndex: true
        }
      }

      return DecoupledEditor.create(element, config)
        .then(editor => {
          // @todo: This part is already handled in the main ckeditor.js file. So if removing stays there this part can be safely removed.
          // // it is possible for the user to navigate away from the editor before it has finished loading.
          // // So we need to check that the editor still exists here
          // if($(document).find(element).length == 0) {
          //   watchdog.destroy();
          //   // At this point, the editor has not been saved to the watchdog yet so we need to destroy both independently
          //   editor.destroy();
          //   return;
          // }
          editor['suppressReloadWarning'] = false;

          const $outputArea = $(element).parent().find("input.ckeditor-document");
          const $outputVersionNumber = $(element).parent().find("input.ckeditor-document-version");

          function saveChanges() {
            setAutosaveStatus('saving');
            $outputArea.val(editor.getData());
            $outputVersionNumber.val(editor.plugins.get('RealTimeCollaborationClient').cloudDocumentVersion);
          }

          for (const saveButtonElement of document.querySelectorAll('.ckeditor-save-button')) {
            editor.ui.view.listenTo(saveButtonElement, 'click', (eventInfo, domEvent) => {
              domEvent.preventDefault();
              performSave();
            })
          }

          function performSave() {
            saveChanges();
            setTimeout(function () {
              saveData(editor.getData());
            }, 1000);
          }

          editor.model.document.on('change', () => {
            setTimeout(() => {
              handleCommentSelection();
            }, '100')
          });


          editor.on('change:isReadOnly', (evt, property, is) => {
            if (is) {
              console.log("We are in read only mode");
              setAutosaveStatus('offline');
            } else {
              console.log("We are NOT in read only mode");
              // Wait 1 second before attempting to do a save
              setTimeout(
                function () {
                  performSave();
                }, 1000
              )
            }
          });

          const commentsRepository = editor.plugins.get('CommentsRepository');
          commentsRepository.on('resolveCommentThread', () => {
            saveData(editor.getData());
          });

          // Set a custom container for the toolbar.
          document.querySelector('.ck-collaboration__toolbar').appendChild(editor.ui.view.toolbar.element);
          editor.on('destroy', () => editor.ui.view.toolbar.element.remove());
          document.querySelector('.ck-toolbar').classList.add('ck-reset_all');

          loaderElement.classList.add('fadeout');
          setTimeout(() => {
            document.querySelector('.ck-collaboration').classList.add('ck-collaboration--ready');
            loaderElement.parentNode.removeChild(loaderElement);
            if (editor.ui.view.editable.element.classList.contains("autofocus")) {
              editor.editing.view.focus();
            }
          }, 200);

          handleCopyUrlButton();

          // Prevent closing the tab when any action is pending.
          // beforeunload is handled by the default ckeditor behaviour.
          editor.ui.view.listenTo(window, 'beforeunload', unloadFunction);
          // If the user triggers a turbolinks event by clicking a link, we need to add our own listener
          editor.ui.view.listenTo(window, 'turbolinks:before-visit', unloadFunction);

          function unloadFunction(evt, domEvt) {
            // Safari wasn't doing the right thing when we use editor.suppressReloadWarning directly but if we set it to a var here it works as expected.
            autosaveDebug("Unload function called");
            autosaveDebug(`editor.suppressReloadWarning = ${editor.suppressReloadWarning}`);
            var suppressReloadWarning = editor.suppressReloadWarning;
            if (editor.plugins.get('PendingActions').hasAny && !suppressReloadWarning) {
              var userForceLeave = false;
              // Turbolinks doesn't work with the standard ckeditor alert if there are unsaved changes on the page so we have to do this instead
              if (domEvt.type === "turbolinks:before-visit") {
                userForceLeave = confirm("There are unsaved changes. Are you sure you want to leave the page?");
              }
              if (userForceLeave) {
                hideCollaborationContainer();
              } else {
                domEvt.preventDefault();
                domEvt.returnValue = true;
              }
            } else {
              hideCollaborationContainer();
            }
          }

          function hideCollaborationContainer() {
            $(".ck-collaboration").hide();
          }

          const saveTimerId = setInterval(function () {
            // Trigger a save every 5 minutes. This is to protect against us making an update to ckeditor
            // and a user leaving a tab open for a long period.
            saveData(editor.getData());
          }, 300000);

          editor.once('destroy', () => window.clearInterval(saveTimerId));

          configureTrackChangesAndSuggestions(config, editor);
          configureReadOnly(config, editor);
          configureComments(config, editor);

          // this is used for triggering an event that is captured by the stimulus controller.
          // The stimulus controller will then handle generating the suggestion content through the editor
          // and submitting the form.
          const event = new CustomEvent('ckeditor:collaborative-editor:initialized', {detail: {editor: editor}})
          element.dispatchEvent(event)
          return editor;
        })
        // If we can't start the editor successfully, show an error div with a link
        // That will allow the user to flush the collaborative cache and try again.
        // Usually this is triggered by an out of sync editor version between the client and the cache.
        .catch(error => {
          $(".editor-error").show();
          $(".ck-collaboration").hide();
          console.log(error);

          // this event is useful for the ci bot - it let's the bot know the editor is failing to start
          // so that the ci bot will stop waiting and move on
          const event = new CustomEvent('ckeditor:initialization-failure', { detail: { error: error } })
          document.dispatchEvent(event)
        });
    });

    this.setDestructor(editor => {
      // It is possible for the destructor method to be called on the watchdog before the editor is actually set.
      // This happens if the user navigates away from the page before the editor finishes loading
      // @todo: case when it's not yet loaded should be gracefully handled too.
      if (editor != null && typeof editor !== 'undefined' && editor.state != 'destroyed') {
        editor
          .destroy()
          .then(() => {
            $(".ck-toolbar").remove();
            $(".ck-presence-list").remove();
          });
      }
    });

    function autosaveDebug(string) {
      // console.log(string);
    }

    function setAutosaveStatus(status) {
      const autosaveBar = document.getElementsByClassName("collaboration__topbar-autosave")[0];
      if (!autosaveBar) return;
      if (status == "offline") {
        autosaveBar.classList.add("offline");
        autosaveBar.classList.remove("saving");
        autosaveBar.classList.remove("saved");
        autosaveBar.classList.remove("text-danger");
      } else if (status == 'saved') {
        autosaveBar.classList.remove("offline");
        autosaveBar.classList.remove("saving");
        autosaveBar.classList.add("saved");
        autosaveBar.classList.remove("text-danger");
      } else if (status == 'saving') {
        autosaveBar.classList.remove("offline");
        autosaveBar.classList.add("saving");
        autosaveBar.classList.remove("saved");
      }
      // Restore the defaults:
      autosaveBar.classList.remove("text-danger");
      const saveDescrip = document.querySelector(".collaboration__topbar-autosave-saving .autosave-description")
      if (saveDescrip) saveDescrip.textContent = "Saving...";
    }

    function saveData(data) {
      const editor = watchdog.editor;
      const element = editor.ui.getEditableElement();
      const defaultLockID = Symbol('default-readonly-lock');

      return new Promise(resolve => {

        if (editor.isReadOnly) {
          resolve();
          setAutosaveStatus('offline');
          return false;
        }

        // TODO this, and really the rest of the implementation, doesn't support more than one autosaving ckeditor
        // instance on the page at a time.
        var form = document.querySelector("form.ckeditor-autosave-form");

        var afterError;
        var afterSave;
        var countDownThenRetry;

        afterSave = function (xhr, data, response) {
          autosaveDebug("🛠 Save succeeded.");

          // revert the saving status element to it's default state.
          setAutosaveStatus('saved');
          editor.disableReadOnlyMode(defaultLockID);
          const url = window.location.href;
          // If we just did a force update of the ckeditor version number after a reload, remove the reloaded param here
          if (url.indexOf("reloaded=true") > -1) {
            const url = window.location.href.split("/").pop().split("?")[0]
            window.history.replaceState({}, null, url);
            // Reset the hidden input element back to its default state
            document.getElementById("solicitations_section_page_reloaded").value = false;
          }

          // remove the ajax callbacks we attached to the form. we can't leave these attached, because the success
          // callback has to specifically reference the specific promise that is instantiated for each save attempt.
          $(form).off('ajax:success', afterSave);
          $(form).off('ajax:error', afterError);

          // this is the successful end of everything. the promise is fulfilled and now ckeditor will start attempting
          // to make new saves. if the ckeditor content has changed since the last save was attempted, it will actually
          // trigger another save, however in our case it shouldn't really make a difference, because we were submitting
          // whatever the most recent content was anyway. (we don't submit `data`, we pull from the form directly.)
          resolve();
        }

        afterError = function (event, xhr, status, error) {
          // TODO i don't know why xhr, status, and error are being received as null, but this grabs the status code.
          status = event.originalEvent.detail[2].status;

          // this is the status code we receive when they've tried to save an outdated version of the section content.
          if (status == 412) {

            // disable the editor.
            editor.enableReadOnlyMode(defaultLockID);

            // explain to the user what is about to happen.
            alert("The version of section content loaded on this page is now outdated. To avoid overwriting work completed elsewhere, this page must be reloaded.");

            // reload the page.
            // this suppresses the warning users get if there are unsaved changes on the page
            editor['suppressReloadWarning'] = true;
            var url = window.location.href;
            if (url.indexOf('reloaded=true') < 0) {
              if (url.indexOf('?') > -1) {
                url += "&reloaded=true";
              } else {
                url += "?reloaded=true";
              }
            }
            window.location.href = url;


            // 426 == "upgrade required"
            // This happens we tried to do a save when using an outdated version of ckeditor
          } else if (status == 426) {
            alert("There has been an update to the Solicitation Builder text editor.  We need to refresh the page to load the latest version for you.");
            editor['suppressReloadWarning'] = true;
            document.getElementsByClassName("ckeditor-flush-link")[0].click();

            // this is the status code we receive if a user is signed out.
          } else if (status == 401) {

            // the `true` here tells `countDownThenRetry` that they're signed out.
            // TODO can we make these calls more clean with some named parameters or something?
            autosaveDebug("🛠 User appears to be signed out. Will try again in 5 seconds.");
            // If we don't make the editor read only, it is possible for a logged out user to continue making changes. Ckeditor will sync these changes over to a user who is still logged in and the changes will be persisted to the database
            // There is still an issue where the first change the user makes after signing out will still be saved by a colleague's editor. However, after that initial save fails, the logged out user won't be able to make any further changes
            editor.enableReadOnlyMode(defaultLockID);
            return countDownThenRetry(5, 0, true);

            // this is the status code we receive when a user was signed out and then signed back in.
          } else if (status == 422) {
            autosaveDebug("🛠 The user appears to be signed in again. Fetching a new authenticity token.");

            // we need to fetch the updated authenticity token.
            $.ajax({

              // we will just grab a copy of the same page we're on, where the ckeditor and it's form live.
              url: document.URL,

              // if we've been doing our job correctly, the current page should have always been a `GET` request.
              type: "GET",

              // if we're able to successfully grab a copy of the current page ..
              success: function (data) {
                autosaveDebug("🛠 Refetched the page.");

                // if we find an authenticity token on the new fetched copy of the page ..
                var authenticityToken = $(data).find("form input[name='authenticity_token']").val();
                if (authenticityToken) {
                  autosaveDebug("🛠 Found a new authenticity token.");

                  // update the authenticity token on the current page from the one we fetched.
                  $(form).find("input[name='authenticity_token']").val(authenticityToken);

                  autosaveDebug("🛠 Trying again immediately.");
                  countDownThenRetry(0, 0, false);
                } else {
                  autosaveDebug("🛠 Didn't find a new authenticity token.");
                  autosaveDebug("🛠 Save still failed. Will try again in 5 seconds.");

                  // this situation is weird, but there isn't anything we can do about it but retry the whole process.
                  countDownThenRetry(5, 0, false);
                }
              },

              // if we weren't able to successfully grab a copy of the current page, we're in a very rare situation.
              // we're reasonably confident that the user is signed in because that's the only way we're getting a
              // `422` instead of a `401` when trying to save. so something else is probably wrong. perhaps the user
              // no longer has access to the section to edit it. in that case, it's actually okay that they're stuck
              // because we don't want their changes anyway. however, it's a bad experience for them, because they
              // might continue trying to do work and then lose it, and maybe they were removed as a team member
              // by accident or something. either way:
              //
              // TODO make this edge case fail more gracefully. inform the user something is wrong.
              error: function () {

                // if for some reason fetching the authenticity token fails, just redo this loop again.
                autosaveDebug("🛠 Save failed. Will try again in 5 seconds.");
                countDownThenRetry(5, 0, false);

              }
            });

            // TODO i'm not actually sure what the right thing to return here is. this seems to work.
            return false;

          } else if (status == 404 && $(form).find("input[name='_method']").val() != 'patch') {
            // we don't know why this is happening, but sometimes in firefox when you're a non-admin user, the
            // http method for the rails form is being overwritten by an authenticity token. it doesn't quite make
            // sense, but we haven't figured it out yet. in the short term, it appears that this will fix the issue.
            // This intermittently happens when reloading the page that contains an editor
            autosaveDebug("The _method value was changed.");
            $(form).find("input[name='_method']").val("patch");
            countDownThenRetry(0, 0, false);

            // this is the status code we receive if the section was deleted
          } else if (status == 404) {
            autosaveDebug("🛠🚨 Save failed and we received a 404 from the server.");

            countDownThenRetry(5, 0, false);
            // for all other status codes, e.g. `500`, etc. ..
          } else {

            // don't do anything special.
            autosaveDebug("🛠 Save failed. Will try again in 5 seconds.");
            countDownThenRetry(5, 0, false);

          }
        }

        async function saveRailsForm() {
          // find the output area and update it with the editor data.
          // usually the rails form has already been updated, but this actually isn't true on the first save that
          // happens when loading the page, and it's possible updates are pending in the collaborative session.
          const $outputArea = $(element).parent().find("input.ckeditor-document");
          const $ckeSuggestionHighlightedInput = $(element).parent().find("input.ckeditor-suggestion-highlighted-document");
          const $ckeSuggestionAcceptedInput = $(element).parent().find("input.ckeditor-suggestion-accepted-document");
          const $ckeSuggestionRejectedInput = $(element).parent().find("input.ckeditor-suggestion-rejected-document");
          const $outputVersionNumber = $(element).parent().find("input.ckeditor-document-version");

          // Updating value of hidden input for actual body
          $outputArea.val(editor.getData());

          // Updating value of hidden input for suggestion highlighted body
          if ($ckeSuggestionHighlightedInput.length) {
            $ckeSuggestionHighlightedInput.val(editor.getData({ showSuggestionHighlights: true }));
          }

          // Updating value of hidden input for suggestion accepted body
          if ($ckeSuggestionAcceptedInput.length) {
            const acceptedBody = await getBodyWithAcceptedSuggestions(editor);
            $ckeSuggestionAcceptedInput.val(acceptedBody);
          }

          // Updating value of hidden input for suggestion rejected body
          if ($ckeSuggestionRejectedInput.length) {
            const rejectedBody = await getBodyWithRejectedSuggestions(editor);
            $ckeSuggestionRejectedInput.val(rejectedBody);
          }

          // Updating value of hidden input for cke document version
          $outputVersionNumber.val(editor.plugins.get('RealTimeCollaborationClient').cloudDocumentVersion);

          // The form may not exist if the user has navigated away from the page before we get here
          if ($(document).find(form).length > 0) {
            // have rails-ujs try to submit the form for us, which will result in an `ajax:success` or `ajax:error` event.
            Rails.fire(form, "submit");
          }
        }

        function getBodyWithAcceptedSuggestions(editor) {
          return new Promise(resolve => {
            resolve(editor.plugins.get('TrackChangesData').getDataWithAcceptedSuggestions());
          });
        }

        function getBodyWithRejectedSuggestions(editor) {
          return new Promise(resolve => {
            resolve(editor.plugins.get('TrackChangesData').getDataWithDiscardedSuggestions());
          });
        }

        countDownThenRetry = function (secondsRemaining, delayInSeconds, signedOut) {
          autosaveDebug("🛠 Counting down. " + secondsRemaining + " seconds remaining.");
          // If the user has left the page, don't try to save - This is an issue if the user clicks away before the editor finishes instantiating.
          // The initial save will fail once the editor is ready because the form object no longer exists.
          if ($(document).find(form).length == 0) {
            return;
          }
          setTimeout(function () {
            // if we're still counting down ..
            setAutosaveStatus('saving');
            if (secondsRemaining > 0) {
              $(".collaboration__topbar-autosave").addClass("text-danger");

              // update the saving state to "Unable to save. Trying again in X seconds..."
              var $autosaveDescription = $(".collaboration__topbar-autosave-saving .autosave-description");
              $autosaveDescription.html("<span class='text-danger'>Unable to save. <span class='additional_context'></span>Trying again in " + secondsRemaining + " " + (secondsRemaining > 1 ? "seconds" : "second") + "...</span>")

              // if we know they're signed out ..
              if (signedOut) {
                // we can give them a link to sign in again.
                $autosaveDescription.find('.additional_context').html("You're not signed in. <a href='' class='sign_in_link' style=\"text-decoration: underline;\"><b>Click here to sign in again.</b></a><br>")
                $autosaveDescription.find('.additional_context').find('.sign_in_link').click(function () {
                  // browsers warn them about leaving the page, because of the unsaved form, so we need to address
                  // that before it pops up.
                  // alert("Before allowing you to sign in, your browser may warn you that you're about to navigate away from the current page. This is OK. You'll return to this page after signing in and your changes will be saved.");
                  editor.suppressReloadWarning = true;
                  // reloading the current page will a) bump them to the sign in page and b) return them back to this
                  // same page after they sign in. when they return after sign-in, their collaborative session will be
                  // reloaded from ckeditor (e.g. pulling up whatever their last changes were) and it will be saved
                  // right off the bat.
                  location.reload();
                })
              }

              countDownThenRetry(secondsRemaining - 1, 1, signedOut);

              // once the countdown has completed ..
            } else {
              autosaveDebug("🛠 Submitting form.");

              // update the saving state indicator.
              $(".collaboration__topbar-autosave-saving .autosave-description").html("Saving...")

              // this results in a `ajax:success` or `ajax:error` event.
              saveRailsForm();
            }
          }, delayInSeconds * 1000);
        }

        // react to the rails-ujs form submission success or failure.
        $(form).on('ajax:success', afterSave);
        $(form).on('ajax:error', afterError);

        // this is our first attempt to save. usually this succeeds.
        autosaveDebug("🛠 Submitting form.");

        // update the saving state indicator.
        $(".collaboration__topbar-autosave").addClass("saving");
        $(".collaboration__topbar-autosave").removeClass("saved");

        // this results in a `ajax:success` or `ajax:error` event.
        saveRailsForm();
      })
    }

    function handleCommentSelection() {
      const selectedComment = $('.ck-comment-marker--active');

      if (selectedComment.length > 0) {
        const topOffset = selectedComment.offset().top - 350;
        $('html, body').animate({ scrollTop: topOffset }, 'slow');
      }
    }

    function handleCopyUrlButton() {
      const triggerElement = document.querySelector('.ck-collaboration__share i');
      const inputElement = document.getElementById('demo-collaboration-url');

      if (!inputElement || !triggerElement) return;

      inputElement.value = window.location;
      inputElement.addEventListener('click', () => inputElement.select());
      triggerElement.addEventListener('click', (e) => {
        e.preventDefault();
        copyFromInput(inputElement, triggerElement);
      });
    }

    function copyFromInput(inputElement, triggerElement) {
      let buttonTimeout;

      inputElement.select();
      document.execCommand('copy');

      triggerElement.textContent = 'Copied!';
      clearTimeout(buttonTimeout);
      buttonTimeout = setTimeout(() => {
        triggerElement.textContent = '';
      }, 1500);
    }
  }
};
