import { ApplicationController } from "./application_controller"
const debounce = require("lodash/debounce");

export default class extends ApplicationController {
  static get targets() {
    return ['clear', 'input']
  }

  initialize() {
    this.searchCallback = null;
    this.trigger = debounce(this.trigger, 1000).bind(this);
  }

  inputTargetConnected() {
    this.inputTarget.addEventListener('keyup', () => {
      this.showClear();
      this.trigger();
    })
  }

  registerSearchCallback(callback) {
    this.searchCallback = callback;
  }

  showClear() {
    if (!this.clearTarget) return;

    if (this.inputTarget.value) {
      this.clearTarget.classList.remove('d-none');
    } else {
      this.clearTarget.classList.add('d-none');
    }
  }

  trigger() {
    this.searchCallback(this);
  }

  clear() {
    this.inputTarget.value = '';
    this.clearTarget.classList.add('d-none');
    this.searchCallback(this);
  }
}
