import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['distributeWeightInput', 'weightInput', 'submitButton', 'totalWeight', 'multiplier'];
  }

  static get values() {
    return {
      maximumScore: Number,
    };
  }

  connect() {
    if (this.hasWeightInputTarget) {
      this.distributeWeight();
    }

    this.weightInputTargets.forEach((input) => {
      input.addEventListener('keypress', (event) => {
        if (!this.isNumeric(event.key)) {
          event.preventDefault();
          return false;
        }
      })
    })
  }

  distributeWeight() {
    if (this.distributeWeightEnabled()) {
      this.weightInputTargets.forEach((input) => {
        const maximumValue = input.dataset.maximumValue;
        input.value = ((maximumValue / this.maximumScoreValue) * 100).toFixed(2);
      })

      this.submitButtonTarget.disabled = false;
      this.totalWeightTarget.innerHTML = '100.00%';
      this.totalWeightTarget.classList.remove('text-danger');
      this.totalWeightTarget.classList.add('text-success');

      this.calculatePercentageDistributionMultiplier();

    } else {
      this.calculateTotalWeight();
    }
  }

  distributeWeightEnabled() {
    let distributeWeightEnabled = false;
    this.distributeWeightInputTargets.forEach((input) => {
      if (input.value == 'true' && input.checked) {
        distributeWeightEnabled = true;
      }
    })

    return distributeWeightEnabled;
  }

  weightInputChanged() {
    this.disableDistributeWeight();
    this.calculateTotalWeight();
    this.calculatePercentageDistributionMultiplier();
  }

  calculatePercentageDistributionMultiplier() {
    this.multiplierTargets.forEach((multiplier, index) => {
      const input = this.weightInputTargets[index];
      const weight = input.value || 0;
      const maximumValue = input.dataset.maximumValue;
      multiplier.innerHTML = ((weight / 100) * this.maximumScoreValue / maximumValue).toFixed(2);
    })
  }

  calculateTotalWeight() {
    let totalSum = 0;

    this.weightInputTargets.forEach((input) => {
      totalSum += parseFloat(input.value.replace('%', '') || 0);
    })

    this.submitButtonTarget.disabled = !this.distributeWeightEnabled() && totalSum.toFixed(2) !== '100.00'
    this.totalWeightTarget.innerHTML = totalSum.toFixed(2);

    if (totalSum.toFixed(2) === '100.00') {
      this.totalWeightTarget.classList.remove('text-danger');
      this.totalWeightTarget.classList.add('text-success');
    } else {
      this.totalWeightTarget.classList.add('text-danger');
      this.totalWeightTarget.classList.remove('text-success');
    }
  }

  disableDistributeWeight() {
    this.distributeWeightInputTargets.forEach((input) => {
      input.checked = input.value !== 'true';
    })
  }

  conditionStatusChanged(event) {
    const target = event.target;
    if (target.value === 'Pass') {
      target.classList.remove('text-danger');
      target.classList.add('text-success');
    } else {
      target.classList.remove('text-success');
      target.classList.add('text-danger');
    }
  }

  isNumeric(value) {
    return (!isNaN(value) && parseFloat(Number(value)) == value && !isNaN(parseFloat(value, 10))) || value == '.';
  }
}
