import {
  ClassicEditor as ClassicEditorBase
} from 'ckeditor5'

import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';

import { cloneDeep } from 'lodash';
import { ckeditorPluginList, ckeditorToolbar } from './v1.2/shared';

export default class SingleLineEditor extends ClassicEditorBase {
  static create(sourceElementOrData, config = {}) {
    return super.create(sourceElementOrData, config)
      .then(editor => {
        // Strip all html tags from pasted/dropped content and just insert a single text string
        editor.editing.view.document.on('clipboardInput', (evt, data) => {
          const dataTransfer = data.dataTransfer
          const clipboard = editor.plugins.get('Clipboard')
          let content = dataTransfer.getData('text/plain')
          content = content.replace(/\r?\n|\r/g, " ")
          content = plainTextToHtml(content)
          content = editor.data.htmlProcessor.toView(content)
          clipboard.fire('inputTransformation', {
            content,
            dataTransfer
          })
        })
        // Disable these commands if we are creating a single line editor:
        let disabledCommands = [
          "heading",
          "fontSize",
          "pageBreak",
          "horizontalLine",
          "insertTable",
          "bulletedList",
          "numberedList",
          "imageUpload",
          "imageInsert",
          "shiftEnter",
          "enter",
        ]
        Array.from(editor.commands).forEach(function(command) {
          let commandTitle = command[0]
          if (disabledCommands.includes(commandTitle)) {
            command[1].forceDisabled()
          }
        })

        return editor;
      });
  }
}

SingleLineEditor.builtinPlugins = Array.from(ckeditorPluginList);

// Editor configuration.
SingleLineEditor.defaultConfig = {
  toolbar: cloneDeep(ckeditorToolbar),
  licenseKey: process.env.CKE_LICENSE_KEY,
  language: 'en',
  list: {
    properties: {
      startIndex: true
    }
  }
};
