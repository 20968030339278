function enableBulkNestedFields($scope) {
  $scope.on('cocoon:before-insert', function(event, insertedItem, originalEvent) {
    let $target = $(originalEvent.target)
    if ($target.hasClass('add_bulk_fields')) {
      event.preventDefault();
      $target.removeClass('add_bulk_fields');
      let $source = $($target.data('source'));
      let values = $source.val().toString().split(/\r?\n/)
      let insertionTemplate = $target.data('association-insertion-template');

      for (let i = 0; i < values.length; i++) {
        let value = values[i];
        let newInsertionTemplate = insertionTemplate.replace('{{value}}', value);
        $target.data('association-insertion-template', newInsertionTemplate);
        originalEvent.target.click();
      }

      $source.val('');
      $target.data('association-insertion-template', insertionTemplate);
      $target.addClass('add_bulk_fields');
    }
  });
}

$(document).on('turbolinks:load', function() {
  enableBulkNestedFields($(document.body));
})
