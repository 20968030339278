require("spectrum-colorpicker/spectrum.css");
import 'spectrum-colorpicker';
import ColorPallete from '../grid/components/ColorPallete'

$(document).on('turbolinks:load', function () {
  $(".color-picker").spectrum({
    allowEmpty: true,
    showPaletteOnly: true,
    togglePaletteOnly: true,
    togglePaletteMoreText: 'more',
    togglePaletteLessText: 'less',
    palette: ColorPallete,
    change: function (color) {
      $("#hex-code").text("hex code: " + color.toHexString());
    }
  });
})
