import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['trigger'];
  }

  triggerTargetConnected(e) {
    const action = e.dataset.datatableAction
    switch(action) {
      case 'refresh':
        this.refresh();
        break;
      case 'alert':
        this.alert(e);
        break;
    }

    this.triggerTarget.remove();
  }

  refresh() {
    const datatableId = this.element.id;
    this.element.dispatchEvent(
      new CustomEvent(`${datatableId}:refresh`)
    )
  }

  alert(e) {
    alert(e.dataset.datatableMessage);
  }
}