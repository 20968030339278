import { DatatablesBase } from "../base";

export default class extends DatatablesBase {
  static get orderConfig() {
    return [[0, 'desc']];
  }

  static get languageConfig() {
    return {
      "sLengthMenu": "Showing_MENU_",
      "info": "of _TOTAL_ Editor Init Check Records",
      "oPaginate": {
        "sFirst": "",
        "sLast": ""
      }
    };
  }

  static get searchConfig() {
    return { searching: false }
  }

  ajaxConfig() {
    return {
      ajax: {
        url: this.table.dataset.source,
        method: 'GET',
        data: (d) => {
          const formObject = $('#ckeditor_init_checks-datatable-filter-form').serializeObject();

          for (let key in formObject) {
            if (formObject[key] === '') continue;

            d[key] = formObject[key];
          }
          return d;
        }
      }
    }
  }

  static get columnConfig() {
    return [
      {
        data: 'id',
        render: (datum) => {
          return `<a href="/developers/ckeditor/initialization_checks/${datum}" target="_blank">${datum}</a>`
        }
      },
      {
        data: 'source_name'
      },
      {
        data: 'attempts'
      },
      {
        data: 'status'
      },
      {
        data: 'result'
      },
      {
        data: 'finished_at'
      }
    ]
  }

}
