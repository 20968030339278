import { Controller } from "@hotwired/stimulus";
import hljs from 'highlight.js'
import 'highlight.js/scss/a11y-light.scss'

export default class extends Controller {
  connect() {
    hljs.configure({ languages: ['ruby', 'erb', 'python', 'javascript', 'bash'] })
    this.highlightCodeBlocks()
  }

  highlightCodeBlocks() {
    this.element.querySelectorAll('pre code').forEach((el) => {
      hljs.highlightElement(el)
    })
  }
}