
// This function removes a <tr> element as soon as the delete button is clicked and confirmed
// We do this to make the app seem faster
// If something goes wrong with the delete request on the server, add the row back in and show an alert
// To use this function add data-remove="fadeout" to the delete button within a <tr>

function enableRemoveButtons(scope) {
  $(scope).find("tr [data-method='delete'][data-remove]")
    .on("ajax:send", function(e) {
      if( e.target.dataset.remove == "fadeout" ) {
        $(e.target).closest("tr").fadeOut()
      }
    })
    .on("ajax:error", function(e) {
      $(e.target).closest('tr').fadeIn()
      alert("There was an error removing that item.  It has been logged to the console")
      console.log(e)
    })
}


$(document).on('turbolinks:load', function() {
  enableRemoveButtons(document.body)
})

$(document).on('sprinkles:update', function(event) {
  enableRemoveButtons(event.target);
})