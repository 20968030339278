import { attachDocumentListener } from "../../attach_document_listener";

var ARIA_ATTRIBUTE_PATTERN = /^aria-[\w-]*$/i
var defaultWhitelist = {
  // Global attributes allowed on any supplied element below.
  '*': ['class', 'dir', 'id', 'lang', 'role', ARIA_ATTRIBUTE_PATTERN],
  a: ['target', 'href', 'title', 'rel', 'data-method', 'data-confirm', 'data-remote'],
  area: [],
  b: [],
  br: [],
  col: [],
  code: [],
  div: [],
  em: [],
  hr: [],
  h1: [],
  h2: [],
  h3: [],
  h4: [],
  h5: [],
  h6: [],
  i: [],
  img: ['src', 'srcset', 'alt', 'title', 'width', 'height'],
  li: [],
  ol: [],
  p: [],
  pre: [],
  s: [],
  small: [],
  span: [],
  sub: [],
  sup: [],
  strong: [],
  u: [],
  ul: []
}

const ESCAPE_KEY = 'Escape';

const closeOnEscape = (event) => {
  if(event.key === ESCAPE_KEY) {
    $('[data-toggle="popover"').popover('hide');
  }
}

const hideOnClickOutside = (event) => {
  if ($(event.target).data('toggle') !== 'popover'
      && $(event.target).parents('.popover.in').length === 0) {
      $('[data-toggle="popover"]').popover('hide');
  }
}

export default function enablePopover() {
  $('[data-toggle="popover"]').popover({
    html : true,
    whiteList: defaultWhitelist
  }).on('show.bs.popover', function(e){
    $('[data-toggle="popover"').not(e.target).popover('hide');
    $('.popover').remove();
  });

  /* this ensures the listener is only applied once */
  attachDocumentListener('click', hideOnClickOutside, 'bsPopoverHideOnClickOutside');
  attachDocumentListener('keyup', closeOnEscape, 'bsPopoverCloseOnEscape');
  /* Using this specific listener for the links that have remote true, where the click event doesn't work. */
  attachDocumentListener('ajax:success', hideOnClickOutside, 'bsPopoverCloseOnLinkClick');
  /* Using this specific listener for the destroy link because the once
  above are not working due to confirmation message and the remote: true */
  $('.confirmation_popover').on('click', hideOnClickOutside);
}

$(document).on('turbolinks:load', function () {
  enablePopover();
});

$(document).on('sprinkles:update', function() {
  enablePopover();
})
