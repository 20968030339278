import {
  EditorWatchdog
} from 'ckeditor5'

import DecoupledEditor from './decoupled_editor';
import { afterError, afterSave, saveRailsForm } from './helpers/collaborative_editor_callbacks'

import {
  configureTrackChangesAndSuggestions,
  configureReadOnly,
  configureComments,
  unloadFunction,
  handleUrlChange
} from './helpers/collaborative_editor_helpers';

import {
  setAutosaveStatus,
  handleCopyUrlButton,
  handleSidebarElementClick,
  loadOnSaveEvent,
  addToolbarContainer,
  handleSidebarResize
} from './helpers/utilities';

export default class CollaborativeEditorWatchdog extends EditorWatchdog {
  constructor(Editor, watchdogConfig = {
    crashNumberLimit: 3,
    minimumNonErrorTimePeriod: 10000
  }) {
    super(Editor, watchdogConfig);

    const watchdog = this;

    this.on('error', (_, { error }) => {
      if (error.message.match("Document.+does not exist") || error.message.match("cloudservices-reconnection-error")) {
        alert("We need to refresh your browser to update to the latest content for this section");
        document.getElementsByClassName("ckeditor-flush-link")[0].click();
      }
    });

    const loaderElement = document.querySelector('.ck-collaboration .ck-loader');
    this.setCreator((element, config) => {
      // Customize autosave.
      config.autosave = Object.assign({}, {
        save(editor) {
          return saveData();
        },
        waitingTime: 3000
      }, config.autosave || {});

      config.list = {
        properties: {
          startIndex: true
        }
      }

      return DecoupledEditor.create(element, config)
        .then(editor => {
          editor['suppressReloadWarning'] = false;
          const outputArea = element.parentNode.querySelector('input.ckeditor-document');
          const outputVersionNumber = element.parentNode.querySelector('input.ckeditor-document-version');
          loadOnSaveEvent(editor, outputArea, outputVersionNumber, saveData);
          handleSidebarElementClick(editor);
          handleSidebarResize(editor);

          editor.on('change:isReadOnly', (evt, property, is) => {
            if (is) {
              console.log('readOnlyMode')
              setAutosaveStatus('offline');
            } else {
              // Wait 1 second before attempting to do a save
              setTimeout(
                function () {
                  setTimeout(function () {
                    setAutosaveStatus('saving');
                    outputArea.value = editor.getData();
                    outputVersionNumber.value = editor.plugins.get('RealTimeCollaborationClient').cloudDocumentVersion;
                    saveData();
                  }, 1000);
                }, 1000
              )
            }
          });

          // on page refresh or navigation, prompt the user to confirm if they want to leave the page
          editor.ui.view.listenTo(window, 'beforeunload', (evt, domEvt) => unloadFunction(evt, domEvt, editor));
          editor.ui.view.listenTo(window, 'turbolinks:before-visit', (evt, domEvt) => unloadFunction(evt, domEvt, editor));
          window.addEventListener('popstate', (evt) => handleUrlChange(evt, editor));

          const commentsRepository = editor.plugins.get('CommentsRepository');
          commentsRepository.on('resolveCommentThread', () => {
            saveData();
          });

          commentsRepository.on('reopenCommentThread', ( evt, data ) => {
            saveData();
          } );


          // Set a custom container for the toolbar.
          addToolbarContainer(editor, loaderElement)
          handleCopyUrlButton();

          const saveTimerId = setInterval(function () {
            // Trigger a save every 5 minutes. This is to protect against us making an update to ckeditor
            // and a user leaving a tab open for a long period.
            saveData();
          }, 300000);

          editor.once('destroy', () => window.clearInterval(saveTimerId));

          configureTrackChangesAndSuggestions(config, editor);
          configureReadOnly(config, editor);
          configureComments(config, editor);

          // this is used for triggering an event that is captured by the stimulus controller.
          // The stimulus controller will then handle generating the suggestion content through the editor
          // and submitting the form.
          const event = new CustomEvent('ckeditor:collaborative-editor:initialized', { detail: { editor: editor } })
          element.dispatchEvent(event)

          return editor;
        })
        .catch(error => {
          document.querySelector('.editor-error').style.display = 'block';
          document.querySelector('.ck-collaboration').style.display = 'none';
          console.log(error);

          // this event is useful for the ci bot - it let's the bot know the editor is failing to start
          // so that the ci bot will stop waiting and move on
          const event = new CustomEvent('ckeditor:initialization-failure', { detail: { error: error } });
          document.dispatchEvent(event);
        });
    });

    this.setDestructor(editor => {
      // It is possible for the destructor method to be called on the watchdog before the editor is actually set.
      // This happens if the user navigates away from the page before the editor finishes loading
      if (editor != null && typeof editor !== 'undefined' && editor.state != 'destroyed') {
        editor
          .destroy()
          .then(() => {
            window.removeEventListener('popstate', handleUrlChange);
            const ckToolbar = document.querySelector('.ck-toolbar');
            const ckPresenceList = document.querySelector('.ck-presence-list');
            if (ckToolbar) { ckToolbar.remove() }
            if (ckPresenceList) { ckPresenceList.remove(); }
          });
      }
    });

    function saveData() {
      if (!watchdog.editor) { return false; }

      const editor = watchdog.editor;
      const form = document.querySelector('form.ckeditor-autosave-form');
      const defaultLockID = Symbol('default-readonly-lock');

      return new Promise(resolve => {

        if (editor.isReadOnly) {
          resolve();
          setAutosaveStatus('offline');
          return false;
        }

        form.addEventListener('ajax:success', () => afterSave(editor, defaultLockID, form, resolve));
        form.addEventListener('ajax:error', (event) => afterError(event, defaultLockID, editor, form));

        const elements = document.querySelectorAll('.collaboration__topbar-autosave');
        elements.forEach(element => {
          element.classList.add('saving');
          element.classList.remove('saved');
        });

        saveRailsForm(editor);
      })
    }
  }
};
