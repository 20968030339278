import { RevealOperator } from "../reveal_operator";

/**
 * Reveal Operator that will reveal itself if the content is within the range of the first and last elements of its values.
 * It will provide warnings if the values array length does not equal 2.
 *
 * The checks here are inclusive of the values provided, meaning if [1,5] are the provided values it will return true if the values are 1,2,3,4 or 5.
 *
 * A use case example would be a numeric input that has a floor and ceiling range. Say you wanted to show a warning if the user puts a value in that is
 * not within the safe/expected range:
 *
 * <div data-controller='reveal' data-reveal-operator='not-in-range' data-reveal-value="[1, 5]">
 *  <this content is shown if the user puts in a value less than or equal to 0 or greater than or equal to 6>
 * </div>
 */
export class InRange extends RevealOperator {
  sanitize() {
    this.values = Array.isArray(this.values) ? this.values : JSON.parse(this.values);
    this.values = this.values.map(v => Number(v));
  }

  validate() {
    if (!Array.isArray(this.values)) {
      this.warn('requires an array value')
    } else if (this.values.length > 2) {
      this.warn('received an array of length greater than 2. Only first and second values will be used.');
    } else if (this.values.length < 2) {
      this.warn('requires an array length of 2 to work as intended');
    }

    if (Number.isNaN(this.values[0]) || Number.isNaN(this.values[1])) {
      this.warn(`requires numeric inputs and one of the values in ${this.values} is NaN`)
    }
  }

  _valueCheck(formdata) {
    const formvalue = Number(this._manyFormValues(formdata).find(v => !Number.isNaN(Number(v))));
    if (Number.isNaN(formvalue)) return false;
    return formvalue >= this.values[0] && formvalue <= this.values[1];
  }
}