function enableDatatables($scope){
  $scope.find(".datatable").DataTable({
    retrieve: true,
    stateSave: true,
  })
}

$(document).on('turbolinks:load', function() {
  enableDatatables($('body'));
})

$(document).on('sprinkles:update', function(event) {
  enableDatatables($(event.target));
})
