import { getPath, textColorByContrast } from '../../../utils/utils'

class TagEditor {
  init(params) {
    const id = params.data.tagId
    this.params = params
    let tagColumnData = TagEditor.HtmlToData(params.value)
    this.verifiedTagPath = params.colDef.gridTagPath.replace('/id', `/${id}`) // TODO: response_id -> id

    this.container = document.createElement('div')
    this.container.tabIndex = "0"; // to allow the div to capture keypresses
    this.container.style = "min-width: 200px; text-align:center; display:inline-block; outline:none";

    this.select = document.createElement('select')
    this.select.setAttribute('multiple', 'multiple')
    this.select.className = "form-control select2 accepts-new select2-hidden-accessible"
    this.select.style = 'width: 100%'
    this.select.innerHTML = tagColumnData.map((tag) => {
      const { id, text, color } = tag
      return `<option selected="selected" value="${id}:${color}">${text}</option>`
    }).join('')

    this.container.appendChild(this.select)
  }

  // gets called once when grid ready to insert the element
  getGui() {
    return this.container;
  }

  afterGuiAttached() {
    // this.container.focus()

    $(this.select).select2({
      tags: true,
      tokenSeparators: [','],
      placeholder: 'Select an option',
      allowClear: true,
      ajax: {
        delay: 250,
        url: this.verifiedTagPath,
        processResults: function (data) {
          return {
            results: data.valid_tags && data.valid_tags.map((tag) => {
              return { id: [tag.id, tag.color].join(':'), text: tag.text }
            })
          };
        }
      }
    })


    $(this.select).select2('open')

    const currentValue = () => $(this.select).select2('data').map((x) => [x.id, x.text])
    this.tag = currentValue()

    $(this.select).on('change select2:close', (e) => {
      this.tag = currentValue()
      this.params.stopEditing()
    })
  }

  getValue() {
    if (!this.tag) return;

    let parsed = this.tag.map((tag) => {
      const [id, color] = tag[0].split(':')
      const text = tag[1]
      return { id, color, text }
      })

    return TagEditor.dataToHtml(parsed);
  }

  // any cleanup we need to be done here
  destroy() {
    $(this.select).select2('close');
  }

  isPopup() {
    return true;
  }

  // Helper methods
  static dataToHtml(tags, setDefaultColor = true) {
    const toHtml = (tag) => {
      let { id, color, text } = tag
      color = setDefaultColor && !color ? '#047af8' : color
      return `<a data-id="${id}"
            class="badge filter-option"
            data-color="${color}"
            style="margin-right: 3px; background: ${color}; color: ${textColorByContrast(color)};">${ text }</a>`
    }

    if (Array.isArray(tags)) {
      return tags.map((tag) => toHtml(tag)).join(' ')
    } else {
      return toHtml(tags)
    }
  }

  static HtmlToData(html) {
    return $.parseHTML(html)
    .filter((el) => el.dataset)
    .map((tag) => {
      let { id, color } = tag.dataset;
      color = color === 'undefined' ? undefined : color

      return {
        id: id, text: tag.innerText, color: color
      }
    })
  }
}

export default TagEditor;
