// Used for the multi-root editor configuration.
import { cloneDeep } from 'lodash';
import {
  configuration as defaultConfiguration,
  newConfiguration,
  forceEnabledCommandsForTrackChanges,
} from '../shared';
import { saveRailsForm } from './collaborative_editor_callbacks';

function getMention(queryText, source) {
  return new Promise(resolve => {
    setTimeout(() => {
      const itemsToDisplay = source
        .filter(isItemMatching)
        .slice(0, 100);

      resolve(itemsToDisplay);
    }, 100);
  });

  function isItemMatching(item) {
    const searchString = queryText.toLowerCase();
    return (
      item.label.toLowerCase().includes(searchString) ||
      item.id.toLowerCase().includes(searchString)
    );
  }
}

export function MentionCustomization(editor) {
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'span',
      key: 'data-mention',
      classes: 'mention',
      attributes: {
        'data-model': true,
        'data-id': true,
        'data-label-method': true,
        'data-label': true
      }
    },
    model: {
      key: 'mention',
      value: viewItem => {
        const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          modelName: viewItem.getAttribute('data-model'),
          modelId: viewItem.getAttribute('data-id'),
          labelMethod: viewItem.getAttribute('data-label-method'),
          label: viewItem.getAttribute('data-label'),
          dataToggle: viewItem.getAttribute('data-toggle'),
          dataTitle: viewItem.getAttribute('data-original-title'),
        });
        return mentionAttribute;
      }
    },
    converterPriority: 'high'
  });
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, { writer }) => {
      if (!modelAttributeValue) {
        return;
      }
      setTimeout(function () {
      }, 500);

      return writer.createAttributeElement('span', {
        'class': 'mention',
        'data-model': modelAttributeValue.modelName,
        'data-id': modelAttributeValue.modelId || crypto.randomUUID(),
        'data-label': modelAttributeValue.label,
        'data-label-method': modelAttributeValue.labelMethod,
        'data-toggle': modelAttributeValue.dataToggle,
        'data-original-title': modelAttributeValue.dataTitle,
        'data-mentionings--render-target': 'mentioning',
        'data-container': '.element-box',
      }, {
        priority: 20,
        id: modelAttributeValue.uid
      });
    },
    converterPriority: 'high'
  });
}

export function customItemRenderer(item) {
  function createElement(tag, ...classes) {
    const element = document.createElement(tag);
    element.classList.add(...classes);
    return element;
  }

  const buttonElement = createElement('button', 'ck', 'ck-button', 'ck-button_with-text');
  const itemElement = createElement('span', 'custom-item');
  itemElement.id = `mention-list-item-id-${item.modelId}`;
  itemElement.textContent = `${item.fullLabel} `;

  const usernameElement = createElement('span', 'custom-item-badge', item.type.toLowerCase());
  usernameElement.textContent = item.type;

  itemElement.appendChild(usernameElement);
  buttonElement.appendChild(itemElement);

  return buttonElement;
}

export function addFeedIfAvailable(feeds, usersFeeds, marker, dataKey, itemRenderer = null) {
  if (window[dataKey]) {
    const feedObject = {
      marker: marker,
      feed: function (queryText) { return getMention(queryText, window[dataKey]) }
    };
    if (itemRenderer) {
      feedObject.itemRenderer = itemRenderer;
    }

    feeds.push(feedObject);
    if (marker === '@') {
      usersFeeds.push(feedObject);
    }
  }
}

export function generateCloudServiceConfig(element) {
  const newEditor = document.getElementsByClassName('new-ckeditor');
  let config = cloneDeep(defaultConfiguration);
  if (newEditor) {
    config = cloneDeep(newConfiguration);
  }
  config.cloudServices = {
    tokenUrl: element.dataset.ckeditortokenurl,
    uploadUrl: element.dataset.ckeditoruploadurl,
    webSocketUrl: element.dataset.ckeditorwebsocketurl,
    documentId: element.dataset.ckeditordocumentid,
  };
  config.mention = {
    feeds: feeds,
    dropdownLimit: 20
  };

  return config;
}

export const configureReadOnly = (config, editor) => {
  if (config.readOnlyMode) {
    editor.enableReadOnlyMode('readOnly');
  }
}

export const configureComments = (config, editor) => {
  if (config.disableComments) {
    const comments = editor.commands.get('addCommentThread');
    comments.forceDisabled('comments');
  }
};

export const configureTrackChangesAndSuggestions = (config, editor) => {
  const trackChangesCommand = editor.commands.get('trackChanges');

  trackChangesCommand.on('execute', (eventInfo) => {
    if (eventInfo.source.value) {
      onTrackChangesEnabled(editor);
    }
  })

  if (config.trackChangesOn) {
    editor.execute('trackChanges');
    trackChangesCommand.forceDisabled('disableTcButton');

    if (config.disableSuggestion) {
      configureSuggestions(editor);
    }
  }

  if (config.disableTrackChangesToggle) {
    trackChangesCommand.forceDisabled('disableTcButton');
  }
}

export function unloadFunction(evt, domEvt, editor) {
  const suppressReloadWarning = editor.suppressReloadWarning;
  if (editor.plugins.get('PendingActions').hasAny && !suppressReloadWarning) {
    let userForceLeave = false;

    if (domEvt.type === 'turbolinks:before-visit' || domEvt.type === 'popstate') {
      userForceLeave = confirm("There are unsaved changes. Are you sure you want to leave the page?");
      saveRailsForm(editor);
    }

    if (userForceLeave) {
      document.querySelector('.ck-collaboration').style.display = 'none';
    } else {
      domEvt.preventDefault();
      domEvt.returnValue = true;
    }
  } else {
    document.querySelector('.ck-collaboration').style.display = 'none';
  }
}

export function handleUrlChange(evt, editor) {
  if (!editor) { return false; }

  const suppressReloadWarning = editor.suppressReloadWarning;
  if (editor.plugins.get('PendingActions').hasAny && !suppressReloadWarning) {
    unloadFunction(null, evt, editor);
  }
}


const configureSuggestions = (editor) => {
  editor.commands.get('acceptSuggestion').forceDisabled('userNoRights');
  editor.commands.get('acceptAllSuggestions').forceDisabled('userNoRights');
  editor.commands.get('discardSuggestion').forceDisabled('userNoRights');
  editor.commands.get('discardAllSuggestions').forceDisabled('userNoRights');
}

const onTrackChangesEnabled = (editor) => {
  for (const command of forceEnabledCommandsForTrackChanges) {
    editor.commands.get(command).clearForceDisabled('TrackChangesCommand');
  }
}
