import { Controller } from '@hotwired/stimulus';

// Used to select or deselect all options in a <select>
export default class extends Controller {
  static get targets() {
    return [
      'select',
      'selectAllButton',
      'deselectAllButton'
    ];
  }

  selectAll(e) {
    e.preventDefault();
    this._updateOptions(true);
  }

  deselectAll(e) {
    e.preventDefault();
    this._updateOptions(false);
  }

  _triggerChange() {
    this.selectTarget.dispatchEvent(new Event('change', {
      bubbles: true,
      cancelable: true
    }));
  }

  _updateOptions(selected) {
    const options = this.selectTarget.options;

    for (let i = 0; i < options.length; i++) {
      options[i].selected = selected;
    }

    this._triggerChange();
  }
}
