DocumentSettings = {
  confirmDocumentComplete: event => {
    const completeButton = event.target;
    const container = completeButton.closest('.toggle-btn-wrap');
    const currentDocStatus = container.querySelector('[checked="checked"]');
    const message = `
      Are you sure you want to complete the Document?
      This will "complete" all sections within this Document as well.
  `;

    // if they click cancel
    if (!confirm(message)) {
      // re-check the current document status
      currentDocStatus.checked = true;
    }
  }
};


$(document).on('turbolinks:load', () => {
  $('#document-complete').on('click', DocumentSettings.confirmDocumentComplete);
});