import { Controller } from "@hotwired/stimulus";

// data-controller='nagigation--tab--component'
export default class extends Controller {
  static get targets() {
    return ['tab'];
  }

  static get values() {
    return {
      scope: {
        type: String,
        default: 'tab'
      }
    }
  }

  activate({ params: { id } }) {
    const tabRadio = this.element.querySelector(`#${id}`);
    tabRadio.checked = true;
  }

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get(this.scopeValue);
    if (id) {
      this.activate({ params: { id: id } })
    } else {
      this.tabTargets[0].checked = true;
    }

    // The commented out code here can be useful if we want to support back/fwd button navigation moving
    // between previous tab states. The current implmentation is using replaceState, so if the user clicks
    // back after navigating between many tabs they are taken to the previous page they were on, not the previous
    // tab. However, using pushState means that the back/fwd buttons will navigate users to their previous tab
    // selection instead.

    // this._onPopState = () => {
    //   this._handleUrlParams();
    // }

    // window.addEventListener('popstate', this._onPopState)
  }

  disconnect() {
    // window.removeEventListener('popstate', this._onPopState)
  }

  _handleUrlParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get(this.scopeValue);
    if (id) {
      this.activate({ params: { id: id } })
    }
  }

  tabTargetConnected(tab) {
    tab.addEventListener('click', () => {
      const urlParams = new URLSearchParams(window.location.search)
      urlParams.delete(this.scopeValue)
      urlParams.set(this.scopeValue, tab.id)
      const href = [window.location.href.split('?')[0], urlParams.toString()].join('?')
      window.history.replaceState(window.history.state, '', href)
    })
  }
}