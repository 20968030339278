import {
  Alignment,
  Autoformat,
  Autosave,
  BlockQuote,
  Bold,
  CKFinderUploadAdapter,
  CloudServices,
  CodeBlock,
  EasyImage,
  Essentials,
  FindAndReplace,
  Font,
  Heading,
  Highlight,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  ListProperties,
  Mention,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline
} from 'ckeditor5'

import {
  Comments,
  PresenceList,
  RealTimeCollaborativeComments,
  RealTimeCollaborativeEditing,
  RealTimeCollaborativeTrackChanges,
  TrackChanges,
  TrackChangesData
} from 'ckeditor5-premium-features';

import { WProofreader } from '@webspellchecker/wproofreader-ckeditor5';
import { MentionCustomization } from '../account/ckeditor/shared';

// Determines the toolbar for the editor. Order does matter here.
export const toolbar = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'bulletedList',
  'numberedList',
  'comment',
  'trackChanges',
  '|',
  'horizontalLine',
  'pageBreak',
  '|',
  'fontSize',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'insertTable',
  'imageUpload',
  '|',
  'outdent',
  'indent',
  '|',
  'highlight',
  'findAndReplace',
  '|',
  'alignment',
  'removeFormat',
  '|',
  'undo',
  'redo',
  '|',
  'blockQuote',
  'subscript',
  'superscript',
  'link',
  'wproofreader',
  'codeBlock'
];

// All of the plugins included in document view editor.
export const plugins = [
  Essentials,
  Autoformat,
  Bold,
  BlockQuote,
  Italic,
  Strikethrough,
  Underline,
  Subscript,
  Superscript,
  Heading,
  Link,
  ListProperties,
  Paragraph,
  Mention,
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
  TableColumnResize,
  Indent,
  IndentBlock,
  RemoveFormat,
  Font,
  Highlight,
  HorizontalLine,
  PasteFromOffice,
  Alignment,
  PageBreak,
  Autosave,
  CloudServices,
  RealTimeCollaborativeEditing,
  PresenceList,
  RealTimeCollaborativeComments,
  RealTimeCollaborativeTrackChanges,
  CKFinderUploadAdapter,
  EasyImage,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageResize,
  ImageTextAlternative,
  FindAndReplace,
  Comments,
  TrackChanges,
  TrackChangesData,
  WProofreader,
  CodeBlock,
  MentionCustomization,
  TableCaption
];
