require("dragula/dist/dragula.min.css")
import { refreshPageBase } from './page_refresh'

import dragula from 'dragula';

window.enableSortable = function($scope) {
  setTimeout(function() {
    var selector = '[data-reorder]';
    var $reorderable = $scope.find(selector).addBack(selector);
    if ($reorderable.length == 0) {
      return;
    }

    $reorderable.each(function (index, container) {

      var $container = $(container);

      if ($container.hasClass('draggable-enabled')) {
        return;
      } else {
        $container.addClass('draggable-enabled');
      }

      // enable drag-and-drop reordering.
      var dragulaObj = dragula([container], {
        moves: function(el, container, handle) {
          var $handles = $(el).find('.reorder-handle')
          if ($handles.length) {
            return !!$(handle).closest('.reorder-handle').length
          } else {
            if (!$(handle).closest('.undraggable').length) {
              return $(handle).closest('[data-reorder]')[0] == container;
            } else {
              return false;
            }
          }
        },
        accepts: function (el, target, source, sibling) {
          if ($(sibling).hasClass('undraggable') && $(sibling).prev().hasClass('undraggable')) {
            return false;
          } else {
            return true;
          }
        },
      }).on('drop', function (el) {

        // save order here.
        saveSortOrder($container);
        if (!$container.hasClass('responses-reordering')) { $(el).addClass('refreshing'); }

      }).on('over', function (el, container) {

        // deselect any text fields, or else things go slow!
        $(document.activeElement).blur();

      });
    });
  }, 500);

  function saveSortOrder($container) {
    var idsInOrder = $container.find('> *').map(function(index,element) { return parseInt($(element).attr('data-id')); }).toArray();

    if ($container.attr('data-reorder') == "") {
      // Sort fields using js, which will be saved on form submissino
      let $nestedFields = $container.find('.nested-fields');

      if ($nestedFields.length) {
        $nestedFields.each((index, field) => {
          let $field = $(field);
          let $input = $field.find('input[type="hidden"].sort-order')
          $input.val(index.toString());
        })
      }
    } else if ($container.hasClass('responses-reordering')) {
      updateResponsesSorting(idsInOrder)
    } else {
      $.post($container.attr('data-reorder'), {ids_in_order: idsInOrder}, function() {
        if ($container.closest('.opened-modally').length) {
          refreshModalBase();
        } else {
          var baseSelector = $container.attr('data-target') || '.modal-base';
          refreshPageBase(baseSelector);
        }
      });
    }
  }

  const updateResponsesSorting = (idsInOrder) => {
    let responseIds = idsInOrder.filter((id) => {
      return !isNaN(id);
    });
    let responsesField = document.querySelector('.ordered-ids');
    if (responsesField) { responsesField.value = responseIds; }
    updateIndexRanking()
  }

  const updateIndexRanking = () => {
    let rankIndexValues = document.querySelectorAll('.rank-index-value');

    for (let i = 0; i < rankIndexValues.length; i++) {
      let value = rankIndexValues[i];
      value.textContent = i + 1;
    }
  }
}

$(document).on('turbolinks:load', function() {
  enableSortable($('body'));
})

$(document).on('sprinkles:update', function(event) {
  enableSortable($(event.target));
})
