$(document).on('turbolinks:load', function () {
  if ($("#variation-table").length) {
    $('#variation-table').DataTable({
      searching: true,
      serverSide: true,
      processing: true,
      aLengthMenu: [
        [10, 25, 50, 100, 200],
        [10, 25, 50, 100, 200]
      ],
      iDisplayLength: 10,
      language: {
        "sLengthMenu": "Showing_MENU_",
        "info": "of _TOTAL_ Pendig Variations",
        "oPaginate": {
          "sFirst": "",
          "sLast": ""
        }
      },
      ajax: {
        url: $("#variation-table").data('source'),
      },
      pagingType: "full_numbers",
      "dom": "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
      columns: [
        { data: "name" },
        {
          data: "dependent_question",
        },
        { data: "dependent_answer" },
        { data: "purchase_class" },
        { data: "types" },
        { data: "lpas" },
        {
          data: "approved",
          render: function (data) {
            if (data == "green") {
              return '<div class="status-pill green"></div>'
            }
            else {
              if (data == "true") {
                return '<div class="status-pill red"></div>'
              }
              else {
                return '<div class="status-pill yellow"></div>'
              }
            }
          }
        },
        { data: 'updated_at' }
      ]
    });
  }
})
