export class StimulusRefresh {
  static start() {
    window.StimulusRefresh = new StimulusRefresh();
  }

  constructor () {
    this._debouncers = {};
    this._fromSelectorsLookup = {};
  }

  refresh(selectors, from) {
    selectors = Array.isArray(selectors) ? selectors : [selectors];

    this._fromSelectorsLookup[from] = this._fromSelectorsLookup[from] || [];
    this._fromSelectorsLookup[from] = this._fromSelectorsLookup[from].concat(selectors)

    this._debouncers[from] = this._debouncers[from] || _.debounce( () => {
      this._handle(from);
    }, 500)

    this._debouncers[from]();
  }

  _handle(from) {
    const elementsToHandle = this.elementsWithSelectors(this._fromSelectorsLookup[from]);
    delete this._fromSelectorsLookup[from];
    if (elementsToHandle.length === 0) return;

    const url = from === '#' ? window.location.href : from
    const joiner = url.includes('?') ? '&' : '?';
    const fetchLocation = [url, 'layoutless=true&stimulus_refresh=true'].join(joiner)
    delete this._debouncers[from];
    fetch(fetchLocation).then(
      resp => resp.text()
    ).then(html => {
      if (html.trim().startsWith('<turbo-stream')) {
        Turbo.renderStreamMessage(html);
      } else {
        const page = new DOMParser().parseFromString(html, 'text/html');
        let newElement;
        elementsToHandle.forEach( ({existingElement, selector}) => {
          newElement = page.getElementById(selector);
          if (newElement) existingElement.replaceWith(newElement);
        })
      }
    })
  }

  elementsWithSelectors(selectors) {
    let ret = [];
    let element;
    const uniqSelectors = [...new Set(selectors)];
    uniqSelectors.forEach(selector => {
      element = document.getElementById(selector)
      if (element) {
        ret.push({existingElement: element, selector: selector})
      }
    })
    return ret
  }
}
