function sanitizeFilename(filename) {
  return filename.replace(/[^a-z0-9_\-\.]/gi, '_');
}

export function downloadFile({ url, params, filename, onError }) {
  return fetch(url, params)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.blob();
    })
    .then(blob => {
      // Create a blob URL and initiate the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (filename) {
        a.download = sanitizeFilename(filename);
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      } else {
        console.error('Fetch error:', error);
      }
    });
}

export default downloadFile;
