import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['header', 'footer'];
  }

  initialize() {
    this.imageWaitLimit = 5;
  }

  connect() {
    this.headerColumnHeight = this.footerColumnHeight = 20;
  }

  headerTargetConnected(_header) {
    if (this.hasFooterTarget) {
      this.fetchMarginalsSize()
    }
  }

  footerTargetConnected(_footer) {
    if (this.hasHeaderTarget) {
      this.fetchMarginalsSize()
    }
  }

  fetchMarginalsSize() {
    setTimeout(() => {
      this.adjustDocumentSize(this.headerTarget, 'header');
      this.adjustDocumentSize(this.footerTarget, 'footer');
      this.ajaxPatchHeaderFooterHeight();
    }, 200);
  }

  adjustDocumentSize(target, targetName) {
    if(this._imagesLoaded(target)) {
      const targetColumns = target.children;

      const heights = [];
      Array.from(targetColumns).forEach( column => {
        heights.push(this.findHeightWithoutOverflow(column, target));
      })
      this.setTargetHeight(target, targetName, Math.max(...heights));

    } else {
      setTimeout(() => this.adjustDocumentSize(target, targetName), 500)
    }
  }

  findHeightWithoutOverflow(col, target) {
    this.setColumnHeight(col, target);
    if (col.scrollHeight > col.clientHeight) {
      (target === this.headerTarget) ? this.headerColumnHeight += 10 : this.footerColumnHeight += 10;
      this.findHeightWithoutOverflow(col, target);
    }

    return (target === this.headerTarget) ? this.headerColumnHeight : this.footerColumnHeight;
  }

  setColumnHeight(col, target) {
    (target === this.headerTarget) ? col.style.height = `${this.headerColumnHeight}px` : col.style.height = `${this.footerColumnHeight}px`;
  }

  setTargetHeight(target, targetName, maxHeight) {
    const targetAttribute = target.getAttribute(`data-${targetName}-size`);
    const targetHeight = target === this.headerTarget ? this.headerColumnHeight : this.footerColumnHeight;

    target.style.height = `${Math.max(maxHeight, targetAttribute, targetHeight)}px`;
  }

  ajaxPatchHeaderFooterHeight() {
    if (this._heightDidNotChange()) return;

    Rails.ajax({
      url: `${this.element.getAttribute('data-url')}?header_height=${this.headerColumnHeight}&footer_height=${this.footerColumnHeight}`,
      type: 'PATCH'
    })
  }

  _heightDidNotChange() {
    return (this.headerColumnHeight == this.headerTarget.getAttribute('data-header-size') &&
      this.footerColumnHeight == this.footerTarget.getAttribute('data-footer-size'))
  }

  _imagesLoaded(target) {
    const imagesInTarget = Array.from(target.querySelectorAll('img'));

    if( imagesInTarget.every(img => img.complete) ) return true;

    this.imageWaitLimit--;

    return this.imageWaitLimit < 0;
  }
}
