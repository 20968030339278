$(document).on('turbolinks:load', function() {
  const ua = window.navigator.userAgent;
  const isIE = /MSIE|Trident/.test(ua);
  const isEdge = /Edge/.test(ua);

  if ((isIE || isEdge) && window.location.href != 'https://sb.cityinnovate.com/users/sign_in') {
    // log the user out if they somehow managed to become logged in
    const signOutLinks = document.querySelectorAll('[href="/users/sign_out"]');
    signOutLinks.forEach((link) => {
      link.click();
    });
  }

  if (!isIE && !isEdge) {
    const browserWarningElements = document.querySelectorAll('.browser-warning');
    browserWarningElements.forEach((element) => {
      element.parentNode.removeChild(element);
    });

    const unsupportedBrowserInputs = document.querySelectorAll('input.unsupported-browser-disabled');
    unsupportedBrowserInputs.forEach((input) => {
      input.removeAttribute('disabled');
      if (input.classList.contains('autofocus')) {
        input.focus();
      }
    });
  }
});
