import { ApplicationController } from "../application_controller"

export default class extends ApplicationController {
  static get targets() {
    return ['button', 'summary'];
  }

  static get outlets() {
    return ['search-field', 'form'];
  }

  static get values() {
    return {
      namespace: String,
      datatableNamespace: String
    };
  }

  connect() {
  }

  initialize() {
    window[`${this.datatableNamespaceValue}-filters`] = window[this.namespaceValue] || { filterSelects: {} };
    this.datatableController = null;
  }

  register(datatable) {
    this.datatableController = datatable;
  }

  formOutletConnected() {
    this.renderPillView()
    document.addEventListener('filter-pills:reload', () => {
      this.renderPillView()
    })
  }

  searchFieldOutletConnected(outlet) {
    outlet.registerSearchCallback(() => this.tableReload())
  }

  windowNamespace() {
    return window[`${this.datatableNamespaceValue}-filters`];
  }

  apply() {
    this.tableReload();
    this.formOutlet.fetch();
  }

  renderPillView() {
    if (!this.summaryTarget) return;

    const pillsView = this._createPills(this._selectedFilters());

    this.summaryTarget.innerHTML = pillsView;
  }

  tableReload() {
    if (this.datatableController) {
      this.datatableController.reload();
    } else {
      window[this.datatableNamespaceValue].draw('full-hold');
    }
    this.updateFilterCountButton();
  }

  tableRefresh() {
    if (this.datatableController) {
      this.datatableController.refresh();
    } else {
      window[this.datatableNamespaceValue].draw('full-hold');
    }
  }

  clear() {
    this.formOutlet.clear();
    this.tableReload();
  }

  clearSingleFilter(ele) {
    const targetId = ele.target.attributes['data-filter-id'].value;
    const targetFilter = document.getElementById(targetId);
    if (targetFilter.classList.contains('select2')) {
      const targetFilterValues = $(targetFilter).val();
      const selectedValue = ele.target.attributes['data-value'].value;
      const index = targetFilterValues.indexOf(selectedValue);
      if (index != -1 && Array.isArray(targetFilterValues)) {
        targetFilterValues.splice(index, 1);
        this.formOutlet.setInputValue(targetFilter, targetFilterValues);
      } else {
        this.formOutlet.setInputValue(targetFilter);
      }
    } else {
      targetFilter.value = '';
    }

    this.tableReload();
  }

  updateFilterCountButton() {
    const filterCount = this.formOutlet.inputsWithValues().length;
    const filterBtnText = this.buttonTarget.dataset['filterBtnText'] || "Filters";
    this.buttonTarget.textContent = `${filterBtnText} ( ${filterCount} )`;
    this.renderPillView();
  }

  _selectedFilters() {
    // selectedFilters are any inputs with values. For selects, that means by default the first input
    // add an include_blank: 'Some Prompt' to form_helper_options to ensure there's no initial pills rendered
    return this.formOutlet.inputsWithValues()
  }

  _createPills(selectedFilters) {
    let pillsView = '';

    selectedFilters.forEach(element => {
      if (element.classList.contains('select2')) {
        Array.from(element.options).filter(opt => opt.selected).forEach(ele => {
          pillsView += this._createPill(element.id, element.attributes.pill_label.value, ele.text, ele.value)
        })
      } else if (element.classList.contains('multi-daterange')) {
        pillsView += this._createPill(element.id, element.attributes.pill_label.value, element.value, element.value);
      }
    })

    pillsView += this._clearAllFilters(selectedFilters)
    return pillsView;
  }

  _createPill(filterId, filterType, label, value) {
    return `<div class="filter-pill">
                <span class="clear-filter mr-2 p-2">
                  <button class="btn btn-primary text-light" data-action="datatables--filters#clearSingleFilter" data-filter-id="${filterId}" data-value="${value}" aria-label="Clear ${filterType} filter">x</button>
                  <strong>${filterType}</strong> : ${label}
                </span>
            </div>`
  }

  _clearAllFilters(selectedFilters) {
    if (selectedFilters.length < 1) return '';

    return `<div class="filter-pill">
              <button class="clear-btn" data-action="datatables--filters#clear" aria-label="Clear all filters">x</button>
              <span class="font-weight-bold mr-2 p-2 clear_filter" data-action="click->datatables--filters#clear">Clear All</span>
            </div>`
  }
}
