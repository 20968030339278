import { Controller } from "@hotwired/stimulus";
import consumer from "channels/consumer"

export default class extends Controller {
  initialize() {
    this.id = undefined;
  }

  connect() {
    this.id = this.element.dataset.id;
    if (this.id) this.subscribe();
  }

  subscribe() {
    consumer.subscriptions.create({
      channel: 'BuildProjectChannel',
      anotherVar: this.id,
      projectId: this.id,
    }, {
      received: (data) => {
        this.progressBuildingStep(data);
      },
    });
  }

  progressBuildingStep(showQuestions = false) {
    // really would like to refactor this away from jquery - but the project build modal isn't worth keeping as-is.
    var $element = $("li.in-progress");
    var $next = $element.next("li");
    if ($next.length == 0) {
      $next = $(".features-list.part-two li:first");
    }
    $element.removeClass("in-progress").addClass("done");
    if (!$next.hasClass("done")) {
      $next.addClass("in-progress");
    }
    if (parseInt(showQuestions)) {
      if ($("li.done").length < parseInt(showQuestions)) {
        this.progressBuildingStep(showQuestions);
      }
    } else {
      if (showQuestions) {
        $(".finished-questions").slideDown();
        $(".finished").hide();
      } else {
        $(".finished").slideDown();
        $(".finished-questions").hide();
      }
    }
  }
}