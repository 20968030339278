import {
  Plugin
} from 'ckeditor5'

// import { Plugin } from '@ckeditor/ckeditor5-core';

import {
  Annotations
} from 'ckeditor5-premium-features';
// import Annotations from '@ckeditor/ckeditor5-comments/src/annotations/annotations';

/**
 * A plugin that listens that controls the annotations sidebar shrinking/expanding.
 *
 * It also listens to the window size and based on this adjusts the annotation UI.
 */
export default class ResponsiveAnnotationsUI extends Plugin {
	/**
	 * @inheritDoc
	 */
	static get requires() {
		return [Annotations];
	}

	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'ResponsiveAnnotationsUI';
	}

	init() {
		const editor = this.editor;
		// Switch between inline, narrow sidebar and wide sidebar according to the window size.
		const buttonElement = document.getElementById('sidebar-display-toggle');
		const sidebarElement = document.querySelector('.ck-collaboration__sidebar');
		const hideComments = $(editor.ui.view.editable.element).closest(".ck-collaboration").hasClass("hide-comments");
		const editorDiv = document.querySelector('.ck-collaboration__editable');
		const showSidebar = editorDiv.dataset.showSidebar;

		if (showSidebar === 'true') {
			editor.ui.view.listenTo(window, 'resize', refreshDisplayMode);
			refreshDisplayMode();
			switchDisplayMode();
		}

		function refreshDisplayMode() {
			if (hideComments) {
				sidebarElement.classList.remove('hidden', 'narrow');
				editor.plugins.get('AnnotationsUIs').switchTo('wideSidebar');
			} else if (window.innerWidth < 1000) {
				sidebarElement.classList.remove('narrow');
				sidebarElement.classList.add('hidden');
				editor.plugins.get('AnnotationsUIs').switchTo('inline');
				if (!hideComments) { }
			} else if (window.innerWidth < 1300) {
				buttonElement.classList.add('narrow-display');
				sidebarElement.classList.remove('hidden');
				sidebarElement.classList.add('narrow');
				editor.plugins.get('AnnotationsUIs').switchTo('narrowSidebar');
			} else {
				sidebarElement.classList.remove('hidden', 'narrow');
				editor.plugins.get('AnnotationsUIs').switchTo('wideSidebar');
			}
		}

		function switchDisplayMode() {
			editor.ui.view.listenTo(buttonElement, 'click', (eventInfo, domEvent) => {
				domEvent.preventDefault();
				// Fire events only while narrow or wide display mode is active. Do not fire on inline sidebar.
				if (editor.plugins.get('AnnotationsUIs').activeUIs.has('narrowSidebar')) { // ( annotations.uiType == 'narrowSidebar' ) {
					editor.plugins.get('AnnotationsUIs').switchTo('wideSidebar');
					sidebarElement.classList.remove('hidden');
					sidebarElement.classList.remove('narrow');
					buttonElement.classList.remove('narrow-display');
				}

				else if (editor.plugins.get('AnnotationsUIs').activeUIs.has('wideSidebar')) { // ( annotations.uiType == 'wideSidebar' ) {
					editor.plugins.get('AnnotationsUIs').switchTo('narrowSidebar');
					sidebarElement.classList.remove('hidden');
					sidebarElement.classList.add('narrow');
					buttonElement.classList.add('narrow-display');
				}
			});
		}
	}
}
