import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
  static get targets() {
    return ['documentSelect', 'sectionInput', 'sectionSelect', 'fieldInput', 'selectButton']
  }

  static get outlets() {
    return ['form'];
  }

  documentSelectTargetConnected(element) {
    if (element.classList.contains('select2')) {
      $(element).on('change', () => {
        this.clearSectionInputValue()
        this.clearFieldInputValue()
        this.formOutlet.fetch();
      })
    }
  }

  sectionSelectTargetConnected(element) {
    if (element.classList.contains('select2')) {
      // we need to populate the sectionInputTarget when this input first connects
      // since that target is used for determining if the Select button is enabled or disabled.
      this.handleSectionSelect(element);
      $(element).on('change', () => {
        this.handleSectionSelect(element)
        this.formOutlet.fetch();
        this.clearFieldInputValue();
      })
    }
  }

  handleSectionSelect(element) {
    const value = this.selectValueFromTargets(element);
    this.sectionInputTarget.value = value;
  }

  toggle() {
    if (!this.formOutlet && this.formOutlet.element) return;

    const hiddenInput = this.formOutlet.element.querySelector('input[name="selecting_location"]');
    hiddenInput.value = String(hiddenInput.value !== 'true');
    this.formOutlet.fetch();
  }

  selectButtonTargetConnected(element) {
    element.disabled = this.sectionInputTarget.value === '';
  }

  selectValueFromTargets(element) {
    if (element.value !== '') {
      return element.value;
    } else {
      return this.selectValueFromPreviousTarget(element);
    }
  }

  selectValueFromPreviousTarget(element) {
    const targetIndex = this.sectionSelectTargets.indexOf(element);
    const previousTarget = this.sectionSelectTargets[targetIndex - 1]

    if (previousTarget) {
      return previousTarget.value;
    } else {
      return ''
    }
  }

  clearSectionInputValue() {
    if (this.hasSectionInputTarget) {
      this.sectionInputTarget.value = '';
    }
  }

  clearFieldInputValue() {
    if (this.hasFieldInputTarget) {
      this.fieldInputTarget.value = '';
    }
  }
}
