import { RevealOperator } from "../reveal_operator";

/**
 * Reveal Operator that will reveal itself when the source data is equal to the provided value. It is intended to only work with a singular value
 * and will provide a warning if an array of values is passed and has a length greater than 1.
 *
 * Common usage for this operator would be to only reveal content when a certain radio button is checked (or not checked by using 'not-equal-to').
 */
export class Changed extends RevealOperator {
  validate() {
    let fd_values = this._manyFormValues(this.initialFormData);

    if (fd_values[0] && fd_values[0].constructor.name === 'File') {
      fd_values = this._valuesFromFileInput(this.initialFormData)
    }
    this._initial_values = fd_values
  }

  _valueCheck(formdata) {
    const compareTo = (this._manyFormValues(formdata)[0] && this._manyFormValues(formdata)[0].constructor.name === 'File') ? this._valuesFromFileInput(formdata) : this._manyFormValues(formdata)
    return !(this._initial_values.length === compareTo.length && this._initial_values.every((value, idx) => compareTo[idx] === value));
  }

  _valuesFromFileInput(formdata) {
    return this._manyFormValues(formdata).map( entry => entry.name )
  }
}