import { DatatablesBase } from "../base";

export default class extends DatatablesBase {
    static get orderConfig() {
        return [[2, 'desc']];
    }

    static get languageConfig() {
        return {
            "sLengthMenu": "Showing_MENU_",
            "info": "of _TOTAL_ Logs",
            "oPaginate": {
                "sFirst": "",
                "sLast": ""
            }
        };
    }

    static get searchConfig() {
        return { searching: false }
    }

    static get columnConfig() {
        return [
            {
                data: 'changed_by'
            },
            {
                data: 'changed_columns'
            },
            {
                data: 'changed_at'
            },
            {
                data: 'event'
            },
            {
                data: 'id',
                orderable: false
            }
        ]
    }

}
