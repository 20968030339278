var modelsWithDependentQuestions = [
  {
    modelName: "solicitations_documents_template",
    fields: [
      "select[id='solicitations/documents/template_dependent_question_id_or_new']",
      "input[name='solicitations_documents_template[type_ids][]']",
      "input[name='solicitations_documents_template[vehicle_ids][]']"
    ]
  },
  {
    modelName: "solicitations_sections_template",
    fields: [
      'select[id="solicitations/sections/template_dependent_question_id_or_new"]',
      "input[name='solicitations_sections_template[type_ids][]']",
      "input[name='solicitations_sections_template[vehicle_ids][]']"
    ]
  },
  {
    modelName: "solicitations_sections_templates_variation",
    fields: [
      'select[id="solicitations/sections/templates/variation_dependent_question_id_or_new"]',
      "input[name='solicitations_sections_templates_variation[type_ids][]']",
      "input[name='solicitations_sections_templates_variation[vehicle_ids][]']"
    ]
  }
];

$(document).ready(function(){
  for (model of modelsWithDependentQuestions) {
    for (field of model.fields) {
      let currentModel = model.modelName;
      $(document).on('change', field, function(event) {
        replaceDependentQuestionField(event, currentModel);
      });
    }
  }
});

function replaceDependentQuestionField(event, modelName) {
  var answersFieldSelector = '#answers_field';
  var $answersField = $(answersFieldSelector);
  var questionsFieldSelector = '#questions_field select';
  var $questionField = $(questionsFieldSelector);
  var url = $answersField.attr('data-refresh-from');
  $questionField.attr('disabled', 'disabled');
  var $form = $(event.target).closest('form');
  var params = {};
  params[`${modelName}[dependent_question_id_or_new]`] = $questionField.val();
  params[`${modelName}[type_ids]`] = $form.find(`input[name='${modelName}[type_ids][]']:checked`).map(function(){return $(this).val() }).get();
  params[`${modelName}[vehicle_ids]`] = $form.find(`input[name='${modelName}[vehicle_ids][]']:checked`).map(function(){return $(this).val() }).get();
  $answersField.html('<p class="text-muted">Loading...</p>');
  $.get(url, params, function(data) {
    $questionField.html($(data).find(questionsFieldSelector).html());
    $answersField.replaceWith($(data).find(answersFieldSelector));
    $questionField.removeAttr('disabled');
  });
}
