import {
  Alignment,
  Autoformat,
  BlockQuote,
  Bold,
  CKFinderUploadAdapter,
  CloudServices,
  CodeBlock,
  EasyImage,
  Essentials,
  FindAndReplace,
  Font,
  Heading,
  Highlight,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  ListProperties,
  Mention,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline
} from 'ckeditor5'

import { WProofreader } from '@webspellchecker/wproofreader-ckeditor5';
import { MentionCustomization } from './helpers/collaborative_editor_helpers';

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

export const ckeditorPluginList = [
  Essentials,
  CKFinderUploadAdapter,
  Autoformat,
  Bold,
  Italic,
  Strikethrough,
  Underline,
  BlockQuote,
  EasyImage,
  CloudServices,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageResize,
  ImageTextAlternative,
  Link,
  ListProperties,
  Paragraph,
  Mention,
  MentionCustomization,
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
  TableColumnResize,
  Indent,
  IndentBlock,
  RemoveFormat,
  Font,
  HorizontalLine,
  PasteFromOffice,
  Alignment,
  PageBreak,
  Subscript,
  Superscript,
  FindAndReplace,
  WProofreader,
  TableCaption,
  CodeBlock,
  Highlight
];

export const ckeditorToolbar = {
  items: [
    'heading',
    'fontSize',
    {
      label: 'Text Styles',
      icon: 'bold',
      items: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'subscript',
        'superscript',
        'removeFormat',
      ]
    },
    'link',
    'bulletedList',
    'numberedList',
    'blockQuote',
    'horizontalLine',
    'alignment',
    'pageBreak',
    '|',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'insertTable',
    'imageUpload',
    '|',
    'outdent',
    'indent',
    '|',
    'findAndReplace',
    '|',
    'undo',
    'redo',
    'wproofreader',
    'codeblock',
  ]
};

export const newCkeditorToolbar = {
  items: [
    'undo',
    'redo',
    '|',
    'heading',
    '|',
    'fontSize',
    {
      label: 'Text Styles',
      icon: 'bold',
      items: [
        'bold',
        'strikethrough',
        'subscript',
        'superscript'
      ]
    },
    'fontColor',
    'italic',
    'underline',
    'fontBackgroundColor',
    '|',
    'alignment',
    'bulletedList',
    'numberedList',
    'outdent',
    'indent',
    'pageBreak',
    '|',
    'link',
    'imageUpload',
    'insertTable',
    '|',
    'findAndReplace',
    'removeFormat',
    'wproofreader',
    'blockQuote',
    'horizontalLine',
    'commentsArchive'
  ]
};


export const ckeditorTable = {
  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties', 'toggleTableCaption'],
  defaultHeadings: { rows: 1 },
  tableProperties: {
    defaultProperties: {
      backgroundColor: 'hsl(0, 0%, 100%)',
      borderStyle: 'solid',
      borderColor: 'gray',
      borderWidth: '2px',
      alignment: 'center',
      width: '',
      height: '',
    }
  },
  tableCellProperties: {
    defaultProperties: {
      borderStyle: 'solid',
      borderColor: 'gray',
      borderWidth: '2px',
      backgroundColor: 'hsl(0, 0%, 100%)',
      width: '',
      height: '',
      padding: ''
    }
  }
};

export const ckeditorImage = {
  resizeUnit: 'px',
  toolbar: [
    'imageStyle:inline',
    'imageStyle:wrapText',
    'imageStyle:breakText',
    '|',
    'imageTextAlternative',
    'toggleImageCaption',
  ],
  styles: [
    'alignLeft',
    'alignCenter',
    'alignRight'
  ]
};

export const ckeditorNumericFontSizeConfig = {
  options: [10,12,14,'16 (default)',18,20,24,36],
}

export const headingsConfig = {
  options: [
    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
  ]
}
export const forceEnabledCommandsForTrackChanges = [
  "listStyle",
  "tableBorderColor",
  "tableBorderStyle",
  "tableBorderWidth",
  "tableAlignment",
  "tableWidth",
  "tableHeight",
  "tableBackgroundColor",
  "tableCellBorderStyle",
  "tableCellBorderColor",
  "tableCellBorderWidth",
  'tableCellHorizontalAlignment',
  'tableCellWidth',
  'tableCellHeight',
  'tableCellPadding',
  'tableCellBackgroundColor',
  'tableCellVerticalAlignment',
  'listStart'
]

export const codeBlockConfig = {
  languages: [
    { language: 'ruby', label: 'Ruby', class: 'ruby'},
    { language: 'plaintext', label: 'Plain text', class: '' },
    { language: 'javascript', label: 'JavaScript', class: 'js javascript js-code' },
    { language: 'python', label: 'Python', class: 'python' },
    { language: 'bash', label: 'Bash', class: 'bash' }
  ]
};

export const wproofreaderConfig = {
  serviceId: process.env.WPROOFREADER_SERVICE_ID,
  srcUrl: process.env.WPROOFREADER_SERVICE_URL
};

export const configuration = {
  extraPlugins: [allowNestedTables],
  list: {
    properties: {
      startIndex: true
    }
  },
  link: {
    defaultProtocol: 'https://'
  },
  licenseKey: process.env.CKE_LICENSE_KEY,
  toolbar: ckeditorToolbar,
  table: ckeditorTable,
  image: ckeditorImage,
  indentBlock: {
    offset: 1,
    unit: 'em'
  },
  fontSize: ckeditorNumericFontSizeConfig,
  language: 'en',
  wproofreader: wproofreaderConfig,
  codeBlock: codeBlockConfig
};

export const newConfiguration = {
  extraPlugins: [allowNestedTables],
  list: {
    properties: {
      startIndex: true
    }
  },
  link: {
    defaultProtocol: 'https://'
  },
  licenseKey: process.env.CKE_LICENSE_KEY,
  toolbar: newCkeditorToolbar,
  table: ckeditorTable,
  image: ckeditorImage,
  indentBlock: {
    offset: 1,
    unit: 'em'
  },
  fontSize: ckeditorNumericFontSizeConfig,
  language: 'en',
  wproofreader: wproofreaderConfig,
  codeBlock: codeBlockConfig
};

export function allowNestedTables(editor) {
  editor.model.schema.on('checkChild', (evt, args) => {
    const context = args[0];
    const childDefinition = args[1];

    if (context.endsWith('tableCell') && childDefinition && childDefinition.name == 'table') {
      evt.stop();
      evt.return = true;
    }
  }, {
    priority: 'highest'
  });
};

export function initialEditorConfiguration(editableElement) {
  return {
    tokenUrl: editableElement.dataset.ckeditortokenurl,
    uploadUrl: editableElement.dataset.ckeditoruploadurl,
    webSocketUrl: editableElement.dataset.ckeditorwebsocketurl,
    bundleVersion: editableElement.dataset.ckeditorversion,
    channelId: editableElement.dataset.ckeditordocumentid,
    trackChangesOn: editableElement.dataset.ckeditortrackchangeson === 'true',
    disableSuggestion: editableElement.dataset.ckeditordisablesuggestion === 'true',
    disableTrackChangesToggle: editableElement.dataset.ckeditordisabletrackchangestoggle === 'true',
    readOnlyMode: editableElement.dataset.ckeditorreadonlymode === 'true',
    disableComments: editableElement.dataset.ckeditordisablecomments === 'true',
  }
}
