/**
 * This is the base class to be extended for the operators that are used as part of the stimulus reveal controller.
 *
 * This class is responsible for determining if the target value exists in the form data in the `check()` function.
 *
 * In general, the reveal operators all support their negative counter parts by using `not-` as part of the operator name.
 * For example, data-reveal-operator="included_in" also supports data-reveal-operator="not_included_in".
 * The stimulus controller will strip out the `not` portion of the operator name and pass in {negated:true} as part
 * of the initialization of the operator instance.
 *
 * # Implentation Requirements
 * And class extending RevealOperator must implement it's own version of `_valueCheck()`.
 * `_valueCheck()` is the function that does the real logic check for determining if the content
 * form data has the target values. See the individual sub-classes for more examples.
 */

export class RevealOperator {
  /**
   *
   * @param {string} name The name of the input
   * @param {any} values The valid values for this operator
   * @param {object} opts Additional supported options
   * @param {boolean} [opts.negated] Determines if a `not` variant of the operator is used and negates the return value if so
   * @param {HTMLElement[]} [opts.sourceElements] Array of elements that represent the source data
   */
  constructor(name, values, { negated = false, sourceElements = [], initialFormData = new FormData }) {
    this.name = name;
    this.values = values;
    this.negated = negated;
    this.sourceElements = sourceElements;
    this.initialFormData = initialFormData;
    this.sanitize();
    this.validate();
  }

  warn(message) {
    console.warn(`${this.constructor.name}: ${message}`)
  }

  /**
   * Override in sub-class to validate the proper structure of the received arguments and outputs warnings to the console was misconfigurations are in place
   */
  validate() {

  }

  /**
   * Override in sub-class to re-write the setter values (convert string to numbers, convert json to array/objects, etc)
   */
  sanitize() {

  }

  /**
   * Retrieves the first value from teh formdata for the name
   * @param {formdata} formdata Form data object to be checked
   * @returns the single value for the operators name within the form object
   */
  _singleFormValue(formdata) {
    return formdata.get(this.name);
  }

  /**
   * Retrieves an array of values form the formdata
   * @param {formdata} formdata Form data object to be checked
   * @returns the array of values for the operators name within the form object
   */
  _manyFormValues(formdata) {
    return formdata.getAll(this.name);
  }

  /**
   *
   * @param {formdata} formdata Form data object to be checked
   * @returns {boolean} the result of the operators _valueCheck function. If negated is true, the returned value is inverted (true becomes false and false becomes true)
   */
  check(formdata) {
    return this.negated ? !this._valueCheck(formdata) : this._valueCheck(formdata)
  }

  /**
   *
   * @param {formdata} formdata Form data object to be checked
   * @note This function MUST be defined in sub-classes of RevealOperator. The function is passed the formdata of the source form object.
   */
  _valueCheck(formdata) {
    throw new Error(`${this.constructor.name} must define _valueCheck function. See 'reveal_operator.js' for more info.`)
  }
}