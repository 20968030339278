import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get outlets() {
    return ['datatables--filters'];
  }

  initialize() {
    this.datatable = null;
    this.filters = null;
  }

  connect() {
    if (this.element.dataset.initFunction) {
      this.datatable = window[this.element.dataset.initFunction]();
    }
  }

  datatablesFiltersOutletConnected(outlet) {
    this.filters = outlet;
    outlet.register(this)
  }

  reload() {
    this.datatable.ajax.reload();
  }

  refresh() {
    this.datatable.draw('full-hold');
  }
}
