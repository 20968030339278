import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reveal-when-checked"

export default class extends Controller {
  static get targets() {
    return ['checkbox', 'element'];
  }

  connect() {
    this.handleState();
  }

  handleState() {
    // Loop through the checkboxes and check if any are checked
    let atLeastOneChecked = false;
    for (let i = 0; i < this.checkboxTargets.length; i++) {
      if (this.checkboxTargets[i].checked) {
        atLeastOneChecked = true;
        break; // exit the loop as soon as one is found
      }
    }
    this.elementTargets.forEach((element) => {
      if(atLeastOneChecked){
        element.removeAttribute("hidden"); 
      }else{
        element.setAttribute('hidden', true); 
      }
    });
  }
}

