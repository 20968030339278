import { Controller } from "@hotwired/stimulus";
import './component';

/**
 * Stimulus controller for handling modal display state
 * @identifier 'modal--component'
 */

export default class extends Controller {
  /**
   * @show {boolean} - the current state of the modal display. When true the modal is given display: block styling. When false it is given display:none; styling.
   * @remove {boolean} - determines if the modal is removed from the dom when closing. This is mostly utilized when rendering dynamic modals
   */
  static get values() {
    return {
      show: {
        type: Boolean,
        default: false
      },
      remove: {
        type: Boolean,
        default: false
      }
    }
  }

  static get targets() {
    return ['disablable']
  }

  connect() {
    // create a bound show/hide method that can be re-used throughout the controller
    this._boundHide = this.hide.bind(this);
    this._boundShow = this.show.bind(this);

    // attach the show/hide functions directly to the element
    this.element.open = this._boundShow;
    this.element.show = this._boundShow;
    this.element.hide = this._boundHide;
    this.element.close = this._boundHide;
    this._attachClosers();
  }

  disable() {
    // using setTimeout here because this disable will cause the button to not submit the form.
    // the delay doesn't need to be a significant amount, we just need to let the event continue on
    // long enough to fire off the network request. In local testing, a timeout of 0 seemed to work
    // just fine, but adding a small amount of padding to ensure there isn't some other race condition.
    setTimeout( () => {
      this.disablableTargets.forEach( ele => {
        ele.disabled = true
        ele.classList.add('disabled')
      })
    }, 20)
  }

  /**
   * Callback for changes in the showValue
   */
  showValueChanged() {
    this.showValue ? this._handleShow() : this._handleHide()
  }


  // PUBLIC METHODS
  /**
 * Updates the showValue to true which trigger the showValueChanged callback
 */
  show() {
    this.showValue = true;
  }

  /**
   * Updates the showValue to false which trigger the showValueChanged callback
   */
  hide() {
    this.showValue = false;
  }

  /**
   * Alias #close as another function for handling #hide.
   */
  close() {
    this.hide();
  }

  /**
   * Alias #open as another function for handling #show.
   */
  open() {
    this.show();
  }


  // HANDLERS
  /**
   * Handler for showing the modal.
   * @note - This also handles displaying the backdrop as well as adding 'modal-open' to the body which removes the users ability to scroll
   * @return {void}
   */
  _handleShow() {
    this.element.style.display = 'block';
    this.backdrop.classList.add('show');
    document.body.classList.add('modal-open')
  }

  /**
   * Handler for hiding the modal
   * @return {void}
   */
  _handleHide() {
    this.element.style.display = 'none';
    this.backdrop.classList.remove('show')
    document.body.classList.remove('modal-open')
    if (this.removeValue) this.element.remove();
  }

  /**
   * Attach the close function to any elements within the controlling element that have a class of `modal-closer`
   * @return {void}
   */
  _attachClosers() {
    this.element.querySelectorAll('.modal-closer').forEach( ele => {
      ele.addEventListener('click', this._boundHide);
    })
  }

  /**
   * Fetches or creates a backdrop element on the dom
   * @return {HTMLElement}
   */
  get backdrop() {
    if (this._backdrop) return this._backdrop;

    let backdrop = document.querySelector('.ci-modal-backdrop');

    if (!backdrop) {
      backdrop = document.createElement('div');
      backdrop.classList.add('ci-modal-backdrop');
    }

    document.body.appendChild(backdrop);

    this._backdrop = backdrop
    return this._backdrop
  }
}