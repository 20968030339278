import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
  static get values() {
    return {
      selector: String
    }
  }

  connect() {
    const selectors = []
    if (this.selectorValue) selectors.push(this.selectorValue);
    if (this.element.id) selectors.push(`.${this.element.id}-confirmation`);
    if (selectors.length === 0) console.log('No selector values provided');
    this.selectors = [...new Set(selectors)].join(' ')
    this.elements = [];
    this.listenToSelectors()
  }

  listenToSelectors() {
    if (!this.selectors) return;

    this.selectors.split(' ').forEach( (selector) => {
      document.querySelectorAll(selector).forEach( (ele) => {
        switch (ele.type) {
          case 'checkbox':
            this._listenCheckbox(ele);
            break;
          case 'text':
            this._listenText(ele);
            break;
          default:
            console.log(`${ele.type} is not yet handled. Please implement`);
        }
      })
    })
  }

  _listenCheckbox(ele) {
    ele.addEventListener('change', () => {
      this._handleChange();
    })
    this.elements.push(ele)
  }

  _listenText(ele) {
    if (!ele.dataset['accept'] && !ele.placeholder) {
      console.log('[data-accept] value is required and not specified')
      return;
    } else {
      ele.addEventListener('keyup', () => {
        this._handleChange()
      })
      this.elements.push(ele)
    }
  }

  _validateText(ele) {
    const target = ele.dataset['accept'] || ele.placeholder;
    return (ele.value || '').toLowerCase() === target.toLowerCase();
  }

  _validateCheckbox(ele) {
    return ele.checked;
  }

  _handleChange() {
    let validated = true;
    this.elements.forEach( (ele) => {
      switch (ele.type) {
        case 'checkbox':
          validated = validated && this._validateCheckbox(ele);
          break;
        case 'text':
          validated = validated && this._validateText(ele);
          break;
        default:
          console.log(`${ele.type} not supported`);
          validated = false
      }
    })

    this.element.disabled = !validated
  }
}
