import { Controller } from "@hotwired/stimulus";

// updates the sectiom comments count each time the section body is connected.
export default class extends Controller {
  connect() {
    this.contentChanged();
  }

  contentChanged() {
    const statusElementId = this.element.dataset.statusContainerId;
    const newCommentCount = this.element.dataset.commentCount;
    const statusElement = document.getElementById(statusElementId);

    if (statusElement && parseInt(newCommentCount) > 0) {
      statusElement.classList.remove('d-none');
      statusElement.textContent = newCommentCount;
    } else if (statusElement) {
      statusElement.classList.add('d-none');
    }
  }
}
