import { Controller } from "@hotwired/stimulus";
/*
  A general purpose controller used to dispatch custom events when an element within the controller/container
  is removed from the DOM.

  Add `data-controller="element-removed-event"` to the element that contains the elements to be removed.
  Add `data-element-removed-event-target="element"` to the elements you want to watch that may be removed.

  If you have more than one removal event on a page, you can give them different names by adding `data-element-removed-event-name-value="<name>"`
  to the controller element. This will allow you add separate event listeners to each event.

  To use:
  Add the event listener on the controller/container element for the event name.

  $(<controller/container>).on('<name>:removed', function (event) {
    // Do some stuff
  });
*/
export default class extends Controller {
  static get targets() {
    return ['element'];
  }

  static get values() {
    return {
      name: String
    };
  }

  connect() {
    const name = this.nameValue || 'element';
    this.removalEvent = new CustomEvent(`${name}:removed`);
  }

  elementTargetDisconnected() {
    this.element.dispatchEvent(this.removalEvent);
  }
}