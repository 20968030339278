function enableSelectAllButtons(scope) {
  $(scope).find(".js-select-all-button").on("click", fireSelectAll)

  function fireSelectAll(e){
    $(e.target).popover("show")
    e.target.disabled = true

    function selectElementText(el, win) {
      win = win || window;
      var doc = win.document, sel, range;
      if (win.getSelection && doc.createRange) {
        sel = win.getSelection();
        range = doc.createRange();
        range.selectNodeContents(el);
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (doc.body.createTextRange) {
        range = doc.body.createTextRange();
        range.moveToElementText(el);
        range.select();
      }
    }
    const selector = e.target.dataset.targetSelector
    const el = scope.querySelector(selector)
    selectElementText(el, window)

    setTimeout(function() {
      e.target.disabled = false
      $(e.target).popover("hide")
    }, 2000)
  }
}

$(document).on("turbolinks:load", function() {
  enableSelectAllButtons(document.body)
})

$(document).on("sprinkles:update", function(event) {
  enableSelectAllButtons(event.target)
})