import { RevealOperator } from "../reveal_operator";

/**
 * Reveal Operator that will reveal itself when the source data is equal to the provided value. It is intended to only work with a singular value
 * and will provide a warning if an array of values is passed and has a length greater than 1.
 *
 * Common usage for this operator would be to only reveal content when a certain radio button is checked (or not checked by using 'not-equal-to').
 */
export class ArrayEqualTo extends RevealOperator {
  sanitize() {
    if (!Array.isArray(this.values)) {
      this.values = JSON.parse(this.values)
    }
  }

  validate() {
    if (!Array.isArray(this.values)) {
      this.warn('requires an array of values')
    }
  }

  _valueCheck(formdata) {
    const valuesToSearch = this._manyFormValues(formdata).map( v => String(v) );
    return this.values.every( v => valuesToSearch.includes(String(v)) )
  }
}