import { RevealOperator } from "../reveal_operator";

/**
 * This is a bit of an ad-hoc operator that was needed for a special case in the form validations.
 * This will return true if the source value is empty or if the source value is greater than the provided value.
 *
 * The original use case for this operator is that the password_reuse_limit must be greater than the min value (let's say 5 for example) or it must be nil.
 * So in this case, we are using 'not-greater-than-or-empty` to show a front-end field warning that the value is not valid if the user inputs a value of 3.
 */

export class GreaterThanOrEmpty extends RevealOperator {

  sanitize() {
    this.values = Number(values)
  }

  validate() {
    if (Number.isNaN(this.values)) {
      console.warn('GreaterThanOrEmpty requires a numeric value and is current NaN');
    }
  }

  _valueCheck(formdata) {
    const formvalue = Number(this._manyFormValues(formdata).find(v => !Number.isNaN(Number(v))));

    return Number.isNaN(formvalue) || formvalue >= this.values;
  }
}