$(document).on('turbolinks:load', function() {
  let navbarScroll = $('#navbar-scroll')[0];
  if (navbarScroll && $(navbarScroll).children().position()) {
    let position = $(navbarScroll).children().position().left;
    const slideAmount = 150;

    $('#arrow-right').on('click', function() {
      $(navbarScroll).animate({
        scrollLeft: position + slideAmount
      }, 500);
      position += slideAmount;
    })

    $('#arrow-left').on('click', function() {
      $(navbarScroll).animate({
        scrollLeft: position - slideAmount
      }, 500);
      position -= slideAmount;
    });
  }
});
