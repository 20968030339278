import Rails from '@rails/ujs';
import { setAutosaveStatus, updateQueryStringParameter } from './utilities'

export function afterError(event, defaultLockID, editor, form) {
  let status = null;
  if (event.detail) {
    status = event.detail[2].status;
  }

  const handleOutdatedSection = () => {
    editor.enableReadOnlyMode(defaultLockID);
    alert('The version of section content loaded on this page is now outdated...');
    reloadWithParameter('reloaded', 'true');
  };

  const handleOutdatedCkeditor = () => {
    alert('There has been an update to the Solicitation Builder text editor.  We need to refresh the page to load the latest version for you.');
    document.getElementsByClassName('ckeditor-flush-link')[0].click();
  };

  const handleSignedOut = () => {
    editor.enableReadOnlyMode(defaultLockID);
    countDownThenRetry(5, 0, true, editor);
  };

  const handleSignedInAgain = () => {
    console.log('🛠 The user appears to be signed in again...');
    fetchAndUpdateAuthenticityToken(editor);
  };

  const handleUnexpectedMethod = () => {
    form.querySelector("input[name='_method']").value = 'patch'
    countDownThenRetry(0, 0, false, editor);
  };

  const defaultErrorHandler = () => {
    console.log('🛠 Save failed. Will try again in 5 seconds.');
    countDownThenRetry(5, 0, false, editor);
  };

  editor['suppressReloadWarning'] = true;

  switch (status) {
    case 412:
      handleOutdatedSection();
      break;
    case 426:
      handleOutdatedCkeditor();
      break;
    case 401:
      handleSignedOut();
      break;
    case 422:
      handleSignedInAgain();
      break;
    case 404:
      if (form.querySelector("input[name='_method']").value != 'patch') {
        handleUnexpectedMethod();
      }
      break;
    default:
      // Debugger to check the editor status. Do not delete.
      console.log(status);
      defaultErrorHandler();
  }
}

function countDownThenRetry(secondsRemaining, delayInSeconds, signedOut, editor) {
  if (document.querySelector('form.ckeditor-autosave-form') == null) {
    return;
  }

  setTimeout(function () {
    setAutosaveStatus('saving');
    if (secondsRemaining > 0) {
      document.querySelector('.collaboration__topbar-autosave-saving').classList.add('tw-text-orange-600');

      let autosaveDescription = document.querySelector('.collaboration__topbar-autosave-saving .autosave-description');

      const timeUnit = secondsRemaining > 1 ? 'seconds' : 'second';
      autosaveDescription.innerHTML = `<span class='text-danger'>Unable to save. <span class='additional_context'></span>Trying again in ${secondsRemaining} ${timeUnit}...</span>`;

      // in case the user is signed out, we want to give them a link to sign back in
      if (signedOut) {
        const additionalContext = autosaveDescription.querySelector('.additional_context');
        additionalContext.innerHTML = "You're not signed in. <a href='' class='sign_in_link' style='text-decoration: underline;'><b>Click here to sign in again.</b></a><br>";

        const signInLink = additionalContext.querySelector('.sign_in_link');
        signInLink.addEventListener('click', function() {
          editor.suppressReloadWarning = true;
          location.reload();
        });
      }

      countDownThenRetry(secondsRemaining - 1, 1, signedOut, editor);
    } else {
      document.querySelector('.collaboration__topbar-autosave-saving .autosave-description').innerHTML = 'Saving...';
      saveRailsForm(editor);
    }
  }, delayInSeconds * 1000);
}

export function afterSave(editor, defaultLockID, form, resolve) {
  setTimeout(() => setAutosaveStatus('saved'), 2000);
  editor.disableReadOnlyMode(defaultLockID);
  const url = window.location.href;
  // If we just did a force update of the ckeditor version number after a reload, remove the reloaded param here
  if (url.indexOf('reloaded=true') > -1) {
    const url = window.location.href.split('/').pop().split('?')[0]
    window.history.replaceState({}, null, url);
    // Reset the hidden input element back to its default state
    document.getElementById('solicitations_section_page_reloaded').value = false;
  }

  // remove the ajax callbacks we attached to the form. we can't leave these attached, because the success
  form.removeEventListener('ajax:success', afterSave);
  form.removeEventListener('ajax:error', afterError);
  resolve();
}

export function saveRailsForm(editor) {
  const element = editor.ui.getEditableElement();
  const form = document.querySelector('form.ckeditor-autosave-form');
  const parent = element.parentNode;

  const updateIfPresent = (selector, valueFunction) => {
      const input = parent.querySelector(selector);
      if (input) {
          input.value = valueFunction();
      }
  };

  updateIfPresent('input.ckeditor-document', () => editor.getData());
  updateIfPresent('input.ckeditor-suggestion-highlighted-document', () => editor.getData({ showSuggestionHighlights: true }));
  updateIfPresent('input.ckeditor-suggestion-accepted-document', async () => await getBodyWithAcceptedSuggestions(editor));
  updateIfPresent('input.ckeditor-suggestion-rejected-document', async () => await getBodyWithRejectedSuggestions(editor));
  updateIfPresent('input.ckeditor-document-version', () => editor.plugins.get('RealTimeCollaborationClient').cloudDocumentVersion);

  if (form !== null) {
    Rails.fire(form, 'submit');
  }
}

const fetchAndUpdateAuthenticityToken = (editor) => {
  fetch(document.URL)
    .then(response => response.text())
    .then(data => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, 'text/html');
      const authenticityTokenInput = doc.querySelector("form input[name='authenticity_token']");

      const authenticityToken = authenticityTokenInput ? authenticityTokenInput.value : null;

      if (authenticityToken) {
        const methodInput = doc.querySelector("input[name='_method']"); // Corrected to use 'doc'
        if (methodInput) {
          methodInput.value = authenticityToken;
        }
      }
      countDownThenRetry(0, 0, false, editor);
    }).catch(() => {
      countDownThenRetry(5, 0, false, editor);
    });
};

const reloadWithParameter = (key, value) => {
  let url = window.location.href;
  let updatedUrl = updateQueryStringParameter(url, key, value);
  window.location.href = updatedUrl;
};

function getBodyWithAcceptedSuggestions(editor) {
  return new Promise(resolve => {
    resolve(editor.plugins.get('TrackChangesData').getDataWithAcceptedSuggestions());
  });
}

function getBodyWithRejectedSuggestions(editor) {
  return new Promise(resolve => {
    resolve(editor.plugins.get('TrackChangesData').getDataWithDiscardedSuggestions());
  });
}
