/* attaches a document event listener within a namespace, ensuring it only attaches once
 * @params listener {String}: the event listener, 'click', 'keyup', etc
 * @params cb {Object}: the callback function that will be invoked, with the event being passed in as an argument
 * @params namespace {String}: [optional] the namespace to attach the listener to
 */
export const attachDocumentListener = (listener, cb, namespace = undefined) => {
  namespace = namespace || `${listener}Attached`;
  if (!document[namespace]) {
    document.addEventListener(listener, function(e) {
      cb(e);
    })
    document[namespace] = true;
  }
}
