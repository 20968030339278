import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

function enablePhoneFields($scope) {
  document.querySelectorAll('input[type="tel"]').forEach(input => {
    if (!input._phone_field_enabled) {
      input._phone_field_enabled = true;
      intlTelInput(input, {
        hiddenInput: input.dataset.method,
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
        initialCountry: 'us'
      });
    }
  });
};

$(document).on('turbolinks:load', function () {
  enablePhoneFields($('body'));
})

$(document).on('sprinkles:update', function (event) {
  enablePhoneFields($(event.target));
})
