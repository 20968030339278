import consumer from "../channels/consumer";

var subscriptions = {};
var pendingRequests = {};
var lastProcessedResponse = {};

function scrollMax($element) {
  $element.scrollTop($element[0].scrollHeight - $element.outerHeight());
}

function stripXray(string) {
  if (string) {
    return string.replace(/<!--XRAY [A-Z]+ \d+[^>]*-->/g, '');
  }
}

function stripWhitespace(string) {
  if (string) {
    return string.replace(/\s+/g, '');
  }
}

function stripDraggableClass(string) {
  if (string) {
    return string.replace(/class=["']draggable-enabled['"]/g, '');
  }
}

function stripBootstrapAttrs(string) {
  if (string) {
    string = string.replace(/data-original-title=""/g, '')
    return string.replace(/"title="">/g, '">')
  }
}

function stripCacheBuster(string) {
  if (string) {
    string = string.replace(/%3Fscb%3D.{10}/g, '') // ?scb=10 characters
    return string = string.replace(/%26scb%3D.{10}/g, '') // &scb=10characters
  }
}

function prepareForComparison(string) {
  if (string) {
    string = stripXray(string)
    string = stripWhitespace(string)
    string = stripDraggableClass(string)
    string = stripBootstrapAttrs(string)
    string = stripCacheBuster(string)
    return string;
  }
}

// this is a place where you can strip out any funky debug html that might be different from request to request.
function htmlIsEqual(first, second) {
  first = prepareForComparison(first)
  second = prepareForComparison(second)
  return first == second
}

function refreshCollectionBase(parentName, parentId, collectionName, fromLazy = false) {
  var selector = '[data-model="' + parentName + '"][data-id="' + parentId + '"] [data-collection="' + collectionName + '"]';
  var $oldPagination = $(document).find(".search-pagination");
  $(selector).each(function(_, element) {

    var $existingCollectionBase = $(element);

    // Check if this is a dataTable.  If so, refresh like this:
    if ($(element).data('table') == true) {
      if( element.dataset['source'] != undefined ) {
        // This is an AJAX datatable, refresh using:
        $(element).DataTable().draw('full-hold');
        return;
      }
    }

    // save the state of select fields.
    var selectValues = {}
    $existingCollectionBase.find('select').each(function(_, select) {
      selectValues[select.name] = $(select).val();
    });

    // we support restoring the scroll position of overflowed divs that are being redrawn.
    // we also support restoring a scroll position that was at the bottom of the scroll area.
    var $scrollBase = $existingCollectionBase.closest(".modal.chat-style-scrolling");
    if (!$scrollBase.length) {
      $scrollBase = $existingCollectionBase.closest(".chat-style-scrolling");
    }
    if ($scrollBase.length > 0) {
      var existingScroll = $scrollBase.scrollTop();
      var existingMaxScrollTop = $scrollBase[0].scrollHeight - $scrollBase.outerHeight();
      var scrollMaxed = existingScroll > (existingMaxScrollTop - 20);
    }

    // if we're in a modal with a current url, use that to redraw the collection.
    var modalUrl = $existingCollectionBase.closest('.modal[data-url]').attr('data-url');

    // if our content was presented inline, use the url we were fetched from to redraw the collection.
    var inlineUrl = $existingCollectionBase.closest('.inline[data-url]').attr('data-url');

    var url = modalUrl || inlineUrl || document.location.href;
    // If we have an anchor in the URL, remove it:
    if (url.indexOf("#") > -1) {
      url = url.substr(0, url.indexOf("#"));
    }
    var urlWithoutCacheBuster = url;

    // The Chameleon snipit that has been added seems to cause a caching issue in Safari.  We can do this as a workaround:
    // Add a random parameter (scb - Sprinkles Cache Buster) to the url - this stops Safari (and maybe other browsers?) from caching the request
    function createScb(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
   }

    if (url.includes("?")) {
      url += "&scb=" + createScb(10);
    } else {
      url += "?scb=" + createScb(10);
    }

    var type_of_action = localStorage.getItem("type");
    var keys = [];
    if(type_of_action == "search"){
      keys = ['search_key'];
    }else if(type_of_action == "filter"){
      keys = ['statuses', 'project_ids'];
    }
    var data = {};
    keys.forEach(element => {
      data[element]  = localStorage.getItem(element);
    });
    Object.keys(data).forEach(function(key) {
      if(data[key] !== undefined && data[key] !== null);
      {
        url += "&" +  key +"=" + data[key].replace(/&quot;/g, '"');
      }
    });

    function applyUpdatedView(data) {
      var $collectionBase = $(data).find(selector).addBack(selector);
      var $newPagination = $(data).find(".search-pagination");
      // TODO we should implement _something_ like this... but it has to work. probably needs an html linter.
      if (htmlIsEqual($collectionBase.html(), $existingCollectionBase.html())) {
        if(sprinklesDebugging) {
          console.log(`🍩 ${(new Date).toISOString()} ${parentName}::${parentId}::${collectionName} The collection HTML returned exactly matches the content in the page. We are not applying the update.`);
        }
        return;
      } else {
        if(sprinklesDebugging) {
          console.log(`🍩 ${(new Date).toISOString()} ${parentName}::${parentId}::${collectionName} The collection HTML returned is different to what is in the browser...applying the update.`);
        }
      }

      var $elementsWithPersistentClasses = $existingCollectionBase.find('[data-persistent-classes]');
      $existingCollectionBase.empty();
      $existingCollectionBase.append($collectionBase.children());

      $existingCollectionBase.find('[data-persistent-classes]').each(function(_, element) {
        var $element = $(element);
        var subselector = '[data-persistent-classes][data-model="' + $element.attr('data-model') + '"][data-id="' + $element.attr('data-id') + '"]';
        var $oldElement = $elementsWithPersistentClasses.find(subselector).addBack(subselector);
        $oldElement.attr('data-persistent-classes').split(' ').forEach(function(className) {
          if ($oldElement.hasClass(className)) {
            $element.addClass(className);
          }
        })
      });

      if ($scrollBase.length > 0) {
        if (scrollMaxed) {
          scrollMax($scrollBase);
        } else {
          $scrollBase.scrollTop(existingScroll);
        }
      }

      $.each(selectValues, function(key, value) {
        $existingCollectionBase.find("select[name='" + key + "']").val(value);
      })

      // allow developers to be notified when the contents of a collection have been updated.
      // if js was applied to these elements, they'll need to reapply it.
      $existingCollectionBase.trigger('sprinkles:collection:repopulated');

      // tasks updating triggers custom event
      if (collectionName == "tasks") {
        if(sprinklesDebugging) {
          console.log(`🍩 ${(new Date).toISOString()} ${parentName}::${parentId}::${collectionName} The tasks collection is applying an update`);
        }

        $existingCollectionBase.trigger("sprinkles:update:tasks");
      }

      // we're still triggering an update, in case you subscribed to non-specific updates
      $existingCollectionBase.trigger('sprinkles:update');

      // TODO - this should really be triggered on sprinkles:member:refresh - Can we find a better place to reinitialize all the required elements that may come in via a sprinkles request?
      $existingCollectionBase.find('[data-toggle="popover"]').popover();
      $existingCollectionBase.find('[data-toggle="tooltip"]').tooltip();

      if (fromLazy) {
        let currentUrl = window.location.href;
        if (currentUrl.indexOf("#") > -1) {
          let anchor = currentUrl.substr(currentUrl.indexOf("#"), currentUrl.length);
          let div = $(anchor);
          if (div.length) {
            div[0].scrollIntoView();
          }
        }
      }

      function removeQueryStringParams(link) {
        if(link.length > 0){
          url = link.attr('href');
          link.attr('href', url.replace(/(scb=([^&]*))|(layoutless=([^&]*))/g, ''));
        }
      }

      if($oldPagination != null && $oldPagination !== undefined){
        $oldPagination.replaceWith($newPagination);
          removeQueryStringParams($(".page.next a").not(".disabled"));
          removeQueryStringParams($(".page.prev a").not(".disabled"));
      }
    };

    // if there is already a pending request to this url ..
    if (pendingRequests[urlWithoutCacheBuster]) {

      // don't trigger another request, just register ourselves to also be a recipient of that payload.
      pendingRequests[urlWithoutCacheBuster].push(applyUpdatedView);

    } else {

      // otherwise, register ourselves as the first to be a recipient of the payload.
      pendingRequests[urlWithoutCacheBuster] = [applyUpdatedView];

      function fetchContent(urlRequesterCount) {

        // keep track when when we're dispatching this request.
        var requestDispatchedAt = Date();

        // and when the response comes in ..
        $.get(url, {layoutless: true}, function(data) {

          // don't even bother processing this request if it was requested before a request we've already processed.
          if (lastProcessedResponse[urlWithoutCacheBuster] && lastProcessedResponse[urlWithoutCacheBuster] > requestDispatchedAt) {
            return;
          } else {
            lastProcessedResponse[urlWithoutCacheBuster] = requestDispatchedAt;
          }

          // console.log("🍩 By the time the results got back, we had " + pendingRequests[urlWithoutCacheBuster].length + " folks waiting in total, vs. " + urlRequesterCount + " when we began.");

          var processedRequests = pendingRequests[urlWithoutCacheBuster];

          // only bother applying these updates if no additional folks have requested from the same url.
          // if others have requested from the same url, we need to fetch a newer version anyway.
          // TODO we can improve this by tracking local updates to individual sections of the page.
          if (processedRequests.length <= urlRequesterCount) {
            delete pendingRequests[urlWithoutCacheBuster];
            $.each(processedRequests, function(_, scopedApplyUpdatedView) {
              scopedApplyUpdatedView(data);
            });
          } else {

            // actually, we can actually still apply these updates to the view as long as the view author hasn't
            // specifically warned us not to. they may want to withhold these updates from being presented to the
            // user on views that are heavy in multiple-step client-side manipulation, like dragging and dropping
            // on the kanban board.
            if ($existingCollectionBase.attr('data-suppress-outdated-view-updates') === undefined) {
              $.each(processedRequests, function(_, scopedApplyUpdatedView) {
                scopedApplyUpdatedView(data);
              });
            }

            // if there were follow-alongs that jumped onboard to wait for this request, we actually need to send one more
            // request to the server just to make sure no results changed after the first request was made, as subsequent
            // changes that wouldn't be represented in that payload might have been waht caused the additional requests
            // to the same url. this is still the best approach because we can discard it if the results are the same (e.g.
            // not do a redraw of the elements) and in the case of multiple follow-alongs, we're consolidating those
            // requests to _one_ extra, instead of many extra.

            // and when the response comes in ..
            fetchContent(pendingRequests[urlWithoutCacheBuster].length);
          }

        });

      }

      setTimeout(function() {
        fetchContent(pendingRequests[urlWithoutCacheBuster].length);
      }, 100);

    }
  });
}

let sprinklesDebugging = false;

var renewedSubscriptions = {};

function subscribeToCollections() {
  $("[data-collection]").each(function(_, element) {
    var $element = $(element);
    var $parent = $element.closest('[data-model]');
    var parentName = $parent.attr('data-model');
    var parentId = $parent.attr('data-id');
    var collectionName = $element.attr('data-collection');

    var lazyLoad = $element.find("[data-lazy]").data("lazy") == true;

    if (lazyLoad) {
      refreshCollectionBase(parentName, parentId, collectionName, true);
    }

    var key = parentName + ":" + parentId + ":" + collectionName;
    if(renewedSubscriptions[key] !== undefined) {
      return;
    } else {
      if(sprinklesDebugging) {
        console.log("This is a new subscription!", key);
      }
    }

    if (subscriptions[key]) {
      renewedSubscriptions[key] = subscriptions[key];

    } else {
      var timer;
      renewedSubscriptions[key] = consumer.subscriptions.create({
        channel: 'Sprinkles::CollectionsChannel',
        parent_name: parentName,
        parent_id: parentId,
        collection_name: collectionName
      }, {
        received(data) {
          if(sprinklesDebugging) {
            console.log(`🍩 ${(new Date).toISOString()} Received sprinkles collection update trigger for ${parentName}::${parentId}::${collectionName} - Refreshing the view.`);
          }
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }

          timer = setTimeout(function() {
            refreshCollectionBase(parentName, parentId, collectionName);
          }, 100);

        },
      });

    }
  });

  $.each(subscriptions, function(key, subscription) {
    if (!renewedSubscriptions[key]) {
      consumer.subscriptions.remove(subscription);
    }
  });

  subscriptions = renewedSubscriptions;
}

$(document).on('turbolinks:load', function() {
  sprinklesDebugging = document.querySelector("body").dataset['sprinklesDebugging'] == "true";
  localStorage.removeItem("statuses");
  localStorage.removeItem("project_ids");
  localStorage.removeItem("search_key");
  localStorage.setItem("type", "");
  subscribeToCollections();
})

$(document).on('sprinkles:update', function(event) {
  subscribeToCollections();
})

// TODO keep track of connections and each time the page changes, see whether you need to unsubscribe from any.
// TODO only broadcast _after_ all the transaction has committed to the database.
// TODO save a list of all the channels to broadcast to and then broadcast to them once at the end of the entire transaction.
