
// This function allows us to use a single cache for users with different permission levels
// by tagging elements with data attributes, we can show/hide them depending on the user's permissions
function customizeCache(scope) {
  const actions = ['index', 'show', 'new', 'create', 'edit', 'update', 'destroy']
  for(const action of actions) {
    // Find any cached items that we need to check for visibility
    const itemAttribute = `data-cache-item-${action}`
    const itemSelector = `[${itemAttribute}]`
    for(const cacheItem of scope.querySelectorAll(itemSelector)) {
      // Loop through each found item and check the ability cache above it in the DOM
      const cacheItemType = cacheItem.getAttribute(itemAttribute)
      const abilityStoreAttribute = `data-cache-${action}-${cacheItemType}`
      const abilityStoreSelector = `[${abilityStoreAttribute}]`
      const abilityStore = cacheItem.closest(abilityStoreSelector)
      if (typeof(abilityStore) === 'undefined' || abilityStore === null) {
        console.error(
                'Fragment cache error:',
                `the call to \`cache_can? :${action}, ${cacheItemType}\` in the Rails view on this page is broken\n`,
                `Could not find a related abilityStore outside of the cached object.\n`,
                `Ensure you have added \`current_user_abilities_for(${cacheItemType})\` to an HTML element outside of the fragment cache.\n`
          )
      } else {
        const result = abilityStore.getAttribute(`${abilityStoreAttribute}`)
        if (result == "true") {
          cacheItem.classList.remove("d-none")
        } else {
          cacheItem.classList.add("d-none")
        }
      }
    }
  }
}

// @note - sprinkles is using jquery events
// You can't catch a jquery event using vanila js
// https://makandracards.com/makandra/71890-events-triggered-by-jquery-cannot-be-observed-by-native-event-listeners
// If we swap jquery out of sprinkles at some stage, we could use the native versions of these instead

$(document).on("turbolinks:load", function() {
  customizeCache(document.querySelector('body'))
})

$(document).on("sprinkles:update", function(event) {
  customizeCache(event.target)
})

$(document).on("cache:refresh", function(event) {
  customizeCache(event.target)
})

// document.addEventListener("turbolinks:load", function() {
//   customizeCache(document.querySelector('body'))
// })

// document.addEventListener("sprinkles:update", function(event) {
//   customizeCache(event.target)
// })


// document.addEventListener("cache:refresh", function(event) {
//   customizeCache(event.target)
// })
