$(document).on('turbolinks:load', function () {
  if ($("#memberships-datatable").length) {
    window.membershipsTable = $("#memberships-datatable").DataTable({
      processing: true,
      searching: false,
      serverSide: true,
      order: [[0, "asc"]],
      ajax: {
        url: $("#memberships-datatable").data('source'),
        data: function (d) {
          d.search = { value: $("#search-memberships-datatable").val(), regex: false }
          d.scope = $("#memberships-datatable").attr("data-former") == "true" ? "tombstones" : "current_and_invited"
        }
      },
      language: {
        "sLengthMenu": "Showing_MENU_",
        "info": "of _TOTAL_ Users",
        "oPaginate": {
          "sFirst": "",
          "sLast": ""
        }
      },
      pagingType: "full_numbers",
      "dom": "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
      columnDefs: [
        {
          targets: [0],
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).closest("tr").attr('data-id', rowData['DT_RowId']);
            $(td).addClass("profile-photos memberships-photo");
          }
        },
        {
          targets: [2],
          createdCell: function (td, cellData, rowData, row, col) {
            const is_former = $("#memberships-datatable").attr("data-former") == "true";
            window.membershipsTable.columns(2).visible(!is_former);
            td.classList.add('text-lowercase');
          }
        },
        {
          targets: [3],
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).closest("tr").attr('data-id', rowData['DT_RowId']);
            $(td).addClass("text-right");
          }
        }
      ],

      columns: [
        { data: "team_member", width: "20%" },
        { data: "role", width: "20%" },
        { data: "email", width: "40%" },
        { data: "dt_actions", width: "20%", orderable: false },
      ],
      drawCallback: function() {
        $(document).ready(function() {
          $('.custom-tooltip').tooltip({
            template: '<div class="tooltip custom-tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
          });
        });

        if ($('#memberships-datatable_processing')) {
          $('#memberships-datatable_processing').attr('role', 'alert');
        }
      }
    });

    //setup before functions
    var typingTimer;                //timer identifier
    var doneTypingInterval = 1000;  //time in ms, 1 second for example
    var $input = $('#search-memberships-datatable');

    //on keyup, start the countdown
    $input.on('keyup', function () {
      $(this).val() ? $("#search-clear").removeClass("d-none") : $("#search-clear").addClass("d-none")
      clearTimeout(typingTimer);
      typingTimer = setTimeout(window.membershipsTable.ajax.reload, doneTypingInterval);
    });

    //on keydown, clear the countdown
    $input.on('keydown', function () {
      clearTimeout(typingTimer);
    });

    if ($input.length && $input.val().length == 0) {
      $("#search-clear").addClass("d-none");
    }

    $("#search-clear").on('click', function () {
      $input.val('')
      $input.trigger('keyup');
      $("#search-clear").addClass("d-none");
    })
  }

  $(".tab-link").click(function () {
    if ($(this).attr("data-collection") == "memberships")
    {
      //check current or former user type
      is_former = $(this).attr("data-former") == "true";

      //set to data-former attr
      $("#memberships-datatable").attr('data-former', is_former);
      setTimeout(function () { window.membershipsTable.ajax.reload() }, 300);

      //add active class to current tab
      $(".tab-link").parents("li").removeClass("active");
      $(this).parent().addClass("active");

      //set header and description based on user type
      if (is_former == true) {
        header = "Deactivated Users";
        $(".membership-new-btn").addClass("d-none")
      }
      else {
        header = "Users";
        $(".membership-new-btn").removeClass("d-none")
      }
      $(".memberships-header").html(header);
      $(".memberships-desc").html(setMessage());
    }
  })

  function setMessage() {
    var team_name = $("#team_name").val();
    if (is_former) {
      return "The following people have been removed from " + team_name + ", but you can still manage their profile and reassign their assignments to other team members."
    }
    else {
      return "The following people are members of your team. You can also invite new team members."
    }
  }

  $(document).on('click', ".copy-btn", function () {
    var url = $(this).attr("data-copy-url");
    navigator.clipboard.writeText(url);
    var tooltip = $(this).children(".copied_password");
    tooltip.text("Copied Successfully");
  })

  $(document).on('mouseout', ".copy-btn", function () {
    var tooltip = $(this).children(".copied_password");
    tooltip.text(tooltip.attr("data-text"));
  })
});
