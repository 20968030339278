$(document).on('turbolinks:load', function () {
  if($('.teams.configuration').length) {
    AccountTeamsConfiguration.initConfigurationSidebar();
    AccountTeamsConfiguration.setupTabListLinkClick();
    AccountTeamsConfiguration.setupFilter();
  }
})
class AccountTeamsConfiguration {

  // Define class methods as static
  static initConfigurationSidebar() {
    var hash = location.hash.substring(1);
    if (hash != '') {
      $('.' + hash).removeClass('d-none'); // show the active partial
      $('#' + hash).addClass('active'); //active the sidebar link
    }
    else {
      $(".templates-element").removeClass('d-none') // Initially show the templates
      $('#templates-element').addClass('active'); // active the sidebar link
    }

    $('.sidebar-list-link').on('click', function () {
      var sidebar_active_ele = $('.sidebar-list-link.active');
      var prevId = sidebar_active_ele.attr('id');
      var currentId = $(this).attr('id');

      //remove active class from previous active element and add to current element
      sidebar_active_ele.removeClass('active');
      $(this).addClass('active');
      $('.' + prevId).addClass('d-none');
      $('.' + currentId)
        .removeClass('d-none')
        .find(`${AccountTeamsConfiguration.tabListLinkSelector}:first`)
        .addClass("active")
        .trigger('click');
    });
  }

  static setupFilter() {
    $('.filter-dropdown .form-group').on('click', function (event) {
      event.stopPropagation();
    });

    $(document).on('click', '.filter_cancel', function () {
      const isCancel = true;
      const boundOnFilterClick = AccountTeamsConfiguration.onFilterClick.bind(this);
      boundOnFilterClick(isCancel);
    });

    $('.dropdown').on('show.bs.dropdown', function() {
      const boundOnDropdownClick = AccountTeamsConfiguration.onDropdownClick.bind(this);
      boundOnDropdownClick();
    })

    $(document).on('click', '.filter_apply', function () {
      const boundOnFilterClick = AccountTeamsConfiguration.onFilterClick.bind(this);
      boundOnFilterClick();
    });
  }

  static onFilterClick(isCancel=false) {
    const filterName = $(this).attr('data-filter-name');
    const filterButtonSelector = $(this).attr('data-filter-button-selector');
    const dataTableName = $(this).attr('data-apply-to-datatable');
    let filterStatus = {};

    if (isCancel) {
      $(`[id^=${filterName}_]`).filter((i, s) => $(s).val([]).trigger('change'));
    }

    $(`[id^=${filterName}_]`).each((_, filter) => {
      filterStatus[filter.id] = [];
      $(filter).find('option:selected').each((_, selectedOption) => {
        filterStatus[filter.id].push(selectedOption.value);
      })
    })

    $(this).closest('.dropdown').attr('data-filter-status', JSON.stringify(filterStatus))

    AccountTeamsConfiguration.updateFilterCount(filterButtonSelector, filterName);
    setTimeout(function () { window[dataTableName].ajax.reload() }, AccountTeamsConfiguration.dataTableAjaxReloadWait);
  }

  static onDropdownClick() {
    const filterName = $(this).find('*[data-filter-name]').attr('data-filter-name')
    let filterStatus = $(this).attr('data-filter-status');
    if (filterStatus) {
      filterStatus = JSON.parse(filterStatus)
      Object.keys(filterStatus).forEach(function (key) {
        $(`#${key}`).val(filterStatus[key]).change();
      })
    } else {
      $(`[id^=${filterName}_]`).filter((i, s) => $(s).val([]).change())
    }
  }

  static updateFilterCount(filterButtonSelector, filterName) {
    const filterCount = $(`[id^=${filterName}_]`)
                          .filter((i, s) => $(s)[0].selectedIndex >= 0)
                          .length
    const filterHtml = `Filters ( ${filterCount} )`;

    $(filterButtonSelector).html(filterHtml)
  }

  static setupTabListLinkClick() {
    $(AccountTeamsConfiguration.tabListLinkSelector).on('click', function () {
      const boundTemplatesOnTabListLinkClick = AccountTeamsConfiguration.onTabListLinkClick.bind(this)
      boundTemplatesOnTabListLinkClick();
    })
  }

  /**
   * Allows dataTable to reload data for the appropriate tab clicked
   * Depends on html attributes being set for the .tab-list-link that is clicked
   * "data-table-dom-id": The table dom ID that `DataTable()` is invoked upon
   * "data-table-name": the data table name, usually set on the window. Ex: for window.worksheetsDatatable, worksheetsDatatable is the data-table-name
   * "data-collection": the collection name, used to set up the header and description HTML
   * "data-archive": "true" or "false" used to dtermine if we are on the Archived tab
   * @returns undefined; sets up DOM tab with provided data attributes
   */
  static onTabListLinkClick() {
    // `this` needs to be bound to the click event
    if (!this) { return }

    const tableDomId = $(this).attr("data-table-dom-id");
    const dataTableName = $(this).attr("data-table-name");
    if (!window[dataTableName]) {
      console.warn("Please configure data attributes for this .tab-list-link item");
      return;
    }

    const templatesNamePlural = $(this).text();
    const templatesTableBodyTag = `#${tableDomId} > tbody`;
    const collectionName = $(this).attr('data-collection')
    const is_archive = $(this).attr("data-archive") == "true";
    $(`#${tableDomId}`).attr('data-archive', is_archive);

    setTimeout(function(){ window[dataTableName].ajax.reload() }, AccountTeamsConfiguration.dataTableAjaxReloadWait);

    // set 'active' class when clicked
    $(`${AccountTeamsConfiguration.tabListLinkSelector}.active`).removeClass("active");
    $(this).addClass("active");

    // setup header and description text
    let header = templatesNamePlural;
    if (is_archive)
    {
      $(".configuration-box-title-btn").addClass("d-none");
    }
    else {
      $(".configuration-box-title-btn").removeClass("d-none");
    }
    $(`.${collectionName}-header`).html(header);
    $(`.${collectionName}-desc`).html(
      AccountTeamsConfiguration.setTemplateDescriptionMessage(
        templatesNamePlural,
        templatesTableBodyTag
      )
    );
  }

  static setupDatatableXhrEvents(dtInstance, searchEle, timeXhr=false) {
    const $searchEle = $(searchEle)
    if (timeXhr) {
      window.dtXhrStart=Date.now();
      window.dtXhrEnd=Date.now();
    }
    // see https://datatables.net/reference/event for more info on DataTables events
    dtInstance.on('preXhr.dt', function() {
      timeXhr ? dtXhrStart = Date.now() : ''
      AccountTeamsConfiguration.disableInput($searchEle)
    })
    dtInstance.on('xhr.dt', function() {
      if (timeXhr) {
        dtXhrEnd=Date.now();
        const difference = ((dtXhrEnd - dtXhrStart)/1000);
        console.log(`request took ${difference} seconds`);
      }
      AccountTeamsConfiguration.enableInput($searchEle)
    })
  }

  static enableInput($input) {
    $input.attr('disabled', false)
    $input.trigger('focus');
  }

  static disableInput($input) {
    $input.attr('disabled', true)
  }

  static setTemplateDescriptionMessage(templateNamePlural, templateTableBodyTag) {
    const teamName = $("#team_name").val();
    let descriptionMessage;
    if ($(templateTableBodyTag).length > 0) {
      descriptionMessage = `Below is a list of ${templateNamePlural} that have been added to ${teamName}. You can manage them with the options below.`
    }
    else {
      descriptionMessage = `No ${templateNamePlural} have been added for ${teamName} yet. To get started, use the button below!`;
    }
    return descriptionMessage;
  }

  static hideTableColumns(indexes, table_id, api) {
    for (const index in indexes) {
      api.column(index).visible($(table_id).attr('data-archive') !== 'true');
    }
  }
}

// Define class variables
AccountTeamsConfiguration.tabListLinkSelector = '.tab-list-link';
AccountTeamsConfiguration.dataTableAjaxReloadWait = 300;

module.exports = AccountTeamsConfiguration;
