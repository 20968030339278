$(document).on('turbolinks:load', () => {
  $(document).ready(function () {
    var hash = location.hash.substring(1);
    if (hash != '') {
      $(`.${hash}-tab`).removeClass('d-none'); // show the active partial
      $(`#${hash}-tab`).addClass('active'); //active the sidebar link
    } else {
      $('.organizations-tab').removeClass('d-none') // Initially show the Organizations
      $('#organizations-tab').addClass('active'); // active the sidebar link
    }
  })

  $('.sidebar-list-link').click(function(){
    var sidebar_active_ele = $('.sidebar-list-link.active');
    var prevId = sidebar_active_ele.attr('id');
    var currentId = $(this).attr('id');

    //remove active class from previous active element and add to current element
    sidebar_active_ele.removeClass('active');
    $(this).addClass('active');
    $('.'+ prevId).addClass('d-none');
    $('.'+ currentId).removeClass('d-none').find('.tab-list-link:first').addClass('active').click();
  });
});
