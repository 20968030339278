import consumer from "../../channels/consumer"

$(document).on('click', ".use-example-link", function (event) {
  event.preventDefault();
  if (confirm("Are you sure? This will override any existing data.")) {
    var exampleData = $(event.target).closest(".element-box").find(".ck-content");
    useExample(exampleData);
  }
});

$(document).on('click', ".use-snapshot-link", function (event) {
  event.preventDefault();
  const snapshotData = $(event.target).closest(".element-box").find(".ck-content");
  useExample(snapshotData);
  $(event.target).closest(".modal").trigger("close-modal")
})

function useExample(exampleData) {
  const domEditableElement = document.querySelector('.ck-editor__editable');
  const editorInstance = domEditableElement.ckeditorInstance;
  editorInstance.data.set(exampleData.prop('outerHTML'), { suppressErrorInCollaboration: true });
}

$(document).on('click', ".complete-section-button", function (event) {
  event.preventDefault();
  var time = prompt("In minutes, approximately how long did it take you to complete this section? (eg enter 45 if it took around 45 minutes to complete)");
  if (!time) {
    return;
  }
  var $form = $("form.mark-complete-form");
  $form.find("input[name='solicitations_section[time_taken_to_complete]']").val(time);
  $form.submit();
});

var subscriptions = {};

$(document).on("turbolinks:load", function () {
  if ($("[data-section-name]").length) {
    var sectionId = $("[data-section-name]").attr("data-section-name");
    subscriptions[sectionId] = consumer.subscriptions.create({
      channel: 'SectionNameChannel',
      sectionId: sectionId,
    }, {
      received(data) {
        if ($("[data-section-name]").length) {
          let sectionId = data.id;
          let newTitle = data.title;
          let finder = `section-name-${sectionId}`;
          let element = document.getElementById(finder);
          if (element) {
            element.textContent = newTitle;
          }
        }
      }
    });
  }
  $.each(subscriptions, function (key, subscription) {
    let sectionId = key;
    if ($(`[data-section-name='${sectionId}']`).length == 0) {
      consumer.subscriptions.remove(subscription);
      delete subscriptions[key];
    }
  })

  $(document).on('change', '.section-status-dropdown', function (e) {
    if (!e.handled) {
      e.handled = true;
      var section_id = $(this).closest('.section-container').data('id');
      var status = $(this).val();
      $.ajax({
        type: "POST",
        url: '/account/solicitations/sections/' + section_id + '/change_section_status?status=' + status
      });
    }
  })

});
