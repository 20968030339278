require("select2/dist/css/select2.min.css");

import 'select2';
import { escape } from 'lodash';

// Adjusts the aria-labelledby of the select2-selection to match the label of the select
// Without doing this, the aria-labelledby is pointing to the span.select2-selection__rendered which contains the same value as select2-selected
function ariaLabelledByMatchSelectLabel($select) {
  const $label = $($select.siblings('label')[0]);
  const $selectionContainer = $($select.siblings('.select2-container'));
  const $select2Selected = $($selectionContainer.children('.selection').children('.select2-selection'));
  $select2Selected.attr('aria-labelledby', $label.attr('id'));
}

/*
  Some of the select2 inputs add the dropdown and searchfield to the DOM once it
  is clicked. It appends it to the `body`. So we need to just add a MutationObserver
  to watch for it to be appended to the body, then find the search field and add a aria-label.
  Since it is added and removed based on whether it has focus, we don't have
  any context on what input we are searching for, so a generic label is added.
*/
function addObserverForSearchFields() {
  const observer = new MutationObserver(function (mutations_list) {
    mutations_list.forEach(function (mutation) {
      mutation.addedNodes.forEach(function (added_node) {
        if (added_node.nodeType==1 && added_node.classList.contains('select2-container--default')) {
          const searchField = added_node.querySelector('.select2-search__field');
          if (searchField) {
            searchField.setAttribute('aria-label', 'Search input options');
          }
          observer.disconnect();
        }
      });
    });
  });

  observer.observe(document.body, { subtree: false, childList: true });
}

/*
  Most of the select2 inputs are loaded into the DOM as expected and we can find them
  and their associated labels and add a proper aria-label to it.
*/
function addAriaLabelToSearchFields() {
  document.querySelectorAll('.select2-search__field').forEach(searchField => {
    const formFieldContainer = searchField.closest('.form-group');
    if (formFieldContainer) {
      const fieldLabel = formFieldContainer.querySelector('label');
      if (fieldLabel) {
        const labelText = fieldLabel.innerText;
        searchField.setAttribute('aria-label', `Search ${labelText}`);
      }
    }
  });
}

// Check select2 version 4.1 when it is released to see if this and other accessibility concerns are addressed
function addAccessibleAttrs($select) {
  try {
    ariaLabelledByMatchSelectLabel($select);
    addObserverForSearchFields();
    addAriaLabelToSearchFields();
  } catch (error) {
    console.error('could not add accessibility attributes to select2: ', error);
  }
}

function enableSelects($scope) {
  $scope.find('.select2').each(function (index, select) {
    enableSelect(select);
  });
}

function enableSelect(select) {
  var $select = $(select);
  var options = {};

  if ($select.hasClass('disable-search')) {
    options.minimumResultsForSearch = -1;
  }

  options.tags = $select.hasClass('accepts-new');

  // https://stackoverflow.com/questions/29290389/select2-add-image-icon-to-option-dynamically
  function formatState(opt) {
    var imageUrl = $(opt.element).attr('data-image');
    var errorImageUrl = $(opt.element).attr('data-image-onerror');
    var imageHtml = "";
    if (imageUrl) {
      var image = $("<img/>");
      image.attr("src", imageUrl);
      if (errorImageUrl) {
        image.attr("onerror", errorImageUrl);
      }
      imageHtml = image.prop('outerHTML');
    }
    var element = $('<span>' + imageHtml + escape(opt.text) + '</span>');
    return element;
  };

  options.templateResult = formatState;
  options.templateSelection = formatState;

  $select.select2(options);

  if ($select.hasClass('accessible-via-js')) {
    addAccessibleAttrs($select);
  }

  $select.siblings('.select2-container').find('input').attr('aria-label', $select.attr('aria-label'))
}

$(document).on('turbolinks:load', function () {
  enableSelects($('body'));
})

$(document).on('sprinkles:update', function (event) {
  enableSelects($(event.target));
})

export { enableSelects, enableSelect };
