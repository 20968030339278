import GridFromTable from './GridFromTable'
import { compareObjectData } from '../../utils/utils'

$(document).on('turbolinks:load', function() {
  insertSortedTable();
});

function insertSortedTable() {
  const grids = document.querySelectorAll('.js-ag-grid')
  let initializedGrids = {}

  const createGrids = (grid, i) => {
    const gridFromTable = new GridFromTable(grid, {
      id: `grid-${i}`
    })

    Object.assign(initializedGrids, gridFromTable.initGrid())
  }

  // Needs to be here so it's added after the grid is initialized.
  $(document).on('click', ".ag-tab[aria-label='filter']", function() {
    if ($('.ag-filter-no-matches')) {
      $('.ag-filter-no-matches').attr('role', 'alert');
    }
  });

  const actionCableHandler = (event) => {
    const updatedTable = event.target.querySelector('table') || $('table.js-ag-grid')[0]
    const grid = event.target.querySelector('table') ? initializedGrids[updatedTable.id] : initializedGrids['']

    if (!grid) {
      console.warn('Could not update the grid via Action Cable.', grid)
      return
    };

    const gridApi = grid.gridOptions.api
    const editingCells = gridApi.getEditingCells().map((cell) => cell && cell.rowIndex)
    const tableData = GridFromTable.extract(updatedTable)

    tableData.rows.forEach((row) => {
      const node = gridApi.getRowNode(row.rowPosition)
      const isCellBeingEdited = editingCells.includes(node.childIndex)
      const shouldUpdateRow = !isCellBeingEdited

      if (shouldUpdateRow) {
        if (!compareObjectData(node.data, row)) {
          node.setData(row)
          gridApi.flashCells({ rowNodes: [node] })
        }
      }
    })
  }

  Array.from(grids).forEach(createGrids)

  if ($(grids).hasClass('evaluations-workbook-grid')) {
    $(document).on('sprinkles:update', function() {
      const grids = document.querySelectorAll('.js-ag-grid.ag-grid-nested')

      if (!$('.ag-theme-alpine').length) {
        Array.from(grids).forEach(createGrids)
      }
    })
  } else {
    $(document).on('sprinkles:update', actionCableHandler)
  }
}
