import { RevealOperator } from "../reveal_operator";

/**
 * Reveal Operator that returns true if any of the source elements are checked
 *
 * Common use case is hiding a field on a form until the user has selected another field of data first.
 */
export class IsChecked extends RevealOperator {
  _valueCheck(_) {
    return this.sourceElements.some(source => (source.element.checked) );
  }
}
