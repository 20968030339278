import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {

    this.check = _.debounce(this._check.bind(this), 500);
    this.element.addEventListener('ckeditor:collaborative-editor:initialized', this.check)

    const observer = new MutationObserver(this.check)
    observer.observe(this.element, {
      subtree: true,
      characterData: true
    })
  }

  _check() {
    this.dispatch(this._eventToFire, {bubble: true})
  }

  get _eventToFire() {
    return this._exceeding ? 'exceeding' : 'ok'
  }

  get _exceeding() {
    return this.element.scrollWidth > this.element.clientWidth;
  }
}