import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['button', 'input'];
  }

  connect() {
    this._updationObserver = new MutationObserver(() => {
      this.updateButtons()
    })
    this.updateButtons();
    this._observeInputDisability();
  }

  updateButtons() {
    this.buttonTargets.forEach((button, index) => {
      const input = this.inputTargets[index];
      button.setAttribute('aria-pressed', input.checked)
      if (input.disabled) {
        button.setAttribute('disabled', true);
      } else {
        button.removeAttribute('disabled');
      }
    });
  }

  /**
   * Observes the inputs disabled attribute - when it changes it re-triggers the updateButtons function so that the updateButtons disabled state
   * state stays in sync with the input disabled state.
   */
  _observeInputDisability() {
    this.inputTargets.forEach(input => {
      this._updationObserver.observe(input, {
        attributeFilter: ['disabled']
      })
    })
  }
}
