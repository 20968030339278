import AutoNumeric from 'autonumeric';

function enableCurrencyFields($scope) {
  window.AutoNumeric = AutoNumeric;
  $scope.find(".currency-field").each(function () {
    var options = {
      currencySymbol: "$",
      unformatOnSubmit: true,
      modifyValueOnWheel: false,
      modifyValueOnUpDownArrow: false
    };

    new AutoNumeric(this, options);
  });

}

$(document).on('turbolinks:load', function () {
  enableCurrencyFields($('body'));
})

$(document).on('sprinkles:update', function (event) {
  enableCurrencyFields($(event.target));
})
