import { Controller } from "@hotwired/stimulus";
/**
 * Currently not being used but will most likely be used with upcoming
 * toast notifications
 *
 * Add 'fade' to the data-controller attributes and
 * data-fade-target attribute of 'out'and
 * data-delay attribute that represents mSecs until action begins
 * Future intent with targets is to allow for 'in'
 *
 */
export default class extends Controller {
  static get targets() {
    return ['out'];
  }

  outTargetConnected(ele) {
    const delay = ele.dataset.delay
    if (!delay) return;
    this.fadeOut(ele, delay);
    if (ele.dataset.clickToDismiss) {
      ele.addEventListener('click', this._handleClickToDismissEvent, { once: true })
    }
  }

  fadeOut(element, delay) {
    let opacity = 1.0;
    const fader = () => {
      setInterval(() => {

        if (opacity <= 0.03) {
          clearInterval(fader);
          element.remove();
        }
        element.style.opacity = opacity;
        element.style.filter = `alpha(opactiy=${opacity * 100})`;
        opacity -= opacity * 0.03;
      }, 20);
    }

    setTimeout(fader, delay);
  }

  _handleClickToDismissEvent(event) {
    event.currentTarget.classList.add('d-none'); // incase the user gets impatient
  }
}