import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['persisted', 'select', 'submissionButton'];
  }

  connect() {
    const confirmMessage = this.selectTarget.dataset.confirmMessage;
    const submissionButton = this.submissionButtonTarget;

    if (!confirmMessage) return;

    // Persist this if persisted target exists. Used in situations where a form may be reloaded, such as when using `form_target: 'responsive'`
    if (this.hasPersistedTarget) {
      this.persistedTarget.value = true
    }

    // The only way to trigger events changes for select2 is using jquery
    $(this.selectTarget).on('select2:select', function() {
      submissionButton.setAttribute('data-confirm', confirmMessage);
    });

    // This is for the button that also needs to prop the data-confirm
    $(this.selectTarget).on('change', function() {
      submissionButton.setAttribute('data-confirm', confirmMessage);
    });

    // Needed to add change for the other button as the target was always the first button.
    const selectTargetSibiling = $(this.selectTarget).parent().next().children(":first");
    selectTargetSibiling.on('change', function () {
      submissionButton.setAttribute('data-confirm', confirmMessage);
    })
  }
}
