import { DatatablesBase } from "../base";

export default class extends DatatablesBase {
    static get searchConfig() {
        return { searching: false }
    }

    static get languageConfig() {
        return {
            lengthMenu: "Showing _MENU_",
            sInfo: 'of _TOTAL_ invoices',
            oPaginate: {
                "sFirst": "",
                "sLast": ""
            },
            infoEmpty: ''
        };
    }

    static get emptyTableConfig() {
        return {
            oLanguage: {
                sEmptyTable: '<div><div class="datatables--blank-document"></div><h3 role="alert">No Invoices Added Yet</h3><p class="no-text-transform">Click the Add New Invoice button below to add your first invoice.</p></div>'
            }
        }
    }

    static get orderingConfig() {
        return { ordering: false }
    }

    static get columnConfig() {
        return [
            {
                data: 'label',
                orderable: false
            },
            {
                data: 'candidate_name',
                orderable: false
            },
            {
                data: 'milestones',
                orderable: false
            },
            {
                data: 'status',
                orderable: false
            },
            {
                data: 'total_invoiced',
                orderable: false
            },
            {
                data: 'deliverables',
                orderable: false
            }
        ]
    }
}
