import { Controller } from "@hotwired/stimulus";
import { getMention, customItemRenderer } from "../../account/fields/ckeditor";
import MultirootEditor from "../../account/ckeditor/multiroot_editor";
import { ckeditorImage, ckeditorNumericFontSizeConfig, ckeditorTable, ckeditorToolbar, headingsConfig, wproofreaderConfig, codeBlockConfig } from "../../account/ckeditor/shared";

const defaultLockID = Symbol('default-readonly-lock');
export default class extends Controller {
  static get targets() {
    return [
      'columnSelect'
    ]
  }

  initialize() {
    this['ckeditors'] = [];
    this.feeds = this.assignFeeds();
    this.editables = this.getEditables();
    this.cloudServicesConfig = {
      tokenUrl: this.element.dataset.ckeditortokenurl,
      uploadUrl: this.element.dataset.ckeditoruploadurl,
      webSocketUrl: this.element.dataset.ckeditorwebsocketurl,
      bundleVersion: this.element.dataset.ckeditorversion
    }
  }

  connect() {
    this.enableMultiRoot();
    this.element[this.identifier] = this;
  }

  columnSelectTargetConnected(ele) {
    const marginalPlacement = ele.dataset['multiMarginalLayout'];
    const component = this;
    $(ele).on('change', (evt) => {
      component.onColumnLayoutSelect(evt, marginalPlacement);
    })
  }

  onColumnLayoutSelect(evt, marginalPlacement) {
    // update hidden output textarea with new layout
    const outputArea = this.element.querySelector('textarea');
    const editor = this['ckeditors'][0];
    if(editor) {
      outputArea.value = this.dataValue(editor);
    }
  }

  enableMultiRoot() {
    const outputArea = this.element.querySelector('textarea');
    outputArea.style.display = 'none';
    const sections = {};
    const placeholders = {};

    this.editables.forEach( editable => {
      sections[editable.id] = editable;
      placeholders[editable.id] = editable.dataset.placeholder;
    })

    const dataValue = (editor) => this.dataValue(editor);

    this.editor = MultirootEditor.create(sections, {
      toolbar: ckeditorToolbar,
      licenseKey: process.env.CKE_LICENSE_KEY,
      image: ckeditorImage,
      table: ckeditorTable,
      placeholder: placeholders,
      cloudServices: this.cloudServicesConfig,
      collaboration: {
        channelId: this.element.dataset.ckeditordocumentid
      },
      mention: {
        feeds: this.feeds
      },
      list: {
        properties: {
          startIndex: true
        }
      },
      fontSize: ckeditorNumericFontSizeConfig,
      heading: headingsConfig,
      wproofreader: wproofreaderConfig,
      codeBlock: codeBlockConfig
    }).then( newEditor => {
      this['ckeditors'].push(newEditor);
      if (this.element.classList.contains('readonly')) {
        newEditor.enableReadOnlyMode(defaultLockID)
      }
      this.element.querySelector('.toolbar').appendChild(newEditor.ui.view.toolbar.element);
      newEditor.model.document.on('change:data', function(e) {
        outputArea.value = dataValue(newEditor);
      })

      newEditor.model.document.on('change:data', () => {
        this.dispatch('change');
      })

      newEditor.on('destroy', () => newEditor.ui.view.toolbar.element.remove());
    }).then( () => {
      this.dispatch('ready')
    }).catch( err => {
      console.group(err.stack);
    })
  }

  dataValue(editor) {
    let result = '';
    this.editables.forEach( editable => {
      const id = editable.id;
      const classList = this.getMultiColumnClassList(editable).value;

      let data = editor.getData({
        rootName: id
      });

      if (data == null || data == "") {
        data = "<p></p>";
      }

      result += `<div id="${id}" class="${classList}">${data}</div>`
    })

    return result;
  }

  // Ensure we add appropriate multi header footer column layout classes
  // This will allow the desired layout to save with the ck-editor content
  // @param {HTML Element} editable - DOM element with class .multiroot-element
  // @returns [DomTokenList] collection of the editable's classes
  getMultiColumnClassList(editable) {
    let multiColumnClassList = $(editable).parent().parent()[0].classList; // depends on marginal_field html structure :(
    multiColumnClassList.add('multiroot-column'); // .multiroot-column is used for styling and text processing
    return multiColumnClassList
  }

  getEditables() {
    return this.element.querySelectorAll('.multiroot-element')
  }

  assignFeeds() {
    const ret = []
    if (window.users) {
      ret.push({
        marker: '@',
        feed: function (queryText) { return getMention(queryText, window.users) },
      });
    }

    if (window.topics) {
      ret.push({
        marker: '+',
        feed: function (queryText) { return getMention(queryText, window.topics) },
        itemRenderer: customItemRenderer
      })
    }

    if (window.questions && window.placeholders) {
      ret.push({
        marker: '$',
        feed: function (queryText) { return getMention(queryText, window.questions.concat(window.placeholders)) },
        // itemRenderer: customItemRenderer
      })
    } else if (window.questions) {
      ret.push({
        marker: '$',
        feed: function (queryText) { return getMention(queryText, window.questions) },
        // itemRenderer: customItemRenderer
      })
    } else if (window.placeholders) {
      ret.push({
        marker: '$',
        feed: function (queryText) { return getMention(queryText, window.placeholders) },
        // itemRenderer: customItemRenderer
      })
    }

    return ret
  }
}
