import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="click-forward"
export default class extends Controller {
  static get values() {
    return {
      selectors: String,
    };
  }

  connect() {
    this.element.addEventListener("click", () => {
      this.handleClick();
    });
  }

  trigger() {
    this.handleClick();
  }
  // multiple selectors can be passed in as a space separated list if one button should click multiple elements
  handleClick() {
    document.querySelectorAll(
      this.selectorsValue.split(" ").forEach((selectorValue) => {
        try {
          document.querySelectorAll(selectorValue).forEach((ele) => {
            ele.click();
          });
        } catch {
          console.warn(`${selectorValue} could not be clicked`)
        }
      })
    );
  }
}
