$(document).on('click', '.shorten-text', function() {
  let summary_text = document.getElementsByClassName('summary-text');
  let completed_summary_text = document.getElementsByClassName('summary-text-complete');
  if (this.innerHTML == 'Show More...') {
    summary_text[0].classList.add('d-none');
    completed_summary_text[0].classList.remove('d-none');
    this.innerHTML = 'Show Less...';
  } else {
    summary_text[0].classList.remove('d-none');
    completed_summary_text[0].classList.add('d-none');
    this.innerHTML = 'Show More...';
  }
});
