$(document).on("change", "input.criterium", function() {
  validateValue($(this));
})

$(document).on("change", "input.cost-criteria", function() {
  calculateTotalCostValue($(this));
})

$(document).on("change", "input.cost-criteria-incentive", function() {
  calculateTotalCostIncentiveValue($(this));
})

function calculateTotalCostValue($input) {
  const $parent = $input.parent();
  const totalCostValue = $parent.find('.total-cost-value');
  const totalCostIncentiveValue = $parent.find('.cost-criteria-incentive.total-cost');
  const formula = $parent.data('cost-formula');
  const lowestValues = $parent.data('lowest-values');
  const lowestValue = $parent.data('lowest-value');
  const children = $parent.children();
  let valuesFormula = '';

  if (!$input.hasClass('total-cost-value')) {
    for (let i = 0; i < formula.length; i++) {
      const operator = children[i];
      if (operator.localName === 'input') {
        if (i == 0) validateLowest(operator, $input, lowestValues, lowestValue);
        valuesFormula += operator.value;
      } else {
        valuesFormula += operator.innerHTML;
      }
    }

    let newValue = eval(valuesFormula).toFixed(2);
    if (newValue === 'NaN') newValue = 0;
    totalCostValue.val(newValue)
  }

  if (totalCostIncentiveValue) {
    const costCriteriaIncentive = $parent.parent().find('.cost-criteria-incentive')[0];
    calculateTotalCostIncentiveValue($(costCriteriaIncentive));
  }
}

function calculateTotalCostIncentiveValue($input) {
  const $parent = $input.parent();
  const totalCostValue = $parent.find('.total-cost-value');
  const costType = $input.data('cost-type');
  const totalCostIncentiveValue = $parent.find('.cost-criteria-incentive.total-cost');
  let newValue;

  if (costType === 'cost-based') {
    newValue = parseFloat(totalCostValue.val()) - parseFloat($input.val());
  } else if (costType === 'points-based') {
    newValue = parseFloat(totalCostValue.val()) + parseFloat($input.val());
  }

  if (`${newValue}` === 'NaN') newValue = parseFloat(totalCostValue.val());

  if (newValue) newValue = newValue.toFixed(2)

  totalCostIncentiveValue.val(newValue);
}

function validateLowest(operator, $input, lowestValues, lowestValue) {
  if (!lowestValues || !lowestValues.length || lowestValues[0] == 0) {
    operator.value = $input.val();
    return;
  }

  if (lowestValues[0] > parseInt($input.val())) {
    operator.value = $input.val();
    lowestValues.splice(1, 1);
    lowestValues.push($input.val());
    $input.parent().data('lowest-value', true);
  } else if (lowestValue) {
    if (lowestValues[0] < parseInt($input.val()) && parseInt($input.val()) <= lowestValues[1]) {
      operator.value = $input.val();
      lowestValues[0] = $input.val();
      $input.parent().data('lowest-value', true);
    } else if (lowestValues[1] <= parseInt($input.val())) {
      operator.value = lowestValues[0];
      $input.parent().data('lowest-value', false);
    }
  }

  $input.parent().data('lowest-values', lowestValues)
}

function validateValue($input) {
  const existingValue = parseFloat($input.val());
  const maximumValue = $input.attr("data-max-value");
  validValue = !isNaN(existingValue) && existingValue >= 0 && existingValue <= maximumValue
  $('input[type="submit"], button[name="submission_step_id"]').prop("disabled", !validValue);

  if (validValue) {
    $("input.criterium, .validation-text").removeClass("text-danger");
    $(".validation-text").addClass("text-muted");
  } else {
    $(".validation-text").removeClass("text-muted");
    $(".validation-text, input.criterium").addClass("text-danger");
  }
}
