import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get values() {
    return {
      time: {
        type: Number,
        default: 2000
      }
    }
  }
  connect() {
    this.element.requestSubmit = _.debounce(this.element.requestSubmit, this.timeValue);
  }
}