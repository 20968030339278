function updateWizard($scope) {
  // safari does what we expected by default, but chrome wasn't checking the radio or checkbox.
  var $wizardProgress = $scope.find('.wizard-progress');
  if ($wizardProgress.length > 0) {
    var $wizardProgressBar = $scope.find('.wizard-progress-bar');
    var wizardLeft = $wizardProgress.offset().left;
    var width = $wizardProgress.outerWidth();
    var mostRight = 0;
    $scope.find('.wizard-item.active').each(function(_, item) {
      var $item = $(item);
      var right = $item.offset().left + $item.outerWidth() - wizardLeft;
      if (right > mostRight) {
        mostRight = right;
      }
    });
    $wizardProgressBar.css('width', mostRight + 'px');
  }
}

$(document).on('turbolinks:load', function() {
  updateWizard($('body'));
  setTimeout(function() {
    updateWizard($('body'));
  }, 500);
})

$(document).on('sprinkles:update', function(event) {
  updateWizard($(event.target));
})

$(window).resize(function() {
  updateWizard($('body'));
});
