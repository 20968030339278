import { hideTableColumns } from "../teams/configuration";

$(document).on('turbolinks:load', function () {
  const tableEle = document.getElementById('worksheets-table')
  if (!!tableEle) {
    window.worksheetsDatatable = $(tableEle).DataTable({
      processing: true,
      searching: true,
      serverSide: true,
      order: [[1, 'asc']],
      aLengthMenu: [
        [10, 25, 50, 100, 200],
        [10, 25, 50, 100, 200]
      ],
      iDisplayLength: 10,
      language: {
        'sLengthMenu': 'Showing_MENU_',
        'info': 'of _TOTAL_ Worksheets',
        'oPaginate': {
          'sFirst': '',
          'sLast': ''
        }
      },
      drawCallback: function(settings) {
        const api = this.api();
        const column_indexes = [0, 1];
        hideTableColumns(column_indexes, '#worksheets-table', api);
      },
      ajax: {
        url: $('#worksheets-table').data('source'),
        data: function (d) {
          d.is_archive = $("#worksheets-table").attr('data-archive');
        },
      },
      pagingType: 'full_numbers',
      'dom': "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
      columns: [
        {
          data: 'move_actions',
          className: 'px-0',
          orderable: false
        },
        {
          data: 'display_order',
          className: 'order-actions pr-0'
        },
        { data: 'name' },
        { data: 'updated_at' },
        {
          data: 'dt_actions',
          className: 'text-right',
          orderable: false
        },
      ]
    });
    tableEle.addEventListener('worksheets-table:refresh', () => {
      window.worksheetsDatatable.draw('full-hold');
    })
  }
})

