class PageRefresh {
  static collectScrollPositions($element) {
    var scrollPositions = {};
    scrollPositions['body'] = {left: $('body').scrollLeft(), top: $('body').closest('body').scrollTop()}
    $element.find("*").each(function () {
      var $scrollElement = $(this);
      if ($scrollElement.scrollLeft() || $scrollElement.scrollTop()) {
        var scrollId = $scrollElement.attr('data-scroll-id');
        if (scrollId) {
          scrollPositions[scrollId] = {left: $scrollElement.scrollLeft(), top: $scrollElement.scrollTop()}
        }
      }
    })
    return scrollPositions;
  }

  static applyScrollPositions(scrollPositions, $element) {
    $.each(scrollPositions, function(scrollId, scroll) {
      var $scrollElement;
      if (scrollId == 'body') {
        $scrollElement = $('body');
      } else {
        $scrollElement = $element.find("[data-scroll-id='" + scrollId + "']");
      }
      $scrollElement.scrollLeft(scroll.left).scrollTop(scroll.top);
    });
  }

  static updatePageBase(baseSelector, data) {
    var $existingBase = $(baseSelector)
    var $newBase = $(data).find(baseSelector);
    if ($newBase.length) {
      var scrollPositions = PageRefresh.collectScrollPositions($(baseSelector));
      $existingBase.empty();
      $existingBase.replaceWith($newBase);
      PageRefresh.applyScrollPositions(scrollPositions, $newBase);
      $newBase.trigger('sprinkles:update');
    }
  }

  static refreshPageBase(baseSelector) {
    var $existingBase = $(baseSelector)
    if (!$existingBase.hasClass('skip-modal-base-refreshes')) {
      $.get(document.location.href, { layoutless: true }, function(data) {
        PageRefresh.updatePageBase(baseSelector, data);
      });
    }
  }
}

module.exports = PageRefresh;
