import { Controller } from "@hotwired/stimulus";
import 'regenerator-runtime/runtime';
import { customItemRenderer, addFeedIfAvailable } from '../../account/ckeditor/v1.2/helpers/collaborative_editor_helpers';

const destroyableRefs = new Set();

// abstract class
export default class extends Controller {
  static get targets() {
    return ['editor'];
  }

  editorTargetConnected(element) {
    this.loadFeeds(element);
  }

  disconnect() {
    this.removeDetachedEditors();
  }

  loadFeeds(_element) {
    let feeds = [];
    let userFeeds = [];

    addFeedIfAvailable(feeds, userFeeds, '@', 'users');
    addFeedIfAvailable(feeds, userFeeds, '+', 'topics', customItemRenderer);

    if (window.questions && window.placeholders) {
      window.questionsAndPlaceholders = window.questions.concat(window.placeholders);
      addFeedIfAvailable(feeds, userFeeds, '$', 'questionsAndPlaceholders');
    } else {
      addFeedIfAvailable(feeds, userFeeds, '$', 'questions');
      addFeedIfAvailable(feeds, userFeeds, '$', 'placeholders');
    }

    return { feeds, userFeeds, destroyableRefs };
  }

  removeDetachedEditors() {
    for (const reference of destroyableRefs) {
      if (!reference) {
        destroyableRefs.delete(reference);
      } else {
        const editor = reference.editor || reference;

        if (!document.contains(editor.sourceElement)) {
          reference.destroy();
          destroyableRefs.delete(reference);
        }
      }
    }
  }
}
