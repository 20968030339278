import { invokeSelectorControllerAction } from '../utils/stimulus_utils';

function collectScrollPositions($element) {
  var scrollPositions = {};
  scrollPositions['body'] = {left: $('body').scrollLeft(), top: $('body').closest('body').scrollTop()}
  $element.find("*").each(function () {
    var $scrollElement = $(this);
    if ($scrollElement.scrollLeft() || $scrollElement.scrollTop()) {
      var scrollId = $scrollElement.attr('data-scroll-id');
      if (scrollId) {
        scrollPositions[scrollId] = {left: $scrollElement.scrollLeft(), top: $scrollElement.scrollTop()}
      }
    }
  })
  return scrollPositions;
}

function applyScrollPositions(scrollPositions, $element) {
  $.each(scrollPositions, function(scrollId, scroll) {
    var $scrollElement;
    if (scrollId == 'body') {
      $scrollElement = $('body');
    } else {
      $scrollElement = $element.find("[data-scroll-id='" + scrollId + "']");
    }
    $scrollElement.scrollLeft(scroll.left).scrollTop(scroll.top);
  });
}

function refreshModalBase(baseSelector) {
  var $existingModalBase = $(baseSelector)
  if (!$existingModalBase.hasClass('skip-modal-base-refreshes')) {
    $.get(document.location.href, {layoutless: true}, function(data) {
      var $modalBase = $(data).find(baseSelector);
      if ($modalBase.length) {
        var scrollPositions = collectScrollPositions($(baseSelector));
        $existingModalBase.empty();
        $existingModalBase.replaceWith($modalBase);
        applyScrollPositions(scrollPositions, $modalBase);
        $modalBase.trigger('sprinkles:update');
      }
    });
  }
}

/**
 * @params $elementBox - the modal base with class element-box
 * @returns the initialized modal container
 */
function cloneModalTemplateAndInitialize($elementBox) {
  const template = ($elementBox.hasClass('offcanvas')) ? '#offcanvas-template' : '#modal-template'
  const $modal = $(template).clone().removeAttr('id').addClass('opened-modally');
  $modal.find('.modal-body').append($elementBox);

  const modalConfig = {}

  const ckeditorExists = $modal.find('.ckeditor').length > 0;
  if(ckeditorExists) {
    // We need to apply focus: false so that ckeditor balloons like "Link" opened within a modal can be entered. The issue is the Bootstrap modal steals focus.
    // See https://ckeditor.com/docs/ckeditor5/latest/installation/integrations/css.html#compatibility-with-bootstrap
    modalConfig['focus'] = false;
  }

  $modal.on('shown.bs.modal', () => {
    // put the focus inside the modal, even if not initialized with focus: true option...
    try {
      // first try autofocus
      $modal.find('[autofocus="autofocus"]').trigger('focus');

      if(!document.getActiveElement) {
        // ideally will focus on the first form element...
        const first_form_element = Array.from($modal.find('form')[0])
                                        .find( (el) => el.type != 'hidden' && el.type != 'radio' )
        first_form_element.focus();
      }
    } catch {
      // couldn't focus...
    }
  })

  $modal.on('hidden.bs.modal', function() {
    $('.disabled-link').removeClass('disabled-link')
    $modal.remove();
  });

  $modal.modal(modalConfig);
  return $modal;
}

function handleModalResponse(data, textStatus, request, $modal, baseSelector) {
  // If the request return javascript rather than HTML we get an error here. UJS will execute the js for us though so we just want to catch the error and return.
  try {
    var $termination = $(data).find('.modal-terminate');
  } catch (err) {
    console.log("There was an error handling the modal response. The error was ", err);
    return;
  }
  if ($termination.length) {
    refreshModalBase(baseSelector);
    if ($modal) {
      closeModal($modal);
      if ($termination.data('alert') && $termination.data('alert').length) {
        alert($termination.data('alert'));
      }
      var collectionName = $termination.data('refresh-collection');
      var itemId = $termination.data('refresh-id');
      if (collectionName && collectionName.length) {
        var collectionElement = $("[data-collection='" + collectionName + "']");
        collectionElement.addClass("refreshing");
        collectionElement.find("tr[data-id='" + itemId + "']").addClass("refreshing");
      }
    }
  } else {
    var $elementBox = $(data).find('.element-box');
    if (!$modal.length) {
      $modal = cloneModalTemplateAndInitialize($elementBox)
    } else {
      $modal.find('.modal-body').empty().append($elementBox);
    }
    // Optionall add .wide-modal or .medium-modal to your element box to make the modal wider than normal
    // wide-modal = max-width: 80%
    // medium-modal = max-width 1200px
    if ($elementBox.hasClass('wide-modal')) {
      $modal.addClass('wide-modal')
      $modal.removeClass('medium-modal')
    } else if ($elementBox.hasClass('medium-modal')) {
      $modal.addClass('medium-modal')
      $modal.removeClass('wide-modal')
    } else {
      $modal.removeClass('wide-modal')
      $modal.removeClass('medium-modal')
    }

    // run any scripts in this payload.
    $.each($elementBox.find('script'), function(_, script) {
      eval($(script).text());
    });

    // keep track of where the current content came from, incase we need to reload it.
    // TODO I don't think we actually do anything with the method. Should we?
    var url = request.getResponseHeader('X-Sprinkles-Request-URL');
    var method = request.getResponseHeader('X-Sprinkles-Request-Method');
    $modal.attr('data-url', url);
    $modal.attr('data-method', method);

    // Ignore any parameters in the URL (these can cause false negatives if the url ends in ? but does not actually have any parameters added).
    // second part of this conditional is to account for an edge case of using the modal on the tasks page for creating a new task
    // In that special case, both the modal url and window location will match - but we don't want to close the modal.
    if (window.location.href.split('?')[0] == url.split('?')[0] && !window.location.href.match(/\/account\/teams\/[0-9]*\/tasks/)) {
      // we have redirected back to where we started from so we're going to close the modal
      var alertText = $modal.find("[data-alert]").data('alert')
      closeModal($modal);
      if (alertText && alertText.length) {
        alert(alertText);
      }
    }

    $modal.on("close-modal", function() {

      closeModal($modal)
    })

    // Trix fields don't autofocus properly in modals so we have to do this:
    // TODO it looks like autofocus will be blocked if other element has focus...and, does this only work on pageload? Not upon modal click?
    $modal.find('[autofocus="autofocus"]').focus();

    $('[data-toggle="tooltip"]').tooltip();
    window.observeTurboFramesToRefreshJs($elementBox);
    $elementBox.trigger('sprinkles:update');
    refreshModalBase(baseSelector);
  }
}

function closeModal($modal) {
  $modal.addClass('modal--remove');
  $modal.parent().find('.modal-backdrop').addClass('modal-backdrop--remove');
  setTimeout(function() {
    $modal.modal('hide');
  }, 1000);
}

function modalSubmitSuccess(data, baseSelector, $form, $modal, request, textStatus) {
  if ($form.data("updates-table")) {
    const tableToUpdate = [$form.data("updates-table")];
    setTimeout(() => window[tableToUpdate].draw('full-hold'), 400);
  }
  if ($form.data("trigger-controller-action")) {
    invokeSelectorControllerAction($form.data("trigger-controller-action"));
  }
  if (!$form.hasClass('action-cable-result')) {
    $form.find('input[type=submit]').removeAttr('disabled');
    $form.trigger('reset');
    // We're using an empty body here as a return val from the controller to say everything went well. It's faster than rendering the HTML so we can dismiss the modal quicker.
    if (data == "" || $(data).find(".close-modal").length > 0) {
      closeModal($($modal));
    } else {
      handleModalResponse(data, textStatus, request, $modal, baseSelector);
    }
  }
}

// submit modal forms via ajax and populate the results in the modal.
$(document).on('submit', 'form.submit-modally, .opened-modally form', function(event) {

  if (event.currentTarget.dataset.turbo === 'true') return;

  var $form = $(event.currentTarget);
  event.preventDefault();
  var $modal = $form.closest('.modal')

  if ($form.hasClass('filter-form')) {
    return false;
    // closeModal($modal);
  }

  var path = $form.attr('action');
  var type = $form.attr('method') || 'POST';
  var baseSelector = $form.attr('data-target') || '.modal-base'
  var payload = $form.serializeArray();

  // TODO this doesn't really do enough right now, because of redirects, which won't remember this.
  if (!$(baseSelector).hasClass('avoid-layoutless')) payload.push({name: 'layoutless', value: 'true'});

  if ($form.hasClass('action-cable-result')) {
    // TODO this is solving a race condition where rails is disabling the submit button _after_ we were trying to re-enable it.
    setTimeout(function() {
      $form.trigger('reset');
      $form.find('input[type=submit]').removeAttr('disabled');
    }, 100);
  }

  $.ajax(
    {
      url: path,
      type: type,
      data: payload,
      success: function(data, textStatus, request) {
        modalSubmitSuccess(data, baseSelector, $form, $modal, request, textStatus)
      },
      error: function(data) {
    alert("Sorry, we encountered an error. Please try again and if the issue persists please contact support.")
    console.log(data.responseText);
  }});
});

// TODO this doesn't play nicely with rails ujs stuff, e.g. data-method = destroy.
// Sometimes we want to customize the behaviour of a link within a modal.  Add the .no-sprinkles class to stop this code from running
$(document).on('click', 'a.open-modally, .opened-modally a:not(.no-sprinkles)', function(event) {
  var $link = $(event.currentTarget);

  if ($link.hasClass('upload-file') || $link.hasClass('remove-file') || $link.hasClass('add_fields') || $link.hasClass('remove_fields') || $link.hasClass('disabled-link')) return false;

  $link.addClass('disabled-link');

  if (!$link.hasClass('follow-unmodally') && !$link.attr('target')) {
    event.preventDefault();
    if ($link.hasClass('follow-layoutless')) event.stopImmediatePropagation();
    var path = $link.attr('href');

    // The Chameleon snipit that has been added seems to cause a caching issue in Safari.  We can do this as a workaround:
    // Add a random parameter (scb - Sprinkles Cache Buster) to the url - this stops Safari (and maybe other browsers?) from caching the request
    var randomParam = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    if (path.includes("?")) {
      path += "&scb=" + randomParam;
    } else {
      path += "?scb=" + randomParam;
    }

    var type = $link.attr('data-method') || 'GET';
    var $modal = $link.closest('.modal').first(); // this might not exist.
    // we're going to disable this for everything but get requests and let ujs handle all the other types of requests.
    if (type.toUpperCase() == 'GET' || $link.hasClass('follow-layoutless')) {
      if ((path == window.location.pathname && type.toUpperCase() == 'GET') || $link.hasClass('close-modal')) {
        closeModal($modal);
      } else {
        $.ajax({url: path, type: type, data: {layoutless: true}, success: function(data, textStatus, request) {
          handleModalResponse(data, textStatus, request, $modal);
        }});
      }
    }
  }
});

$(document).on('click', 'a.close-modal-or-redirect', function(event) {
  var $link = $(event.currentTarget);
  if ($('.modal.opened-modally').length) {
    event.preventDefault();
    var $modal = $link.closest('.modal').first(); // this might not exist.
    closeModal($modal);
  }
})

$(document).on('modal:close', function() {
  const openModals = document.querySelectorAll('.opened-modally');
  openModals.forEach( (modal) => {
    closeModal($(modal))
  })
})
