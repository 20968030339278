window.initRequestLogsDatatable = () => {
  window.requestLogsTable = $('#request-logs-datatable').DataTable({
    processing: true,
    searching: false,
    retrieve: true,
    serverSide: true,
    order: [[3, 'desc']],
    ajax: {
      url: $('#request-logs-datatable').data('source'),
      data: function (d) {
        d.search = { value: $('#search-request-logs-datatable').val(), regex: false }
      }
    },
    language: {
      'oPaginate': {
        'sFirst': '',
        'sLast': ''
      }
    },
    info: false,
    pagingType: 'full_numbers',
    createdRow: (row, data, index) => {
      $(row).addClass('hover-secondary small');
      $(row).attr("data-action", "click->modal#set");
      const queryAttributes = {
        truncated_error_message: data.truncated_error_message,
        controller_name: data.controller_name,
        controller_action: data.controller_action
      };
      const queryString = Object.keys(queryAttributes).map((key) => {
        return `aggregate_request_log_attributes[${encodeURIComponent(key)}=${encodeURIComponent(queryAttributes[key])}`;
      }).join('&');
      $(row).attr("data-src", `/developers/request_logs/aggregated_details_modal?${queryString}`);
    },

    columns: requestLogColumns()
  });

  //setup before functions
  var typingTimer;                //timer identifier
  var doneTypingInterval = 1000;  //time in ms, 1 second for example
  var $input = $('#search-request-logs-datatable');

  //on keyup, start the countdown
  $input.on('keyup', function () {
    $(this).val() ? $('#search-request-logs-clear').removeClass('d-none') : $('#search-request-logs-clear').addClass('d-none')
    clearTimeout(typingTimer);
    typingTimer = setTimeout(window.requestLogsTable.ajax.reload, doneTypingInterval);
  });

  //on keydown, clear the countdown
  $input.on('keydown', function () {
    clearTimeout(typingTimer);
  });

  if ($input.length && $input.val().length == 0) {
    $('#search-request-logs-clear').addClass('d-none');
  }

  $('#search-request-logs-clear').on('click', function () {
    $input.val('')
    $input.trigger('keyup');
    $('#search-request-logs-clear').addClass('d-none');
  })
}

const formatTime = (timestamp) => {
  let month = timestamp.getMonth() + 1;
  let date = timestamp.getDate();
  let hours = timestamp.getHours();
  let minutes = timestamp.getMinutes();
  let seconds = timestamp.getSeconds();
  if (month < 10) month = `0${month}`;
  if (date < 10) date = `0${date}`;
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return `${month}/${date} - ${hours}:${minutes}:${seconds}`;
}

function requestLogColumns() {
  return [
    {
      data: 'controller_name',
      width: "15%",
      render: (data, display, row) => {
        return `${row.controller_name}#${row.controller_action}`;
      }
    },
    { data: 'request_type', width: "5%" },
    { data: 'occurrences', width: "8%" },
    {
      data: 'last_occurrence',
      width: "10%",
      render: (data, display, row) => {
        const time = new Date(data);
        return time.toLocaleDateString() === 'Invalid Date' ? '---' : `${formatTime(time)}`;
      }
    },
    { data: 'truncated_error_message', width: "62%" },
    { data: 'agg_ids', visible: false }
  ];
}

$(document).on('request-logs-datatable:attached', () => {
  window.initRequestLogsDatatable();
});
