$(document).on('turbolinks:load', function () {
  // Only do this on the initial page load.
  $(document).on('click', '.app-email-w .open-conversation', function (event) {
    const currentAeItem = $(event.currentTarget).closest('.ae-item');
    if (!currentAeItem.hasClass('active')) {
      currentAeItem.siblings('.ae-item.active').removeClass('active');
      currentAeItem.addClass('active');
    }
  });
  updatePagination();
  localStorage.removeItem("current_conversation");
})



$(document).on('sprinkles:update', function () {
  // for set/update the value of active conversation while sprinkle update
  setVisibility();
  setTimeout(function() {
    updatePagination()
  }, 10)
})

// open conversation in another section of the page.
$(document).on('click', '.open-conversation', function (event) {
  const $link = $(event.currentTarget);
  event.preventDefault();

  const humanReadUrl = $link.attr('href');
  const sprinklesDataUrl = $link.attr('data-sprinkles-url');
  const subjectUrl = $link.attr('data-subject-target-url');
  const subject_project = $link.attr('data-subject-project');
  const has_cke_thread = $link.attr('data-has-cke-thread');

  let $inlineBase = $($link.attr('data-target'));
  let $metadataBase = $($link.attr('data-subject-target'));

  $inlineBase.addClass('loading'); // TODO Add spinner to grayed-out conversation
  $metadataBase.addClass('loading');

  // hide_participants to speed up loading of the conversation
  $.get(humanReadUrl, { layoutless: true, hide_participants: true }, function (data) {
    $inlineBase.empty();
    $inlineBase.append(`<div class="content-i">${$(data).find('#main-box').html()}</div>`);
    $('#main-box .todo-sidebar-open').remove();
    $inlineBase.attr('data-url', sprinklesDataUrl);
    $inlineBase.removeClass('loading');
    $inlineBase.trigger('sprinkles:update'); // Need this to update conversation after sending a new message

    // For scrolling to the bottom of the first conversation on first load
    let conversationContainer = $('div.ae-content-w');
    conversationContainer.animate({ scrollTop: conversationContainer.height() }, 0);
  }).fail(function () {
    $inlineBase.removeClass('loading');
    $inlineBase.empty();
    $inlineBase.append("<p class='alert alert-warning' style='margin: 2rem 2.5rem;'>The conversation failed to load. Please try again later</p>");
  });

  $.get(subjectUrl, function (data) {
    if(typeof subjectUrl !== "undefined"){
      $metadataBase.empty();
      $metadataBase.append($(data));
      $metadataBase.attr('data-url', subjectUrl);
      if (subject_project) {
        $metadataBase.append('<span class="badge badge-info">' + subject_project + '</span>');
      }
      if (has_cke_thread == 'true') {
        $metadataBase.append('<span class="badge badge-success">Section Comment</span>');
      }
      $metadataBase.removeClass('loading');
    }
    else{
      $metadataBase.removeClass('loading');
      $metadataBase.empty();
      $metadataBase.append("<p class='alert alert-warning'>The conversation subject do not exist!</p>");
    }
  }
  ).fail(function () {
    $metadataBase.removeClass('loading');
    $metadataBase.empty();
    $metadataBase.append("<p class='alert alert-warning'>The information of the conversation subject failed to load. Please try again later</p>");
  });

  localStorage.setItem("current_conversation", $link.attr('data-subscription'));
});

function updatePagination() {
  const prev_svg =
    `<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.75 5H11.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.5 1.25L0.75 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.5 8.75L0.75 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;

  const next_svg =
    `<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.25 5H0.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.5 1.25L11.25 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.5 8.75L11.25 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`

  $(".page").not('.prev , .next').hide()
  $(".prev.disabled").html('<a class="disabled">' + prev_svg + '</a>');
  $(".next.disabled").html('<a class="disabled">' + next_svg + '</a>');
  $('.prev a, .next a').addClass('btn btn-icon-outline square-btn btn-sm')
  $('.prev a').attr('aria-label', 'Navigation previous button')
  $('.next a').attr('aria-label', 'Navigation next button')

  $('.prev a, .next a').text('');
  $('.prev a, .next a').text('');
  $('.prev a').append(prev_svg);
  $('.next a').append(next_svg);
}

function setVisibility(){
  const current_conversation_id = localStorage.getItem("current_conversation");
  if(current_conversation_id){
    current_conversation = $('[data-id="'+ current_conversation_id +'"]');
    current_conversation.addClass("active");
  }
}
