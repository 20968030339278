import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['fileField', 'downloadFile', 'uploadIcon', 'uploadSpan', 'removeFile', 'uploadReplacement'];
  }

  connect() {
    this.fileFieldTarget.addEventListener('change', this.uploadCompleted.bind(this));
  }

  uploadCompleted() {
    this.downloadFileTargets.forEach(element => element.remove());
    this.uploadIconTargets.forEach(element => {
      element.classList.remove('ti-upload');
      element.classList.add('ti-check');
    });
    this.uploadSpanTargets.forEach(element => {
      element.textContent = 'Upload Completed';
    });
    this.removeFileTargets.forEach(element => element.classList.remove('d-none'));
  }

  removeFile(event) {
    event.preventDefault();
    this.downloadFileTargets.forEach(element => element.remove());
    this.removeFileTargets.forEach(element => element.classList.add('d-none'));
    this.element.querySelector('.remove-file-flag').value = true;
    this.uploadIconTargets.forEach(element => {
      element.classList.add('ti-upload');
      element.classList.remove('ti-check');
    });
    this.uploadSpanTargets.forEach(element => {
      element.textContent = 'Upload New Document';
    });
  }
}
