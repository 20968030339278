import { Controller } from "@hotwired/stimulus";
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  connect() {
    if (!this.element._phone_field_enabled) {
      this.element._phone_field_enabled = true;
      intlTelInput(this.element, {
        hiddenInput: this.element.dataset.method,
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
        initialCountry: 'us'
      });
    }
  }
}