import { Controller } from "@hotwired/stimulus";
import { enableSelect } from '../../account/fields/select';

export default class extends Controller {

  connect() {
    this.element.classList.add('select2');
    enableSelect(this.element);
  }
}
