import { setupDatatableXhrEvents, updateFilterCount, hideTableColumns } from "../../teams/configuration";

const initTemplatesDatatable = function () {
  window.templatesDatatable = $('#templates-table')
    .DataTable({
    searching: true,
    processing: true,
    serverSide: true,
    stateSave: true,
    order: [[1, "asc"]],
    aLengthMenu: [
      [10, 25, 50, 100, 200],
      [10, 25, 50, 100, 200]
    ],
    iDisplayLength: 10,
    language: {
      "sLengthMenu": "Showing_MENU_",
      "info": "of _TOTAL_ Document Templates",
      "oPaginate": {
        "sFirst": "",
        "sLast": ""
      }
    },
    drawCallback: function (settings) {
      const api = this.api();
      const column_indexes = [0, 1];
      hideTableColumns(column_indexes, '#templates-table', api);
    },
    ajax: {
      url: $("#templates-table").data('source'),
      data: function (d) {
        d.templates_classes = $("#templates_classes").val();
        d.templates_types = $("#templates_types").val();
        d.templates_lpas = $("#templates_lpas").val();
        d.is_reset = $("#document_templates_is_reset").val();
        d.is_archive = $("#templates-table").attr('data-archive');
        d.search.value = $("#search_documents_datatable").val();
      },
    },
    pagingType: 'full_numbers',
    'dom': "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
    columns: [
      {
        data: 'move_actions',
        className: 'icon-wrap',
        orderable: false
      },
      { data: 'display_order' },
      {
        data: 'title',
        className: 'custom-width'
      },
      { data: 'created_at' },
      { data: 'updated_at' },
      { data: 'type' },
      { data: 'one_per_role' },
      { data: 'purchase_class' },
      { data: 'solicitations_type' },
      { data: 'solicitations_vehicle' },
      { data: 'parent_document' },
      {
        data: 'requires_action',
        render: function (data) {
          if (data == "green") {
            return '<div class="status-pill green" aria-label="Requires action"></div>'
          }
          else {
            return '<div class="status-pill yellow" aria-label="No requires action"></div>'
          }
        }
      },
      { data: 'assign_to' },
      {
        data: 'dt_actions',
        className: 'icon-wrap',
        orderable: false
      }
    ],
  })
}

const setupDocumentsDatatableSearch = () => {
  //setup before functions
  var typingTimer;
  const doneTypingInterval = 500;
  const $input = $('#search_documents_datatable');

  //on keyup, start the countdown
  $input.on('keyup', function () {
    $(this).val() ? $("#search_documents_clear").removeClass("d-none") : $("#search_documents_clear").addClass("d-none")
    clearTimeout(typingTimer);
    typingTimer = setTimeout(window.templatesDatatable.draw, doneTypingInterval);
  });

  //on keydown, clear the countdown
  $input.on('keydown', function () {
    clearTimeout(typingTimer);
  });

  if ($input.length && $input.val().length == 0) {
    $("#search_documents_clear").addClass("d-none");
  }

  $("#search_documents_clear").on('click', function () {
    $input.val('')
    $input.trigger('keyup');
    $("#search_documents_clear").addClass("d-none");
  })
}

$(document).on('turbolinks:load', function () {
  const templatesTable = document.getElementById("templates-table");
  if (!!templatesTable) {
    initTemplatesDatatable();

    const dtInstance = window.templatesDatatable;
    const searchEle = "#search_documents_datatable";
    const timeXhr = false; // set to true to console log processing times
    setupDatatableXhrEvents(dtInstance, searchEle, timeXhr);

    const buttonFilterSelector = '.btnTemplateFilter';
    const filterName = 'templates';
    updateFilterCount(buttonFilterSelector, filterName);

    setupDocumentsDatatableSearch();

    templatesTable.addEventListener('templates-table:refresh', () => {
      window.templatesDatatable.draw('full-hold');
    })
  }
});
