import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['menu'];
  }

  show(event) {
    event.stopPropagation();
    this._closeOtherKebabs();

    this.menuTarget.classList.toggle('d-none');

    if (this.element.classList.contains('kebab-container--datatable')) {
      this._adjustKebabPosition();
    }
    document.addEventListener('click', this.hide.bind(this), { once: true });
  }

  hide(event) {
    if (event.target.closest('.kebab-menu')) {
      document.addEventListener('click', this.hide.bind(this), { once: true });
    } else {
      this.element.style.position = 'relative';
      this.menuTarget.classList.add('d-none');
    }
  }

  _closeOtherKebabs() {
    const elements = Array.from(document.querySelectorAll('.kebab-menu'));
    elements.forEach((kebab) => {
      // If the kebab-menu doesn't have d-none it's open so we should close it as we are opening another menu.
      if (!kebab.classList.contains('d-none')) {
        kebab.classList.add('d-none');
      }
    })
  }

  // If the kebab is within a datatable, the kebab-container may need its
  // position set to absolute when the menu is shown to prevent the
  // datatable from overflowing and hiding the menu. Seems to happen when there's just one item in the dt.
  _adjustKebabPosition() {
    const parentTable = this.element.closest('.dataTable');

    // see if table has overflow. If so, the menu is probably getting cut off. 
    // position: absolute will fix that issue but cause the kebab icon to jump a little bit.
    if(parentTable && (parentTable.scrollHeight > parentTable.clientHeight)) {
      this.element.style.position = 'absolute';
    }
  }
}
