var msg, unsaved;

msg = "If you navigate away from this page, your form entries will not be saved. Are you sure you want to navigate to another page?";

unsaved = false;

function unsavedWarningDebug(string) {
  // console.log(string);
}

$(document).on('change', 'form.with-unsaved-warning :input', function() {
  unsavedWarningDebug("💝 Forms are marked dirty.")
  return unsaved = true;
});

$(document).on('turbolinks:load', function() {
  unsavedWarningDebug("💝 Page just loaded. Forms are marked clean.")
  return unsaved = false;
});

$(document).on('submit', 'form.with-unsaved-warning', function() {
  unsavedWarningDebug("💝 Form was submitted. All forms are marked clean. Not really ideal.")
  unsaved = false;
});

// in practice this should almost never happen.
$(window).bind('beforeunload', function() {
  if (unsaved) {
    return msg;
  }
});

$(document).on('turbolinks:before-visit', function(event) {
  if (unsaved && !confirm(msg)) {
    return event.preventDefault();
  }
});
