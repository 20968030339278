import { Controller } from "@hotwired/stimulus";
import { enableLiveWordCountOnCollection } from "../account/fields/text_with_progress_circle";

export default class extends Controller {
  connect() {
    setTimeout(this.enable.bind(this), 1000);
  }

  enable() {
    enableLiveWordCountOnCollection([this.element], this.inputType)
  }

  get inputType() {
    if (this.element.type === 'textarea' && this.element.classList.contains('ckeditor')) {
      return 'ckeditor';
    } else if (this.element.type === 'textarea') {
      return 'textarea';
    } else if (this.element.type === 'text') {
      return 'textfield'
    }
  }
}