import { Controller } from "@hotwired/stimulus";
import consumer from "channels/consumer";

export default class extends Controller {
  static get targets() {
    return ['element'];
  }

  connect() {
    // this allows for notification dismissal via websocket
    // upon clicking of the 'Close' or ANY anchor tag within the notice element
    // will trigger a dismissal through the websocket connection
    this.subscription = consumer.subscriptions.create({
      channel: 'Notification::DismissChannel'
    }, {
      // triggered via the stimulus controller through _channelDismiss
      dismiss(id) {
        this.perform('dismiss', {id: id})
      }
    })
  }

  close(event) {
    const ele = event.currentTarget.closest('[data-notification-target="element"]');
    this._channelDismiss(ele)

    ele.remove();
  }

  _channelDismiss(ele) {
    this.subscription.dismiss(ele.id);
  }

  elementTargetConnected(ele) {
    if (!!Number(ele.dataset.delay)) {
      const hideAction = ele.dataset.autoHideAction

      ele.setAttribute('data-fade-target', 'out')
      ele.setAttribute('data-controller', hideAction);
    }

    this._attachLinkListeners(ele)
  }

  _attachLinkListeners(ele) {
    Array.from(ele.querySelectorAll('.ci-notice--body a')).forEach( link => {
      link.addEventListener('click', () => {
        this._channelDismiss(ele)
      })
    })
  }
}
