class StimulusUtils {

  /**
   * Invoke stimulus controller from another controller, external scripts, jQuery, console, etc
   * Relies on this snippet in your controller's connect() method:
   *    `this.element[this.identifier] = this`
   * @param {String} dataTriggerControllerAction - String consisting of 3 parts separated by ":"
   * selector   - DOM selector for the stimulus-enabled element
   * controller - some stimulus controller that is loaded on the DOM
   * action     - some action that will be invoked from the controller
   * 
   * example data attribute: "data-trigger-controller-action": "#task-detail-overlay:flyout:doClose"
   */
  static invokeSelectorControllerAction(dataTriggerControllerAction) {
    const [selector, controller, action] = dataTriggerControllerAction.split(":");
    try {
      document.querySelector(`${selector}`)[controller][action]();
    } catch (e) {
      // TypeError if reading properties of null. Pass...
    }
  }
}

module.exports = StimulusUtils;