const enableTextWithProgress = () => {
    const textareas = document.querySelectorAll('textarea.form-control:not(.ckeditor)[data-maximum-words]')
    const ckeditors = document.querySelectorAll('textarea.form-control.ckeditor[data-maximum-words]')
    const textfields = document.querySelectorAll('input[data-maximum-words]')

    if (textareas) enableLiveWordCountOnCollection(textareas, 'textarea');
    if (textfields) enableLiveWordCountOnCollection(textfields, 'textfield');
    if (ckeditors) enableLiveWordCountOnCollection(ckeditors, 'ckeditor');
}

export function enableLiveWordCountOnCollection(inputs, textType) {
  if (!textType) return;

  // Make sure that how we count words is aligned with word count in entry.rb. Any changes here should be reflected there.
  const countWords = (text) => {
      return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/(\d),(\d)/g, '$1$2').replace(/[’']/g, '').split(/\W+/).filter((x) => x).length
  }

  const setTextareaHeight = (textarea) => {
      textarea.style.cssText = 'height:auto;';
      textarea.style.cssText = 'height:' + textarea.scrollHeight + 'px';
  }

  Array.from(inputs).forEach((input, textIndex) => {
      if (input._word_count_progress_enabled) return;

      input._word_count_progress_enabled = true;
      const TEXT_AREA_LIMIT = parseInt(input.dataset.maximumWords)
      const WARNING_THRESHOLD = 75;

      // insert the svg
      const svg = `
          <div class="progress-circle-wrap" data-${textType}-id=${textIndex}>
              <span><span class="js-num-words">0</span>/${TEXT_AREA_LIMIT} words maximum</span>
              <svg class="js-progress-ring progress-ring circle-width">
                  <circle class="progress-ring__circle_under" stroke-width="5" fill="transparent" r="17.3" cx="20" cy="20"/>
                  <circle class="js-progress-ring__circle progress-ring__circle progress-ring__circle--safe" stroke="black" stroke-width="5" fill="transparent" r="17.3" cx="20" cy="20"/>
              </svg>
          </div>
      `;

      let newTextArea = input;
      if (textType == 'ckeditor') {
          newTextArea = $(input).next().find('.ck.ck-content')[0];
      }

      newTextArea.insertAdjacentHTML('afterend', svg)

      const circle = $(newTextArea).closest('.form-group').find('.js-progress-ring__circle')[0];
      const radius = circle.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;

      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset = `${circumference}`;

      const setProgress = (percent) => {
          const offset = circumference - percent / 100 * circumference;
          circle.style.strokeDashoffset = offset;
          circle.classList[percent >= WARNING_THRESHOLD ? 'add' : 'remove']('progress-ring__circle--warning');
          circle.classList[percent == 100 ? 'add' : 'remove']('progress-ring__circle--stop');
      }

      const setNumWords = (number) => {
          document.querySelector(`div[data-${textType}-id="${textIndex}"] .js-num-words`).innerText = number || 0
      }

      const calculatePercent = (numWords) => {
          let calc = numWords / TEXT_AREA_LIMIT * 100
          return calc < 100 ? calc : 100;
      }

      ['keyup', 'change'].forEach((event) => {
          newTextArea.addEventListener(event, e => {
              const target_value = textType == 'ckeditor' ? $(e.target).text() : e.target.value;
              let numWords = countWords(target_value)
              if (textType == 'textarea') setTextareaHeight(newTextArea);
              setNumWords(numWords)
              setProgress(calculatePercent(numWords))
          })
      })

      // init num words in textarea
      const nextTextAreaValue = textType == 'ckeditor' ? $(newTextArea).text() : newTextArea.value;
      const initialNumberOfWords = countWords(nextTextAreaValue)
      setNumWords(initialNumberOfWords)
      setProgress(calculatePercent(initialNumberOfWords))
      if (textType == 'textarea') setTextareaHeight(newTextArea);
  })
}

$(document).on('turbolinks:load', function() {
  setTimeout(enableTextWithProgress, 1000);
})
