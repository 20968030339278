$(document).on('turbolinks:load', function () {
  if ($("#vehicle-table").length) {
    $('#vehicle-table').DataTable({
      searching: true,
      serverSide: true,
      aLengthMenu: [
        [10, 25, 50, 100, 200],
        [10, 25, 50, 100, 200]
      ],
      iDisplayLength: 10,
      language: {
        "sLengthMenu": "Showing_MENU_",
        "info": "of _TOTAL_ Leveraged Procurement Agreements",
        "oPaginate": {
          "sFirst": "",
          "sLast": ""
        }
      },
      ajax: {
        url: $("#vehicle-table").data('source'),
      },
      pagingType: "full_numbers",
      "dom": "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
      columns: [
        { data: "name" },
        { data: "abbreviation" },
        { data: 'updated_at' },
        {
          data: "dt_actions",
          className: "icon-wrap",
          orderable: false
        },
      ]
    });
  }
})
