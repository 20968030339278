import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.element.style.cursor = 'pointer'
    this.element.addEventListener('click', () => this.copy())
  }

  copy() {
    navigator.clipboard.writeText(this.element.innerHTML.trim())
  }
}