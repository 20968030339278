import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  connect() {
    if (!this.hidden()) this._setHideTimeout(1000);
    this._eye = this.eyeSlash()
    this.element.insertAdjacentElement('afterend', this._eye)
  }

  hidden() {
      return this.element.type === 'password'
  }

  eyeSlash() {
    const element = document.createElement('i');
    element.classList.add('fa-solid', 'fa-eye');
    element.style['cursor'] = 'pointer';
    element.style['margin-left'] = '10px';
    element.addEventListener('click', () => this._handleClick())
    return element;
  }

  _setHideTimeout(timeout = 5000) {
    this._timeout = setTimeout(() => this._handleHide(), timeout)
  }

  _handleHide() {
    this._eye.classList.remove('fa-eye-slash');
    this._eye.classList.add('fa-eye');
    this.element.type = 'password';
    if (this.timeout) clearTimeout(this._timeout);
  }

  _handleReveal() {
    this._eye.classList.add('fa-eye-slash');
    this._eye.classList.remove('fa-eye');
    this.element.type = 'text';
    this._setHideTimeout();
  }

  _handleClick() {
    this.hidden() ? this._handleReveal() : this._handleHide();
  }
}