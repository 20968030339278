// The input target represents input elements in the HTML form that the controller interacts with.
// The responsive target is an optional element that the controller uses to trigger server requests.

// The controller has a responsiveUrl value that represents the URL
// that the controller requests to the server.

// inputTargetConnected() method handles events that occur when an 'input' element change the value
// responsiveTargetConnected() method handles events that occur when the 'responsive' element change its values
import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
  static get targets() {
    return ['input', 'responsive']
  }

  static get values() {
    return {
      responsiveUrl: String
    }
  }

  connect() {
    this.dispatchEvents();
  }

  initialize() {
    this.suspendFetch = false;
    this.triggerCallback = null;
    this.debouncedSubmit = _.debounce(() => {
      this.submit();
    }, 2000)
  }

  registerTriggerCallback(callback) {
    this.triggerCallback = callback;
  }

  trigger() {
    this.triggerCallback(this);
  }

  inputTargetConnected(ele) {
    if (ele.classList.contains('select2')) {
      this.handleSelect2($(ele).select2());
    }
  }

  responsiveTargetConnected(ele) {
    if (ele.classList.contains('select2')) {
      $(ele).on('change', () => this.fetch())
    } else {
      ele.addEventListener('change', () => this.fetch())
    }
  }

  handleSelect2($ele) {
    // super hack-ish code to keep the select2 from opening when the user clears a selection
    // overall it creates a bad experience when turbo reloads the form
    // it seems newer versions of select2 have fixed this behavior
    // see here for details: https://github.com/select2/select2/issues/3320#issuecomment-151570396
    $ele.on('select2:unselecting', function (e) {
      $(this).on('select2:opening', function (e) {
        e.preventDefault();
      });
    });

    $ele.on('select2:unselect', function (e) {
      const sel = $(this);
      setTimeout(function () {
        sel.off('select2:opening');
      }, 1);
    });
  }

  queryString() {
    const formData = new FormData;
    const formObject = $(this.element).serializeObject();

    for (let key in formObject) {
      if (Array.isArray(formObject[key])) {
        formObject[key].forEach((value) => formData.append(key, value))
      } else {
        formData.append(key, formObject[key]);
      }
    }
    const urlParams = new URLSearchParams(formData);

    return urlParams.toString();
  }

  urlWithQueryString() {
    return `${this.responsiveUrlValue}?${this.queryString()}`
  }

  fetch() {
    if (this.suspendFetch) return;
    if (!this.hasResponsiveUrlValue) return;

    fetch(this.urlWithQueryString(), {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      },
    })
      .then(resp => resp.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }

  dispatchEvents() {
    const event = new CustomEvent('btn-toggle:enable', {bubbles: true})
    window.enableCurrencyFields($(this.element));
    window.enableCKEditors($(this.element));
    this.element.dispatchEvent(event);
  }

  clear() {
    this.suspendFetch = true;
    this.inputTargets.forEach((ele) => this.setInputValue(ele, null))
    this.suspendFetch = false;
  }

  inputsWithValues() {
    return this.inputTargets.filter((input) => !!input.value);
  }

  setInputValue(ele, value = null) {
    if (ele.classList.contains('select2')) {
      $(ele).val(value).trigger('change');
    } else {
      $(ele).val(value);
    }
  }

  clearInputBySelector(selector) {
    this.element.querySelectorAll(selector).forEach((ele) => this.setInputValue(ele, null))
  }

  submit() {
    Rails.fire(this.element, 'submit');
  }

  submitWithDebounce() {
    this.debouncedSubmit();
  }
}
