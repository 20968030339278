window.initStageProjectDatatable = () => {
  window.stageProjectsTable = $('#stage-projects-datatable').DataTable({
    processing: true,
    searching: false,
    retrieve: true,
    serverSide: true,
    stateSave: true,
    stateSaveCallback: (_settings, data) => {
      const savedState = {
        iDisplayLength: data.length,
      };

      localStorage.setItem('stage_projects_datatable', JSON.stringify(savedState));
    },
    stateLoadCallback: (settings) => {
      const savedState = JSON.parse(localStorage.getItem('stage_projects_datatable'));
      if (savedState) {
        settings._iDisplayLength = savedState.iDisplayLength
      }

      return savedState;
    },
    aLengthMenu: [
      [10, 25, 50, 100, 200],
      [10, 25, 50, 100, 200]
    ],
    iDisplayLength: 10,
    order: [[4, 'desc']],
    ajax: {
      url: $('#stage-projects-datatable').data('source'),
      data: function (d) {
        d.search = { value: $('#search-stage-projects-datatable').val(), regex: false }
        d.scope = $('#stage-projects-datatable').attr('data-status') || getScopeFromUrl();
        d.buyers = $("#filter_buyers").val();
        d.owners = $("#filter_owners").val();
        d.classes = $("#filter_classes").val();
        d.types = $("#filter_types").val();
        d.categories = $("#filter_categories").val();
        d.sub_categories = $("#filter_sub_categories").val();
        d.is_reset = $("#is_reset").val();
      }
    },
    language: {
      'sLengthMenu': 'Showing_MENU_',
      'info': 'of _TOTAL_ Projects',
      'oPaginate': {
        'sFirst': '',
        'sLast': ''
      }
    },
    pagingType: 'full_numbers',
    'dom': "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
    // Sort column 2 (updated_at) by column 3 (hidden updated at with time)
    columnDefs: [
      { "orderData":[ 3 ],   "targets": [ 4 ] },
      {
        "targets": [ 3 ],
        "visible": false,
        "searchable": false
      },
    ],
    columns: getColumns(),
    preDrawCallback: function() {
      $(document).ready(function() {
        const status = getScopeFromUrl();
        const refresh = false;
        setCurrentStatus(status, refresh)
      });
    },
    drawCallback: function() {
      $(document).ready(function() {
        $('.custom-tooltip').tooltip({
          template: '<div class="tooltip custom-tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
        });

        if ($('#stage-projects-datatable_processing')) {
          $('#stage-projects-datatable_processing').attr('role', 'alert');
        }
      });
    }
  });

  $(document).on('click', '.filter_apply_stage_projects',  function() {
    $(".btnStageProjectsFilterText").html("Filters ( "+ $('[id^=filter_]').filter((i,s) => $(s)[0].selectedIndex >= 0).length + " )")
    setTimeout(function(){ window.stageProjectsTable.ajax.reload() }, 300);
  });

  $(document).on('click', '.filter_cancel_stage_projects',  function() {
    $('[id^=filter_]').filter((i,s) => $(s).val([]).change())
    $(".btnStageProjectsFilterText").html("Filters ( "+ $('[id^=filter_]').filter((i,s) => $(s)[0].selectedIndex >= 0).length + " )")
    setTimeout(function(){ window.stageProjectsTable.ajax.reload() }, 300);
  });

  $(".btnStageProjectsFilterText").html("Filters ( "+ $('[id^=filter_]').filter((i,s) => $(s)[0].selectedIndex >= 0).length + " )")

  //setup before functions
  var typingTimer;                //timer identifier
  var doneTypingInterval = 1000;  //time in ms, 1 second for example
  var $input = $('#search-stage-projects-datatable');

  $('#request').on('change', () => {
    setTimeout(window.stageProjectsTable.draw(), 500);
  });

  //on keyup, start the countdown
  $input.on('keyup', function () {
    $(this).val() ? $('#search-clear').removeClass('d-none') : $('#search-clear').addClass('d-none')
    clearTimeout(typingTimer);
    typingTimer = setTimeout(window.stageProjectsTable.ajax.reload, doneTypingInterval);
  });

  //on keydown, clear the countdown
  $input.on('keydown', function () {
    clearTimeout(typingTimer);
  });

  if ($input.length && $input.val().length == 0) {
    $('#search-clear').addClass('d-none');
  }

  $('#search-clear').on('click', function () {
    $input.val('')
    $input.trigger('keyup');
    $('#search-clear').addClass('d-none');
  })

  $('.tab-label').on('click', function () {
    //  activate associated tab
    const status = this.getAttribute('for');
    const tabRadio = this.parentElement.querySelector(`input[data-status="${status}"]`);
    if (tabRadio.dataset['collection'] == 'stage-projects') {
      const refresh = true;
      setCurrentStatus(tabRadio.dataset['status'], refresh);
    }
  })
}

/**
 * Set the data-status attr and optionally fire ajax request to retrieve the stage_projects
 * @param {string} status "active | completed | on_hold | canceled | archived"
 * @param {boolean} refresh make an ajax request or not
 */
function setCurrentStatus(status, refresh) {
  $('#stage-projects-datatable').attr('data-status', status);
  if (refresh) {
    setTimeout(function () { window.stageProjectsTable.ajax.reload() }, 300);
  }

  //set header and description based on user type
  if (status == 'active') {
    document.querySelector('.project-new-btn').classList.remove('d-none')
  } else {
    document.querySelector('.project-new-btn').classList.add('d-none')
  }

  const currentTabContent = document.querySelector(`label[for="${status}"]`).textContent;
  document.querySelector('.stage-project-header').textContent = currentTabContent;
}

/**
 * Get the scope (status in this case) from the URL query param
 * @returns {string}
 */
function getScopeFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const scope = urlParams.get('status');
  return scope ? scope : 'active';
}

function getColumns() {
  let columns;
  if ($('#stage-projects-datatable').attr('data-workspaces-enabled') == 'true') {
    columns = [
      { data: 'purchasing_agency' },
      { data: 'project_name', className: 'w-30 table-links-cell' },
      { data: 'project_created_at' },
      { data: 'project_updated_at' },
      { data: 'project_updated_at_hidden' },
      { data: 'project_team' },
      { data: 'project_workspace' },
      { data: 'project_status', orderable: false },
      { data: 'project_progress', orderable: false, className: 'w-15' },
      { data: 'project_type', className: 'w-10' },
      { data: 'project_owner', className: 'profile-photos text-center w-10' },
      { data: 'purchase_estimate' },
      { data: 'solicitations_purchase_class' },
      { data: 'solicitations_categories' },
      { data: 'solicitations_subcategories' },
      { data: 'dt_actions', className: 'table-links-cell', orderable: false }
    ];
  } else {
    columns = [
      { data: 'purchasing_agency' },
      { data: 'project_name', className: 'w-30 table-links-cell' },
      { data: 'project_created_at' },
      { data: 'project_updated_at' },
      { data: 'project_updated_at_hidden' },
      { data: 'project_status', orderable: false },
      { data: 'project_progress', orderable: false, className: 'w-15' },
      { data: 'project_type', className: 'w-10' },
      { data: 'project_owner', className: 'profile-photos text-center w-10' },
      { data: 'purchase_estimate' },
      { data: 'solicitations_purchase_class' },
      { data: 'solicitations_categories' },
      { data: 'solicitations_subcategories' },
      { data: 'dt_actions', className: 'table-links-cell', orderable: false }
    ]
  }

  return columns;
}

$(document).on('turbolinks:load', () => {
  window.initStageProjectDatatable();
});
