$(document).on('turbolinks:load', function(){
  if ($("#analytics-datatable").length) {
    window.table = $("#analytics-datatable").DataTable({
      searching: false,
      retrieve: true,
      processing: true,
      serverSide: true,
      stateSave: true,
      aLengthMenu: [
        [10, 25, 50, 100, 200],
        [10, 25, 50, 100, 200]
      ],
      iDisplayLength: 100,
      columnDefs: [
        { targets: [7], className: 'table-links-cell' },
      ],
      aoColumnDefs: [
        { bSortable: false, aTargets: [ 4, 5, 6, 7, 8, 9, 10, 11, 12 ] },
      ],
      ajax: {
        url: $("#analytics-datatable").data('source'),
        type: "POST",
        data: function(d){
          d.date_range = $("#request").val();
          d.statuses = $("#filter_statuses").val();
          d.buyers = $("#filter_buyers").val();
          d.owners = $("#filter_owners").val();
          d.classes = $("#filter_classes").val();
          d.types = $("#filter_types").val();
          d.categories = $("#filter_categories").val();
          d.sub_categories = $("#filter_sub_categories").val();
          d.is_reset = $("#is_reset").val();
          d.search_analytics_key = $("#search_datatable").val();
        }
      },
      language: {
        "sLengthMenu": "Showing_MENU_",
        "info": "of _TOTAL_",
        "oPaginate": {
          "sFirst": "",
          "sLast": ""
        }
      },
      pagingType: "full_numbers",
      dom: '<"table-top"fB><"table-responsive"rt><"table-bottom"<"table-task-info"li>p>',
      buttons: [{
        extend: 'csvHtml5',
        text: 'Export Data To CSV',
        customize: function (csv) {
          var csvRows = csv.split('\n');
          csvRows[0] = csvRows[0].replace('""', '"Description"')
          return csvRows.join('\n');
        },
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4, 5, 6, 7, 9, 11, 12, 13, 14, 15],
          charSet: "utf-8",
          format: {
              body: function ( data, row, column, node ) {
                var str = data
                var a_reg = /<a[^>]*>([^<]+)<\/a>/g
                switch(column) {
                  case 1:
                    var regex_data = a_reg.exec(data)
                    if(regex_data){
                      str = regex_data[1]
                    }else{
                      str = "N/A"
                    }
                    break;
                  case 2:
                    if (data == "<span></span>")
                      str = ""
                    else
                      var title = data.split("data-content=")[1]
                      if(title){
                        str = title.split('"')[1]
                      }else{
                        str = "N/A"
                      }
                    break;
                  case 3:
                    var reg = /<span[^>]*>([^<]+)<\/span>/g
                    str = reg.exec(data)
                    if (Array.isArray(str))
                      str = str[1]
                    else
                      str = reg.exec(str)
                    break;
                  case 5:
                      str = data.replace("</br>", "");
                    break;
                  case 6:
                    if (data.split("title=")[1])
                      str = data.split("title=")[1].split('"')[1]
                    else
                     str = ""
                    break;
                  case 7:
                    if (data.split(">"))
                      str = data.split("</a>")[0].split("\>").reverse()[0]
                    else
                      str = ""
                    break;
                  case 14:
                    created_at = new Date(data);
                    str = created_at.toISOString()
                    break;
                }
                return str.replace(/['"]+/g, '');
              }
          },
        }
      }],
      columns: [
        {data: "purchasing_agency"},
        {
          data: "project_name_and_id",
          render: function (data, type, full, meta) {
            let [id, projectName] = data.split(",");

            if (projectName){
              return '<a class = "link_name" href=' + "/account/solicitations/" + id + "/analytics_data" + ' type="button">' + projectName +'</a>'
            } else {
              return data;
            }
          }
        },
        {
          data: "description",
          render: function (data, type, full, meta) {
            if (data && data !=""){
              return '<i role="button" aria-label="Description information" class="ti-info-alt text-decoration-none" tabindex="0" data-toggle="popover" data-html="true" data-trigger="click focus" data-placement="top" data-content="' + data.trimToLength(200) + '"></i>';
            }else{
              return '<span></span>'
            }
          }
        },
        {
          data: "status"
        },
        {data: "tasks"},
        {data: "updated_at"},
        {data: "procurement_official", className: "profile-photos text-center"},
        {
          data: "editors",
          render: function (data, type, full, meta) {
            var myArr = data.split(" , ").filter(function(v){return v!==''});
            var newArr = myArr.slice(1);
            if (newArr.length > 0){
              return '<a href= "/account/solicitations/' + myArr[0] + '/fetch_users' + ' "data-html="true" type="button" data-remote=true data-toggle="tooltip" title="'+ newArr.slice(0,5).join(" <br> ") +'...">'+ newArr.length +'</a>'
            }else{
              return '<span>0</span>'
            }
          }
        },
        {data: "purchase_estimate"},
        {data: "solicitations_purchase_class"},
        {
          data: "solicitations_type",
          render: function (data, type, full, meta) {
            if (data && data !=""){
              return jQuery('<div />').html(data).text()
            }
          }
        },
        {data: "solicitations_categories"},
        {data: "solicitations_subcategories"},
        {data: "solicitations_full_types", className: "d-none"},
        {data: "created_at", className: "d-none"},
        {data: "purchase_estimate_with_number", className: "d-none"},
      ],
      destroy : true,
      drawCallback: function(settings) {
        Chartkick.eachChart( function(chart) {
          chart.updateData(settings.json[chart.getElement().id])
          const altText = $(chart.element).siblings('h4')[0].innerHTML;
          $(chart.element).find('canvas').attr('alt', altText)
        })
        $("#total_budget").text(Math.round(settings.json["total_budget"])).digits()
        $("[data-toggle=tooltip]").tooltip();
        $('[data-toggle="popover"]').popover({
          html: true,
        })
      },
    });
  }

  $(document).on('click', '.filter_apply_analytics',  function() {
    $(".btnAnalyticFilterText").html("Filters ( "+ $('[id^=filter_]').filter((i,s) => $(s)[0].selectedIndex >= 0).length + " )")
    setTimeout(function(){ window.table.ajax.reload() }, 300);
  });

  $(document).on('click', '.filter_cancel_analytics',  function() {
    $('[id^=filter_]').filter((i,s) => $(s).val([]).change())
    $(".btnAnalyticFilterText").html("Filters ( "+ $('[id^=filter_]').filter((i,s) => $(s)[0].selectedIndex >= 0).length + " )")
    setTimeout(function(){ window.table.ajax.reload() }, 300);
  });

  $(".btnAnalyticFilterText").html("Filters ( "+ $('[id^=filter_]').filter((i,s) => $(s)[0].selectedIndex >= 0).length + " )")

  //setup before functions
  let typingTimer;                //timer identifier
  const doneTypingInterval = 1000;  //time in ms, 1 second for example
  let searchableInput = $('#search_datatable');

  $('.applyBtn').on('click', () => {
    setTimeout(doneTyping, 500);
  });

  $('#request').on('change', () => {
    setTimeout(doneTyping, 500);
  });

  //on keyup, start the countdown
  searchableInput.on('keyup', function () {
    $(this).val() ? $('#search_clear').removeClass('d-none') : $('#search_clear').addClass('d-none')
    clearTimeout(typingTimer);
    typingTimer = setTimeout(doneTyping, doneTypingInterval);
  });

  //on keydown, clear the countdown
  searchableInput.on('keydown', function () {
    clearTimeout(typingTimer);
  });

  if (searchableInput.length && searchableInput.val().length == 0){
    $('#search_clear').addClass('d-none');
  }

  $('#search_clear').on('click', function(){
    searchableInput.val('')
    searchableInput.trigger('keyup');
    $('#search_clear').addClass('d-none');
  })

})

//user is "finished typing," do something
function doneTyping () {
  window.table && window.table.draw();
}

$.fn.digits = function(){
  return this.each(function(){
      $(this).text( "$" + $(this).text().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") );
  })
}
