window.initTaskDatatable = () => {
  $("#filter_assignees").select2();
  $("#filter_statuses").select2();
  window.tasksDatatable = $("#tasks-datatable").DataTable({
    searching: false,
    retrieve: true,
    processing: true,
    serverSide: true,
    stateSave: true,
    aLengthMenu: [
      [10, 25, 50, 100, 200],
      [10, 25, 50, 100, 200]
    ],
    iDisplayLength: 10,
    language: {
      "sLengthMenu": "Showing_MENU_",
      "info": "of _TOTAL_ Tasks",
      "oPaginate": {
        "sFirst": "",
        "sLast": ""
      }
    },
    "oLanguage": {
      "sEmptyTable": '<div class="no-task-data"><div class="no-task-data-image"></div><h3 role="alert">No Tasks</h3><p class="no-text-transform">Clear any filters to view all tasks</p></div>',
    },
    pagingType: "full_numbers",
    dom: '<"table-top"fB><"table-responsive"rt><"table-bottom"<"table-task-info"li>p>',
    buttons: [{
      extend: 'csvHtml5',
      text: 'Export Data To CSV',
      customize: function (csv) {
        var csvRows = csv.split('\n');
        return csvRows.join('\n');
      },
      exportOptions: {
        columns: [ 1, 2, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
      }
    }],
    ajax: {
      url: $("#tasks-datatable").data('source'),
      type: "GET",
      data: function(d){
        const formObject = $('#tasks-datatable-filter-form').serializeObject();

        for (let key in formObject) {
          if (formObject[key] === '') continue;

          d[key] = formObject[key];
        }

        return d;
      }
    },
    pagingType: "full_numbers",
    columnDefs: [
      { orderable: false, targets: 2 },
      { orderable: false, targets: 4 },
      {
        targets: [5],
        className: 'table-links-cell'
      },
      {
          orderable: false,
          className: 'task_select',
          targets:   0,
          'render': function (data, type, full, meta){
            return `<input type="checkbox" aria-label="Select task ${data}" name="task_id[]" onclick="window.Tasks.selectTask();" class="checkbox_select" value="${data}">`;
          }
      },
      {
        targets: [7],
        createdCell: function(td, cellData, rowData, row, col){
          $(td).closest("tr").attr('data-id', rowData['DT_RowId']);
          $(td).addClass("profile-photos-small");
        }
      },
    ],
    select: {
      style:    'multi',
      selector: 'td:first-child'
    },
    order: [[ 8, 'desc' ]],
    columns: [
      {data: "id"},
      {
        data: "task_details",
        render: function (data, type, full, meta) {
          return '<a class = "link_name link_task_details text-blue" href="/account/tasks/'+ data.split(" , ")[0] +'/task_details" title="' + data.split(" , ")[0] + '" data-remote=true type="button">#' + data.split(" , ")[0] +'</a>'
        }
      },
      {data: "task_name", className: "taskName",
        render: function (data, type, full, meta) {
          return '<a class = "link_name link_task_details text-blue" href="/account/tasks/'+ data.split(" , ")[0] +'/task_details" title="' + data.split(" , ")[1] + '" data-remote=true type="button">' + data.split(" , ")[1] +'</a>'
        }
      },
      {
        data: "description",
        render: function (data, type, full, meta) {
          var data = jQuery('<div />').html(data).text()
          if (data && data !=""){
            return '<i data-toggle="tooltip" title="' + data.trimToLength(200) + '" class="ti-info-alt text-decoration-none"> </i>';
          }else{
            return '<span></span>'
          }
        }
      },
      {data: "status", orderable: true},
      {data: "project"},
      {data: "labels", orderable: true},
      {data: "assigned_to"},
      {data: "due_date"},
      {data: "last_updated"},
      {data: "task_id", className: "d-none"},
      {data: "status_arr", className: "d-none"},
      {data: "project_arr", className: "d-none"},
      {data: "document_arr", className: "d-none"},
      {data: "section_arr", className: "d-none"},
      {data: "assigned_to_arr", className: "d-none"},
      {data: "labels_arr", className: "d-none"},
      {data: "created_at_by_timezone", className: "d-none"},
      { data: "last_updated_at_by_timezone", className: "d-none"},
      {data: "due_at_by_timezone", className: "d-none"},
    ],
    destroy : true,
    drawCallback: function(settings) {
      $("[data-toggle=tooltip]").tooltip();

      $(".link_task_details").click(function () {
        $('tr').removeClass('active');
        $(this).closest("tr").addClass("active");
      });

      const event = new CustomEvent('filter-pills:reload', {bubbles: true})
      document.dispatchEvent(event)
      window.Tasks.updateSelectedTaskCount();
    }
  });

  window.tasksDatatable.buttons().container().prependTo('.table-filter');

  return window.tasksDatatable;
}
