import { DatatablesBase } from "../../../../../../app/javascript/controllers/datatables/base";

export default class extends DatatablesBase {
  static get orderConfig() {
    return [[2, 'desc']];
  }

  static get languageConfig() {
    return {
      "sLengthMenu": "Showing_MENU_",
      "info": "of _TOTAL_ Requisition Requests",
      "oPaginate": {
        "sFirst": "",
        "sLast": ""
      }
    };
  }

  static get searchConfig() {
    return { searching: false }
  }

  // order-dependent column config.
  // For column visibility, set data-column-visibility on the table. See setupTableConnectedCallbacks()
  static get columnConfig() {
    return [
      { data: 'id' },
      { data: 'ref_no', width: '8rem'},
      {
        data: 'title',
        render: (datum, _, dataset) => {
          return `<a href="${dataset.link}">${datum}</a>`
        },
        width: '19rem'
      },
      { data: 'dateinitiated' },
      { data: 'status' },
      { data: 'requestor_name' },
      { data: 'datarequestorname' },
      { data: 'vendorname' },
      { data: 'division'},
      { data: 'next_approver_name' },
      { data: 'amount' },
      { data: 'created_at' },
      { data: 'updated_at', target: 9, orderData: 10 }, // order by updated_at_datetime
      { data: 'updated_at_datetime' },
    ]
  }

  ajaxConfig() {
    return {
      ajax: {
        url: this.table.dataset.source,
        method: 'GET',
        data: (d) => {
          const formObject = $(`#${this.element.dataset.namespace}-datatable-filter-form`).serializeObject();

          for(let key in formObject) {
            if (formObject[key] === '') continue;

            d[key] = formObject[key];
          }
          return d;
        },
        complete: (data) => {
          this.ajaxCompleteCallback(data.responseJSON);
        }
      }
    }
  }

  // param data: { recordsTotal<int>, recordsFiltered<int>, data<array> } || undefined if no valid response
  ajaxCompleteCallback(data) {
    this._removeClassSelected();

    if (data == undefined) return;

    this._toggleFormDescription(data);
  }

  // this might be a better choice than ajaxCompleteCallback for updating once datatable is drawn/ready...
  drawCallback() {
    return { drawCallback: (settings) => {
      this._updateStatWidgetsWith(settings.json);
      this._formatCharts();
    }}
  }

  // For some reason I can't get 'visible' key in the columnConfig above to work as I'd like, so I'm using this instead
  setupTableConnectedCallbacks() {
    this._applyColumnVisibility()
    this._applyFilterVisibility()
  }

  // allows updating of stat widgets present on on the page with data from the datatable ajax response
  _updateStatWidgetsWith(data) {
    if (data == undefined || data['recordsTotal'] == undefined) return;

    const statWidgets = document.querySelectorAll('[data-stat]');
    const statAmountDataAttr = this.element.dataset.namespace + "_amount";
    const statSizeDataAttr = this.element.dataset.namespace + "_size";

    statWidgets.forEach((widget) => {
      if (widget.dataset['stat'] == statAmountDataAttr) widget.innerHTML = data[statAmountDataAttr];
      if (widget.dataset['stat'] == statSizeDataAttr) widget.innerHTML = data[statSizeDataAttr];
    });
  }

  _formatCharts() {
    this._formatChart('completed-by-division');
  }

  // for javascript-heavy chart manipulation that can't be done before rendering the chartkick helper, like callbacks
  _formatChart(chartId) {
    const chart = Chartkick.charts[chartId]

    if (chart == undefined) return;

    // the library key is sent from chartkick chart.js directly, as the key of 'options'.
    const newOptions = {
      library: {
        scales:{
          y: {
            ticks: {
              stepSize: 5000,
              // adds $ before y-axis values
              callback: function(value, _index, _ticks) {
                          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                        }
            }
          }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    // adds $ before tooltip values
                    // from https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                        }
                        return label;
                    }
                }
            }
        }
      }
    }
    const mergedOptions = jQuery.extend(chart.options,newOptions)
    // using setOptions allows passing in a javascript callback function to chartkick chart:
    chart.setOptions(mergedOptions);
  }

  _removeClassSelected() {
    const selected = this.element.querySelectorAll('table.dataTable tr.selected')
    if (selected.length > 0) {
      selected.classList.remove('selected');
    }
  }

  _toggleFormDescription(data) {
    // datatables description based on recordsTotal count
    if(data['recordsTotal'] != undefined && data['recordsTotal'] > 0) {
      this._toggleElements(this.element.dataset.namespace + "-form-desc", this.element.dataset.namespace + "-form-desc-empty");
    } else {
      this._toggleElements(this.element.dataset.namespace + "-form-desc-empty", this.element.dataset.namespace + "-form-desc")
    }

    // insights
    if(data['total_records_all_years'] && data['total_records_all_years'] > 0 ) {
      this._toggleElements("intake-insights-form-desc", "intake-insights-form-desc-empty")
    } else {
      this._toggleElements("intake-insights-form-desc-empty", "intake-insights-form-desc")
    }
  }

  _toggleElements(idToShow, idToHide) {
    const toHide = document.getElementById(idToHide);
    const toShow = document.getElementById(idToShow);
    if(toHide) {
      toHide.classList.add('d-none');
    }
    if(toShow) {
      toShow.classList.remove('d-none');
    }
  }

  _applyColumnVisibility() {
    const columnVisibility = JSON.parse(this.table.dataset.columnVisibility);
    for (const [key, val] of Object.entries(columnVisibility)) {
      const idx = this.constructor.columnConfig.findIndex((colConfig) => colConfig['data'] === key);
      this.datatable.column(idx).visible(val);
    }
  }

  _applyFilterVisibility() {
    const columnVisibility = JSON.parse(this.table.dataset.columnVisibility);

    for (const [key, _val] of Object.entries(columnVisibility)) {
      const idx = this.constructor.columnConfig.findIndex((colConfig) => colConfig['data'] === key);
      const filterEle = document.getElementById(`${this.element.dataset.namespace}-filter-${key}`);
      if (filterEle == undefined) continue;

      if (this.datatable.column(idx).visible()) {
        filterEle.classList.remove('d-none');
      } else {
        filterEle.classList.add('d-none');
      }
    }
  }
}
