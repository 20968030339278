import { DatatablesBase } from "../base";

export default class extends DatatablesBase {
  static get searchConfig() {
      return { searching: false }
  }

  static get languageConfig() {
    return {
      sLengthMenu: 'Showing_MENU_',
      info: 'of _TOTAL_ Supporting Materials',
      oPaginate: {
        sFirst: '',
        sLast: ''
      },
      infoEmpty: ''
    };
  }

  static get emptyTableConfig() {
    return {
      oLanguage: {
        sEmptyTable: '<div><div class="datatables--blank-document"></div><h3 role="alert">No Supporting Materials Added Yet</h3><p class="no-text-transform">Click the Add Supporting Materials button below to add your first supporting material.</p></div>'
      }
    }
  }

  static get orderingConfig() {
    return { ordering: false }
  }

  static get columnConfig() {
    return [
      {
        data: 'label',
        orderable: false
      },
      {
        data: 'candidate_name',
        orderable: false
      },
      {
        data: 'milestones',
        orderable: false
      },
      {
        data: 'deliverables',
        orderable: false
      },
    ]
  }
}
