import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  /**
   * Controller for triggering front end actions from back-end broadcast or turbo streams
   *
   * Append action divs into the action-center in the base layout
   * There is a helper partial for rendering an action is shared/stimulus/_action
   * action_name corresponds to the function to be called locally
   * acrguments will be a json string that is turned into a js object and passed to the called function
   */
  static get targets() {
    return ['trigger'];
  }

  connect() {
    const args = JSON.parse(this.element.dataset.arguments);
    // providing some debouncing for the refresh action so that the backend can't spam network requests
    // on the client side.
    window._refresher = _.debounce(this.refresh, 250);
    this.element.dataset.name.split(' ').forEach( action => {
      switch (action) {
        case 'alert':
          this.alert(args);
          break;
        case 'event':
          this.event(args);
          break;
        case 'turbo-frame-reload':
          this.turboFrameReload(args)
        case 'refresh':
          window._refresher(args)
        case 'reload':
          window.location.reload();
      }
    })
    this.element.remove();
  }

  /*
  * args - object with key of text
  *
  * {
  *   alertText: 'Hello world'
  * }
  */
  alert(args) {
    alert(args.alertText || args.text);
  }

  /*
  * Fires event from target element bubbling to document
  * args - object with key of eventName
  *
  * {
  *   eventName: 'event:name'
  * }
  */
 event(args) {
  const event = new CustomEvent(args.eventName, {bubbles: true})
  const targetElements = args.eventTargetSelector ? document.querySelectorAll(args.eventTargetSelector) : [this.element]
  targetElements.forEach( (ele) => ele.dispatchEvent(event) )
 }

 /*
 * Reloads an existing turbo frame on the page - will set the turbo frame src if passed in the arguments
 * args - object containing the turboFrameId and an optional turboFrameSrc
 */
 turboFrameReload(args) {
  const frame = document.getElementById(args.turboFrameId);
  if (args.turboFrameSrc) {
    frame.src = args.turboFrameSrc
  } else {
    frame.reload()
  }
 }


 /**
  * Refreshes the users current page
  */
 refresh(args) {
   const ids = Array.isArray(args.ids) ? args.ids : [args.ids].filter(id => !!id)
   window.StimulusRefresh.refresh(ids, args.from);
 }

}
