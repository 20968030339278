export function setAutosaveStatus(status) {
  const autosaveBar = document.getElementsByClassName("collaboration__topbar-autosave")[0];

  if (autosaveBar) {
    autosaveBar.classList.remove('offline', 'saving', 'saved', 'text-danger');
  }

  if (status === 'saving') {
    const autosaveDescription = document.querySelector(".collaboration__topbar-autosave-saving .autosave-description");
    if (autosaveDescription) {
      autosaveDescription.textContent = "Saving...";
    }
  }

  if (status === 'offline' || status === 'saved' || status === 'saving') {
    if (autosaveBar) {
      autosaveBar.classList.add(status);
    }
  }
}

export function handleCopyUrlButton() {
  const triggerElement = document.querySelector('.ck-collaboration__share i');
  const inputElement = document.getElementById('demo-collaboration-url');

  if (!inputElement || !triggerElement) return;

  inputElement.value = window.location;
  inputElement.addEventListener('click', () => inputElement.select());

  triggerElement.addEventListener('click', (e) => {
    e.preventDefault();
    let buttonTimeout;
    inputElement.select();
    document.execCommand('copy');
    triggerElement.textContent = 'Copied!';
    clearTimeout(buttonTimeout);

    buttonTimeout = setTimeout(() => {
      triggerElement.textContent = '';
    }, 1500);
  });
}

export function loadOnSaveEvent(editor, outputArea, outputVersionNumber, saveData) {
  for (const saveButtonElement of document.querySelectorAll('.ckeditor-save-button')) {
    editor.ui.view.listenTo(saveButtonElement, 'click', (eventInfo, domEvent) => {
      domEvent.preventDefault();
      saveChanges(editor, outputArea, outputVersionNumber);
      setTimeout(function () {
        saveData();
      }, 1000);
    })
  }
}

function saveChanges(editor, outputArea, outputVersionNumber) {
  setAutosaveStatus('saving');
  outputArea.value = editor.getData();
  outputVersionNumber.value = editor.plugins.get('RealTimeCollaborationClient').cloudDocumentVersion;
}

let scrollBlocked = false;
function handleCommentSelection() {
  if (scrollBlocked) return;
  scrollBlocked = true;

  const selectedElement = document.querySelector('.ck-comment-marker--active') || document.querySelector('.ck-suggestion-marker--active')
  if (selectedElement) {
    const topOffset = selectedElement.getBoundingClientRect().top + window.pageYOffset - 350;
    window.scrollTo({ top: topOffset, behavior: 'smooth' });
  }

  setTimeout(() => {
    scrollBlocked = false;
  }, 500);
}

export function handleSidebarElementClick(editor) {
  setTimeout(() => {
    const sidebarItems = document.querySelectorAll('.ck-sidebar-item');
    for (const item of sidebarItems) {
      editor.ui.view.listenTo(item, 'click', () => {
        setTimeout(() => {
          handleCommentSelection();
        }, '100')
      })
    }
  }, 500)
}

export function addToolbarContainer(editor) {
  document.querySelector('.ck-collaboration__toolbar').appendChild(editor.ui.view.toolbar.element);
  editor.on('destroy', () => editor.ui.view.toolbar.element.remove());
  document.querySelector('.ck-toolbar').classList.add('ck-reset_all');
  const loaderElement = document.querySelector('.ck-collaboration .ck-loader');

  if (loaderElement) { loaderElement.classList.add('fadeout'); }
  setTimeout(() => {
    document.querySelector('.ck-collaboration').classList.add('ck-collaboration--ready');
    loaderElement.style.display = 'none';
    if (editor.ui.view.editable.element.classList.contains('autofocus')) {
      editor.editing.view.focus();
    }
  }, 200);
}

export function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return uri + separator + key + "=" + value;
  }
}

export function handleSidebarResize(editor) {
  const annotationsUI = editor.plugins.get('AnnotationsUIs');
  const buttonElement = document.getElementById('sidebar-display-toggle');
  const sidebarElement = document.querySelector('.ck-collaboration__sidebar');
  const editorDiv = document.querySelector('.ck-collaboration__editable');

  if (editorDiv && editorDiv.dataset.showSidebar === 'true') {
    editor.ui.view.listenTo(window, 'resize', refreshDisplayMode);
    refreshDisplayMode();
    switchDisplayMode();
  }

  function refreshDisplayMode() {
    if (window.innerWidth < 1100) {
      if (sidebarElement) {
        sidebarElement.classList.remove('narrow');
        sidebarElement.classList.add('hidden');
      }
      if (annotationsUI) {
        annotationsUI.switchTo('inline');
      }
    } else if (window.innerWidth < 1400) {
      if (buttonElement) {
        buttonElement.classList.add('narrow-display');
      }
      if (sidebarElement) {
        sidebarElement.classList.remove('hidden');
        sidebarElement.classList.add('narrow');
      }
      if (annotationsUI) {
        annotationsUI.switchTo('narrowSidebar');
      }
    } else {
      if (sidebarElement) {
        sidebarElement.classList.remove('hidden', 'narrow');
      }
      if (annotationsUI) {
        annotationsUI.switchTo('wideSidebar');
      }
    }
  }

  function switchDisplayMode() {
    editor.ui.view.listenTo(buttonElement, 'click', (eventInfo, domEvent) => {
      domEvent.preventDefault();
      // Fire events only while narrow or wide display mode is active. Do not fire on inline sidebar.
      if (annotationsUI && annotationsUI.activeUIs.has('narrowSidebar')) {
        annotationsUI.switchTo('wideSidebar');
        if (sidebarElement) {
          sidebarElement.classList.remove('hidden');
          sidebarElement.classList.remove('narrow');
        }
        if (buttonElement) {
          buttonElement.classList.remove('narrow-display');
        }
      }

      else if (annotationsUI && annotationsUI.activeUIs.has('wideSidebar')) {
        annotationsUI.switchTo('narrowSidebar');
        if (sidebarElement) {
          sidebarElement.classList.remove('hidden');
          sidebarElement.classList.add('narrow');
        }
        if (buttonElement) {
          buttonElement.classList.add('narrow-display');
        }
      }
    });
  }
}
