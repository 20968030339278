/**
 * This class is for the elements that acts as the source inputs for the reveal controller.
 *
 * The prime responsibility of this class is merely to attach the listeners to the inputs.
 * The listener then triggers a callback inside the stimulus controller as needed. The stimlus controller
 * also debounces this callback to help reduce noise,
 */

export class SourceElement {
  /**
   *
   * @param {HTMLElement} ele The input element that should be listened to
   * @param {Controller} stimulus The parent stimlus controller which will receive the handleChange callback
   */
  constructor(ele, stimulus) {
    this.element = ele;
    this.stimulus = stimulus;
    this._setupListener()
  }

  /**
   * Attaches the listener to the element
   */
  _setupListener() {
    if (this.element.classList.contains('select2') || (this.element.dataset.controller || '').includes('select2')) {
      $(this.element).on('change', (event) => this.stimulus.handleChange(event))
    } else {
      this.element.addEventListener(this.listenEvent, (event) => this.stimulus.handleChange(event))
    }
  }

  /**
   * Returns the applicable listen event for the input element
   * @returns {string} the listen event for the input
   */
  get listenEvent() {
    switch(this.element.type) {
      case 'text':
        return this.element.dataset.datepickerTarget ? 'change' : 'keyup';
      case 'textarea':
        return 'keyup';
      case 'tel':
        return 'keyup';
      default:
        return 'change';
    }
  }
}