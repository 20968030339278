import { Controller } from '@hotwired/stimulus';

// This controller sets the top property of some sticky elements on the page.
// The value changes dependending on the height of the elements above them, and the present elements.

export default class extends Controller {
  static get targets() {
    return ['sticker'];
  }

  initialize() {
    /* height calculation cache: */
    this.offsetHeights = {};

    this._boundCheckScrollable = _.debounce(() => this.checkElementsPosition(), 200);

    /* at the moment we are hardcoding a few fixed menu items */
    this.fixedMenuItems = ['#impersonation-banner-warning', '#ci-navigation-bar', '#breadcrumb-nav'];
    this.fixedMenuItemsWithSubMenu = this.fixedMenuItems.concat(['.selected .sub-menu-w']);

    this.calculateOffsetHeights();

    this.offsetSelectorsHeight = this.setOffsetSelectorsHeight(this.fixedMenuItems);

    this._updationObserver = new MutationObserver((_mutations) => {
      this._boundCheckScrollable();
    })

    this.trigger_updationObserver();
  }

  stickerTargetConnected(sticker) {
    this.adjustTopStickyTarget(sticker)
  }

  trigger_updationObserver() {
    this._updationObserver.observe(document.body, {
      characterData: false,
      attributes: false,
      childList: true,
      subtree: true
    });
  }

  connect() {
    this.adjustTopStickyElements()
  }

  disconnect() {
    this._updationObserver.disconnect();
  }

  /* When target is defined */
  adjustTopStickyTarget(sticker) {
    sticker.style.top = this.offsetSelectorsHeight;
  }

  /* When class is hardcoded in the list below */
  adjustTopStickyElements() {
    const elementsByClass = ['.modal-dialog', '.overlay-content', '.ck-collaboration__toolbar', '.configuration-left-sidebar-inner']
    elementsByClass.forEach(className => {
      const elements = document.querySelectorAll(className);
      elements.forEach(element => {
        this.setOffset(element, this.fixedMenuItems);
      });
    })

    /* Now let's adjust a handful of IDS: */
    const elementsById = [
      { id: 'ci-navigation-bar', offsetSelectors: this.fixedMenuItems.slice(0,1) },
      { id: 'breadcrumb-nav', offsetSelectors: this.fixedMenuItems.slice(0,2) },
      { id: 'sticky-document-sidebar', offsetSelectors: this.fixedMenuItems },
      { id: 'ck-collaboration__toolbar', offsetSelectors: this.fixedMenuItems },
      { id: 'main-content', offsetSelectors: this.fixedMenuItems },
      { id: 'notification-container', offsetSelectors: this.fixedMenuItemsWithSubMenu },
    ];

    elementsById.forEach(({ id, offsetSelectors }) => {
      const element = document.getElementById(id);
      this.setOffset(element, offsetSelectors);
    });

    /* old section editor: */
    const sectionActionsContainers = $('.section-actions-container')
    sectionActionsContainers.each((index) => {
      this.setOffset(sectionActionsContainers[index], this.fixedMenuItems);
    });
  }

  /* calculate the height of the fixed elements, and cache in object for later use */
  calculateOffsetHeights() {
    this.fixedMenuItemsWithSubMenu.forEach(id => {
      const element = document.querySelector(id);
      this.offsetHeights[id] = element ? element.offsetHeight : 0;
    });
  }

  getOffsetPosition(ids) {
    return ids.reduce((total, id) => total + this.offsetHeights[id], 0);
  }
  
  setOffsetSelectorsHeight(offsetSelectors) {
    return `${this.getOffsetPosition(offsetSelectors)}px`;
  }

  // Set the top property of the element to the sum of the heights of the elements in the offsetElements array.
  setOffset(element, offsetElements) {
    if (element) {
      element.style.top = `${this.getOffsetPosition(offsetElements)}px`
    }
  }

  checkElementsPosition() {
    this.adjustTopStickyElements();
  }
}
