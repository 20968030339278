import { ApplicationController } from "../application_controller"
/*
This serves as a simple controller for triggering a show on a modal. This allows for modal content to not have to wrap the entire page.
Now instead, the modal--static (and future modal--dynamic) will only need to wrap their respective elements. modal--trigger can be placed
on buttons/links and target those specific modals.

To target a specific modal there are currently a couple options:

define a selector value using: modal--trigger-selector-value='<selector>' - this will use document.querySelector and call show() on the element
Note that the new modal--static controller adds the show function directly to the element

define a modal--static outlet using: modal--trigger-modal--static-outlet='<selector>' - this will wire the modal--static controller to the trigger
controller. This functions well at the moment but the selector-value approach seemed a bit simpler to reason with. However, there could be a future
use case - especially in the case of dynamic modals, where wiring the controllers together makes more sense.
*/
export default class extends ApplicationController {
  static get outlets() {
    return ['modal--static'];
  }

  static get values() {
    return {
      selector: String
    }
  }

  connect() {
    this.element.addEventListener('click', () => this.show())
  }

  show() {
    if (this.selectorValue) {
      document.querySelector(this.selectorValue).show();
    } else if (this.modalStaticOutlet) {
      this.modalStaticOutlet.show();
    } else {
      console.log('There are no modal outlets assigned to this trigger. For example, to add a static modal outlet, define `data-modal--trigger-modal--static-="<selector>" on this.element')
    }
  }
}