
// 🚫 DEFAULT BULLET TRAIN INCLUDES
// This section represents the default includes for a Bullet Train application. Your own includes should be specified
// at the end of the file. This helps avoid merge conflicts in the future, should we need to change our own includes.

require("./account");
require("sprinkles");
require("./clean_admin");
require("legacy/homepage");

// ✅ YOUR APPLICATION'S INCLUDES
// If you need to customize your application's includes, this is the place to do it. This helps avoid merge
// conflicts in the future when Rails or Bullet Train update their own default includes.
require("./account/grid/grid");

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext, definitionForModuleWithContextAndKey, definitionForModuleAndIdentifier } from "@hotwired/stimulus-webpack-helpers";
import { StimulusRefresh } from "./stimulus_refresher";

StimulusRefresh.start();
window.Stimulus = Application.start();


const context = require.context("./controllers", true, /\.js$/);
const packsContext = require.context("../../packs/intake/app/javascript/controllers", true, /\.js$/);
const componentContext = require.context("../components", true, /\.js$/);
// toggle the following line to true to enable and output some debugging info into the console
if (false) {
  window.Stimulus.debug = true;

  const contextKeys = context.keys()
  for (let i = contextKeys.length - 1; i >= 0; i--) {
    const key = contextKeys[i]
    try {
      const identifier = definitionForModuleWithContextAndKey(context, key)
      if (identifier) {
        const definition = definitionForModuleAndIdentifier(context(key), identifier)
        console.log(`Stimulus Loaded: ${definition}`)
      }
    } catch (error) {
      console.log(`error loading ${key}`)
      console.error(error)
    }
  }
}
Stimulus.load(definitionsFromContext(context));
Stimulus.load(definitionsFromContext(packsContext));
Stimulus.load(definitionsFromContext(componentContext));
