$(document).on('recommendations-form:attached', (event) => {
  const fetchTitle = () => {
    return titleEditor.getData();
  }

  const fetchBody = () => {
    return bodyEditor.getData();
  }

  const valuesAreUnchanged = () => {
    return fetchTitle() === originalTitleValue && fetchBody() === originalBodyValue;
  }

  const buttonEnabler = () => {
    buttonElement.disabled = valuesAreUnchanged();
  }

  const disableAllSubmitters = () => {
    document.querySelectorAll('.recommendation-submission').forEach((ele) => {
      ele.disabled = true;
    });
  }

  const formElement = event.target;
  const bodyElement = formElement.querySelector("[name='solicitations_sections_recommendation[solicitations_section][body]'");
  const buttonElement = document.getElementById(formElement.id.replace("edit", "submit"));
  const titleElement = formElement.querySelector("[name='solicitations_sections_recommendation[solicitations_section][title]'");
  let bodyEditor; // these need to be set after there has been time to initialize the editors
  let titleEditor;
  let originalBodyValue;
  let originalTitleValue;

  formElement.addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
      // disable submitting the form if enter is pressed
      event.preventDefault();

      // its ok to update the button though...
      buttonEnabler();
    }
  })

  // wait for the editor to be initialized then add a listener for changes
  bodyElement.addEventListener('ckeditor:initialized', (event) => {
    bodyEditor = event.detail.editor;
    originalBodyValue = fetchBody();
    bodyEditor.model.document.on('change:data', () => {
      buttonEnabler();
    });
  })

  titleElement.addEventListener('ckeditor:initialized', (event) => {
    titleEditor = event.detail.editor;
    originalTitleValue = fetchTitle();
    titleEditor.model.document.on('change:data', () => {
      buttonEnabler();
    });
  })

  // check for a diff if the title changes
  titleElement.addEventListener('change', (event) => {
    event.preventDefault();
    buttonEnabler();
  });

  // disable submission buttons to avoid double submitting
  // it needs a slight delay or else it can interrupt the form submission
  document.querySelectorAll('.recommendation-submitter').forEach( (ele) => {
    ele.addEventListener('click', (event) => {
      event.target.innerHTML = "Saving...";
      setTimeout( disableAllSubmitters, 100)
    });
  })

  $('#accept-all-submitter').on('confirm:complete', (e) => {
    if (e.detail[0]) {
      disableAllSubmitters();
    }
  })
})

$(document).on('recommendation-dismissal-modal:attached', (event) => {
  const textarea = event.target.querySelector("[name='solicitations_sections_recommendation[reason]'");
  const dismissButton = document.getElementById('dismiss-recommendation-button');
  textarea.addEventListener('keyup', () => {
    dismissButton.disabled = (textarea.value === '')
  })
});
