// If you're using one of our integration packages, like `@sentry/react` or
// `@sentry/angular`, substitute its name for `@sentry/browser` here
import * as Sentry from "@sentry/browser";

// If taking advantage of automatic instrumentation (highly recommended)
import { Integrations as TracingIntegrations } from "@sentry/tracing";
// Or, if only manually tracing
// import * as _ from "@sentry/tracing"
// Note: You MUST import the package in some way for tracing to work

document.addEventListener('turbolinks:load', () => {
  const { sentryDsn, sentrySampleRate, sentryEnvironment, sentryUserId, sentryUserEmail } = document.body.dataset;

  Sentry.init({
    dsn: sentryDsn,

    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    integrations: [new TracingIntegrations.BrowserTracing()],

    // To set a uniform sample rate
    tracesSampleRate: sentrySampleRate,

    environment: sentryEnvironment
  });

  if (sentryUserId && sentryUserEmail) {
    Sentry.setUser({
      id: sentryUserId,
      email: sentryUserEmail
    });
  } else {
    Sentry.setUser(null);
  }
});
