import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

export default class extends Controller {
    static get outlets() {
        return ['ckeditor--multiroot'];
    }

    static get targets() {
        return ['column'];
    }

    connect() {
        this.disableSubmit();
        this.columnHeight = 20;
        this.marginalPlacement = this.element.dataset['marginalPlacement'];
        this.marginalInput = document.getElementById(`${this.marginalPlacement}-height`);
        this.debounced = debounce(() => this.setColumnHeights(), 500)
        this.form = this.element.closest('form');
        this.disableSubmit();
    }

    disableSubmit() {
        if (this.form) {
            const button = this.form.querySelector('input[type="submit"]')
            if (!button) return;

            button.disabled_by = button.disabled_by || [];
            button.disabled_by.push(this);
            button.disabled = true;
        }
    }

    enableSubmit() {
        if (this.form) {
            const button = this.form.querySelector('input[type="submit"]')
            if (!button) return;

            button.disabled_by = button.disabled_by || [];
            button.disabled_by = button.disabled_by.filter( (disabled_by) => disabled_by !== this )
            if (button.disabled_by.length === 0) {
                button.disabled = false;
            }
        }
    }

    ckeditorMultirootOutletConnected(outlet) {
        outlet.element.addEventListener('ckeditor--multiroot:ready', () => {
            this.setColumnHeights();
        })

        outlet.element.addEventListener('ckeditor--multiroot:change', () => {
            this.debounced();
        })
    }

    setColumnHeights() {
        this.columnHeight = 50;
        this.columnTargets.forEach( column => {
            this.findHeightWithoutOverflow(column);
        });

        this.columnTargets.forEach( column => {
            this.setColumnHeight(column);
        });

        if (this.marginalInput) {
            this.marginalInput.value = this.columnHeight;
        }

        this.enableSubmit();
    }

    setColumnHeight(col) {
        col.style.height = `${this.columnHeight}px`
    }

    findHeightWithoutOverflow(col) {
        this.setColumnHeight(col);
        if (col.scrollHeight > col.clientHeight) {
            this.columnHeight += 10;
            this.findHeightWithoutOverflow(col)
        }
    }
}
