const localStorage = window.localStorage;

let copiedCriteria;

$(document).on('change', '#evaluation_form_form_id', function() {
  var formId = $(this).val();
  var template = $(`.evaluation-form-template[data-form-id="${formId}"]`)

  $('.empty-templates').removeClass('d-none');
  $('.evaluation-form-template').addClass('d-none');

  if (template.length) {
    $('.empty-templates').addClass('d-none');
    template.removeClass('d-none');
  }
})

$(document).on('click', '.copy-criteria', function(event) {
  event.preventDefault();
  copiedCriteria = $(this).attr('href');
  localStorage.setItem('copied_criteria', copiedCriteria);
})

$(document).on('click', '.paste-criteria', function() {
  $(this).css('pointer-events', 'none');
  copiedCriteria = localStorage.getItem('copied_criteria');
  const path = `${$(this).attr('href')}&criteria_id=${copiedCriteria ? copiedCriteria : ''}`;

  $.ajax({url: path, type: 'POST', success: function() {
    location.reload();
  }, error: function(data) {
    alert("Sorry, we encountered an error. It will be logged in the server console or exception tracker.")
    console.log(data.responseText);
  }});

  return false;
})
