import { Controller } from "@hotwired/stimulus";

/**
 * A stimulus component for fetching the content for a dynamic modal and attaching it to the DOM.
 * @identifier - 'modal--fetch'
 *
 * To aid in rendering of a `modal--fetch` element, see the ViewComponent Modal::Trigger
 */
export default class extends Controller {
  /**
   * @src {string} required! - the url that will return the modal to be display
   */
  static get values() {
    return {
      src: String
    }
  }

  connect() {
    this.element.addEventListener('click', () => this.fetch())
  }

  /**
   * Fetches the content of the modal
   * @note - The response should either be a turbo stream or an html response with only the modal - no layout!
   */
  fetch() {
    if (!this.hasSrcValue) {
      console.warn(`${this.identifier} is being used to fetch a modal but does not have a modal src. Please add 'modal--fetch-src-value' to the element.`)
    } else {
      // grab the content
      fetch(this.srcValue).then(
        resp => resp.text()
      ).then( html => {
        // determine which handler to use based on if this is a turbo or html response
        html.trim().startsWith('<turbo-stream') ? this._handleTurbo(html) : this._handleHTML(html)
      })
    }
  }

  /**
   * Converts the html response into a dom element and appends it to the target
   * @param {string} html An html response containing the modal
   */
  _handleHTML(html) {
    const ele = new DOMParser().parseFromString(html, 'text/html').body.childNodes[0]
    this.modalTarget.appendChild(ele);
  }

  /**
   * Delegates the rendering of the modal to Turbo
   * @note The turbo response here will be handled like every other turbo response. Meaning the targeted element is based on the turbo response
   *        and there can be multiple actions that happen based on the response as well.
   * @param {string} html An html response containing a turbo-stream
   */
  _handleTurbo(html) {
    Turbo.renderStreamMessage(html);
  }

  /**
   * Fetches the modal target parent element - if none is provided it falls back to the document.body
   * @return {HTMLElement}
   */
  get modalTarget() {
    return (
      this.element.dataset.modalParent ?
        document.querySelector(this.element.dataset.modalParent) :
        document.body
    );
  }
}