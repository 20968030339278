import BaseEditorController from './base_editor_controller';
import { createCollaborativeEditor } from '../../account/ckeditor/v1.2/helpers/initializers';

export default class extends BaseEditorController {
  static get targets() {
    return ['editor'];
  }

  editorTargetConnected(element) {
    this.loadCkeditor(element)
  }

  loadCkeditor(element) {
    const mentions = this.loadFeeds();

    createCollaborativeEditor(element, mentions.userFeeds, mentions.feeds, mentions.destroyableRefs)
  }
}
