import { refreshPageBase, updatePageBase } from './page_refresh'

$(document).on('confirm:complete', '.btn-link[data-remote="true"]', function(event) {
  if (event.detail[0]) {
    event.stopPropagation();
    event.preventDefault();

    var $link = $(event.currentTarget);
    var path = $link.attr('href');
    var type = $link.attr('data-method') || 'GET';
    var baseSelector = $link.attr('data-target') || '.modal-base'

    $.ajax({ url: path, type: type, success: function(data, textStatus, request) {
      refreshPageBase(baseSelector);
    }});
  }
});

$(document).on('ajax:success', 'form.remote-update', function(event) {
  const [data, _status, _xhr] = event.detail;

  var $form = $(event.currentTarget);
  var baseSelector = $form.attr('data-target') || '.modal-base';

  var $newBase = $(data).find(baseSelector);
  if ($newBase.length) {
    updatePageBase(baseSelector, data);
  } else {
    refreshPageBase(baseSelector);
  }
});
