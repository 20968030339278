import consumer from "../channels/consumer"
document.addEventListener("turbolinks:load", function () {

  window.submitProjectStatusForm = () => {
    var form = $("#edit_project");
    $.ajax({
      type: "PATCH",
      url: form[0].action,
      data: form.serialize(), // serializes form input
    });
  }
});

$(document).on("turbolinks:load", function () {
  var showPublications = $("[href='#publications-table']");
  if (showPublications.length) {
    showPublications.on("click", function (e) {
      e.preventDefault();
      var currentLocation = window.location.href;
      if (currentLocation.indexOf("#") > -1) {
        currentLocation = currentLocation.substr(0, currentLocation.indexOf("#"));
      }
      const newLocation = currentLocation + "#publications-table";
      history.replaceState({}, "", newLocation);
      var publications = $("#publications-table");
      $("html, body").animate({
        scrollTop: publications.offset().top
      }, 1000);
    });
  }
});

$(document).on('click', ".show-questions-link", function (event) {
  event.preventDefault();
  $(".questions-to-be-completed").slideToggle();
  var $i = $(this).find("i");
  if ($i.hasClass("icon-arrow-down")) {
    $i.removeClass("icon-arrow-down");
    $i.addClass("icon-arrow-up");
  } else {
    $i.removeClass("icon-arrow-up");
    $i.addClass("icon-arrow-down");
  }
  $i.trigger('blur');
});

$(document).on('click', ".show-sections-link", function (event) {
  event.preventDefault();
  var element = $(this)
  $(".sections-to-be-completed").slideToggle();
  var $i = $(this).find("i");
  if ($i.hasClass("icon-arrow-down")) {
    $i.removeClass("icon-arrow-down");
    $i.addClass("icon-arrow-up");
  } else {
    $i.removeClass("icon-arrow-up");
    $i.addClass("icon-arrow-down");
  }
  $i.trigger('blur');
});

$(document).on("click", ".questions-links .view-all", function (event) {
  event.preventDefault();
  $('.floated-to-do-w').toggleClass('active');
  var div = $("[data-class='Solicitations::QuestionResponse']");
  if (div.length > 0) {
    $("html, body").animate({
      scrollTop: div.offset().top
    }, 1000);
  } else {
    console.log(event, event.target);
    var href = event.target.href;
    window.location.href = href;
  }
});

$(document).on('turbolinks:load', function (event) {
  event.preventDefault();
  $('.floated-to-do-btn, .to-do-close').on('click', function () {
    $('.floated-to-do-w').toggleClass('active');
    return false;
  });
});

var buildingProgressInterval;

$(document).on("turbolinks:load", function () {
  $('#resolve-comments-confirm-input').on('keyup', function (event) {
    if (event.key === 'Enter' && event.target.value.toLowerCase() === 'resolve') {
      event.preventDefault();
      document.getElementById('resolve-comments-button').click()
    }
  })

  $('#accept-changes-confirm-input').on('keyup', function (event) {
    if (event.key === 'Enter' && event.target.value.toLowerCase() === 'accept') {
      event.preventDefault();
      document.getElementById('accept-changes-button').click()
    }
  })
});

// To require user to type "Accept" to accept the paper_trail changes
$(document).on("input", "#accept-changes-confirm-input", function (e) {
  let acceptButton = $('#accept-changes-button');
  if (e.target.value.toLowerCase() === 'accept') {
    acceptButton.removeClass('disabled');
  } else if (!acceptButton.hasClass('disabled')) {
    acceptButton.addClass('disabled');
  }
});

// To require user to type "Resolve" to accept the paper_trail changes
$(document).on("input", "#resolve-comments-confirm-input", function (e) {
  let acceptButton = $('#resolve-comments-button');
  if (e.target.value.toLowerCase() === 'resolve') {
    acceptButton.removeClass('disabled');
  } else if (!acceptButton.hasClass('disabled')) {
    acceptButton.addClass('disabled');
  }
});

$(document).on("shown.bs.modal", "#resolve-comments-confirm-modal", function () {
  $("#resolve-comments-confirm-input").val('');
});

$(document).on("shown.bs.modal", "#accept-changes-confirm-modal", function () {
  $("#accept-changes-confirm-input").val('');
});

// Not using the following two blocks for now but may need it later on when we implement accept/discard CKE suggestions
// $(document).on("input", "#accept-suggestion-confirm-input", function(e){
//   let acceptButton = $('#accept-suggestion-button');
//   if (e.target.value === 'ACCEPT') {
//     acceptButton.removeClass('disabled');
//   } else if (!acceptButton.hasClass('disabled')) {
//     acceptButton.addClass('disabled');
//   }
// });
// $(document).on("input", "#discard-suggestion-confirm-input", function(e){
//   let acceptButton = $('#discard-suggestion-button');
//   if (e.target.value === 'DISCARD') {
//     acceptButton.removeClass('disabled');
//   } else if (!acceptButton.hasClass('disabled')) {
//     acceptButton.addClass('disabled');
//   }
// });

/* Deprecated - use build_controller#progressBuildingStep */
function progressBuildingStep(showQuestions = false) {
  var $element = $("li.in-progress");
  var $next = $element.next("li");
  if ($next.length == 0) {
    $next = $(".features-list.part-two li:first");
  }
  $element.removeClass("in-progress").addClass("done");
  if (!$next.hasClass("done")) {
    $next.addClass("in-progress");
  }
  if (parseInt(showQuestions)) {
    if ($("li.done").length < parseInt(showQuestions)) {
      progressBuildingStep(showQuestions);
    }
  }
  if ($("li.in-progress").length == 0) {
    if (showQuestions) {
      $(".finished-questions").slideDown();
    } else {
      $(".finished").slideDown();
    }
  }
}
