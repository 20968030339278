import { DatatablesBase } from "./base";

export default class extends DatatablesBase {
  static get orderConfig() {
    return [[3, 'desc']];
  }

  static get languageConfig() {
    return {
      "sLengthMenu": "Showing_MENU_",
      "info": "of _TOTAL_ Queued Jobs",
      "oPaginate": {
        "sFirst": "",
        "sLast": ""
      }
    };
  }

  static get searchConfig() {
    return { searching: false }
  }

  ajaxConfig() {
    return {
      ajax: {
        url: this.table.dataset.source,
        method: 'GET',
        data: (d) => {
          const formObject = $('#queued_jobs-datatable-filter-form').serializeObject();

          for(let key in formObject) {
            if (formObject[key] === '') continue;

            d[key] = formObject[key];
          }
          return d;
        }
      }
    }
  }

  static get columnConfig() {
    return [
      {
        data: 'id',
        render: (datum, _, dataset) => {
          return `<a href="/developers/queued_jobs/${dataset.uuid}" target="_blank">${datum}</a>`
        }
      },
      {
        data: 'uuid',
        visible: false
      },
      {
        data: 'type'
      },
      {
        data: 'run_at'
      },
      {
        data: 'integer_0'
      },
      {
        data: 'integer_1'
      },
      {
        data: 'string_0',
        render: (datum) => {
          datum = datum || '';
          const truncationLength = 40;
          if (datum.length > truncationLength) {
            return datum.slice(0, truncationLength) + '...';
          } else {
            return datum;
          }
        }
      },
      {
        data: 'string_1',
        render: (datum) => {
          datum = datum || '';
          const truncationLength = 40;
          if (datum.length > truncationLength) {
            return datum.slice(0, truncationLength) + '...';
          } else {
            return datum;
          }
        }
      },
      {
        data: 'status'
      },
      {
        data: 'error_message',
        render: (datum) => {
          datum = datum || '';
          const truncationLength = 20;
          if (datum.length > truncationLength) {
            return datum.slice(0, truncationLength) + '...';
          } else {
            return datum;
          }
        }
      }
    ]
  }

}
