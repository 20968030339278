import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.load();
  }

  load() {
    $(this.element).tooltip();
  }
}
