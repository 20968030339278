import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="responsive-form"
export default class extends Controller {
  initialize() {
    this.callback = null;
  }

  connect() {
  }
}
