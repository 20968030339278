import { Controller } from "@hotwired/stimulus";
/*
  Documentation for notificaitons can be found at https://github.com/CityInnovateInc/engineering-wiki/wiki/Notifications
*/
export default class extends Controller {
  static get targets() {
    return ['element'];
  }

  connect() {
    this.notificationContainer = document.getElementById('notification-container');
  }

  elementTargetConnected(element) {
    if (this.isDisabled(element) || this.isCurrentlyDisplayed(element.innerHTML)) {
      element.remove();
    } else {
      this.notificationContainer.appendChild(element);
    }
  }

  isDisabled(element) {
    const disableValues = element.parentElement.dataset.disableValues || '';
    return disableValues.split(' ').includes(element.dataset.disabledKey);
  }

  isCurrentlyDisplayed(html) {
    const children = this.notificationContainer.children;
    for (const child of children) {
      if (child.innerHTML === html) {
        return true;
      }
    }

    return false;
  }
}