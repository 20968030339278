$(document).on('turbolinks:load', function () {
  if ($("#document-categories-table").length) {
    $('#document-categories-table').DataTable({
      searching: true,
      serverSide: true,
      aLengthMenu: [
        [10, 25, 50, 100, 200],
        [10, 25, 50, 100, 200]
      ],
      iDisplayLength: 10,
      language: {
        "sLengthMenu": "Showing_MENU_",
        "info": "of _TOTAL_ Document Categories",
        "oPaginate": {
          "sFirst": "",
          "sLast": ""
        }
      },
      ajax: {
        url: $("#document-categories-table").data('source'),
      },
      pagingType: "full_numbers",
      "dom": "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
      columns: [
        { data: "name" },
        {
          data: "alphanumeric_index",
          className: "text-center",
          render: function (data) {
            if (data == "true") {
              return '<div class = "status-pill green" aria-label="Alphanumeric index"></div>'
            }
            else {
              return '<div class = "status-pill red" aria-label="Not alphanumeric index"></div>'
            }
          }

        },
        {
          data: "primary_document",
          className: "text-center"
        },
        {
          data: "solicitation_part",
          className: "text-center"
        },
        {
          data: "subfolder",
          className: "text-center"
        },
        {
          data: "prefix_document_titles",
          className: "text-center"
        },
        {
          data: "document_title_prefix",
          className: "text-center"
        },
        { data: 'updated_at' },
        {
          data: "dt_actions",
          className: "icon-wrap",
          orderable: false
        },
      ]
    });
  }
})
