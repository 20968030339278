import { Controller } from "@hotwired/stimulus";
require("bootstrap-daterangepicker/daterangepicker.css");
import moment from "moment";
import "bootstrap-daterangepicker";

export default class extends Controller {
  static get targets() {
    return ['field'];
  }

  static get values() {
    return {
      type: {
        type: String,
        default: 'single-daterange',
      },
      defaultDateTime: String }
  }

  connect() {
    this.element[this.identifier] = this;
  }

  fieldTargetConnected() {
    $(this.fieldTarget).daterangepicker(this.datePickerOptions);

    this.setupAriaAccessibilityForTimeSelect();
    this.setupApplyDate();
    this.setupCancelDate();
    $(this.fieldTarget).attr('aria-label', 'Date Range Input')
  }

  get datePickerOptions() {
    switch(this.typeValue) {
      case 'single-daterange':
        this.inputFormat = 'MM/DD/YYYY';
        return this.singleDateOptions;
      case 'single-daterange-with-time':
        this.inputFormat = 'MM/DD/YYYY hh:mm A';
        return this.singleDateTimeOptions;
      case 'multi-daterange':
        this.inputFormat = 'MM/DD/YYYY';
        return this.multiDateOptions;
    }
  }

  get singleDateOptions() {
    return {
      singleDatePicker: true,
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Clear'
      }
    }
  }

  get singleDateTimeOptions() {
    let result = {
      singleDatePicker: true,
      timePicker: true,
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Clear',
        format: this.inputFormat
      }
    }

    if (this.hasDefaultDateTimeValue) {
      result['startDate'] = this.defaultDateTime;
    }

    return result;
  }

  get multiDateOptions() {
    return {
      singleDatePicker: false,
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Clear'
      },
      opens: "center",
      drops: "auto",
      ranges: {
       'Today': [moment(), moment()],
       'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
       'Last 7 Days': [moment().subtract(6, 'days'), moment()],
       'Last 30 Days': [moment().subtract(29, 'days'), moment()],
       'This Month': [moment().startOf('month'), moment().endOf('month')],
       'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }
  }

  clear(event) {
    event.preventDefault();
    $(this.fieldTarget).val(null)
    const changeEvent = new CustomEvent('change');
    this.fieldTarget.dispatchEvent(changeEvent);
  }

  setupApplyDate() {
    const instance = this;
    $(this.fieldTarget).on('apply.daterangepicker', function (_, picker) {
      if (instance.typeValue === 'multi-daterange') {
        $(this).val(picker.startDate.format(instance.inputFormat) + ' - ' + picker.endDate.format(instance.inputFormat));
      } else {
        $(this).val(picker.startDate.format(instance.inputFormat));
      }

      const changeEvent = new CustomEvent('change');
      instance.fieldTarget.dispatchEvent(changeEvent);
    });
  }

  setupCancelDate() {
    const instance = this;
    $(this.fieldTarget).on('cancel.daterangepicker', function(e) {
      instance.clear(e);
    });
  }

  setupAriaAccessibilityForTimeSelect() {
    const instance = this;
    if($('.calendar-time').length > 0) {
      $(this.fieldTarget).on('showCalendar.daterangepicker', (e) => {
        /* this plugin seems to overwrite the html for the selects upon selecting an option in the time select, so the gross hack to sleep will apply accessibility without the newly applieda attrs getting overwritten */
        setTimeout( () => { instance.ariaAccessibilityForTimeSelect(e)}, 1)
      });
    }
  }

  ariaAccessibilityForTimeSelect(e) {
    const selectsWithLabelValue = {'hourselect': 'Select the hour','minuteselect': 'Select the minute','ampmselect': 'Select AM or PM'};
    for (let k in selectsWithLabelValue) {
      const ariaAttribute = 'aria-label';
      const ariaValue = selectsWithLabelValue[k];
      document.querySelectorAll(`.calendar-time .${k}`).forEach((el, _i) => {
        el.setAttribute(ariaAttribute, ariaValue);
      })
    }
  }
}
