import ColorEditor from './components/ColorEditor'
import TagEditor from './components/TagEditor'
import TextFieldValueEditor from './components/TextFieldValueEditor'

const update = (url, updateParams) => {
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(updateParams)
  })
  .then((response) => {
    if (!response.ok) {
      alert('Could not update the record.')
      throw new Error(response.status);
    }
    return response;
  })
  .catch(error => console.log(error) );
}

const defaultOnCellValueChanged = (event) => {
  if (!event.data.id) throw Error('ID not found. Add a data-id tag to the table tr.')
  
  const { gridUpdatePath, gridFieldName, gridUpdateModel } = event.colDef;
  const pathWithId = gridUpdatePath.replace('/id', `/${event.data.id}`);
  
  update(pathWithId, { [gridUpdateModel]: { [gridFieldName]: event.newValue } })
  .then((response) => {
    console.log("updated the record") 
  })
}

const defaultFieldType = {
  cellRenderer: (params) => {
    return params.value;
  },
  onCellValueChanged: null,
}

const FieldTypes = {
  default: defaultFieldType,
  color_picker_value: {
    editor: ColorEditor,
    cellRenderer: (params) => {
      return `<div class="status-pill" style="margin-top: -2px; background: ${params.value || '#F1F1F1'}"></div>`
    },
    onCellValueChanged: defaultOnCellValueChanged
  },
  text_field_value: {
    editor: TextFieldValueEditor,
    cellRenderer: (params) => {
      return params.value;
    },
    onCellValueChanged: defaultOnCellValueChanged,
  },
  tags: {
    editor: TagEditor,
    cellRenderer: (params) => {
      if (!params.value) return;

      if (params.value.length > 0) {
        // get the html from the original table & parse out the data (id, text, color)
        let tagColumnData = TagEditor.HtmlToData(params.value)

        // once parsed, turn the data back into HTML using the tag html for the grid
        return tagColumnData.map((tag) => {
          return TagEditor.dataToHtml(tag)
        }).join('')
      } else {
          return params.value;
      }
    },
    onCellValueChanged: (event) => {
      let { tableUpdateModel, gridUpdateModel, gridUpdatePath } = event.colDef
      // tagId is being passed in from the GridFromTable's getRowData method
      const pathWithId = gridUpdatePath.replace('/id', `/${event.data.tagId}`); 
      const data = { 
        [tableUpdateModel]: { [gridUpdateModel]: TagEditor.HtmlToData(event.newValue).map((tag) => tag.id) }
      }
      update(pathWithId, data)
      .then((response) => {
        console.log("updated the record") 
      })
    }
  }
}

Object.assign(FieldTypes, {
  tags_read_only: {
    ...FieldTypes['tags'],
    editor: null
  }
})

export default FieldTypes;
