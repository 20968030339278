require("dragula/dist/dragula.min.css")

import consumer from "../../channels/consumer"
import enablePopover from '../fields/popover'

import dragula from 'dragula';
import Cookies from 'js.cookie';

function saveAssignments(element) {
  // The new version of this funciton only submits the moved element, its siblings and parent to the restructure worker.
  //  These are the only elements that have been "restructured".  Everything else is a renumbering and cache break which happens in the update_references method
  const closestEle = element.closest("[data-restructure]")
  const url = closestEle.dataset.restructure;
  const parentClass = closestEle.dataset.restructureParentClass;
  const restructurableParent = element.parentElement.closest("[data-restructuring-parent]");
  const parentElement = element.parentElement.closest("[data-model='Solicitations::Section']") || element.parentElement.closest("[data-model='Contracts::Section']") || element.parentElement.closest(`[data-model='${parentClass}']`);

  let childIds = [];
  restructurableParent.querySelectorAll(":scope > .dragula-item").forEach(function (element) {
    childIds.push(`${element.dataset["model"]}|${element.dataset["id"]}`);
  });

  // Set to 'root' for top level sections, othewise use the parent section id
  let parentId = 'root';
  if (parentElement !== null) {
    parentId = parentElement.dataset["id"];
  }

  let assignmentsById = {};
  assignmentsById[parentId] = childIds;

  $.post(url, { assignments_by_id: assignmentsById });
}

// This recursive function renumbers sections in the browser.  The only purpose of this is to create a more real-time
// user experience.  Rails will also renumber the sections and sprinkles will push out an update with the new numbering but
// it takes a few seconds.
function renumberChildrenOf(element, parentString) {
  function setSectionHeading(heading, currentNumber, parentHeading) {
    let node = heading.querySelector(".preview-section-heading > .heading-element").childNodes[2];
    node.nodeValue = node.nodeValue.replace(/((\d)+.)+/, `${parentHeading}${currentNumber}.`);
    // This next piece of code will only ever run on Document Template Preview
    // It ensures that the reference numbering for variants stays consistent with the parent section
    let variantHeadings = heading.querySelectorAll('.section-template-variations-container .preview-section-heading > .heading-element');
    variantHeadings.forEach(variantHeading => {
      variantHeading.textContent = variantHeading.textContent.replace(/((\d)+.)+/, `${parentHeading}${currentNumber}.`);
    });
  }
  function childHeadingsFor(element) {
    const children = element.querySelectorAll(":scope > .dragula-nested > .dragula-item");

    if (children.length) {
      // These will be nested sections
      return children;
    } else {
      // These will be the top-level sections
      return element.querySelectorAll(":scope > .ck-content > .dragula-nested > .dragula-item");
    }
  }
  function sectionIsNumbered(element) {
    return !element.querySelector(":scope > .sticky-container > .section-body-container > .preview-section-heading > .heading-element").classList.contains("not-numbered")
  }
  var n = 1;
  let children = childHeadingsFor(element);
  if (typeof (children) !== 'undefined' && children.length > 0) {
    children.forEach(function (heading, _i) {
      if (sectionIsNumbered(heading)) {
        setSectionHeading(heading, n, parentString);
        renumberChildrenOf(heading, `${parentString}${n}.`);
        n++;
      }
    });
  }
}

let drake;
// enableRestructurableQueued will ensure documents with many sections only calls enableRestructurable every 100ms
let enableRestructurableQueued = false;

function enableRestructurable($scope) {
  if (enableRestructurableQueued) { return; }

  enableRestructurableQueued = true;
  setTimeout(function () {
    enableRestructurableQueued = false;
    var selector = '[data-restructure]';
    var $restructurable = $scope.find(selector).addBack(selector);

    $restructurable.each(function (_, container) {

      let boxContent = container.querySelector(".element-box-content");

      var $container = $(container);
      var containers = $container.find('[data-restructuring-parent]').toArray();

      $container.trigger('sprinkles:collection:repopulated')

      // If a user is mid drag when they receive a sprinkles update that triggers a new enableRestructurable, this will gracefully cancel their current drag
      if (drake !== undefined) {
        drake.destroy();
      }

      drake = dragula(containers, {
        moves: function (el, container, handle) {
          if ($(handle).hasClass('undraggable') || ($(handle).closest('.undraggable').length > 0)) {
            return false;
          }
          else {
            return handle.classList.contains('handle');
          }
        },
        accepts: function (el, target, source, sibling) {
          if (el.contains(target)) {
            return false;
          } else if ($(sibling).hasClass('undraggable') && $(sibling).prev().hasClass('undraggable')) {
            return false;
          } else {
            return true;
          }
        },
        // If a section is dropped outside of a target, we should cancel the drag
        revertOnSpill: true,
      }).on('drag', function (el) {
        // No need to do anything here yet.  Leaving this block as a placeholder
      }).on('drop', function (el) {
        renumberChildrenOf(boxContent, "");
        saveAssignments(el);
      }).on('cancel', function (el) {
        // No need to do anything here yet. Leaving this block as a placeholder
      }).on('over', function (el, container) {
        $(document.activeElement).blur();
      });

    });
  }, 100);

  setTimeout(function () {
    enablePopover();
  }, 200)
}

$(document).on('turboframe:load', function () {
  enableRestructurable($('body'));
})

$(document).on('turbolinks:load', function () {
  // add an event handler to each of the turbo-frames to check when the ajax request is finished
  // then reinstantiate the drag-and-drop elements
  const turboFrames = $('turbo-frame.loading');
  const event = new CustomEvent('turboframe:load');
  turboFrames.each((_, frame) => {
    $(frame).on('turbo:before-fetch-response', () => {
      document.dispatchEvent(event);
    });
  });

  $('#document-container').on('section:removed', function (event) {
    const boxContent = event.target.querySelector(".element-box-content");
    renumberChildrenOf(boxContent, "");
  });

  enableRestructurable($('body'));
})

$(document).on('sprinkles:update', function (event) {
  enableRestructurable($(event.target));
})

$(document).on('click', '#reorder-link', function (event) {
  event.preventDefault();
  $(".section-actions-container:visible").hide();
  $(".section-edit-btn:visible").hide();
  $('#document-container').find('.sticky-container').addClass('reordering');
  $('#document-container').removeClass('print-preview-mode').addClass('reorder-mode')
  $('.custom-form-field').removeClass('undraggable').addClass('reorder-mode')
})

$(document).on('click', '#print-preview-link', function (event) {
  event.preventDefault();
  $('.section-edit-btn:not(:visible)').show();
  $('#document-container').find('.sticky-container').removeClass('reordering');
  $('#document-container').removeClass('reorder-mode').addClass('print-preview-mode');
  $('.custom-form-field').addClass('undraggable')
})

$(document).on('mouseenter', '.print-preview .section-body', function (e) {
  showSectionActions(e);
});

$(document).on('mouseenter', '.print-preview .preview-section-heading', function (e) {
  showSectionActions(e);
});

$(document).on('mouseenter', '.print-preview .section-actions-button', function (e) {
  setTimeout(showSectionActions(e), 1000);
});

$(document).on('mouseleave', '.print-preview', function (e) {
  $(".section-actions-container:visible").hide();
});

let lastButtonShown = null, lastButtonTimeout = null, lastSectionContainer = null;

function showSectionActions(e) {
  if ($(e.target).closest(".reorder-mode").length > 0) {
    return;
  }
  const $sectionContainer = $(e.target).closest(".section-container")
  const $buttonContainer = $sectionContainer.find(".section-actions-container:first")

  if ($buttonContainer[0]) {
    var level = $buttonContainer[0].getAttribute('data-level') - 1;
    $buttonContainer[0].style.marginRight = -78 + (level * -22) + "px"

    $(".section-actions-container:visible").each(function () {
      if (this != $buttonContainer[0]) {
        $(this).hide();
      }
    });
    if (lastButtonShown == $buttonContainer) {
      clearTimeout(lastButtonTimeout);
      return;
    }
    if (!$buttonContainer.is(":visible")) {
      $buttonContainer.show();
      lastButtonShown = $buttonContainer;
      lastSectionContainer = $sectionContainer;
    }
  }
}

$(document).on('click', '.download-document-link', function (event) {
  $(this).addClass('disabled');
  $(this).html('<div style="margin-top: -8px;"><div class="square-icon"><i class="ti-reload rotating" style="font-size: 10pt; vertical-align: -2px;"></i></div><span>Preparing Document...</span></div>');
  window.downloadTimer = setInterval(function () {
    restoreDownloadButton();
  }, 500)
});

$(document).on("click", ".open-modally", function () {
  if ($(".floated-to-do-w").hasClass('active')) {
    $('.floated-to-do-w').toggleClass('active');
  }
});

function restoreDownloadButton() {
  if (Cookies.get('download_complete') == true) {
    Cookies.remove('download_complete');
    clearInterval(window.downloadTimer);
    $(".download-document-link").html('<div class="square-icon"><i class="ti-download" style="font-size: 10pt; vertical-align: -2px;"></i></div><span>Download Draft PDF</span>');
    $(".download-document-link").removeClass('disabled');
  }
}
