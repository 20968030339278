function sendBulkArchiveUnarchiveRequest(api_url){
  var checkedOrganization = [];
  $.each($('.organizations_id:checked'), function(){
    checkedOrganization.push($(this).val());
  });

  $.ajax({
    url: api_url,
    type: "POST",
    data: {
      team: {organization_ids: checkedOrganization }
    }
  });
}

function enableSelectedOrganization(){
    if($('.organizations_id:checked').length > 0){
      $('.selected_organizations').html($('.organizations_id:checked').length)
      $('.js--on-select-show').show()
      $('.js--on-select-hide').hide()
    } else{
      $('.js--on-select-show').hide()
      $('.js--on-select-hide').show()
    }
}

$(document).on('turbolinks:load', function(){
  if ($('#organizations-datatable').length) {
    const is_admin = $('#is_admin').val() == 'true';
    window.table = $('#organizations-datatable').DataTable({
      retrieve: true,
      processing: true,
      serverSide: true,
      stateSave: true,
      aLengthMenu: [
        [10, 25, 50, 100, 200],
        [10, 25, 50, 100, 200]
      ],
      iDisplayLength: 10,
      language: {
        "sLengthMenu": "Showing_MENU_",
        "info": "of _TOTAL_ Organizations",
        "oPaginate": {
          "sFirst": "",
          "sLast": ""
        }
      },
      "dom": "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-task-info'li>p>",
      pagingType: "full_numbers",
      'aoColumnDefs': [{
           'bSortable': false,
           'aTargets': [0, 2, 3]
		  }],
      ajax: {
        url: $('#organizations-datatable').data('source')
      },
      columnDefs: [
        {
          targets: [0],
          createdCell: function(td){
            $(td).addClass('task_select');
            window.table.columns(0).visible(is_admin);
          }
        },
        {
          targets: [1],
          createdCell: function(td){
            $(td).addClass('table-links-cell');
          }
        },
        {
          targets: [2],
          createdCell: function(td, cellData, rowData, row, col){
            $(td).closest('tr').attr('data-id', rowData['DT_RowId']);
            $(td).addClass('project-users profile-photos');
          }
        },
        {
          targets: [3],
          createdCell: function(td, cellData, rowData, row, col){
            $(td).addClass('table-action');
          }
        }
      ],
      columns: [
        {data: "id", "orderable": false},
        {data: "team_name"},
        {data: "assigned_to", "orderable": false},
        {data: "dt_actions", "orderable": false},
      ],
      drawCallback: function(settings) {
        $('#team_ids').click(function () {
          if ($(this).prop('checked')){
            $('.organizations_id').prop('checked', true);
          } else {
            $('.organizations_id').prop('checked', false);
          }
          enableSelectedOrganization();
        });

        if ($('.dataTables_empty')) {
          $('.dataTables_empty').attr('role', 'alert');
        }

        $(document).ready(function() {
          $('.custom-tooltip').tooltip({
            template: '<div class="tooltip custom-tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
          });
        });

        if($('#organizations-datatable_processing')) {
          $('#organizations-datatable_processing').attr('role', 'alert');
        }
      }
    });
  }

  $(document).on('change', '.organizations_id', function () {
    enableSelectedOrganization();
  });

  $('#archive_organization_button').on('click', function(){
    sendBulkArchiveUnarchiveRequest('/account/teams/bulk_archive')
  });

  $('#unarchive_organization_button').on('click', function(){
    sendBulkArchiveUnarchiveRequest('/account/teams/bulk_restore')
  });
})
