import { Controller } from "@hotwired/stimulus";
import { upperFirst } from 'lodash';

export default class extends Controller {
  static get targets() {
    return [
      'editMarginalButton',
      'editMarginalTurboForm',
      'selector',
      'columnSelect',
      'marginalColumnHeader',
      'marginalColumnFooter',
    ];
  }

  initialize() {
    this.loadingEditable = false;
  }

  connect() {
    this.element[this.identifier] = this;
  }

  selectorTargetConnected(ele) {
    this.handleEditButtonVisibility();
    $(ele).on('change', () => {
      this.handleEditButtonVisibility();
      if (this.loadingEditable) return;

      this.fetchMarginals()
    });
  }

  columnSelectTargetConnected(select) {
    const marginalPlacement = select.dataset['multiMarginalLayout'];
    this.setColumnLayout(select, marginalPlacement);
    const component = this;
    $(select).on('change', (evt) => {
      component.onColumnLayoutSelect(evt, marginalPlacement);
    })
  }

  columnSelectTargetDisconnected(ele) {
    $(ele).off('change') // TODO is this needed?
  }

  setColumnLayout(select, marginalPlacement) {
    $(select).val(this.getCurrentColumnLayout(marginalPlacement)); // this sets the "selected" value, but select2 needs to also trigger "change"
    $(select).trigger('change'); // allows select2 "selected" value to actually reflect the update
  }

  getCurrentColumnLayout(marginalPlacement) {
    const leftCol = this[`marginalColumn${upperFirst(marginalPlacement)}Targets`][0];
    if (leftCol.classList.contains('col-12')) {
      return "1";
    } else if (leftCol.classList.contains('col-6')) {
      return "2";
    } else {
      return "3";
    }
  }

  onColumnLayoutSelect(evt, marginalPlacement) {
    const columns = this[`marginalColumn${upperFirst(marginalPlacement)}Targets`];
    const leftCol = columns[0];
    const centerCol = columns[1];
    const rightCol = columns[2];
    switch (evt.target.value) {
      case "1":
        const col1RemoveClassList = 'd-none col-4 col-6'
        $(leftCol).addClass('col-12').removeClass(col1RemoveClassList);
        $(centerCol).addClass('d-none');
        $(rightCol).addClass('d-none');
        break;
      case "2":
        const col2RemoveClassList = 'd-none col-4 col-12';
        $(leftCol).addClass('col-6').removeClass(col2RemoveClassList);
        $(rightCol).addClass('col-6').removeClass(col2RemoveClassList);
        $(centerCol).addClass('d-none');
        break;
      case "3":
        const col3RemoveClassList = 'd-none col-6 col-12';
        $(leftCol).addClass('col-4').removeClass(col3RemoveClassList);
        $(rightCol).addClass('col-4').removeClass(col3RemoveClassList);
        $(centerCol).addClass('col-4').removeClass(col3RemoveClassList);
        break;
    }
  }

  handleTurboResponse() {
    this.editMarginalButtonTarget.disabled = false;
    if (this.loadingEditable) {
      // reset the selector value back to empty - this will have the effect of hiding this button as well
      $(this.selectorTarget).val(null).trigger('change');
      this.loadingEditable = false;
    }
  }

  editMarginalButtonTargetConnected(ele) {
    // when the network request finishes re-enable the button
    document.addEventListener('turbo:marginal-multiroot-response', () => {
      this.handleTurboResponse();
    })

    // when the edit button is clicked, click the button inside the turbo form
    ele.addEventListener('click', () => {
      // this.loadEditable is used as a bit of state management to track that an editor is being loaded
      // when the user clicks the 'Edit' button. Attempting to set the selector back to null witin this block
      // was resulting in a race condition where the form values were cleared before the request fired.
      // By utilizing loadingEditable state, the selector value can be nulled after the form is submitted - resulting
      // in better consistency.
      this.loadingEditable = true;

      // if there is a readonly input element, it needs to be removed since the user should be able to edit the content
      this.removeReadonlyInput();

      // click the button
      this.editMarginalTurboFormTarget.requestSubmit();

      // temporarily disable the button to ensure it doesn't get clicked multiple times
      ele.disabled = true;
    });
  }

  handleEditButtonVisibility() {
    const buttonDisplayStyle = this.selectorTarget.value ? 'block' : 'none';
    this.assignValueToForm(this.selectorTarget.value);
    this.editMarginalButtonTarget.style.display = buttonDisplayStyle;
  }

  fetchMarginals() {
    this.editMarginalTurboFormTarget.requestSubmit();
  }

  removeReadonlyInput() {
    const readonly = this.editMarginalTurboFormTarget.querySelector('#readonly_field');
    readonly && readonly.remove();
  }

  insertHiddenInput(value, name, id) {
    const existing = this.editMarginalTurboFormTarget.querySelector(`#${id}`);
    existing && existing.remove();
    const ele = document.createElement('input');
    ele.setAttribute('type', 'hidden');
    ele.setAttribute('value', value);
    ele.setAttribute('name', `[editable_marginal]${name}`);
    ele.setAttribute('id', id);
    this.editMarginalTurboFormTarget.appendChild(ele);
  }

  assignValueToForm(value) {
    if (!this.editMarginalTurboFormTarget) return;

    // this hidden input tells us which value the user has selected
    this.insertHiddenInput(value, 'editable_marginal_id', 'editable_marginal_id_field');

    // readonly input is needed so that when we fetch a preview of the template it comes back in readonly mode
    // but that means if the user selects custom then the editor should be initialized in the editable mode
    if (value) {
      this.insertHiddenInput('true', 'readonly', 'readonly_field')
    } else {
      this.removeReadonlyInput();
    }
  }
}