function enableAccessibilityCheck(){
  elements = document.querySelectorAll("[data-accessibility-category]")
  elements.forEach(element => {
    if($(`#document-container img[data-issue-id=${element.dataset.accessibilityIssueId}]`).length > 0) {
      console.log("Found a matching element...skipping adding this item again")
      return;
    }
    img = document.createElement("img");
    const issueCategory = element.dataset.accessibilityCategory
    const issueCategoryDescription = element.dataset.accessibilityCategoryDescription || element.dataset.accessibilityCategory
    img.setAttribute("src", `/images/${issueCategory}.png`)
    img.setAttribute("data-toggle", "popover")
    img.setAttribute("data-issue-id", element.dataset.accessibilityIssueId)
    img.setAttribute("title", `${issueCategoryDescription}: ${element.dataset.accessibilityName}`)
    img.classList.add("accessibility-warning")
    $(img).insertBefore(element)
    if($(img).closest('a').length > 0) {
      $(img).closest('a').on('click', function(e) {
        e.preventDefault()
      })
    }
    $(img).popover({
      "html": true,
      container: 'body',
      "content": function(){
        var div_id = "tmp-id-" + $.now()
        return details_in_popover(element.dataset.accessibilityLink, div_id)
      }
    })
    function details_in_popover(link, div_id) {
      $.ajax({
        url: link,
        success: function(response) {
          var contentDiv = $('#' + div_id)
          contentDiv.html(response)
          contentDiv.closest(".popover").popover('update')
          contentDiv.trigger("cache:refresh")
        }
      })
      return `<div id="${div_id}">Loading...</div>`
    }
  });

  $table = $(".table-scrollable.big")
  $box = $table.closest(".element-box")
  const differentInHeight = parseFloat($box.height()) - parseFloat($table.height())

  $table.css('max-height', $(window).height() - differentInHeight - 70)

  // When clicking on a section link with an anchor to the section within the document,
  // scroll to that error and open the popup with the issue description
  $(".accessibility-section-link").on('click', function(e) {
    e.preventDefault();
    issueId = this.dataset.issueId
    target = e.target.href.split("#");

    if( $(`[data-accessibility-issue-id=${issueId}]`).length ) {
      $('html, body').animate({
        scrollTop: $(`[data-accessibility-issue-id=${issueId}]`).parent().offset().top - ($(window).height() / 2)
      }, 100, function() {
        $(`[data-accessibility-issue-id=${issueId}]`).prev().popover("show")
      })
    }
  })

  // When a user hovers over an issue icon, add a border around the element that
  // is linked to the issue. (This is to make is easier for users to identify where the issue is located)
  $(document).on('mouseenter', 'img.accessibility-warning', function(e){
    $(".accessibility-hover").removeClass("accessibility-hover")
    $(e.target).parent().addClass("accessibility-hover")
    $(e.target).parent().on('mouseleave', function(e) {
      $(e.target).off('mouseleave')
      $(".accessibility-hover").removeClass("accessibility-hover")
    })
  })
}

document.addEventListener("turbolinks:load", function(){
  enableAccessibilityCheck($('body'))
})


$("html").on("mouseup", function(e){
  if(!$(e.target).closest('.popover').length) {
    $('.popover').each(function(){
      $(this).popover('hide');
    })
  }
})

$(document).on("sprinkles:update", function(event) {
  enableAccessibilityCheck($(event.target));
})
