var timeout;

$(document).on('turbolinks:load', function () {
  $(document).on('change', 'select.autosubmit', function (event) {
    submitForm(event);
  });

  $(document).on('change', 'select2.autosubmit', function (event) {
    submitForm(event);
  });

  $(document).on('keyup', 'input.autosubmit:text', function (event) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      submitForm(event);
    }, 500);
  });

  $("#copy_challenge_url").on("click", function() {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($("#challenge_url").text()).select();
    document.execCommand("copy");
    $temp.remove();
  });

  function submitForm(event) {
    clearTimeout(timeout); // Clear the previous timeout
    timeout = setTimeout(() => {
      const $element = $(event.target);
      const $form = $element.closest('form');
      const isRemote = $form.data('remote');
      if (isRemote) {
        Rails.fire($form[0], 'submit');
      } else {
        $form[0].submit();
      }
    }, 500);
  }

  const bulkAcceptanceForm = $('.bulk-acceptance-form')
  if (bulkAcceptanceForm) {
    bulkAcceptanceForm.on('submit', function(event) {
      event.preventDefault();
      const selectedEvaluators = $('.bulk-acceptance-values:checked');
      const selectedResponseIds = selectedEvaluators.map(function() {
        return $(this).val();
      }).get();

      const selectField = $('#bulk-acceptance-select');
      selectField.val(selectedResponseIds).trigger('change.select2');

      this.submit();
    });
  }

  var currentXhr;
  currentXhr = null;

  $(document).on('ajax:send', function(_event, xhr) {
    if (currentXhr) {
      currentXhr.abort();
    }
    currentXhr = xhr;
    return true;
  });
  $(document).on('ajax:complete', function(_event, _xhr, _status) {
    currentXhr = null;
  });

  $(document).on('click', '.copy-form-btn', function () {
    const url = $(this).attr('data-copy-url');
    navigator.clipboard.writeText(url);
  })
});
