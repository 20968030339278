const ColorPallete = [
 "#047bf8",
 "#6610f2",
 "#6f42c1",
 "#e83e8c",
 "#e65252",
 "#fd7e14",
 "#fbe4a0",
 "#24b314",
 "#20c997",
 "#5bc0de",
 "#636c72",
 "#343a40",
 "#292b2c",
 "#f8f9fa",
 "#3E4B5B",
 "#0356ad",
 "#adb5bd",
 "#334152",
 "#dee2e6",
 "#b9dafd",
 "#7cbafb",
 "#a0cdfc",
 "#c3c4c4",
 "#909191"
]

export default ColorPallete;
