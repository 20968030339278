import { ApplicationController } from "../application_controller"
import consumer from "channels/consumer";

export default class extends ApplicationController {
  static get targets() {
    return ['mentioning'];
  }

  initialize() {
    this.loadConnected = false;
    this.loadConnecting = false;
    this.loadingUUID = crypto.randomUUID();
    this.mentioningTargetsCache = {};
    this.loadSubscription = undefined;
    this.updateSubscription = consumer.subscriptions.create(
      {
        channel: 'Mentionings::UpdateChannel',
      },
      {
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this)
      }
    );
  }

  disconnect() {
    if (this.updateSubscription) {
      this.updateSubscription.consumer.subscriptions.remove(this.updateSubscription);
    }
    if (this.loadSubscription) {
      this.disconnectLoadChannel();
    }
  }

  _connected() {
    this.loadConnected = true;
    this.handleIds();
  }

  _disconnected() {
  }

  _received(data) {
    const parsed = JSON.parse(data)
    if (parsed.result === 'fetch') {
      this.handleFetch(parsed)
    } else if (parsed.result === 'not-found') {
      this.handleNotFound(parsed)
    }
  }

  connectLoading() {
    if (this.loadConnected) {
      this.handleLoading();
      return;
    }
    if (this.loadConnecting) return;

    this.loadConnecting = true
    this.loadSubscription = consumer.subscriptions.create(
      {
        channel: 'Mentionings::LoadChannel',
        uuid_scope: this.loadingUUID
      },
      {
        connected: this.handleLoading.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this)
      }
    );
  }

  handleLoading() {
    for (let id in this.mentioningTargetsCache) {
      let ele_array = [];
      ele_array.push(this.mentioningTargetsCache[id])
      if (ele_array.every(ele => ele.loaded)) continue;

      this.fetchId(id);
    }

    this.checkForMoreLoading()
  }

  checkForMoreLoading() {
    setTimeout( () => {
      if (this.allLoaded()) {
        this.disconnectLoadChannel()
      } else {
        this.handleLoading();
      }
    }, 250)
  }

  disconnectLoadChannel() {
    this.loadSubscription.consumer.subscriptions.remove(this.loadSubscription);
    this.loadSubscription = undefined
    this.loadConnected = false
    this.loadConnecting = false
  }

  allLoaded() {
    return Object.values(this.mentioningTargetsCache).reduce((agg, ele_array) => {
      return agg && ele_array.every(ele => ele.loaded)
    }, true)
  }


  handleNotFound(parsed) {
    const ele_array = this.mentioningTargetsCache[parsed.id];
    if (ele_array.length == 0) return;

    ele_array.forEach(ele => {
      ele.classList.add('bg-warning')
      ele.innerHTML
      ele.innerHTML = ele.innerHTML + ' (mention record not found)'
      ele.loaded = true
    });
  }

  handleFetch(parsed) {
    const ele_array = this.mentioningTargetsCache[parsed.id];
    if (ele_array.length == 0) return;

    ele_array.forEach(ele => {
      const replacementText = ele.dataset.label;
      ele.innerHTML = ele.innerHTML.replaceAll(replacementText, parsed.value);
      ele.dataset.label = parsed.value;
      ele.loaded = true
    })
  }

  mentioningTargetConnected(ele) {
    if (ele.dataset.model !== 'Mentioning') return;
    // Check if mentioningTargetsCache[ele.dataset.id] exists and is an array
    if (!Array.isArray(this.mentioningTargetsCache[ele.dataset.id])) {
      // If it's not an array, initialize it as an empty array
      this.mentioningTargetsCache[ele.dataset.id] = [];
    }

    // Push the element into mentioningTargetsCache[ele.dataset.id]
    this.mentioningTargetsCache[ele.dataset.id].push(ele);

    this.connectLoading();
  }

  fetchId(id) {
    this.loadSubscription.send({
      id: id,
      uuid_scope: this.loadingUUID
    })
  }
}
