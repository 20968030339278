import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this._failed_occured = false;
    this.element.addEventListener('ckeditor:collaborative-editor:initialized', (event) => {
      this.editor = event.detail.editor;
      this.handleEditorInitialized();
    })

    document.addEventListener('ckeditor:initialization-failure', (event) => this.handleEditorFailure(event))
  }

  /**
   * Handler to be used when the editor initialization failure - the event being captured here is triggered inside collaboration_editor_watchdog
   */
  handleEditorFailure(event) {
    this._failed_occured = true
    this.acceptedInput.remove();
    this.rejectedInput.remove();
    this.statusInput.value = 'generation_error';
    const error = event.detail.error;
    error._current_action = 'ckeditor_initialization'
    this.clientErrorInput.value = JSON.stringify(error, Object.getOwnPropertyNames(error));
    Rails.fire(this.form, 'submit');
  }

  /**
   * Generates the suggestion content by first -
   *    - accept all suggestions through editor and set the accepted input to the current data
   *    - undo to get back to original content
   *    - discard all suggestions through the editor and set the rejected input to the current data
   *    - undo to get back to original content
   *    - submit the form
   * @note - If an error is encountered at any point the status input is set to generation_error and the client_error value is set to the caught error object.
   * @note - This approach was taken instead of utilizing the TrackChangesData plugin for a couple reasons.
   *          1. There are sometimes instances where accept/discard has functioned through the UI but not through the plugin
   *          2. This is actually much faster than the plugin
   *          3. This also could give us fine grain control in the future over which data to gather and give error messaging around which attributes failed to generate.
   *
   * @returns {null}
   */
  async handleEditorInitialized() {
    if (!this.editor) return;
    try {
      // we occassionally seem to get crashes attempting to trigger actions on the editor. It's not clear if there
      // are maybe some async functions in the editor that aren't wrapping up before the next action is being called.
      // To avoid this, adding a small sleep seems to remedy the issue so far. Another solution here is to try and capture
      // specific or known issues, then in the suggesetion processor immediately retry if a known issue is encountered.
      await this.sleep(500);

      this._current_action = 'accepting_suggestions'
      this.acceptedInput.value = await this.editor.plugins.get('TrackChangesData').getDataWithAcceptedSuggestions();

      this._current_action = 'discarding_suggestions'
      this.rejectedInput.value = await this.editor.plugins.get('TrackChangesData').getDataWithDiscardedSuggestions();

    } catch (error) {
      error._current_action = this._current_action;
      this.acceptedInput.remove();
      this.rejectedInput.remove();
      this.statusInput.value = 'generation_error';
      this.clientErrorInput.value = JSON.stringify(error, Object.getOwnPropertyNames(error));
    } finally {
      Rails.fire(this.form, 'submit');
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  /**
   * Triggers the undo action in the editor
   */
  undo() {
    this.editor.execute('undo');
  }

  /**
   * Returns the form to be submitted
   */
  get form() {
    return this.element.closest('form');
  }

  /**
   * Returns the hidden input for the client_error attribute
   */
  get clientErrorInput() {
    return document.getElementById('ckeditor_suggested_content_client_error');
  }

  /**
   * Returns the hidden input for the accepted_content attribute
   */
  get acceptedInput() {
    return document.getElementById('ckeditor_suggested_content_suggestion_accepted_content');
  }

  /**
   * Returns the hidden input for the rejected_content attribute
   */
  get rejectedInput() {
    return document.getElementById('ckeditor_suggested_content_suggestion_rejected_content');
  }

  /**
   * Returns the hidden input for the status attribute
   */
  get statusInput() {
    return document.getElementById('ckeditor_suggested_content_status');
  }

  /**
   * Returns the current_data from the editor instance
   */
  get currentData() {
    return this.editor.getData();
  }
}
