window.initMailLogsDatatable = () => {
  if (!$('#mail-logs-datatable').length) return;

  window.mailLogsTable = $('#mail-logs-datatable').DataTable({
    processing: true,
    searching: false,
    retrieve: true,
    serverSide: true,
    order: [[10, 'desc']],
    ajax: {
      url: $('#mail-logs-datatable').data('source'),
      data: function (d) {
        d.search = { value: $('#search-mail-logs-datatable').val(), regex: false }
      }
    },
    language: {
      'oPaginate': {
        'sFirst': '',
        'sLast': ''
      }
    },
    info: false,
    pagingType: 'full_numbers',
    createdRow: (row, data, index) => {
      $(row).addClass('hover-secondary small');
      $(row).attr("data-action", "click->modal#set");
      $(row).attr("data-src", `/developers/mail_logs/${data.id}/details_modal`);
    },

    columns: mailLogColumns()
  });

  //setup before functions
  var typingTimer;                //timer identifier
  var doneTypingInterval = 1000;  //time in ms, 1 second for example
  var $input = $('#search-mail-logs-datatable');

  //on keyup, start the countdown
  $input.on('keyup', function () {
    $(this).val() ? $('#search-mail-logs-clear').removeClass('d-none') : $('#search-mail-logs-clear').addClass('d-none')
    clearTimeout(typingTimer);
    typingTimer = setTimeout(window.mailLogsTable.ajax.reload, doneTypingInterval);
  });

  //on keydown, clear the countdown
  $input.on('keydown', function () {
    clearTimeout(typingTimer);
  });

  if ($input.length && $input.val().length == 0) {
    $('#search-mail-logs-clear').addClass('d-none');
  }

  $('#search-mail-logs-clear').on('click', function () {
    $input.val('')
    $input.trigger('keyup');
    $('#search-mail-logs-clear').addClass('d-none');
  })
}

const formatTime = (timestamp) => {
  let month = timestamp.getMonth() + 1;
  let date = timestamp.getDate();
  let hours = timestamp.getHours();
  let minutes = timestamp.getMinutes();
  let seconds = timestamp.getSeconds();
  if (month < 10) month = `0${month}`;
  if (date < 10) date = `0${date}`;
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return `${month}/${date} - ${hours}:${minutes}:${seconds}`;
}

function mailLogColumns() {
  return [
    { data: 'klass_name' },
    { data: 'method_name' },
    { data: 'to_0' },
    { data: 'to_1' },
    { data: 'from_0' },
    { data: 'from_1' },
    { data: 'cc_0' },
    { data: 'cc_1' },
    { data: 'bcc_0' },
    { data: 'bcc_1' },
    {
      data: 'called_at', render: (data, display, row) => {
        const time = new Date(data);
        return time.toLocaleDateString() === 'Invalid Date' ? '---' : `${formatTime(time)}`;
      }
    },
    {
      data: 'queued_at' , render: (data, display, row) => {
        const time = new Date(data);
        return time.toLocaleDateString() === 'Invalid Date' ? '---' : `${formatTime(time)}`;
      }
    },
    {
      data: 'sent_at', render: (data, display, row) => {
        const time = new Date(data);
        return time.toLocaleDateString() === 'Invalid Date' ? '---' : `${formatTime(time)}`;
      }
    },
    {
      data: 'success',
      render: (data, display, row) => {
        return data === 'true' ? 'Yes' : 'No';
      }
    },
    {
      data: 'error_message',
      render: (data, display, row) => {
        return !!data ? 'Yes' : 'No';
      }
    }
  ];
}

$(document).on('turbolinks:load', () => {
  window.initMailLogsDatatable();
});
