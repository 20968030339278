import { Controller } from "@hotwired/stimulus";
import consumer from "channels/consumer";

/*
  This controller is used to open up a websocket for the sole purpose of determining if this
  Review Session should remain open or not. On page load, a `QueuedJob::EndReviewSession`
  was queued to run in 10 seconds. If that job runs, this review session will be ended. As long as tab
  that this client is connected to remains open, this controller will continue to `requeue` the
  `QueuedJob::EndReviewSession` job to ensure the Review session is not closed.

  It is possible for multiple clients to be connected to the same review session, which could potentially cause
  MANY requeue requests to be sent to the server. Some measures have been put in place prevent this:
  Each client will receive a unique `requeueId`. Each client will set a timeout to send their `requeueId` via the
  `requeue_request` every 3-6 seconds. Once the server receives this request, it will broadcast that `requeueId`
  back out to all clients in a `requeue_response` message. The client that sent the request, will then send another
  message to `requeue` the `QueuedJob::EndReviewSession` job. All other clients will reset their timeout
  for sending the `requeue_request`.
*/
export default class extends Controller {
  static get values() {
    return {
      reviewSessionId: Number
    }
  }

  connect() {
    this.requeueId = crypto.randomUUID();
    this.requeueSubscription = consumer.subscriptions.create(
      {
        channel: 'ReviewSessionRequeueChannel',
        review_session_id: this.reviewSessionIdValue
      },
      {
        received: this._received.bind(this),
        connected: this._connected.bind(this),
        requeue: this.requeue.bind(this),
      }
    );
  }

  /*
    Sets a timeout to send this client's `requeue_id` to the server to request
    the `QueuedJob::EndReviewSession` job to be requeued. If there is already
    a timeout in place, it will be cleared and a new one will be started.
  */
  resetRequeueRequestTimeout() {
    clearTimeout(this.requeueTimeout);
    this.requeueTimeout = setTimeout(() => {
      this.requeueSubscription.perform('requeue_request', {
        'review_session_id': this.reviewSessionIdValue,
        'requeue_id': this.requeueId
      });
    }, this.timeoutDelay());
  }

  /*
    Sends a message to the server to requeue the `QueuedJob::EndReviewSession` job.
  */
  requeue() {
    this.requeueSubscription.perform('requeue', {
      'review_session_id': this.reviewSessionIdValue,
      'requeue_id': this.requeueId
    });
  }

  /*
    Called when the subscription is ready for use on the server.
    Requeues the ReviewSessionClosedChannel job right away to ensure
    it does not close the review session.
  */
  _connected() {
    this.requeueSubscription.requeue();
  }

  /*
    Called when a broadcast is received from the server on this channel.
    Two types of broadcasts will be received:

    requeue_response: When this broadcast is received, if the `requeue_id` matches the
      `requeue_id` for this client, it will send another message to requeue the
      `QueuedJob::EndReviewSession` job. Otherwise, it will reset the timeout
      for requesting a requeue.

    requeue_confirmed: When this broadcast is received, if the `requeue_id` matches the
      `requeue_id` for this client, it will reset the timeout for requesting a requeue.
  */
  _received(data) {
    if (data.message === 'requeue_response') {
      if (data.requeue_id == this.requeueId) {
        this.requeue();
      } else {
        this.resetRequeueRequestTimeout();
      }
    } else if (data.message === 'requeue_confirmed') {
      if (data.requeue_id == this.requeueId) {
        this.resetRequeueRequestTimeout();
      }
    }
  }

  timeoutDelay() {
    const minTimeout = 3000;
    const maxTimeout = 6000;
    return Math.floor(Math.random() * (maxTimeout - minTimeout + 1) + minTimeout);
  }
}
