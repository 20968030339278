$(document).on('turbolinks:load', function() {
  observeTurboFramesToRefreshJs($(document));
})

const observer = new MutationObserver((mutations) => {
  for (let mutation of mutations) {
    // We are using a mix of sprinkles and hotwire
    // The next condition checks if we need to close a offcanvas element after an action
    // Eventually we will stop using sprinkles and this will be handled by hotwire
    if ($(mutation.addedNodes).find('[data-close-offcanvas="true"]').length > 0) {
      closeModals();
    }

    window.observeTurboFramesToRefreshJs($(document));
    window.enableReveal($(mutation.addedNodes));
    window.enableButtons($(mutation.addedNodes));
    window.enableCKEditors($(mutation.addedNodes));
    window.enableSortable($('body'));
  }
});

window.observeTurboFramesToRefreshJs = function($base) {
  $base.find('turbo-frame').each(function() {
    let turboFrame = $(this).parent()[0];
    observer.observe(turboFrame, { childList: true });
  });
}

function closeModals() {
  $('.modal').modal('hide');
}
