import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['cacher'];
  }

  /*
  * This controller will handle firing a turbo request to the backend for caching a page in the background.
  * To leverage this controller use the partial in views/turbo_frames/silent_cacher
  *
  * Traditional turbo_frame_tags can't be utilized since they will throw an error if their src is the same as the current request page.
  * Note: To avoid a looping request cycle it is important that the end point will respond to html requests differently than turbo_stream requests.
  */
  connect() {
    setTimeout(() => this.silentFetch(), 5000)
  }

  silentFetch() {
    fetch(this.cacherTarget.dataset.url)
    .then( (resp) => {
      if (resp.ok && this.data.get('debug') == 'true') console.log("shhhhh.... This page was siliently cached.");
      this.cacherTarget.remove(); // remove the target from the page to keep the DOM clean
    });
  }
}