import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['toggleable'];
  }

  connect() {
    this.element.addEventListener('toggle-display:toggle', (e) => this.toggle(e))
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleableTargets.forEach((element) => this.toggleTarget(element))
  }

  toggleTarget(target) {
    target.classList.toggle('d-none');
  }
}
