import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['checkbox', 'element'];
  }

  static get values() {
    return {
      enable: Boolean
    }
  }

  connect() {
    this.handleState()
  }

  handleState() {
    setTimeout(() => {
      this.elementTargets.forEach((element) => {
        const checkboxValue = this.checkboxTarget.checked;
        element.disabled = this.enableValue ? !checkboxValue : checkboxValue;
        const handler = checkboxValue !== this.enableValue ? this._handleAdd : this._handleRemove;
        handler(element);
      });

    }, 100)
  }

  _handleAdd(ele) {
    ele.classList.add('disabled');
  }

  _handleRemove(ele) {
    ele.classList.remove('disabled');
  }
}
