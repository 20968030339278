// docs: https://www.ag-grid.com/javascript-grid-cell-editor/

class TextFieldValueEditor {
    init(params) {
      this.eInput = document.createElement('input');
      this.eInput.classList.add('ag-grid-edit-input')
      this.eInput.value = params.value;
    }
    
    // gets called once when grid ready to insert the element
    getGui() {
      return this.eInput;
    }
    
    afterGuiAttached() {
      this.eInput.focus();
      this.eInput.select();
    }
    
    getValue() {
      return this.eInput.value;
    }
    
    // any cleanup we need to be done here
    destroy() {
    }
}

export default TextFieldValueEditor;
