import { Controller } from "@hotwired/stimulus";

/*
 * @deprecated
 * @reason I don't see this in use -- instead we can use fixed_position_updater_controller 
*/
export default class extends Controller {
  static get targets() {
    return ['container', 'element'];
  }

  connect() {
    window.addEventListener('scroll', () => this.makeSticky());
  }

  makeSticky() {
    this.elementTargets.forEach((element, index) => {
      const section = this.containerTargets[index];
      const sectionOffset = section.getBoundingClientRect().top + window.pageYOffset;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let headerHeight = 94;
      if (this.calculateOffsetHeights() > 0) {
        headerHeight = this.calculateOffsetHeights();
      }
      const offsetAdjustment = 220;

      if (scrollTop >= sectionOffset - headerHeight - offsetAdjustment) {
        element.style.position = 'fixed';
        element.style.top = headerHeight + 'px';
        element.classList.remove('scroll-section-navbar');
        element.style.right = '500px';
        element.style.zIndex = '1110';
      } else {
        element.style.position = 'absolute';
        element.style.top = '-50px';
        element.style.right = '-46px';
        element.classList.add('scroll-section-navbar');
      }
    });
  }

  calculateOffsetHeights() {
    let offsetSum = 0;
    const elements = [
      'alt-env-warning',
      'ci-navigation-bar',
      'breadcrumb-nav'
    ];

    elements.forEach(id => {
      const element = document.getElementById(id);
      offsetSum += element ? element.offsetHeight : 0;
    });

    return offsetSum
  }
}
