import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["toggle"];
  }

  static get outlets() {
    return ['nav--panel'];
  }

  connect() {
    if (!this.hasToggleTarget) return;

    this.document_container = document.getElementById('toc_criteria_manager');

    if (this.toggleTarget.checked) {
      this.navPanelOutlet.collapse();
      this.showCriteria();
    } else {
      toggle.parent().addClass("d-none").removeClass("d-inline-block");
      this.hideCriteria()
    }
  }

  toggleTargetConnected() {
    this.handleToggle();
  }

  navPanelOutletConnected(outlet) {
    outlet.on('expanded', () => this.hideCriteria() );
    outlet.on('collapsed', () => this.showCriteria() );
  }

  handleToggle() {
    const breakpoint = 1180;
    const width = window.innerWidth;

    if (this.toggleTarget.checked) {
      this.navPanelOutlet.collapse();
      if (width > breakpoint) {
        this.showCriteria();
      } else {
        this.toggleTarget.disabled = true;
        this.hideCriteria();
        $(".element-box-content").removeClass("pr-large");
      }
    } else {
      this.navPanelOutlet.expand();
      if (width > breakpoint) {
        this.toggleTarget.disabled = false;
      } else {
        this.toggleTarget.disabled = true;
      }
    }
  }

  toggle() {
    if (!this.hasToggleTarget) return;

    if (this.toggleTarget.checked) {
      this.navPanelOutlet.collapse();
    } else {
      this.navPanelOutlet.expand();
    }
  }

  showCriteria() {
    if (!this.document_container) return;

    this.toggleTarget.checked = true;
    this.document_container.classList.remove('criteria-hide');
  }

  hideCriteria() {
    if (!this.document_container) return;

    this.toggleTarget.checked = false;
    this.document_container.classList.add('criteria-hide');
  }
}
