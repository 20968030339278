require("./plans");
require("./progress");
require("./fields");
require("./submissions/unsaved_warning");
require("./conversations/subscriptions");
require("./solicitations/documents");
require("./solicitations/running_marginals/templates");
require("./solicitations/sections");
require("./solicitations/sections/templates");
require("./datatables");
require("./task_datatable");
require("./analytics");
require("./memberships_datatable");
require("./copy");
require("./browsers");
require("./projects");
require("./documents/accessibility_check");
require("./cache");
require("./sentry");
require("./show_sidebar");
require("./evaluation_forms");
require("./forms");
require("./invoice");
require("./aggregate_scores");
require("./navbar");
require("./collapsable");
require("./wizard");
require("./reset_password");
require("./turbo_frames");
require("./teams");
require("./evaluation_stage");
require("./users");
require("./stage_projects_datatable");
require("./solicitations/documents/templates");
require("./solicitations/purchase_classes");
require("./solicitations/types");
require("./solicitations/vehicles");
require("./solicitations/categories");
require("./solicitations/documents/categories");
require("./purchasing_agencies");
require("./solicitations/sections/templates/variations");
require("./recommendations");
require("../developers/job_logs_datatable");
require("./solicitations/documents/settings");
require("../developers/mail_logs_datatable");
require("./evaluations/worksheets");
require("./teams/configuration");
require("./teams/roles");
require("../developers/request_logs_datatable");
require("../account/ci_control_panel/teams_datatable");
require("../account/ci_control_panel/users_datatable");
require("./ci_control_panel");
require("./contracts/documents");
require("./criterium");
