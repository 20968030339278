import { RevealOperator } from "../reveal_operator";

/**
 * Reveal Operator that will reveal itself when the source data is equal to the provided value. It is intended to only work with a singular value
 * and will provide a warning if an array of values is passed and has a length greater than 1.
 *
 * Common usage for this operator would be to only reveal content when a certain radio button is checked (or not checked by using 'not-equal-to').
 */
export class EqualTo extends RevealOperator {
  sanitize() {
    if (Array.isArray(this.values)) {
      if (this.values.length > 1) {
        this.warn('is only intended to receive a singular value and received an array of more than 1 value.')
      }

      this.values = this.values[0]
    }
  }

  _valueCheck(formdata) {
    return this._singleFormValue(formdata) === this.values;
  }
}