window.initControlPanelUsersDatatable = () => {
  if (!$('#control-panel-users-datatable').length) return;

  window.ControlPanelUsersDatatable = $('#control-panel-users-datatable').DataTable({
    retrieve: true,
    processing: true,
    serverSide: true,
    stateSave: true,
    aLengthMenu: [
      [10, 25, 50, 100, 200],
      [10, 25, 50, 100, 200]
    ],
    iDisplayLength: 10,
    ajax: {
      url: $('#control-panel-users-datatable').data('source')
    },
    language: {
      'sLengthMenu': 'Showing_MENU_',
      'info': 'of _TOTAL_ Users',
      'oPaginate': {
        'sFirst': '',
        'sLast': ''
      }
    },
    'dom': "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-task-info'li>p>",
    pagingType: 'full_numbers',
    'aoColumnDefs': [{
         'bSortable': false,
         'aTargets': [0, 2, 3]
    }],
    columnDefs: [
    ],
    columns: [
      {data: 'user_name'},
      {data: 'user_email'},
      {data: 'impersonate_user'}
    ]
  });

  //setup before functions
  var typingTimer;                //timer identifier
  var doneTypingInterval = 1000;  //time in ms, 1 second for example
  var $input = $('#search-control-panel-users-datatable');

  //on keyup, start the countdown
  $input.on('keyup', function () {
    $(this).val() ? $('#search-control-panel-users-clear').removeClass('d-none') : $('#search-control-panel-users-clear').addClass('d-none')
    clearTimeout(typingTimer);
    typingTimer = setTimeout(window.ControlPanelUsersDatatable.ajax.reload, doneTypingInterval);
  });

  //on keydown, clear the countdown
  $input.on('keydown', function () {
    clearTimeout(typingTimer);
  });

  if ($input.length && $input.val().length == 0) {
    $('#search-control-panel-users-clear').addClass('d-none');
  }

  $('#search-control-panel-users-clear').on('click', function () {
    $input.val('')
    $input.trigger('keyup');
    $('#search-control-panel-users-clear').addClass('d-none');
  })
}

$(document).on('turbolinks:load', () => {
  window.initControlPanelUsersDatatable();
});
