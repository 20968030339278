import { enableSelects } from './select.js';

$(document).on('turbolinks:load', function() {
  $('.dynamic-fields-container').on('DOMNodeInserted', function(event) {
    if ($(event.target).hasClass('nested-fields')) {
      const dynamicField = $(event.target);

      // If delect2 component hasn't been created yet
      if (dynamicField.find('.nested-fields .select2.select2-container').length === 0) {
        enableSelects(dynamicField);
      }
    }
  });
});
