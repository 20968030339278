$(document).on('turbolinks:load', function() {
  milestoneBeforeRemove();
  milestoneAfterRemove();

  if (!$('.milestone_value').length || !$('.billed-amount').length) {
    // Don't bother updating milestone or billed amount when supporting material
    return;
  }

  $('.invoice-milestone').each(function(index) {
    const milestoneValue = getValue($(this));
    if (milestoneValue) $('.milestone_value')[index].value = milestoneValue;
    const billedAmount = $('.billed-amount')[index].value;
    if (billedAmount) {
      const percentageValue = calculatePercentage(milestoneValue, billedAmount);
      $('.billing_percentage')[index].value = `${percentageValue}%`;
    }
  })

  $(document).on('change', '#invoice-milestones', function() {
    updateInvoiceValues()
  })

  const updateInvoiceValues = () => {
    $(document).on('input', '.billed-amount', function() {
      const index = getIndex($(this)[0], $('.billed-amount'));
      const value = $('.milestone_value')[index].value.replace('$', '');
      if (value) {
        const percentageValue = calculatePercentage(value, $(this).val());
        $('.billing_percentage')[index].value = `${percentageValue}%`;
      }
    })

    $(document).on('change', '.invoice-milestone', function() {
      const value = $(this).val();
      const index = getIndex($(this)[0], $('.invoice-milestone'));
      if (value) $('.milestone_value')[index].value = (`${getValue($(this))}`);
    })

    $(document).on('input', '.billing_percentage', function() {
      const index = getIndex($(this)[0], $('.billing_percentage'));
      const value = $('.milestone_value')[index].value.replace('$', '');
      if (value) {
        const percentageValue =  calculateBilledAmount(value, $(this).val().replace('%',''));
        $('.billed-amount')[index].value = `${percentageValue}`;
      };

      $(this).val(function(i, v) {
        return v.replace('%','') + '%';
      });
    });
  }

  updateInvoiceValues()
})


$(document).on('change', '#invoices .file-upload', function() {
  const invoice_persistence = $("#invoices").attr('data-persisted');
  if (invoice_persistence == "true") {
    $("input[type='submit']").attr('data-confirm', 'This action will remove all invoice documents attached previously if you attached other files.');
  }
});

$(document).on('click', 'a.docusign-trigger', function(event) {
  event.preventDefault();

  const $link = $(event.currentTarget);
  const path = $link.attr('href');
  const recipientEmail = $('.recipient-email').val();

  $.ajax({
    url: path,
    type: 'POST',
    data: { 'recipient_email': recipientEmail }
  });
});

// Adding these to document ready in addition to turbolinks load callbacks
$(() => {
  milestoneAfterRemove()
  milestoneBeforeRemove()
})

const milestoneBeforeRemove = () => {
  const $container = $("#invoice-milestones");
  if ($container.data('milestoneBeforeRemove') === 'true') return;

  $container.data('milestone-before-remove', 'true'); // so we don't duplicate the listener
  // if last milestone, warn before remove...
  $container.on('cocoon:before-remove', (event, removedItem) => {
    if ($container.children('div.nested-fields').length == 1) {
      let confirmation = confirm('You must associate at least one milestone. Are you sure you want to remove the last Milestone?')
      if (!confirmation) event.preventDefault();
    }
  })
}


const milestoneAfterRemove = () => {
    const $container = $("#invoice-milestones");
    if ($container.data('milestoneAfterRemove') === 'true') return;

    $container.data('milestone-after-remove', 'true'); // so we don't duplicate the listener
    $container.on('click','a.remove_fields', (e) => {
      const id = $(e.target).siblings('input').attr('id')
      if (id == undefined) return;

      // Find the associated Deliverable upload fields and remove them:
      $('#'+id.replace('_destroy','deliverable'))
        .closest('div.deliverable')
        .addClass('d-none');
    })
}

function getIndex(object, list) {
  for (var i = 0; i < list.length; i++){
    if (list[i] == object) return i;
  }
}

function getValue(milestone) {
  const listValues = JSON.parse(milestone.attr('data'));
  let value;
  for (var i = 0; i < listValues.length; i++) {
    if (listValues[i].id == milestone.val()) {
      value = listValues[i].value;
    }
  }
  return value;
}

function calculatePercentage(value, billedAmount) {
  return (100 * billedAmount / value).toFixed(2);
}

function calculateBilledAmount(value, percentage) {
  return percentage * value / 100;
}
