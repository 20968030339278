import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  /*
   * OnLoadCallback controller can be used for triggering a function after content is lazy loaded.
   * To use the controller wrap,
   *   1. Define the function that has the logic to be executed after load
   *   2. Create a container element that houses all of the lazy loading content
   *   3. Set the controller callback value on the container element by: data-on-load-callback-callback-value: '<your-custom-function-name-as-a-string>'
   *   4. Add the data-on-load-callback-target='element' to the returned html that is being lazy loaded within the container
   *
   * The controller will queue the callback every half second after elements have been connected to the dom. The reason for triggering every half second
   * instead of on every load is to lessen the requirement on the client side for processing.
   *
   * The half second (500ms) can be overridden by passing in a callbackDelay-value on the container element:
   *   data-on-load-callback-callback-delay-value=3000 to set the delay to 3secs
   *
  */


  /*
  * Set the callback value on the container element
  */
  static get values() {
    return {
      callback: String,
      callbackDelay: Number
    }
  }
  static get targets() {
    return ['container', 'element'];
  }

  connect() {
    this.queued = false;
  }

  containerTargetConnected() {
    this.queueCallback();
  }

  /*
  * When an element target connects trigger the callback
  * @note - This is probably better done using a mutation observer on the container but that seems it might take a bit of setup
  */
  elementTargetConnected(e) {
    if (this.queued) { return; }

    this.queueCallback();
  }

  /*
  * queues the callback to run after a half second
  */
  queueCallback() {
    this.queued = true;
    setTimeout(() => {
      this.queued = false;
      try {
        this.callbackValue && eval(this.callbackValue)();
      } catch {
        return;
      }
    }, this.callbackDelayValue || 500)
  }
}
