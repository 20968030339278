$(document).on('turbolinks:load', function () {

  var upperCasePresent = false;
  var specialCharPresent = false;
  var passwordLengthPresent = false;
  var passwordConfirmPresent = false;

  $(function() {
    $(".submit-btn").attr('disabled', true)
  })

  // Toggle password type
  $("#eye_user_password, #eye_user_password_confirmation").on('click',function() {
    id = $(this).attr('id').replace("eye_", "");
    password = $("#" + id);
    if (password.attr('type') === 'password') {
      password.attr('type', 'text');
      $(this).text('Hide')
    } else {
      password.attr('type', 'password');
      $(this).text('Show')
    }
  })

  // Password and password confirmation match on key up
  function matchPassword(){
    password = $('#user_password').val();
    password_confirmation = $('#user_password_confirmation').val();
    error_block = $('.error_msg');

    if (password.length && password === password_confirmation) {
      error_block.addClass('d-none');
      passwordConfirmPresent = true;
    } else if (password_confirmation && password_confirmation.length > 0) {
      error_block.removeClass('d-none');
      passwordConfirmPresent = false;
    }
  }

  /**
   * Use this helper function to remove .form-control-feedback text and alert banner
   * Useful to run this after the user has seen the text and starts typing again
   */
  function removeErrorText(){
    if($('.alert-dismissible.error-banner').length) {
      $('.alert-dismissible.error-banner').alert('close');
      $('.form-control-feedback').hide();
    }
  };

  $('#user_password_confirmation').on("input", function () {
    matchPassword();
    checkAllValidations();
    removeErrorText();
  })

  $("#user_password").on("input", function () {
    addRemoveMark();
    matchPassword();
    checkAllValidations();
    removeErrorText();
  })

  function addRemoveMark() {
    var password_value = $("#user_password").val();
    const specialChar = /[~`@+=()!#$%^&*[\]\\\';,/{}/|":<>?._ -]/g;
    const upperCase = /[A-Z]/;

    setValue(upperCase.test(password_value), "upperCase", "upperCasePresent");
    setValue(specialChar.test(password_value), "specialChar", "specialCharPresent");
    setValue(password_value.length >= 8 && password_value.length <= 128, "charLength", "passwordLengthPresent");
  }

  function checkAllValidations() {
    if (upperCasePresent && specialCharPresent && passwordLengthPresent && passwordConfirmPresent) {
      $(".submit-btn").removeAttr('disabled')
    } else {
      $(".submit-btn").attr('disabled', true);
    }
  }

  function setValue(conditionSatisfied, elementId, elementVar) {
    eval(elementVar +  '=' + conditionSatisfied);
    if (conditionSatisfied) {
      $(`#${elementId}`).addClass('mark-icon');
    } else {
      $(`#${elementId}`).removeClass('mark-icon');
    }
  }

})
