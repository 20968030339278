import { Controller } from "@hotwired/stimulus";
import { SourceElement } from "./lib/reveal/source_element";
import { IsChecked } from "./lib/reveal/operators/is_checked";
import { GreaterThanOrEmpty } from "./lib/reveal/operators/greater_than_or_empty";
import { EqualTo } from "./lib/reveal/operators/equal_to";
import { InRange } from "./lib/reveal/operators/in_range";
import { Blank } from "./lib/reveal/operators/blank";
import { IncludedIn } from "./lib/reveal/operators/included_in";
import { Includes } from "./lib/reveal/operators/includes";
import { ArrayEqualTo } from './lib/reveal/operators/array_equal_to';
import { Changed } from './lib/reveal/operators/changed';

/**
 * A stimulus controller for hiding/showing form content based on input value(s) of other fields within the form.
 *
 * To attach an element to this controller use
 *    data-controller="reveal"
 * The controller will also require an input name that represents the form data to be checked.
 *    data-reveal-name="project[solictations_type_id]"
 * The controller will usually require an operator as well to determine what logic is used for the conditional check
 *    data-reveal-operator="is_checked" or data-reveal-operator="is-checked" are both valid.
 * Lastly, all operators implicitly support negation by adding of 'not' into the operator name
 *    data-reveal-operator="not_is_checked", data-reveal-operator="is_not_checked", data-reveal-operator="is_checked_not" as evalute to operator => 'is-checked', {negated: true}
 *
 * The controller functions by attaching listeners to the source inputs. That is the inputs within the source form that match the `data-reveal-name` value.
 * The source elements will trigger a callback through the attach listener event that will call `this.handleChange()`. Within `handleChange`, the controller checks to
 * see if the operator function tests true, if so so the element that the controller is attached to removes the class 'd-none'. If it receives `false` then 'd-none' is
 * added to the element.
 *
 *
 * # Basic Operation
 * To better visualize or rationalize how this works, think of it as:
 *    <source-values> <operator> <provided-values>
 *  * source-values are retrieved from the form data
 *  * operator is defined on the data-reveal-operator attribute
 *  * provided-values are the values from the data-reveal-value attribute
 *
 * So seeing a div with
 *    `<div data-controller="reveal" data-reveal-operator="included-in" data-reveal-values=[1,2,3] data-reveal-name="projects[solicitations_type_id]">`
 * means that the div will be displayed if the value from 'projects[solicitations_type_id] is included in the array of [1,2,3]
 *
 *
 * # Operators
 * Custom operators can be defined as a class and should extend the RevealOperator base class. The static method operators() must be updated to provide a mapping
 * for an operator string name to its representative class. See javascript/controllers/lib/reveal/reveal_operator for more details.
 *
 *
 * # Extensions
 * There are currently 2 extensions that are supported by the controller through `data-reveal-extensions` attribute.
 *
 *
 * ## Disable Extension
 * `data-reveal-extensions="disable"` will disable input elements within the controller when the controller element is being hidden. This can be useful when the hidden
 * data should not be submitted with the form.
 *
 *
 * ## Form Validity Check Extension
 * `data-reveal-extensions="form-validity-check" is used for triggering the client side form validations check that occurs within the stimulus form_controller. This will cause
 * the client side validations to be ran through the attached form controller everytime `handleChange` is called. This can be useful since the form validations are only applicable
 * to non-disabled inputs. Consider the scenario where some inputs are not revealed and the controller is extending 'disable' as well. This means the inputs are disabled. The form controller
 * could pass validations because those inputs are disabled - even though they are empty. Now changes are made to the form which causes this controller to reveal itself and therefore
 * causes the input elements to enable. Now if there are validations on those inputs the form may no longer be valid. "form-validity-check" extension allows for that callback to be triggered
 * within the form controller without overcomplicating how the form controller functions.
 *
 *
 * # Complex Logic
 * There are situations where an item should reveal itself under complex conditional logic. For these situations, multiple operators, names, and values
 * can be passed into the controller as json arrays. Take the following example:
 *
 * <div data-controller="reveal" data-reveal-names="['solicitations_type_id', 'solicitations_vehicle_id']" data-reveal-operators="['included_in', 'is_checked']" data-reveal-values="[<short-list-of-ids>, '*']">
 *
 * The div will reveal itself if the solicitations_type_id is included in the <short-list-of-ids> array OR if the solicitations_vehicle_id has a value that is checked.
 * The complex logic is ALWAYS evaluated using OR. If an AND evaluation is needed that can be accomplished by simply having the elements nested like this:
 *
 * <div data-controller="reveal" data-reveal-names="['solicitations_type_id]" data-reveal-operators="['included_in']" data-reveal-values="[<short-list-of-ids>]">
 *    <div data-controller="reveal" data-reveal-names="['solicitations_vehicle_id']" data-reveal-operators="['is_checked']">
 *      ....<hideable-content-here>....
 *    </div>
 * </div>
 *
 * Now the hideable content is only revealed when both reveal elements are set to show.
 *
 * One important note here is that while not all operators require a value (such as is_checked), a good practice is to always provide a value when using complex logic. Take
 * the following 2 examples:
 *
 * <div data-controller="reveal" data-reveal-names="['solicitations_type_id', 'solicitations_vehicle_id']" data-reveal-operators="['included_in', 'is_checked']" data-reveal-values="[<short-list-of-ids>]">
 * <div data-controller="reveal" data-reveal-names="['solicitations_vehicle_id', 'solicitations_type_id']" data-reveal-operators="['is_checked', 'included_in']" data-reveal-values="[<short-list-of-ids>]">
 *
 * They are only slightly different in the ordering of the reveal names. The first option will work because the is_checked operator doesn't require a value, so even though the array of values doesn't have
 * one for the operator, the is_checked.js class doesn't require one because it simply looks at the source elements for one that is checked. On the other hand, the 2nd example above is not going to work as
 * expected because the solicitations_type_id operator is going to be given a value of 'undefined' and the <short-list-of-ids> array is going to be passed to the 'is-checked' operator. 'is-checked' will
 * work as intended but the 'included-in' will be broken.
 *
 * # Targets
 *
 * To reduce the need for many controllers for a single input, a controller can leverage 'positive' and 'negative' targets. When a controller has
 * either a positive or a negative (or both) targets, the hiding and any other extension actions are taken on the positive and negative targets and not
 * directly on the controller element itself.
 *
 * A working example of this currently (on the auth-redesign feature branch) is in the _horizontal_radio_buttons helper that allows for a custom/other option. The Custom radio button option
 * and is wrapped in a Reveal::Wrapper. There are a couple labels and a text input field that should alternate in their state based on
 * whether or not the custom radio button is selected. One label is a positive target and the other a negative target and the text input field is also
 * a positive target. So when the custom button is selected, one of the labels is hidden, the other is shown, and the text input reveals itself as well.
 * Without this approach of positive and negative targets, 3 reveal controllers would need to be utilized for the same input. Targets can help reduce noise
 * when their action is all related to the same input field and value(s).
 *
 *
 * # View Component - Reveal::Wrapper
 *
 * To aid in rendering content within a reveal controller, there is a Reveal::Wrapper component that will wrap its content in a div. The component will also take care
 * of converting any array data into json strings.
 */

export default class extends Controller {
  static get targets() {
    return ['positive', 'negative'];
  }

  connect() {
    // setup a debounced handle to ensure things can't get to spammy....
    this.handleChange = _.debounce((event) => {
      this._handleChange(event)
    }, 100);
    this._boundShow = this.show.bind(this);
    this._boundHide = this.hide.bind(this);

    // trigger the handleChange to hide/reveal content as needed when the controller connects
    if (this.operatorsList.includes('event')) {
      this._setupEventHandlers();
    } else {
      this._handleChange();
    }
  }

  disconnect() {
    if (this._operatorsList.includes('event')) {
      const [showEvent, hideEvent] = this._valuesList;
      if (showEvent) {
        document.removeEventListener(showEvent, this._boundShow)
      }

      if (hideEvent) {
        document.removeEventListener(hideEvent, this._boundHide)
      }

    }
  }

  /**
   * Action to be leveraged through data-action if needed to trigger a reveal check
   */
  handle() {
    this._handleChange();
  }

  /**
   * Shows the respective element(s) by removing the class d-none
   */
  show() {
    this._revealed = true;
    this._agreedTargets.forEach(this._showElement)
    this._opposedTargets.forEach(this._hideElement)
  }

  /**
   * Hides the respective element(s) by adding class d-none
   */
  hide() {
    this._revealed = false;
    this._agreedTargets.forEach(this._hideElement)
    this._opposedTargets.forEach(this._showElement)
  }


  // GETTERS

  /**
   * Builds and returns the list of operators instances for the controller
   */
  get operators() {
    this._operators = this._operators || this.namesList.map((name, idx) => {
      let value = this.valuesList[idx]
      return this._fetchOperator(this.operatorsList[idx], name, value)
    })

    return this._operators
  }

  /**
   * Retrieves an array of target that are in agreement with the _revealed value for this.element
   * @note - The _agreedTargets show status will follow the _revealed status for this.element, when true the elements will be shown, when false they will be hidden
   */
  get _agreedTargets() {
    if (this.hasPositiveTarget) {
      return this.positiveTargets
    } else {
      return [this.element]
    }
  }

  /**
   * Retrieves an array of target that are opposed to the _revealed value for this.element
   * @note - The _opposedTargets show status will follow the opposite of the _revealed status for this.element, when true the elements will be hidden, when false they will be shown
   */
  get _opposedTargets() {
    if (this.hasNegativeTarget) {
      return this.negativeTargets
    } else {
      return []
    }
  }

  /**
   * The object mapping for operator attribute name => operator class
   * @return {object}
   */
  get operatorMapping() {
    return {
      'equal-to': EqualTo,
      'in-range': InRange,
      'blank': Blank,
      'greater-than-or-empty': GreaterThanOrEmpty,
      'included-in': IncludedIn,
      'is-checked': IsChecked,
      'includes': Includes,
      'array-equal-to': ArrayEqualTo,
      'changed': Changed
    }
  }

  /**
   * Retrieves the plural or singluar version of revealName(s)
   * @return {string[]}
   */
  get namesList() {
    this._namesList = this._namesList || this._datasetAttributeToArray('revealName')
    return this._namesList;
  }

  /**
   * Retrieves the plural or singluar version of revealOperator(s)
   * @return {string[]}
   */
  get operatorsList() {
    this._operatorsList = this._operatorsList || this._datasetAttributeToArray('revealOperator');
    return this._operatorsList
  }

  /**
   * Retrieves the plural or singluar version of revealValue(s)
   * @return {string[]}
   */
  get valuesList() {
    this._valuesList = this._valuesList || this._datasetAttributeToArray('revealValue')
    return this._valuesList;
  }

  /**
   * Retrieves the source form element
   * @return {HTMLElement}
   */
  get sourceForm() {
    this._sourceForm = this._sourceForm || (this.element.dataset.revealSourceForm ? document.querySelector(this.element.dataset.revealSourceForm) : this.element.closest('form'));
    return this._sourceForm
  }

  /**
   * Retrieves the current state of the source form formdata
   * @return {FormData}
   */
  get _fetchSourceFormData() {
    return new FormData(this.sourceForm);
  }

  /**
   * Retrieves the reveal extensions
   * @return {string[]}
   */
  get extensions() {
    this._extensions = this.element.dataset.revealExtend ? this.element.dataset.revealExtend.split(' ') : []
    return this._extensions
  }



  // NUTS AND BOLTS



  /**
   * Hides a single element - currently this is done by adding class d-none on the element
   * @param {HTMLElement} e The element to be hidden
   */
  _hideElement(e) {
    e.classList.add('d-none');
  }



  /**
   * Reveals a single element - currently this is done by removing class d-none on the element
   * @param {HTMLElement} e The element to be revealed
   */
  _showElement(e) {
    e.classList.remove('d-none')
  }



  /**
   * Callback handler for the associated extensions
   */
  _handleExtensions() {
    this.extensions.forEach(this._extensionHandler.bind(this))
  }



  /**
   * Handler function for an individual extension
   * @param {string} extension The extension to be handled
   */
  _extensionHandler(extension) {
    switch (extension) {
      case 'disable':
        this._handleDisable()
        break;
      case 'form-validity-check':
        this._handleFormValidityCheck();
        break;
      default:
        console.warn(`unrecognized reveal-extension: ${extension}`)
        break;
    }
  }



  /**
   * Finds the source elements for the input name and instantiates a new operator with those elements
   *
   * @param {string} operator The name of the operator to be retrieved from the static operators lookup
   * @param {string} name The name of the inputs that represent the source data
   * @param {*} value The value to be tested against to determine if the content is revealed
   * @returns {RevealOperator}
   */
  _fetchOperator(operator, name, value) {
    // find the source elements for the operators
    const selector = `[name='${name}']`;
    let sourceElements = Array.from(this.sourceForm.querySelectorAll(selector)).filter(ele => ele.type != 'hidden').map((ele) => new SourceElement(ele, this));
    let [negated, cleanOperatorName] = this._operatorNameAndNegated(operator)
    const klass = this.operatorMapping[cleanOperatorName];
    if (!klass) {
      console.warn(`Invalid operator passed to the reveal controller: ${operator}`);
    } else {
      return new klass(name, value, { negated: negated, sourceElements: sourceElements, initialFormData: this._fetchSourceFormData });
    }
  }

  _setupEventHandlers() {
    const [showEvent, hideEvent] = this.valuesList;
    if (showEvent) {
      document.addEventListener(showEvent, this._boundShow)
    }

    if (hideEvent) {
      document.addEventListener(hideEvent, this._boundHide)
    }
  }



  /**
   * Sanitizes the operator name and determines if the operator should be negated
   * @note Since a common pattern to use in Rails is the utilization of symbols which are snake_cased while html best practices are to often use kebab-case
   *        we may often see differences in operator name structure. This function ensures that is_checked and is-checked are treated equally.
   * @param {string} operatorString The original operator name from the dataset attribute
   * @returns {[boolean, string]}
   */
  _operatorNameAndNegated(operatorString) {
    operatorString = operatorString.replaceAll('_', '-');
    const parts = operatorString.split('-');
    return [parts.includes('not'), parts.filter(p => p !== 'not').join('-')]
  }



  /**
   * Used for looking up a singular or pluralized version of an attribute name and returns the content as an array
   * @note - If the pluralized version is found, this function will use JSON.parse to convert the value to an array
   * @note - If no pluralized version is found, the singular attribute content is wrapped in an array
   * @param {string} attr singular version of the dataset attribute name to be looked up
   * @returns {string[]}
   */
  _datasetAttributeToArray(attr) {
    const pluralized = `${attr}s`;
    let ret;
    if (this.element.dataset[pluralized]) {
      ret = this.element.dataset[pluralized];
      if (!Array.isArray(ret)) {
        ret = JSON.parse(ret)
      }
    } else {
      ret = [this.element.dataset[attr]]
    }

    return ret
  }



  /**
 * The callback for hiding/showing the content controller element
 */
  _handleChange() {
    const fd = this._fetchSourceFormData;
    // if any operators return true from check() then show the content
    if (this.operators.some(op => op.check(fd))) {
      this.show()
    } else {
      this.hide()
    }
    // now handle the extensions
    this._handleExtensions()
  }



  // EXTENSION HANDLERS

  /**
   * Call the validity check on the source form element if it is attached to the form_controller
   */
  _handleFormValidityCheck() {
    const controller = Stimulus.getControllerForElementAndIdentifier(this.sourceForm, 'form')
    if (!controller) {
      console.warn('The source form for the reveal controller is not attached to the form controller. Try adding data-controller="form" to the source form');
      console.log(this.sourceForm);
    } else {
      controller.checkValidity();
    }

  }

  /**
   * Finds all the input elements within the scope and sets its disabled property to true if this element is not revealed
   */
  _handleDisable() {
    if (this.element.tagName == 'INPUT') {
      // If the reveal controller is attached directly to the input, then set disabled on this.element
      this.element.disabled = !this._revealed;
    } else {
      this._agreedTargets.forEach( ele => this._handleDisableScope(ele, !this._revealed) )
      this._opposedTargets.forEach( ele => this._handleDisableScope(ele, !this._revealed) )
    }
  }

  /**
   * Finds all inputs within a scope and sets their disabled value based on the 2nd argument
   * @param {HTMLElement} scope The DOM element that sets the outer-most scope for disabling inputs
   * @param {boolean} disabled True/false value that is used to set the element.disabled value
   */
  _handleDisableScope(scope, disabled) {
    scope.querySelectorAll('input').forEach((input) => {
      input.disabled = disabled;
    })
  }
}
