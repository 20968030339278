import { ApplicationController } from "./application_controller";
import dragula from "dragula";

export default class extends ApplicationController {
    static get targets() {
        return ['container', 'wrapper']
    }

    connect() {
        this.enable();
    }

    initialize() {
        this.onChangeCallbacks = []
        const scrollListener = (e) => { this.scroller.clientY = e.clientY }

        // this code mostly comes from https://github.com/eevleevs/scroller
        // with a refactor to vanilla js
        this.scroller = {
            interval: 10,
            step: 10,
            zone: 100,
            start: () => {
                this.scroller.enabled = true
                document.addEventListener('mousemove', scrollListener)
                this.scroller.tick()
            },
            stop: () => {
                this.scroller.enabled = false
                document.removeEventListener('mousemove', scrollListener);
            },
            tick: () => {
                if (!this.scroller.enabled) { return }

                let direction = 0
                if (this.scroller.clientY) {
                    let dst = window.scrollY;
                    if (this.scroller.clientY < this.scroller.zone && dst > 0)
                        direction = -1
                    else {
                        let dh = document.body.scrollHeight;
                        let wh = document.documentElement.offsetHeight;
                        if (this.scroller.clientY > wh - this.scroller.zone && dst < dh - wh - 1)
                            direction = 1
                    }
                }
                window.scrollBy(0, this.scroller.step * direction)
                setTimeout(this.scroller.tick, this.scroller.interval)
            }
        }
    }

    registerOnChangeCallback(cb) {
        this.onChangeCallbacks.push(cb)
    }

    enable() {
        const scroller = this.scroller;
        this.drake = dragula(this.containerTargets, {
            moves: function (el, container, handle) {
                return handle.classList.contains('reorder__handle') && el.dataset.canDrag == 'true';
            },

            accepts: function (el, target, source, sibling) {
                return !el.contains(target) && target.dataset.canDrop == 'true';
            },
            revertOnSpill: true
        }).on('over', (el, container, source) => {
            container.classList.add('reorder__hover')
        }).on('out', (el, container, source) => {
            container.classList.remove('reorder__hover')
        }).on('drop', el => {
            scroller.stop()
            this.handleChange(el);
        }).on('drag', el => {
            scroller.start()
        }).on('cancel', scroller.stop)

        this.element.querySelectorAll('.reorder__handle').forEach((ele) => {
            ele.addEventListener('mouseover', (e) => {
                e.currentTarget.classList.add('fa-arrows-up-down');
                e.currentTarget.classList.remove('fa-grip-dots-vertical')
            });

            ele.addEventListener('mouseout', (e) => {
                e.currentTarget.classList.add('fa-grip-dots-vertical')
                e.currentTarget.classList.remove('fa-arrows-up-down');
            })
        })

        this.handleIncrementalArrows()
    }

    /*
    * Hides the first up arrow in a container and the last down arrow in a container
    * since arrow sorting is isolated to a single container at the moment
    */
    handleIncrementalArrows() {
        function matchingForContainer(container, ele) {
            // helper function for determining if the arrow's direct container ancestor is the current container
            return ele.closest('[data-dragula-target="container"') == container
        }
        this.containerTargets.forEach(container => {
            // grab all the up arrows
            const upArrows = Array.from(container.querySelectorAll('.dragula__incremental-arrow-up')).filter((ele) => matchingForContainer(container, ele));

            // grab all the down arrows
            const downArrows = Array.from(container.querySelectorAll('.dragula__incremental-arrow-down')).filter((ele) => matchingForContainer(container, ele))

            upArrows.forEach((ele, idx) => {
                if (idx === 0) {
                    // the first up arrow in the container should be hidden
                    ele.classList.add('d-none');
                } else {
                    // all others should be showing
                    ele.classList.remove('d-none');
                }
            })

            const lastIndex = downArrows.length - 1;
            downArrows.forEach((ele, idx) => {
                if (idx === lastIndex) {
                    // the last arrow in the container should be hidden
                    ele.classList.add('d-none');
                } else {
                    // all others should be showing
                    ele.classList.remove('d-none');
                }
            })
        })
    }

    /*
    * Moves a row to a lower sort order within the parent container
    * @note This will move it visually higher on the screen
    */
    decrement(event) {
        const currentRow = event.currentTarget.closest('.reorder__element');
        const wrapper = currentRow.closest('.reorder__element-wrapper');
        const parentTree = wrapper.parentElement.children;
        const currentIndex = Array.from(parentTree).indexOf(wrapper);
        wrapper.parentElement.insertBefore(wrapper, parentTree[currentIndex - 1]);
        this.handleChange(currentRow);
    }

    /*
    * Moves a row to a higher sort order within the parent container
    * @note This will move it visually lower on the screen
    */
    increment(event) {
        const currentRow = event.currentTarget.closest('.reorder__element');
        const wrapper = currentRow.closest('.reorder__element-wrapper');
        const parentTree = wrapper.parentElement.children;
        const currentIndex = Array.from(parentTree).indexOf(wrapper);
        parentTree[currentIndex + 1].after(wrapper);

        this.handleChange(currentRow);
    }

    /*
    * Handles any onChangeCallbacks that have been registered
    */
    handleChange(el) {
        this.onChangeCallbacks.forEach(cb => cb(el))
        this.handleIncrementalArrows()
    }

    /*
    * Builds an object that
    */
    fetchHierarchy(ele = this.containerTargets[0]) {
        const results = {};
        results[ele.dataset.parentId] = Array.from(ele.children).map(container => {
            const child = container.querySelector('.reorder__element');
            return this.fetchHierarchy(document.querySelector(`[data-parent-id='${child.dataset['reorderId']}'`))
        })

        return results;
    }
}
