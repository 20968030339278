import { Controller } from "@hotwired/stimulus";
import { defaultConfiguration } from "./default_configurations";

export class DatatablesBase extends Controller {
  static get outlets() {
    return ['datatables--filters']
  }
  static get values() {
    return {
      autoRefreshCountdown: {
        type: Number,
        default: 5
      }
    }
  }
  static get columnConfig() {
    throw 'columnConfig must be defined in extending class, example `static get columnConfig() {}`';
  }

  static get languageConfig() {
    throw 'languageConfig must be defined in extending class, example `static get languageConfig() {}`'
  }

  static get searchConfig() {
    return { searching: true };
  }

  static get orderConfig() {
    return [[0, 'asc']];
  }

  static get targets() {
    return ['table', 'autorefresh']
  }

  static get orderingConfig() {
    return { ordering: true }
  }

  static get emptyTableConfig() {
    return {}
  }

  autorefreshTargetConnected(element) {
    element.addEventListener('change', () => {
      if (this.autorefreshTarget.checked) this.refresh();
      this._handleAutoRefresh()
    })

    this._handleAutoRefresh();
  }

  autoRefreshCountdownValueChanged(v) {
    if (!this.autoRefreshable) return;

    this.autoRefreshLabel.innerText = ` in ${v}`;
  }

  _handleAutoRefresh() {
    if (!this.autoRefreshable) return;

    if (this.autorefreshTarget.checked) {
      this._autoRefresh = setTimeout( () => {
        this.autoRefreshCountdownValue--
        if (this.autoRefreshCountdownValue === 0) {
          this.refresh();
        }
        this._handleAutoRefresh();
      }, 1000)
    } else {
      this._autoRefresh && clearTimeout(this._autoRefresh)
    }
  }

  datatablesFiltersOutletConnected(outlet) {
    outlet.register(this);
  }

  initialize() {
    this.datatable = null;
    this.autoRefreshLabel = this.element.querySelector('#auto-refresh-label');
    this.autoRefreshable = !!this.autoRefreshLabel
  }

  initializeDatatable() {
    this.datatable = $(this.table).DataTable(this.configurationWithDefaults())
  }

  configurationWithDefaults() {
    return {
      ...defaultConfiguration,
      ...this.constructor.searchConfig,
      ...this.constructor.orderingConfig,
      ...this.constructor.emptyTableConfig,
      columns: this.constructor.columnConfig,
      order: this.constructor.orderConfig,
      language: this.constructor.languageConfig,
      ...this.ajaxConfig(),
      ...this.drawCallback(),
    }
  }

  ajaxConfig() {
    return {
      ajax: {
        url: this.table.dataset.source
      }
    }
  }
  
  drawCallback() {
    return { drawCallback: () => {} }
  }

  listenForTableRefresh() {
    this.element.addEventListener(`${this.table.id}:refresh`, () => {
      this.refresh()
    })

    this.element.addEventListener('datatable:refresh', () => {
      this.refresh()
    })
  }

  connect() {
    this.table = this.hasTableTarget ? this.tableTarget : this.element.querySelector('table');
    this._handleConnect();
  }

  _handleConnect() {
    this.initializeDatatable();
    this.listenForTableRefresh();
    this.setupListeners();
    this.setupTableConnectedCallbacks();
  }

  setupListeners() {};

  setupTableConnectedCallbacks() {};

  reload() {
    this.datatable.ajax.reload();
    this.datatable.page('first').draw('page');
  }

  refresh() {
    this.autoRefreshCountdownValue = 5;
    this.datatable.draw('full-hold');
  }

}
