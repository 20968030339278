import { ApplicationController } from "../application_controller"

export class ModalBase extends ApplicationController {
  show() {
    this.element.style.display = 'block';
  }

  close() {
    this.element.style.display = 'none';
    const event = new CustomEvent('modal:closed');
    this.element.dispatchEvent(event)
  }
}
