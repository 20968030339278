import { Controller } from "@hotwired/stimulus";

import { downloadFile } from "../../../../../app/javascript/utils/download_file";

export default class FileDownloadController extends Controller {
  static get targets() {
    return ['link'];
  }

  static get values() {
    return { action: String, body: Object, fileName: String }
  }

  download(event) {
    event.preventDefault();

    downloadFile({ 
      url: this.actionValue,
      params: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.bodyValue)
      },
      filename: this.fileNameValue
    });
  }
}
