import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['form', 'previous', 'next']
  }

  static get values() {
    return {
      currentIndex: {
        type: Number,
        default: 0
      }
    }
  }

  previousTargetConnected(ele) {
    ele.addEventListener('click', () => this.previous())
  }

  nextTargetConnected(ele) {
    ele.addEventListener('click', () => this.next())
  }

  connect() {
    this.currentIndexValue = this.nextInvalidTargetIndex
  }

  currentIndexValueChanged(v) {
    if (v === 0 || v === -1) {
      this.previousTargets.forEach( ele => ele.disabled = true )
      this.nextTargets.forEach( ele => ele.disabled = false )
    } else if (v === this.formTargets.length - 1) {
      this.previousTargets.forEach(ele => ele.disabled = false)
      this.nextTargets.forEach(ele => ele.disabled = true)
    } else {
      this.previousTargets.forEach(ele => ele.disabled = false)
      this.nextTargets.forEach(ele => ele.disabled = false)
    }
  }

  formTargetConnected(ele) {
    ele.addEventListener('focusin', () => {
      this.currentIndexValue = this.formTargets.indexOf(ele)
    })
  }

  next() {
    this.currentIndexValue = this.currentIndexValue + 1;
    this._scrollToCurrent()
  }

  previous() {
    this.currentIndexValue = this.currentIndexValue - 1;
    this._scrollToCurrent()
  }

  _scrollToCurrent() {
    const currentForm = this.formTargets[this.currentIndexValue]
    currentForm.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  get nextInvalidTargetIndex() {
    return this.formTargets.indexOf(target => target.dataset.entryValid !== 'true')
  }
}
