// 🚫 DEFAULT RAILS INCLUDES
// This section represents the default includes for a Rails 6.0.0-rc1 application. Bullet Train's includes and your own
// includes should be specified at the end of the file, not in this section. This helps avoid merge conflicts in the
// future should the framework defaults change.

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// this is needed to get hotwire up and running since some of our other libraries aren't fully up-to-date
// https://github.com/hotwired/turbo/issues/46#issuecomment-753908775
import "regenerator-runtime/runtime";
import "@hotwired/turbo-rails";

require("@rails/ujs").start();
require("turbolinks").start();

// by default @hotwired sets the Turbo.session.drive to true
// this will capture all non-get requests and will break some of our pages - such as login
// by setting Turbo.session.drive to false this leaves the default behavior unchanged and requires a developer to opt in to turbo behavior
Turbo.session.drive = false;
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// 🚫 DEFAULT BULLET TRAIN INCLUDES
// This section represents the default settings for a Bullet Train application. Your own includes should be specified
// at the end of the file. This helps avoid merge conflicts in the future, should we need to change our own includes.

require("@nathanvda/cocoon");
require("bootstrap");
require("bootstrap-validator");
require("tether");
require("slick-carousel");

// // For some of the libraries above, you also want to include their CSS.
require("slick-carousel/slick/slick.scss");

// Custom JavaScript for Bullet Train
require("../index");

// ✅ YOUR APPLICATION'S INCLUDES
// If you need to customize your application's includes, this is the place to do it. This helps avoid merge
// conflicts in the future when Rails or Bullet Train update their own default includes.
import "bootstrap";
import "../stylesheets/application.scss";
import "chartkick/chart.js";
require("datatables.net-bs4");
require("datatables.net-select-bs4");
require("datatables.net-searchpanes-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.html5.js");

require("autonumeric");

import LocalTime from "local-time";
LocalTime.start();

import '../../components';

global.$ = global.jQuery = window.$ = window.jQuery = $

global.copyToClipboard = function (selector, button_text_name = undefined) {
  const $field = $(selector);
  const $button = $(event.target);

  try {
    navigator.clipboard
      .writeText($field.val())
      .then(() => {
        $button.text("Copied");
        setTimeout(function () {
          $button.text(button_text_name || "Copy URL to Clipboard");
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        $button.text("Unable to copy! Please do it manually.");
        setTimeout(function () {
          $button.text(button_text_name || "Copy URL to Clipboard");
        }, 3000);
      });
  } catch (err) {
    $button.text("Unable to copy! Please do it manually.");
  }
};

String.prototype.trimToLength = function (m) {
  if (typeof this === "string" && this.length > m) {
    return this.trim().substring(0, m).split(" ").slice(0, -1).join(" ") + "...";
  } else {
    return this;
  }
};

$.fn.serializeObject = function () {
  const o = {};
  const a = this.serializeArray();

  $.each(a, function () {
    if (o[this.name]) {
      if (!o[this.name].push) {
        o[this.name] = [o[this.name]];
      }
      o[this.name].push(this.value || "");
    } else {
      o[this.name] = this.value || "";
    }
  });
  return o;
};
