import { Controller } from "@hotwired/stimulus";

/*
  ScrollController is used for navigating a user to a particular html element based on the element id ex: <div id="watch-this-element">
  To navigate the user to the element, pass the element id in the url
  ex: to have the user navigate to section-118615 the url would be /account/solicitations/documents/26318#section-118615

  The html content being rendered will need to have a data-scroll-target='element' in order to trigger elementTargetConnect()

  As an example:
    <div class="section-container dragula-item" data-scroll-target="element" data-model='Solicitations::Section' data-id="<%= section.id %>" data-restructurable id="section-<%= section.id %>">
      ...
    </div>
  from app/view/account/solicitations/sections/_section.html.erb

  Current behavior is that as each element gets rendered into the view the user is navigated to the element with the matching id
  This will happen as long as:
    1) the element with a matching id is found and
    2) `this.scrolled = false`

  Currently the only way to set `this.scrolled = true` is for the user to use their mousewheel.
  This means if the user begins to scroll then they will no longer be navigated back to the targeted element.
  TODO:
      Add in before render functionality to keep the user viewing the same content before and after render.
      Currently if the user is scrolling the page and content is loaded above their current view, the content they are viewing gets pushed down.
      The ideal experience is their view would be scrolled to keep their viewed content in the same relative position before and after render.
*/
export default class extends Controller {
  static get targets() {
    return ['container', 'element'];
  }

  connect() {
    this.scrolled = false;
    window.addEventListener("mousewheel", () => this.scrolled = true ); // the user manually scrolled the window so stop persisting them to the html element
  }

  /*
  * Scrolls the user as soon as the container is connected to the DOM
  */
  containerTargetConnected() {
    this.scrollToElement();
  }

  /*
  * Scrolls the user everytime a scroll element is connected if the user hasn't manually scrolled
  */
  elementTargetConnected(e) {
    if (!window.location.hash || this.scrolled) { return; }

    this.scrollToElement();
  }

  /*
  * scrolls to the element matching the id in the url
  * ex: documents/567#section-1234 would scroll to <div id="section-1234">
  */
  scrollToElement() {
    const id = window.location.hash.replace('#', '');
    const ele = document.getElementById(id)
    if (!ele) { return; }

    ele.scrollIntoView();
  }
}