import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['element'];
  }

  static get values() {
    return {
      name: String
    };
  }

  initialize() {
    const name = this.nameValue || 'element';
    this.attachedEvent = new CustomEvent(`${name}:attached`, {bubbles: true});
  }

  elementTargetConnected() {
    this.elementTarget.dispatchEvent(this.attachedEvent);
  }
}