import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['overlay', 'content'];
  }
  
  /* 
  * From: https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90
  * Allows access to a stimulus controller via its DOM element and controller name
  * Ex: document.querySelector("#task-detail-overlay").flyout.doClose())
  */
  connect() {
    this.element[this.identifier] = this;
  }
  
  /*
  * Close the flyout if the target of the click event has the flyout-closer class
  * We don't want to use currentTarget here because the click has most likely
  * propagated up from another click within the flyout.
  */
  close(event) {
    try {
      if (event.target.className.includes('flyout-closer')) {
        this.doClose();
      }
    } catch {
      // things like svg's of a 3-dot menu will cause a console error
      // so lets not worry about them.
      return;
    }
  }
  
  /*
  * Using a common method to close the flyout so that a caller doesn't necessarily need to pass an event object
  */
  doClose() {
    this.overlayTarget.classList.remove('show');
  }

  isOpen() {
    return this.overlayTarget.classList.contains('show');
  }
  
  /*
  * Using svg's makes life difficult. The close() function will break here because
  * each path of an svg is an html element. I don't want to have to spell out that
  * each path can be a closer. So we are using a unique function for button closing.
  * Here we don't need to check target or currentTarget since there isn't other clickable
  * content within a button.
  */
  immediateClose(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.doClose();
  }
}
