class Utils {
    static getPath(params) {
        if (!gon.paths) return;

        const { pathName, param, paramValue } = params;

        if (param && paramValue) {
            return gon.paths[pathName].replace(param, paramValue)
        } else {
            return gon.paths[pathName]
        }
    }

    static postData(path, data) {
        return fetch(path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
    }

    static convertToSlug(text, seperator = '_') {
        return text.toLowerCase().replace(/ /g, seperator).replace(/[^\w-]+/g,'')
    }

    // https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
    static textColorByContrast(hexcolor) {
        if (!hexcolor) return;

        hexcolor = hexcolor.replace("#", "");
        const r = parseInt(hexcolor.substr(0,2),16);
        const g = parseInt(hexcolor.substr(2,2),16);
        const b = parseInt(hexcolor.substr(4,2),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    static compareObjectData(a, b) {
        return Object.entries(a).sort().toString() === Object.entries(b).sort().toString()
    }

    static waitForElement(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }
}

module.exports = Utils;
