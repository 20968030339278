import {
  Autosave,
  DecoupledEditor as DecoupledEditorBase
} from 'ckeditor5'

import {
  Comments,
  PresenceList,
  RealTimeCollaborativeComments,
  RealTimeCollaborativeEditing,
  RealTimeCollaborativeTrackChanges,
  TrackChanges,
  TrackChangesData
} from 'ckeditor5-premium-features';

import { cloneDeep } from 'lodash';

import { ckeditorPluginList, configuration, newConfiguration } from './shared';

export default class DecoupledEditor extends DecoupledEditorBase { }

// Plugins to include in the build.
DecoupledEditor.builtinPlugins = Array.from(ckeditorPluginList);

// // When enabling debugging plugin remember also to enable `debuggingInstanceTracker` config prop.
// import DebuggingInstanceTracker from './debugging_instance_tracker';
// DecoupledEditor.builtinPlugins.push( DebuggingInstanceTracker );

DecoupledEditor.builtinPlugins.push(
  Autosave,
  RealTimeCollaborativeEditing,
  RealTimeCollaborativeComments,
  RealTimeCollaborativeTrackChanges,
  Comments,
  TrackChanges,
  TrackChangesData,
  PresenceList,
);

const newEditor = document.getElementsByClassName('new-ckeditor');
let defaultConfig = cloneDeep(configuration);
if (newEditor) {
  defaultConfig = cloneDeep(newConfiguration);
}

const setupCommentTrackChanges = () => {
  const commentTrackChanges = [
    'comment',
    'trackChanges',
    '|'
  ];
  const newEditor = document.getElementsByClassName('new-ckeditor');
  let headingIndex = defaultConfig.toolbar.items.indexOf('heading');
  if (newEditor) {
    headingIndex = defaultConfig.toolbar.items.indexOf('horizontalLine');
  }
  // we ( currently ) want Track Changes / Comment buttons to come to the right of the Headings toolbar item
  if (headingIndex > -1) {
    defaultConfig.toolbar.items.splice(headingIndex + 1, 0, ...['|'].concat(commentTrackChanges));
  } else {
    defaultConfig.toolbar.items.unshift(...commentTrackChanges);
  }
}

setupCommentTrackChanges();


// // Should be uncommented if DebuggingInstanceTracker is enabled.
// defaultConfig.debuggingInstanceTracker: {
//  consolePolling: true
// };

// Editor configuration.
DecoupledEditor.defaultConfig = defaultConfig;
