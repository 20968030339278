import { Controller } from "@hotwired/stimulus";
import { cloneDeep } from 'lodash';
import SingleLineEditor from '../account/ckeditor/single_line_editor';
import { getMention, customItemRenderer } from '../account/fields/ckeditor';
import {
  configuration as defaultConfiguration,
} from '../account/ckeditor/shared';

export default class extends Controller {
  connect() {
    this.currentEditor = '';
    this.setFeeds();
  }

  disconnect() {
    if (this.currentEditor.instance) {
      this.currentEditor.instance.destroy();
    }

    this.currentEditor = '';
  }

  setFeeds() {
    this.feeds = [];

    if (window.users) {
      this.feeds.push({
        marker: '@',
        feed: function (queryText) { return getMention(queryText, window.users) },
      });
    }

    if (window.topics) {
      this.feeds.push({
        marker: '+',
        feed: function (queryText) { return getMention(queryText, window.topics) },
        itemRenderer: customItemRenderer
      });
    }

    if (window.questions && window.placeholders) {
      this.feeds.push({
        marker: '$',
        feed: function (queryText) { return getMention(queryText, window.questions.concat(window.placeholders)) },
      });
    } else if (window.questions) {
      this.feeds.push({
        marker: '$',
        feed: function (queryText) { return getMention(queryText, window.questions) }
      });
    } else if (window.placeholders) {
      this.feeds.push({
        marker: '$',
        feed: function (queryText) { return getMention(queryText, window.placeholders) }
      });
    }
  }

  initEditor(event) {
    const titleContent = event.currentTarget;
    const sectionId = titleContent.dataset.sectionid;
    const sectionStatus = titleContent.dataset.status;
    if (sectionStatus !== 'complete') {
      const config = cloneDeep(defaultConfiguration);
      config.mention = {
        feeds: this.feeds
      };

      SingleLineEditor.create(titleContent, config)
        .then(editor => {
          this.showSaveCancelButton(sectionId);
          this.currentEditor = {
            instance: editor,
            enterInput: $(titleContent).html()
          };
        })
        .catch(error => {
          console.log(error);
        })
    }
  }

  closeEditor(event) {
    const sectionId = event.currentTarget.dataset.id;
    this.resetEditor(sectionId, true);
  }

  resetEditor(sectionId, closeEvent = false) {
    const sectionEditor = document.getElementById(`inline-editor-header-${sectionId}`);
    const sectionView = document.getElementById(`editor-header-${sectionId}`);

    this.currentEditor.instance.destroy();
    if (closeEvent) {
      document.getElementById(`inline-editor-header-${sectionId}`).innerHTML = this.currentEditor.enterInput;
    }

    this.hideSaveCancelButton(sectionId);
    this.currentEditor = '';
  }

  saveTitle(event) {
    const container = event.currentTarget;
    const sectionId = container.dataset.id;
    const form = document.getElementById(`title-form-${sectionId}`);

    $.ajax({
      type: "PATCH",
      url: form.action,
      dataType: 'script',
      data: { solicitations_section: { 'title': this.currentEditor.instance.getData() } },
      success: this.resetEditor(sectionId)
    });
  }

  hideSaveCancelButton(sectionId) {
    $(`#save-section-${sectionId}`).addClass('d-none');
    $(`#cancel-section-${sectionId}`).addClass('d-none');
  }

  showSaveCancelButton(sectionId) {
    $(`#save-section-${sectionId}`).removeClass('d-none');
    $(`#cancel-section-${sectionId}`).removeClass('d-none');
  }
}
