$(document).on('change', "select[name='workflows_stage[stage_type_attributes][included_evaluation_form_ids][]']", function() {
  const worksheetsSelect = $("select[name='workflows_stage[stage_type_attributes][evaluations_worksheet_ids][]']");
  if (!worksheetsSelect) return;
  worksheetsSelect.empty();

  let evaluationForms = $(this).val();
  const evaluationFormsWorksheets = JSON.parse($(this).attr('data-worksheets'));

  if (!evaluationForms.length) evaluationForms = Object.keys(evaluationFormsWorksheets);

  evaluationForms.forEach(evaluationForm => {
    evaluationFormsWorksheets[evaluationForm].forEach(worksheet => {
      worksheetsSelect.append($('<option></option>').attr('value', worksheet[1]).text(worksheet[0]));
    })
  })
})

$(document).on('turbolinks:load', function () {
  hiddenDynamicField('included_criteria_button', 'included_criteria_options');
  hiddenDynamicField('summary_strategy_button', 'lead_evaluator_options');
  hiddenDynamicField('vendor_acceptance_email_button', 'preview_email_template_id', true, true);

  $('#include_worksheets_button').on('click', function () {
    document.querySelectorAll('.worksheet-input').forEach((input) => {
      input.checked = true;
    })
  });
});

$(document).on('sprinkles:update', function () {
  hiddenDynamicField('included_criteria_button', 'included_criteria_options');
  hiddenDynamicField('summary_strategy_button', 'lead_evaluator_options');
  hiddenDynamicField('vendor_acceptance_email_button', 'preview_email_template_id', true, true);

  $('#include_worksheets_button').on('click', function () {
    $("#include_all_worksheets > option").prop("selected", "selected");
    $("#include_all_worksheets").trigger("change");
  });
});

function hiddenDynamicField(button, options, reverse_button = false, no_parent = false) {
  if ($(`#${options}`)[0]) {
    const firstOption = $(`#${options}`)[0];
    const included_criteria_field = no_parent ? $(firstOption) : $(firstOption.parentNode);

    if (!$(`.${button}`)[0]) return;
    // At form start, if included criteria button [yes] option is checked hide included criteria field.
    if (!reverse_button && $(`.${button}`)[0].checked == true) {
      included_criteria_field.addClass('d-none');
    }

    if (reverse_button && $(`.${button}`)[1].checked == true) {
      included_criteria_field.addClass('d-none');
    }

    // Get both buttons and on click function ask if the it includes all criteria ir hide if not it shows.
    document.querySelectorAll(`[class=${button}]`).forEach(btn => {
      $(btn).on('click', function () {
        if (!reverse_button) {
          if ($(`.${button}`)[0].checked == true) {
            included_criteria_field.addClass('d-none');
          } else {
            included_criteria_field.removeClass('d-none');
          }
        } else {
          if ($(`.${button}`)[1].checked == true) {
            included_criteria_field.addClass('d-none');
          } else {
            included_criteria_field.removeClass('d-none');
          }
        }
      });
    })
  }
}
