import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
  static get values() {
    return {
      offset: {
        type: Number,
        default: 420 // offset of PST in minutes
      },
      format: {
        type: String,
        default: 'YYYY-MM-DD HH:mm:ss'
      }
    }
  }

  static get targets() {
    return ['time']
  }

  timeTargetConnected(target) {
    const timeString = (target.dataset.timezoneTimestampValue || target.innerText).replace(' UTC', '+0000').replaceAll('"', '')
    const format = target.dataset.timezoneFormatValue || this.formatValue;
    const adjustedValue = moment(timeString).utcOffset(this.offsetValue).format(format);

    if (target.innerText.includes('{{timestamp-in-timezone}}')) {
      target.innerText = target.innerText.replace('{{timestamp-in-timezone}}', adjustedValue)
    } else {
      target.innerText = adjustedValue;
    }

    target.classList.remove('d-none')
  }
}