$(document).on('turbolinks:load', function() {
  $(document).on('click', 'button[data-toggle="collapse"]', function() {
    var collapseContent = $(this).siblings('.collapsing');
    if ($(this).hasClass('collapsed')) {
      $(this).find('i').removeClass('ti-angle-up').addClass('ti-angle-down');
    } else {
      $(this).find('i').removeClass('ti-angle-down').addClass('ti-angle-up');
    }
  })
});
