$(document).on("turbolinks:load", function () {
  var password = '';
  var radio_type = "auto_password";
  var upperCasePresent = false;
  var specialCharPresent = false;
  var passwordLengthPresent = false;

  // reset password type
  $(document).on('change', "input[type=radio][name=reset_password_type]", function () {
    if (this.value == "manually") {
      radio_type = "manual_password"
      if (!password) {
        password = $('#password').val();
      }
      $("#reset_btn").attr('disabled', 'disabled');
      $("#password").removeAttr('disabled').focus();
      $('#password').val('').attr('readonly', false);
      $("#password_constraint").show()
    }
    else {
      radio_type = "auto_password"
      $("#reset_btn").removeAttr('disabled');
      $("#password").attr('disabled', 'disabled');
      $("#password").attr('readonly', true).val(password);
      $("#password_constraint").hide()
    }
  })

  // password show -hide
  $(document).on('click', "#toggle-view-password", function (event) {
    const element = document.getElementById('password');
    if (element.type === 'password') {
      element.type = 'text';
      event.target.innerHTML = 'Hide';
    } else {
      element.type = 'password';
      event.target.innerHTML = 'Show';
    }
  })

  // copy password to clipboard
  $(document).on('click', "#copy_password", function () {
    var copyText = document.getElementById("password");
    if (copyText.value) {
      navigator.clipboard.writeText(copyText.value);
      var tooltip = document.getElementById("copied_password");
      tooltip.innerHTML = "Copied Successfully";
    }
  })

  $(document).on('mouseout', "#copy_password", function () {
    var tooltip = document.getElementById("copied_password");
    tooltip.innerHTML = "Copy to clipboard";
  })

  // reset button enable-disable
  $(document).on('input', "#password", function (event) {
    addRemoveMark();
    if (radio_type == "auto") {
      $("#reset_btn").removeAttr('disabled');
      $("#password_constraint").hide()
    } else {
      $("#password_constraint").show()
      checkAllValidations() ?  $("#reset_btn").removeAttr('disabled') : $("#reset_btn").attr('disabled', 'disabled');
    }
  })

  function checkAllValidations() {
    return upperCasePresent && specialCharPresent && passwordLengthPresent;
  }

  function addRemoveMark() {
    var password_value = $("#password").val();
    const specialChar = /[~`@+=()!#$%^&*[\]\\\';,/{}/|":<>?._ -]/g;
    const upperCase = /[A-Z]/;

    setValue(upperCase.test(password_value), "upperCase", "upperCasePresent");
    setValue(specialChar.test(password_value), "specialChar", "specialCharPresent");
    setValue(password_value.length >= 8 && password_value.length <= 128, "charLength", "passwordLengthPresent");
  }

  function setValue(conditionSatisfied, elementId, elementVar) {
    eval(elementVar +  "=" + conditionSatisfied);
    if(conditionSatisfied) { 
      $("#" + elementId).addClass("mark-icon"); 
    }
    else { 
      $("#" + elementId).removeClass("mark-icon");
    }
  }
})
