import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this._failure_occurred = false;
    this._request_sent = false;
    this._boundErrorHandler = this.handleEditorFailure.bind(this);
    this._boundPartialErrorHandler = this.handlePartialFailure.bind(this);
    this.element.addEventListener('ckeditor:collaborative-editor:initialized', (event) => {
      this.editor = event.detail.editor;
      this.handleEditorInitialized();
    })

    window.addEventListener('error', this._boundPartialErrorHandler);
    document.addEventListener('ckeditor:partial-failure', this._boundPartialErrorHandler);
    window.addEventListener('unhandledrejection', this._boundPartialErrorHandler);
    document.addEventListener('ckeditor:initialization-failure', this._boundErrorHandler);
  }

  disconnect() {
    window.removeEventListener('error', this._boundPartialErrorHandler);
    document.removeEventListener('ckeditor:partial-failure', this._boundPartialErrorHandler);
    window.removeEventListener('unhandledrejection', this._boundPartialErrorHandler);
    document.removeEventListener('ckeditor:initialization-failure', this._boundErrorHandler);
  }

  /**
   * Handler to be used when the editor initialization failure - the event being captured here is triggered inside collaboration_editor_watchdog
   */
  handleEditorFailure(event) {
    this._failure_occurred = true
    const error = event.detail.error;
    error._current_action = 'ckeditor_initialization';
    this.resultInput.value = 'failure';
    this.clientErrorInput.value = JSON.stringify(error, Object.getOwnPropertyNames(error));
    this.submit();
  }

  handlePartialFailure(event) {
    if (this.resultInput.value !== 'success') return;
    // a partial failure will not submit the result. We have seen instances where background promises might crash
    // which won't fail the editor, but may or may not leave the section in an un-useable state - depending on the
    // nature of the exact failure. For this reason, the result state will be updated to :partial_failure, but
    // we will rely on either the editor initialized or editor failed events to submit the final result.
    const error = 'error' in event ? event.error : event.reason;
    if (error && error.name.includes('CKEditor')) {
      error._current_action = 'ckeditor_partial_failure';
      this.resultInput.value = 'partial_failure';
      this.clientErrorInput.value = JSON.stringify(error, Object.getOwnPropertyNames(error));

      // if this is a submit timeout set, it means we called editor initialize. We want to reset this timeout
      // after capturing a soft failure because it could lead to a hard failure, but might take more than 3 seconds
      // to occur.
      if (this._submitTimeout) this.handleEditorInitialized();
    }
  }

  handleEditorInitialized() {
    // this is triggered either when the 'ckeditor:collaborative-editor:initialized' event is captured or
    // when a soft(partial) failure occurs. We are setting a timeout here because someitmes the editor
    // will start but then subsequent other parts of the collaborative session will fail, such as comments
    // or suggestions. 3 seconds should be a sufficient enough period of time for those soft failures to
    // happen.
    if (this._failure_occurred || this._request_sent) return;
    clearTimeout(this._submitTimeout)
    this._submitTimeout = setTimeout( () => {
      if (this._failure_occurred || this._request_sent) return;

      this.submit();
    }, 3000)
  }

  submit() {
    if (this._request_sent) return;

    this._request_sent = true;
    Rails.fire(this.form, 'submit');
  }

  /**
   * Returns the form to be submitted
   */
  get form() {
    return this.element.closest('form');
  }

  /**
   * Returns the hidden input for the client_error attribute
   */
  get clientErrorInput() {
    return document.getElementById('ckeditor_initialization_check_client_error');
  }

  /**
   * Returns the hidden input for the accepted_content attribute
   */
  get resultInput() {
    return document.getElementById('ckeditor_initialization_check_result');
  }
}
