import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
  static get values() {
    return {
      replacement: String,
      scope: String
    }
  }

  connect() {
    this.element.addEventListener('click', () => this._handleClick())
  }

  _handleClick() {
    if (this.replacementValue) {
      this.element.innerText = this.replacementValue;
      if (this.element.type === 'submit') {
        this.element.value = this.replacementValue;
      }
    }

    const elements = this.scopeValue ? document.querySelectorAll(this.scopeValue) : [this.element]
    setTimeout( () => this._disableElements(elements), 100);
  }

  _disableElements(elements) {
    elements.forEach( (ele) => ele.disabled = true );
  }
}