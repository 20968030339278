import { Controller } from "@hotwired/stimulus";
import { Formio } from "formiojs";
import "formiojs/dist/formio.builder.css"

import { downloadFile } from "../utils/download_file";

export default class FormioController extends Controller {
  static get targets() {
    return ["container", "submitModal", "submitButton", "deleteModal", "deleteButton"]
  }

  static get values() {
    return {
      url: String,
      baseUrl: String,
      projectUrl: String,
      redirectUrl: String,
      formioRole: String,
      users: { type: Array, default: [] },
      supervisors: { type: Array, default: [] },
      defaultSubmissionData: { type: Object, default: {} },
      hiddenSubmissionData: { type: Object, default: {} },
      page: Number,
    }
  }

  connect() {
    if (this.formioRoleValue) {
      localStorage.setItem("formioToken", this.formioRoleValue);
    }

    if (this.hasContainerTarget) {
      Formio.setBaseUrl(this.baseUrlValue);
      Formio.setProjectUrl(this.projectUrlValue);
      Formio.createForm(this.containerTarget, this.urlValue, {
        buttonSettings: {
          showCancel: false,
          showPrevious: false,
          showNext: false,
          showSubmit: false,
        },
      }).then((form) => {
        this.setRequestedForSelectData(form);
        this.setSupervisorSelectData(form);
        this.setDefaultSubmissionData(form);
        this.addEventListeners(form);
        this.goToPage(form);
      });
    }
  }
  
  disconnect() {
   // TODO disconnect all event listeners that were added
  }

  setRequestedForSelectData(form) {
    const component = form.getComponent("requestedFor").component;
    component.data.values = this.usersValue;
  }
  
  setSupervisorSelectData(form) {
    const component = form.getComponent("supervisorForApproval").component;
    component.data.values = this.supervisorsValue;
  }

  setDefaultSubmissionData(form) {
    if (!_.isEmpty(this.defaultSubmissionDataValue)) {
      Object.assign(form.submission.data, this.defaultSubmissionDataValue)
    }
  }

  setHiddenSubmissionData(form) {
    if (!_.isEmpty(this.hiddenSubmissionDataValue)) {
      Object.assign(form.submission.data, this.hiddenSubmissionDataValue)
    }
  }

  downloadFiles({ type, files }) {
    if (!files || files.length === 0) {
      return;
    }

    const csrf = $('meta[name=csrf-token]').attr('content');
    const url = `${window.location.origin}${window.location.pathname}/download_files`;
    const requestId = window.location.pathname.split('/').filter(p => p).pop()
    const filename = `${requestId}_${type}.zip`;
    const keys = files.map(file => file.key);

    return downloadFile({ 
      url,
      params: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
        body: JSON.stringify({
          type,
          filename,
          keys
        })
      },
      filename,
      onError: (error) => {
        console.error(error);
        alert("There was an error downloading your files.");
      } 
    });
  }

  addEventListeners(form) {
    const controller = this;

    // These downloadAll* events are set in formio editor as the event 
    // name for the associated buttons in the UI. 
    form.on('downloadAllAttachmentsForRequest', (event) => {
      this.downloadFiles({
        type: 'attachments_for_request',
        files: form.submission.data.attachmentsForRequest
      });
    });

    form.on('downloadAllJustificationDocuments', (event) => {
      this.downloadFiles({
        type: 'justification_documents',
        files: form.submission.data.justificationDocument
      });
    });

    form.on("openSubmitModal", () => {
      $(this.submitModalTarget).modal("show");
    });

    // Event fires on final form submit, "Save and Finalize"
    $(this.submitButtonTarget).on("click", () => {
      // setting some data like "updatedBy" before submission. I couldn't get it to update during the "draft" submission, so sending it here
      controller.setHiddenSubmissionData(form);
      form.submit();
    })

    if (this.hasDeleteModalTarget) {
      form.on("openDeleteModal", () => {
        $(this.deleteModalTarget).modal("show");
      });
    }

    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.addEventListener("click", () => {
        form.deleteSubmission();
        setTimeout(this.redirectToUrl(), 1000);
      })
    }

    form.on("submitDone", (event) => {
      this._addAlertState(form, event.state);
      if (event.state == "submitted") {
        this.redirectToUrl();
      } else if (event.state == "draft") {
        this._addDraftAlert(form);
        this.scrollToTop();
      }
    });

    // These are both fired when going from page 1 to 2. Not in use at the moment
    // Note that a submission XHR is sent before the page is changed, with submission state "draft"
    // form.on("submitAndGoToNextPage", (event) => {});
    // form.on("nextPage", (event) => {})

    form.on("cancelSubmission", () => {
      this.redirectToUrl();
    });

    form.on("goToPreviousPage", () => {
      this.scrollToTop();
    })
  }

  goToPage(form) {
    if (this.hasPageValue) {
      form.root.setPage(this.pageValue - 1);
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  redirectToUrl() {
    window.location.href = this.redirectUrlValue;
  }

  _addAlertState(form, state) {
    try {
      // by default, hide the formio alert banner, since it is difficult to customize
      form.alert.classList.add(`state-${state}`, 'd-none');
    } catch (e) {
      console.log(e);
    }
  }

  _addDraftAlert(form) {
    const parser = new DOMParser();
    const htmlString = '<div class="alert alert-warning" data-controller="fade" data-fade-target="out" data-delay="3000" data-click-to-dismiss=true role="alert">Draft successfully saved.</div>';
    const doc = parser.parseFromString(htmlString, 'text/html');
    const node = doc.body.firstChild;
    form.prepend(node);
  }
}

