import { Controller } from "@hotwired/stimulus";
// This controller should be considered deprecated. There is a bit better of a pattern to follow using the modal--static controller.
// To handle dynamic modals, a modal--dynamic controller should be defined separately. There is also a modal--trigger controller that
// can be used for showing modals.
export default class extends Controller {
  static get targets() {
    return ['modal', 'content', 'staticModal'];
  }

  static get values() {
    return {
      sprinkles: {
        type: Boolean,
        default: true
      }
    }
  }

  connect() {
  }

  modalTargetConnected() {
    this.modalTarget.addEventListener('close:modal', () => this.close())
  }

  set(e) {
    this.contentTarget.src = null;
    this.contentTarget.src = e.currentTarget.dataset.src
  }

  open() {
    $(this.modalTarget).modal('show');

    if (this.sprinklesValue) {
      const event = new CustomEvent('sprinkles:update', { bubbles: true });
      this.modalTarget.dispatchEvent(event);
    }
  }

  close() {
    try {
      this.contentTarget.src = null;
      this.contentTarget.innerHTML = "";
    } catch {
      // don't do anything
    }

    $(this.modalTarget).modal('hide');
    const event = new CustomEvent('modal:closed', { bubbles: true });
    this.modalTarget.dispatchEvent(event);
  }

  staticModalTargetConnected(element) {
    $(`#${element.id}`).modal({ backdrop: true, focus: true, show: true });
  }

}
