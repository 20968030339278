import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['label', 'input'];
  }

  initialize() {
    this.setLabelTarget();
  }

  setLabelTarget() {
    const labels = document.getElementsByClassName('checkbox-target');
    Array.from(labels).forEach((label) => {
      label.setAttribute('data-search-check-boxes-target', 'label');
    })
  }

  connect() {
    this.inputTarget.addEventListener('input', () => this.filter());
  }

  filter() {
    const searchValue = this.inputTarget.value.toLowerCase();

    this.labelTargets.forEach((item) => {
      const label = item.querySelector('label');
      if (label) {
        const labelText = label.textContent.toLowerCase();
        item.style.display = labelText.includes(searchValue) ? 'block' : 'none';
      }
    });
  }
}
