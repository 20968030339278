const setupRunningMarginalsDataTable = () => {
  window.runningMarginalsTemplatesTable = $('#running-marginals-templates-table').DataTable({
    searching: true,
    processing: true,
    serverSide: true,
    stateSave: true,
    aLengthMenu: [
      [10, 25, 50, 100, 200],
      [10, 25, 50, 100, 200]
    ],
    iDisplayLength: 10,
    language: {
      "sLengthMenu": "Showing_MENU_",
      "info": "of _TOTAL_ Header & Footer Templates",
      "oPaginate": {
        "sFirst": "",
        "sLast": ""
      }
    },
    "dom": "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
    pagingType: "full_numbers",
    ajax: {
      url: $("#running-marginals-templates-table").data('source'),
      data: function (d) {
        d.is_archive = $("#running-marginals-templates-table").attr('data-archive');
      },
    },
    columns: [
      { data: "name" },
      { data: "purchase_class" },
      { data: "solicitations_type" },
      { data: "solicitations_vehicle" },
      { data: 'updated_at' },
      {
        data: "dt_actions",
        className: "icon-wrap",
        orderable: false
      },
    ]
  });
}

$(document).on('turbolinks:load', () => {
  if ($("#running-marginals-templates-table").length) {
    setupRunningMarginalsDataTable();
  }
});
