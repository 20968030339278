import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['otherField', 'trigger']
  }

  connect() {
    if (!this.hasTriggerTarget) return;

    // This could live in the select2 controller
    $(this.triggerTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

    this.shouldDisplayOtherField(this.triggerTarget)
  }

  onChange(event) {
    this.shouldDisplayOtherField(event.target);
  }

  shouldDisplayOtherField(target) {
    if (!this.hasOtherFieldTarget) return;

    if (target.value != 'other') {
      this.hideOtherField();
    } else {
      this.showOtherField();
    }
  }

  hideOtherField() {
    $(this.otherFieldTarget).addClass('d-none');
  }

  showOtherField() {
    $(this.otherFieldTarget).removeClass('d-none');
    $(this.otherFieldTarget).trigger('focus');
  }
}