import AutoNumeric from 'autonumeric';

window.enableCurrencyFields = function ($scope) {
  window.AutoNumeric = AutoNumeric;

  $scope.find(".numeric-field").each(function () {
    if (!AutoNumeric.getAutoNumericElement(this)) {
      let decimalPlaces = this.classList.contains('nodecimals') ? 0 : 2;
      let options = {
        currencySymbol: '',
        unformatOnSubmit: true,
        modifyValueOnWheel: false,
        modifyValueOnUpDownArrow: false,
        decimalPlaces: decimalPlaces
      };

      new AutoNumeric(this, options);
    };
  });
}

$(document).on('turbolinks:load', function () {
  window.enableCurrencyFields($('body'));
})

$(document).on('sprinkles:update', function (event) {
  window.enableCurrencyFields($(event.target));
})
