import consumer from "../channels/consumer";

var subscriptions = {};
var pendingRequests = {};
var lastProcessedResponse = {};

function scrollMax($element) {
  $element.scrollTop($element[0].scrollHeight - $element.outerHeight());
}

function stripXray(string) {
  if (string) {
    return string.replace(/<!--XRAY [A-Z]+ \d+[^>]*-->/g, '');
  }
}

// this is a place where you can strip out any funky debug html that might be different from request to request.
function htmlIsEqual(first, second) {
  return stripXray(first) == stripXray(second);
}


function refreshMemberView(memberClass, memberId) {
  // Don't refresh elements with the `data-no-member-refresh` attribute because we use it for elements that
  // act as a parent for a nested `data-collection`. We DON'T want to refresh both the `data-model` parent
  // element and the `data-collection` as they may override each other's changes
  var selector = '[data-model="' + memberClass + '"][data-id="' + memberId + '"]:not([data-no-member-refresh])';

  $(selector).each(function (_, element) {
    var $existingView = $(element);

    // if we're in a modal with a current url, use that to redraw the collection.
    var modalUrl = $existingView.closest('.modal[data-url]').attr('data-url');

    // if our content was presented inline, use the url we were fetched from to redraw the collection.
    var inlineUrl = $existingView.closest('.inline[data-url]').attr('data-url');

    var url = modalUrl || inlineUrl || document.location.href;

    function applyUpdatedView(data) {

      var $memberBase = $(data).find(selector).addBack(selector);

      // TODO we should implement _something_ like this... but it has to work. probably needs an html linter.
      if (htmlIsEqual($memberBase.html(), $existingView.html())) {
        if (sprinklesDebugging) {
          console.log(`🍩 ${(new Date).toISOString()} ${memberClass}::${memberId} The returned member HTML exactly matches the content on the page. We are not applying the update.`);
        }
        return;
      } else {
        if (sprinklesDebugging) {
          console.log(`🍩 ${(new Date).toISOString()} ${memberClass}::${memberId} The member HTML returned is different to what is in the browser...applying the update.`)
        }
      }

      var $elementsWithPersistentClasses = $existingView.find('[data-persistent-classes]');

      if ($(data).find("[data-member-item]").length > 0) {
        $(data).find("[data-member-item]").each(function () {
          let selector = `[data-member-item="${$(this).attr("data-member-item")}"]`
          let incomingElement = $(data).find(selector);
          $existingView.find(selector).replaceWith(incomingElement);
        });
      } else {
        $existingView.empty();
        // Note that using the `children()` function won't include any text not inside an element, e.g.
        // <div>This text won't be included<span>This is included</span></div>
        $existingView.append($memberBase.children());
      }

      $existingView.find('[data-persistent-classes]').each(function (_, element) {
        var $element = $(element);
        var subselector = '[data-persistent-classes][data-model="' + $element.attr('data-model') + '"][data-id="' + $element.attr('data-id') + '"]';
        var $oldElement = $elementsWithPersistentClasses.find(subselector).addBack(subselector);
        $oldElement.attr('data-persistent-classes').split(' ').forEach(function (className) {
          if ($oldElement.hasClass(className)) {
            $element.addClass(className);
          }
        })
      });


      // allow developers to be notified when the contents of a collection have been updated.
      // if js was applied to these elements, they'll need to reapply it.
      $existingView.trigger('sprinkles:member:refreshed');
      $existingView.trigger('sprinkles:update');

      // TODO - this should really be triggered on sprinkles:member:refresh - Can we find a better place to reinitialize all the required elements that may come in via a sprinkles request?
      $existingView.find('[data-toggle="popover"]').popover();
      $existingView.find('[data-toggle="tooltip"]').tooltip();
    };

    // if there is already a pending request to this url ..
    if (pendingRequests[url]) {

      // don't trigger another request, just register ourselves to also be a recipient of that payload.
      pendingRequests[url].push(applyUpdatedView);

    } else {

      // otherwise, register ourselves as the first to be a recipient of the payload.
      pendingRequests[url] = [applyUpdatedView];

      function fetchContent(urlRequesterCount) {

        // keep track when when we're dispatching this request.
        var requestDispatchedAt = Date();

        // and when the response comes in ..
        $.get(url, {layoutless: true}, function (data) {

          // don't even bother processing this request if it was requested before a request we've already processed.
          if (lastProcessedResponse[url] && lastProcessedResponse[url] > requestDispatchedAt) {
            return;
          } else {
            lastProcessedResponse[url] = requestDispatchedAt;
          }

          // console.log("By the time the results got back, we had " + pendingRequests[url].length + " folks waiting in total, vs. " + urlRequesterCount + " when we began.");

          var processedRequests = pendingRequests[url];

          // only bother applying these updates if no additional folks have requested from the same url.
          // if others have requested from the same url, we need to fetch a newer version anyway.
          // TODO we can improve this by tracking local updates to individual sections of the page.
          if (processedRequests.length <= urlRequesterCount) {
            delete pendingRequests[url];
            $.each(processedRequests, function (_, scopedApplyUpdatedView) {
              scopedApplyUpdatedView(data);
            });
          } else {

            // actually, we can actually still apply these updates to the view as long as the view author hasn't
            // specifically warned us not to. they may want to withhold these updates from being presented to the
            // user on views that are heavy in multiple-step client-side manipulation, like dragging and dropping
            // on the kanban board.
            if ($existingView.attr('data-suppress-outdated-view-updates') === undefined) {
              $.each(processedRequests, function (_, scopedApplyUpdatedView) {
                scopedApplyUpdatedView(data);
              });
            }

            // if there were follow-alongs that jumped onboard to wait for this request, we actually need to send one more
            // request to the server just to make sure no results changed after the first request was made, as subsequent
            // changes that wouldn't be represented in that payload might have been waht caused the additional requests
            // to the same url. this is still the best approach because we can discard it if the results are the same (e.g.
            // not do a redraw of the elements) and in the case of multiple follow-alongs, we're consolidating those
            // requests to _one_ extra, instead of many extra.

            // and when the response comes in ..
            fetchContent(pendingRequests[url].length);
          }

        });

      }

      setTimeout(function () {
        fetchContent(pendingRequests[url].length);
      }, 100);

    }
  });
}

let sprinklesDebugging = false;

var renewedSubscriptions = {};

function subscribeToMembers() {
  $("[data-model]").each(function (_, element) {

    var $element = $(element);
    var memberClass = $element.attr('data-model');
    var memberId = $element.attr('data-id');
    var noMemberRefresh = $element.attr('data-no-member-refresh');

    // This is a hack because we use data-model to trigger this member subscription but we also use data-model for things like mentions.
    // We can explicitly stop from subscribing to members using this.
    // We really should update the members.js to use a different and unique data attribute
    if ($element.closest("[data-member='none']").length > 0) {
      return
    }

    // Don't subscribe elements with the `data-no-member-refresh` attributes as they are for marking elements
    // that act as a parent to `data-collection` element
    if (noMemberRefresh === "true") {
      return
    }

    var key = memberClass + ":" + memberId;
    if (renewedSubscriptions[key] !== undefined) {
      return;
    } else {
      if (sprinklesDebugging) {
        console.log("This is a new subscription!", key);
      }
    }

    if (subscriptions[key]) {
      renewedSubscriptions[key] = subscriptions[key];

    } else {
      var timer;
      renewedSubscriptions[key] = consumer.subscriptions.create({
        channel: 'Sprinkles::MembersChannel',
        member_class: memberClass,
        member_id: memberId,
      }, {
        received(data) {
          if (sprinklesDebugging) {
            console.log(`🍩 ${(new Date).toISOString()} Received sprinkles member update trigger for ${memberClass}::${memberId} - Refreshing the view.`);
          }
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }

          timer = setTimeout(function () {
            refreshMemberView(memberClass, memberId);
          }, 100);

        },
      });

    }
  });

  $.each(subscriptions, function (key, subscription) {
    if (!renewedSubscriptions[key]) {
      consumer.subscriptions.remove(subscription);
    }
  });

  subscriptions = renewedSubscriptions;
}

$(document).on('turbolinks:load', function () {
  // Though in the body we set the data attribute with "data-sprinkles-debugging=true", need to change to camelCase
  // when accessed in JS. See https://developer.mozilla.org/en-US/docs/Web/API/HTMLOrForeignElement/dataset
  sprinklesDebugging = document.querySelector("body").dataset['sprinklesDebugging'] == "true";
  subscribeToMembers();
})

$(document).on('sprinkles:update', function (event) {
  subscribeToMembers();
})

// TODO keep track of connections and each time the page changes, see whether you need to unsubscribe from any.
// TODO only broadcast _after_ all the transaction has committed to the database.
// TODO save a list of all the channels to broadcast to and then broadcast to them once at the end of the entire transaction.
