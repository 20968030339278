import { Controller } from "@hotwired/stimulus";

export class ApplicationController extends Controller {
  // helper utility for debugging why an outlet might not be connecting. Call debugOutlets from within connect()
  // in the extending controller
  debugOutlets() {
    if (!this.constructor.outlets) {
      console.log(`no outlets defined in ${this.identifer}`);
      console.log('try adding `static get outlets() { return ["name"] }`');
    } else {
      this.constructor.outlets.forEach(outlet => {
        console.log(`checking ${outlet}...`)
        const dataAttr = `data-${this.identifier}-${outlet}-outlet`
        const selector = this.element.attributes[dataAttr].value
        if (!selector) {
          console.warn(`data attribute was not found. This element should include ${dataAttr}`)
        } else {
          console.log(`selector value for expected data attr: ${selector}`)
          console.log(`elements found using selector:`)
          document.querySelectorAll(selector).forEach(ele => {
            console.log(ele);
            if (!ele.dataset.controller.includes(outlet)) {
              console.log(ele);
              console.warn(`element must attach to the ${outlet} controller`)
            }
          })
        }
      })
    }

  }
}
