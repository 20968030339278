import { ApplicationController } from '../application_controller';

export default class extends ApplicationController {
    static get targets() {
        return ['arrow'];
    }

    initialize() {
        this.showing = true;
        this.main_content = document.getElementById('main-content');
        this.eventTriggers = {
            'expanded': [],
            'collapsed': []
        };
    }

    toggle() {
        if (!this.main_content) return;

        this.showing = !this.showing;
        if (this.showing) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    on(eventName, cb) {
        if (!Object.keys(this.eventTriggers).includes(eventName)) return;

        this.eventTriggers[eventName].push(cb)
    }

    collapse() {
        if (!this.main_content) return;

        this.showing = false;
        this.arrowTarget && this.arrowTarget.classList.add('rotate-180');
        this.main_content.classList.add('close-sidebar');
        this.eventTriggers['collapsed'].forEach(cb => cb());
    }

    expand() {
        if (!this.main_content) return;

        this.showing = true;
        this.arrowTarget && this.arrowTarget.classList.remove('rotate-180');
        this.main_content.classList.remove('close-sidebar');
        this.eventTriggers['expanded'].forEach(cb => cb());
    }
}
