import { Controller } from "@hotwired/stimulus";

/**
 * A stimulus component for opening a modal that is rendered on the DOM.
 * @identifier - 'modal--open'
 *
 * To aid in rendering of a `modal--open` element, see the ViewComponent Modal::Trigger
 */
export default class extends Controller {
  /**
   * @selector {string} required! - The DOM selector that will identifies the modal that should be openend
   */
  static get values() {
    return {selector: String}
  }

  connect() {
    this.element.addEventListener('click', () => this.open())
  }

  /**
   * Opens the modal by calling show() on the element
   */
  open() {
    this.modal.show();
  }

  /**
   * Getter for returning the targeted element
   */
  get modal() {
    return document.querySelector(this.selectorValue);
  }
}