import { DatatablesBase } from "./base";

let filterStatus = {};
export default class extends DatatablesBase {
  static get orderConfig() {
    return [[1, 'asc']];
  }

  static get columnConfig() {
    return [
      {
        data: "move_actions",
        className: "icon-wrap",
        orderable: false
      },
      { data: "display_order" },
      { data: "question" },
      {
        data: "variable_name",
        render: function (data) {
          return `<code>${data}</code>`;
        }
      },
      { data: "created_at" },
      { data: 'updated_at' },
      { data: "solicitations_purchase_class" },
      { data: "solicitations_type" },
      { data: "solicitations_vehicle" },
      { data: "answered_by" },
      {
        data: "asked_upfront",
        className: 'text-center',
        render: function (data) {
          if (data == "true") {
            return '<div class="status-pill green" aria-label="Ask immediately"></div>'
          }
          else {
            return '<div class="status-pill red" aria-label="Defer question"></div>'
          }
        }
      },
      {
        data: "dt_actions",
        className: "icon-wrap",
        orderable: false
      }
    ];
  }

  static get languageConfig() {
    return {
      "sLengthMenu": "Showing_MENU_",
      "info": "of _TOTAL_ Questions",
      "oPaginate": {
        "sFirst": "",
        "sLast": ""
      }
    };
  }

  ajaxConfig() {
    return {
      ajax: {
        url: this.table.dataset.source,
        data: function (data) {
          // turns the options fields into arrays of selected options
          data.solicitations_types = Array.from(document.getElementById("solicitations_types").options).flatMap(o => o.selected ? o.value : []);
          data.solicitations_vehicles = Array.from(document.getElementById("solicitations_vehicles").options).flatMap(o => o.selected ? o.value : []);
          data.is_reset = document.getElementById("questions_is_reset").value;

          return data;
        }
      }
    }
  }

  setupListeners() {
    // TODO: These need refactored
    $(document).ready(function () {
      $('.filter-dropdown .form-group').on('click', function (event) {
        event.stopPropagation();
      });
    });

    $(document).on('click', '.filter_cancel_questions',  () => {
      filterStatus = {};
      $('[id^=solicitations_]').filter((i, s) => $(s).val([]).change())
      $(".btnQuestionFilter").html("Filters ( " + $('[id^=solicitations_]').filter((i, s) => $(s)[0].selectedIndex >= 0).length + " )")
      setTimeout( () => { this.datatable.ajax.reload() }, 300);
    });

    $(document).on('click', '.filter_apply_questions',  () => {
      filterStatus = {};
      $(".btnQuestionFilter").html("Filters ( " + $('[id^=solicitations_]').filter((i, s) => $(s)[0].selectedIndex >= 0).length + " )")
      $('[id^=solicitations_]').each((_, filter) => {
        filterStatus[filter.id] = [];
        $(filter).find('option:selected').each((_, selectedOption) => {
          filterStatus[filter.id].push(selectedOption.value);
        })
      })
      setTimeout( () => { this.datatable.ajax.reload() }, 300);
    });

    $('.questions-dropdown').on('show.bs.dropdown', function() {
      if (Object.keys(filterStatus) && Object.keys(filterStatus).length) {
        Object.keys(filterStatus).forEach(function (key) {
          $(`#${key}`).val(filterStatus[key]).change();
        })
      } else {
        $('[id^=solicitations_]').filter((i, s) => $(s).val([]).change())
      }
    })
  }
}
