import FieldTypes from './FieldTypes'

class ComplexEvaluations {
  static createHeader(key, value, displayCheckbox) {
    let field = value['id'] ? (value['id'] + key) : key

    let header = {
      headerName: value['name'] || key,
      field: field || 'unnamed',
      rowGroup: value['group'],
      hide: value['hide'],
      pinned: value['pinned'],
      checkboxSelection: displayCheckbox,
      headerCheckboxSelection: displayCheckbox,
      headerCheckboxSelectionFilteredOnly: displayCheckbox,
      sortable: false,
      filter: false,
      disableAutoSize: value['disable_auto_size'] || false,
      menuTabs: ['generalMenuTab'],
    };
    Object.assign(header, {
      ...FieldTypes['default']
    })
    if (value['tooltip_toggle']) header.tooltipField = field || 'unnamed';
    if (['open', 'closed'].includes(value['show'])) header.columnGroupShow = value['show']
    if (value.children) {
      header.children = [];
      let children = value.children;
      for (let child in children) {
        let childHeader = this.createHeader(child, children[child]);
        header.children.push(childHeader);
      }
    }
    return header;
  }
}

export default ComplexEvaluations;
