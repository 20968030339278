import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['menu', 'menuContainer'];
  }

  connect() {}

  menuContainerTargetConnected(menuContainer) {
    this.startingZIndex = $(menuContainer).css('zIndex');
    this.nextZIndex = parseInt(this.startingZIndex) + 1;
    this.boundShowBsCallback = this.bsShowCallback.bind(this);
    this.boundHideBsCallback = this.bsHideCallback.bind(this);
    this.containerSelector = '[data-menus--bs-dropdown-target="menuContainer"]';
  }

  menuTargetConnected(menu) {
    /* Notes on jQuery usage:
     * It appears that bootstrap 5 events can be listened to using native addEventListener,
     * but bootstrap 4 is some sort of jQuery event
     **/
    $(menu).on('show.bs.dropdown', this.boundShowBsCallback)
    $(menu).on('hide.bs.dropdown', this.boundHideBsCallback)
  }

  bsShowCallback(event) {
    const container = event.relatedTarget.closest(this.containerSelector);
    container && ($(container).css('zIndex', this.nextZIndex));
  }

  bsHideCallback(event) {
    const container = event.relatedTarget.closest(this.containerSelector);
    container && ($(container).css('zIndex', this.startingZIndex));
  }

  disconnect() {
    this.menuTargets.forEach((menu) => {
      $(menu).off('show.bs.dropdown', this.boundShowBsCallback);
      $(menu).off('hide.bs.dropdown', this.boundHideBsCallback);
    });
  }
}
