let elementIndex = 0;
let deletedItems = 0;

$(document).on('keydown', '[contenteditable].complex-evaluation-cell', function(event) {
  if (event.key === 'Backspace') {
    const customEquationResponse = $('#evaluations_aggregate_score_score_custom_equation');
    const customEquationValue = JSON.parse(customEquationResponse.val());
    const index = $(this)[0].getAttribute('data-value');
    customEquationValue.elements.splice(index - deletedItems, 1);
    customEquationValue.elements = customEquationValue.elements;
    customEquationResponse.val(JSON.stringify(customEquationValue));
    deletedItems++;
    $(this).remove();
  } else {
    return false;
  }
})

$(document).on('click', '.operators', function (event) {
  const equationContainer = $('#custom_equation');
  const value = assignOperators(this);

  equationContainer.append(`<div contenteditable="true" class="border rounded-circle btn-link px-3 py-2 h4 mr-2 complex-evaluation-cell" data-value="${elementIndex}">${value}</div>`);
  elementIndex++;
});

$(document).on('click', '.open-close-section', function (event) {
  const equationContainer = $('#custom_equation');
  const value = assignOperators(this);

  equationContainer.append(`<div contenteditable="true" class="border rounded-circle bg-gray-1 px-3 py-2 h4 mr-2 complex-evaluation-cell" data-value="${elementIndex}">${value}</div>`);
  elementIndex++;
});

$(document).on('submit', '.opened-modally form', function() {
  if ($('.criteria-form.close-modal').closest('form')[0] === $(this)[0]) {
    const evaluatorInput = $(this)[0].querySelectorAll('input[name="evaluations_criterium[name]"]')[0].value;

    assignEvaluatorInput(evaluatorInput);
  }
})

$(document).on('change', '.criteria-field', function() {
  assignCustomEquation(this, 'criteria');
});

$(document).on('change', '.aggregate-field', function() {
  assignCustomEquation(this, 'aggregate');
});

function assignOperators(field) {
  const value = $(field)[0].getInnerHTML();
  const customEquationResponse = $('#evaluations_aggregate_score_score_custom_equation');
  const customEquationValue = JSON.parse(customEquationResponse.val());
  customEquationValue.equation += value;
  customEquationValue.elements.push(value);
  customEquationResponse.val(JSON.stringify(customEquationValue));

  return value;
}

function assignCustomEquation(field, type) {
  const equationContainer = $('#custom_equation');
  const selectedOption = $(field).find(':selected');
  const optionText = selectedOption.text();
  const selectRendered = $(field).siblings('span.select2')[0].querySelector('.select2-selection__rendered').children;
  const customEquationResponse = $('#evaluations_aggregate_score_score_custom_equation');
  const customEquationValue = JSON.parse(customEquationResponse.val());
  customEquationValue.equation += `'${optionText}'`;
  customEquationValue.elements.push(optionText);
  if (type === 'criteria') {
    customEquationValue.included_criteria.push(selectedOption.val());
    $(selectRendered).text('Criteria Score');
  } else if (type === 'aggregate') {
    customEquationValue.included_aggregates.push(selectedOption.val());
    $(selectRendered).text('Aggregate Score');
  }
  customEquationResponse.val(JSON.stringify(customEquationValue));
  $(field).val('');
  equationContainer.append(`<div contenteditable="true" class="complex-evaluation-cell border bg-white mx-2 mb-2 text-truncate px-4 align-self-center" data-value="${elementIndex}">${optionText}</div>`);
  elementIndex++;
}

function assignEvaluatorInput(text) {
  const equationContainer = $('#custom_equation');
  const customEquationResponse = $('#evaluations_aggregate_score_score_custom_equation');
  const customEquationValue = JSON.parse(customEquationResponse.val());
  customEquationValue.equation += `'${text}'`;
  customEquationValue.elements.push(text);
  customEquationValue.evaluator_inputs.push(text);
  customEquationResponse.val(JSON.stringify(customEquationValue));
  equationContainer.append(`<div contenteditable="true" class="complex-evaluation-cell border bg-white mx-2 mb-2 text-truncate px-4 align-self-center">${text}</div>`);
}
