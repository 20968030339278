const { updateFilterCount, hideTableColumns } = require("./configuration");

const initRolesDatatable = function () {
  window.rolesDatatable = $('#roles-table')
    .DataTable({
    searching: true,
    processing: true,
    serverSide: true,
    stateSave: true,
    order: [[0, "asc"]],
    aLengthMenu: [
      [10, 25, 50, 100, 200],
      [10, 25, 50, 100, 200]
    ],
    iDisplayLength: 10,
    language: {
      "sLengthMenu": "Showing_MENU_",
      "info": "of _TOTAL_ Key Staff Role Titles",
      "oPaginate": {
        "sFirst": "",
        "sLast": ""
      }
    },
    drawCallback: function (settings) {
      const api = this.api();
      const column_indexes = [0];
      hideTableColumns(column_indexes, '#roles-table', api);
    },
    ajax: {
      url: $("#roles-table").data('source'),
      data: function (d) {
        d.roles_classes = $("#roles_classes").val();
        d.roles_types = $("#roles_types").val();
        d.roles_lpas = $("#roles_lpas").val();
        d.is_reset = $("#team_roles_is_reset").val();
        d.is_archive = $("#roles-table").attr('data-archive');
      },
    },
    pagingType: "full_numbers",
    "dom": "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
    columns: [
      { data: "display_order" },
      {
        data: "name",
        className: "custom-width"
      },
      { data: "purchase_class" },
      { data: "solicitations_type" },
      { data: "solicitations_vehicle" },
      { data: 'updated_at' },
      {
        data: "dt_actions",
        className: "icon-wrap",
        orderable: false
      },
    ],
  })
}

$(document).on('turbolinks:load', function () {
  const rolesTable = document.getElementById("roles-table");
  if (!!rolesTable) {
    initRolesDatatable();

    const buttonFilterSelector = '.btnRoleFilter';
    const filterName = 'roles';
    updateFilterCount(buttonFilterSelector, filterName);

    rolesTable.addEventListener('roles-table:refresh', () => {
      window.rolesDatatable.draw('full-hold');
    })
  }
});
