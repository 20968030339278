import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.formGroup = undefined;
  }

  connect() {
    this.formGroup = this.element.closest('.form-group')
    this.element.addEventListener('click', () => this.handleAll())
    this.assignListenersToOthers();
  }

  handleAll() {
    this.allOtherCheckboxes().forEach((ele) => {
      ele.checked = this.element.checked
    })
  }

  assignListenersToOthers() {
    this.allOtherCheckboxes().forEach((ele) => {
      ele.addEventListener('click', () => {
        if (!ele.checked) {
          this.element.checked = false;
        } else {
          this.handleOtherClick()
        }
      })
    })
  }

  handleOtherClick() {
    const checkedElements = this.allOtherCheckboxes().filter( (ele) => ele.checked);

    this.element.checked = checkedElements.length === this.allOtherCheckboxes().length;
  }

  allOtherCheckboxes() {
    return Array.from(this.formGroup.querySelectorAll('input[type="checkbox"]')).filter( (ele) => ele !== this.element)
  }
}