import Rails from '@rails/ujs';

function enableTogglers($scope) {

  // #22. Colors Toggler

  $scope.find('.floated-colors-btn').on('click', function(){
    if ($(this).find('.os-toggler-w').hasClass('on')) {
      $(this).find('.os-toggler-w').removeClass('on');
      $(this).find('.os-toggler-w').addClass('off');
    } else {
      $(this).find('.os-toggler-w').addClass('on');
      $(this).find('.os-toggler-w').removeClass('off');
    }
    $(this).find("[data-toggle='tooltip']").tooltip();
    var form = $(this).find('form')[0];
    Rails.fire(form, "submit");
    return false;
  });

};


$(document).on('turbolinks:load', function() {
  enableTogglers($('body'));
});

$(document).on('sprinkles:update', function(event) {
  enableTogglers($(event.target));
});
