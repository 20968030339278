import { Controller } from "@hotwired/stimulus";
/**
 * A stimulus controller for confirming system tags and removing them from the DOM
 *
 * The basic process is the controller receives the confirmation url as a value, fires a POST
 * requst to the endpoint, and uses Turbo to handle the response.
 *
 * To mark a system tag as confirmed, use 'app/views/system_tags/tag_confirmation' partial which
 * will hook into this controller and generate the url automatically.
 */
// data-controller='system-tag--confirmation'
export default class extends Controller {
  static get values() {
    return {
      // data-system-tag--confirmation-url-value=''
      url: String
    }
  }

  urlValueChanged() {
    if (!this.urlValue) return;

    this._handle()
  }

  _handle() {
    fetch(this.urlValue, {
      method: 'post'
    }).then(
      resp => resp.text()
    ).then(
      html =>Turbo.renderStreamMessage(html)
    )
  }
}