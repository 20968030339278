window.enableButtons = function($scope) {
  const buttons = $scope.find('.btn-toggle button').not('.btn-toggle--enabled');
  if (buttons.length === 0) return;

  buttons.addClass('btn-toggle--enabled');
  buttons.on('click', function(event) {
    event.preventDefault();

    // then we need to manually click the hidden checkbox ourselves.
    $(event.target).closest('.btn-toggle').find('input[type=radio], input[type=checkbox]').click();
  });
}

$(document).on('turbolinks:load', function() {
  enableButtons($('body'));
})

$(document).on('btn-toggle:enable', function(event) {
  enableButtons($(event.target))
})

$(document).on('sprinkles:update', function(event) {
  enableButtons($(event.target));
})

$(document).on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
  enableButtons($(insertedItem));
});
