window.initControlPanelTeamsDatatable = () => {
  if (!$('#control-panel-teams-datatable').length) return;

  window.ControlPanelTeamsDatatable = $('#control-panel-teams-datatable').DataTable({
    retrieve: true,
    processing: true,
    serverSide: true,
    stateSave: true,
    aLengthMenu: [
      [10, 25, 50, 100, 200],
      [10, 25, 50, 100, 200]
    ],
    iDisplayLength: 10,
    ajax: {
      url: $('#control-panel-teams-datatable').data('source'),
      data: function (d) {
        d.scope = $('#control-panel-teams-datatable').attr('data-archived') == 'true' ? 'archived' : 'not_archived'
      }
    },
    language: {
      'sLengthMenu': 'Showing_MENU_',
      'info': 'of _TOTAL_ Organizations',
      'oPaginate': {
        'sFirst': '',
        'sLast': ''
      }
    },
    'dom': "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-task-info'li>p>",
    pagingType: 'full_numbers',
    'aoColumnDefs': [{
         'bSortable': false,
         'aTargets': [0, 2, 3]
    }],
    columnDefs: [
    ],
    columns: [
      {data: 'id'},
      {data: 'team_name'}
    ]
  });

  //setup before functions
  var typingTimer;                //timer identifier
  var doneTypingInterval = 1000;  //time in ms, 1 second for example
  var $input = $('#search-control-panel-teams-datatable');

  //on keyup, start the countdown
  $input.on('keyup', function () {
    $(this).val() ? $('#search-control-panel-teams-clear').removeClass('d-none') : $('#search-control-panel-teams-clear').addClass('d-none')
    clearTimeout(typingTimer);
    typingTimer = setTimeout(window.ControlPanelTeamsDatatable.ajax.reload, doneTypingInterval);
  });

  //on keydown, clear the countdown
  $input.on('keydown', function () {
    clearTimeout(typingTimer);
  });

  if ($input.length && $input.val().length == 0) {
    $('#search-control-panel-teams-clear').addClass('d-none');
  }

  $('#search-control-panel-teams-clear').on('click', function () {
    $input.val('')
    $input.trigger('keyup');
    $('#search-control-panel-teams-clear').addClass('d-none');
  })
}

$(document).on('turbolinks:load', () => {
  window.initControlPanelTeamsDatatable();

  $('.tab-link').click(function () {
    if ($(this).attr('data-collection') == 'teams') {
      //check current or former user type
      archived = $(this).attr('data-archived') == 'true';

      //set to data-former attr
      $('#control-panel-teams-datatable').attr('data-archived', archived);
      setTimeout(function () { window.ControlPanelTeamsDatatable.ajax.reload() }, 300);

      //add active class to current tab
      $('.tab-link').parents('li').removeClass('active');
      $(this).parent().addClass('active');

      //set header and description based on user type
      if (archived) {
        header = 'Archived Organizations';
        description = 'Below is a list of all archived organizations in City Innovate platform suite';
      } else {
        header = 'Organizations';
        description = 'Below is a list of all organizations in City Innovate platform suite';
      }
      $('.organization-header').html(header);
      $('.table-description').html(description);
    }
  })
});
