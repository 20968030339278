import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get outlets() {
    return ['form']
  }

  handle() {
    if (!this.element.dataset.params) return;

    let ele, name, value;
    this.element.dataset.params.split(' ').forEach((param) => {
      ele = document.createElement('input');
      [name, value] = param.split(':');
      if (name && value) {
        ele.setAttribute('type', 'hidden');
        ele.setAttribute('value', value);
        ele.setAttribute('name', name);
      }
      this.formOutlet.element.appendChild(ele);
    })
  }
}