import { RevealOperator } from "../reveal_operator";

/**
 * Reveal Operator that will return true if the source data is blank.
 *
 * This is going to be most often used when the source data is a text input of some type (text, password, email, textarea, etc).
 * This operator will likely often be used in the negative format. To utilize this operator add 'blank' or 'not-blank' to the reveal-operator attributes
 *
 */

/**
 * SAMPLE
 *
 * <input type='text' name='user[email]'>
 * <input type='password' data-controller='reveal' data-reveal-name='user[email]' data-reveal-operator='not-blank'>
 *
 * The sample above would have a password field that is hidden until there is content within the email field
 */
export class Blank extends RevealOperator {
  _valueCheck(formdata) {
    return !this._singleFormValue(formdata) || this._singleFormValue(formdata).length === 0;
  }
}