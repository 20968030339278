require("spectrum-colorpicker/spectrum.css");
import 'spectrum-colorpicker';
import ColorPallete from './ColorPallete'
class ColorEditor {
    init(params) {
        this.container = document.createElement('div')
        this.container.tabIndex = "0"; // to allow the div to capture keypresses
        this.container.style = "width: 100px; text-align: center;display: inline-block;outline: none;padding: 5px 0;";
        
        this.colorPicker = document.createElement('input')
        this.colorPicker.setAttribute('type', 'text')
        this.colorPicker.classList.add('color-picker')
    
        this.container.appendChild(this.colorPicker)

        this.color = params.value;

        $(this.colorPicker).spectrum({
            allowEmpty: true,
            showPaletteOnly: true,
            togglePaletteOnly: true,
            togglePaletteMoreText: 'more',
            togglePaletteLessText: 'less',
            color: params.value,
            palette: ColorPallete,
            change: (color) => {
                this.color = color && color.toHexString() || ""
                params.stopEditing()
            }
          });

          $(this.colorPicker).on('blur', (e) => {
            $("#toggle").spectrum("toggle");
          })

    }
    
    // gets called once when grid ready to insert the element
    getGui() {
        return this.container;
    }
    
    afterGuiAttached() {
        this.container.focus()
    }
    
    getValue() {
        return this.color;
    }
    
    // any cleanup we need to be done here
    destroy() {
    }
    
    isPopup() {
        return false;
    }
}

export default ColorEditor;
