import { ApplicationController } from './application_controller'

export default class extends ApplicationController {
  connect() {
    const eventName = this.element.dataset.event
    if (eventName) {
      const event = new CustomEvent(eventName, {bubbles: true})
      this.element.dispatchEvent(event);
    }
  }
}