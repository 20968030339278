import { Controller } from "@hotwired/stimulus";
import consumer from "channels/consumer";

export default class extends Controller {
  static get targets() {
    return ['avatar'];
  }

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'AvatarLoaderChannel',
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this)
      }
    );
  }

  _connected() {
  }

  _disconnected() {
  }

  _received(data) {
    Turbo.renderStreamMessage(data)
  }

  avatarTargetConnected(ele) {
    const id = ele.dataset.avatarId;
    this.subscription.send({
      id: id
    });
  }
}
