import { DatatablesBase } from "../base";

export default class extends DatatablesBase {
  static get orderConfig() {
    return [[2, 'desc']];
  }

  static get languageConfig() {
    return {
      "sLengthMenu": "Showing_MENU_",
      "info": "of _TOTAL_ Policies",
      "oPaginate": {
        "sFirst": "",
        "sLast": ""
      }
    };
  }

  otherConfig() {
    return {
      autoWidth: false
    }
  }

  static get searchConfig() {
    return { searching: false }
  }

  static get columnConfig() {
    return [
      {
        data: 'id',
        width: '5%'
      },
      {
        data: 'domain'
      },
      {
        data: 'team_name'
      }
    ]
  }

}
