import { Controller } from "@hotwired/stimulus";

/**
 * Ensures a form submission will occur before the user leaves a page.
 *
 * To ensure submission, call #guard.
 * To allow the user to leave without interruption call #release.
 *
 * This controller should be attached to a form. The general pattern is on input change, trigger #guard. On form submission, trigger #release.
 * The controller will auto-subscribe to the form 'submit' event to release the lock as soon as the form is submitted. #release will also occur
 * if the element is ever removed from the page.
 *
 */

export default class extends Controller {
  connect() {
    this._preventer = this.prevent.bind(this);
    this._submitter = this.submit.bind(this);
    this.element.addEventListener('submit', () => this.release())
  }

  guard() {
    if (this._guarded) return
    this._guarded = true;
    document.addEventListener('turbolinks:before-visit', this._submitter);
    window.addEventListener('beforeunload', this._preventer)
  }

  release() {
    if (!this._guarded) return;

    this._guarded = false;
    document.removeEventListener('turbolinks:before-visit', this._submitter);
    window.removeEventListener('beforeunload', this._preventer);
  }

  prevent(event) {
    event.preventDefault();
    this.submit()
  }

  submit() {
    if (this.is_turbo) {
      this.element.requestSubmit();
    } else {
      this.element.submit();
    }
  }

  disconnect() {
    if (!this._guarded) return;
    this.release()
  }

  get is_turbo() {
    return this.element.dataset.turbo === 'true'
  }
}