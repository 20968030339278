import ClipboardJS from "clipboard";

var hidePopeverTimeout, removeClassTimeout

function enableCopyButtons(scope) {
  const clipboard = new ClipboardJS(".js-copy-button")
  clipboard.on("success", function(e) {
    // If the user manages to double click the button while a timeout is still running,
    // This will cancel the existing timeout and start a new one.
    if (typeof removeClassTimeout !== undefined) {
      clearTimeout(removeClassTimeout)
    }
    if (typeof hidePopeverTimeout !== undefined) {
      clearTimeout(hidePopeverTimeout)
    }
    e.trigger.disabled = true
    e.trigger.classList.add("copied")

    if(e.trigger.dataset.clearSelection == "true") {
      e.clearSelection();
    }

    if(e.trigger.dataset.content !== undefined || e.trigger.dataset.originalTitle !== undefined) {
      $(e.trigger).popover('show')
    }

    removeClassTimeout = setTimeout(function() {
      e.trigger.disabled = false
      e.trigger.classList.remove("copied")
    }, 1000)

    hidePopeverTimeout = setTimeout(function() {
      $(e.trigger).popover('hide')
    }, 5000)
  })

  clipboard.on("error", function(e) {
    console.log(e)
    console.log("There was an error copying")
  })

}

$(document).on("sprinkles:update", function(event) {
  enableCopyButtons();
})

$(document).on("turbolinks:load", function() {
  enableCopyButtons()
})
