export const defaultConfiguration = {
  processing: true,
  serverSide: true,
  stateSave: true,
  aLengthMenu: [
    [10, 25, 50, 100, 200],
    [10, 25, 50, 100, 200]
  ],
  iDisplayLength: 10,
  pagingType: "full_numbers",
  dom: "<'table-top'f><'table-responsive'rt><'table-bottom'<'table-count-info'li>p>",
}
