import { RevealOperator } from "../reveal_operator";

/**
 * Reveal Operator that returns true if the single value from the formdata is included in the provided array of values.
 * This operator does nicely handle both string and numeric values by first checking to see if the single value from the formdata
 * is found and if not re-maps the values to string and checks again. This helps ensure that there aren't complications between
 * numeric and string values.
 *
 * A common use case here would be to show content if the user selects the radio button for A, B, or C but if the user selects D or E the content
 * is not shown. An example for this use case then is:
 *
 * <div data-controller='reveal' data-reveal-name="radio-buttons-somewhere-in-this-form" data-reveal-operator='included-in' data-reveal-value="['A', 'B', 'C']">
 *  <hideable-content-here>
 * </div>
 */

export class IncludedIn extends RevealOperator {
  sanitize() {
    if (!Array.isArray(this.values)) {
      this.values = JSON.parse(this.values)
    }
  }

  validate() {
    if (!Array.isArray(this.values)) {
      this.warn('requires an array of values')
    }
  }

  _valueCheck(formdata) {
    const valueToFind = this._singleFormValue(formdata);
    return this.values.includes(valueToFind) || this.values.map(v => String(v)).includes(valueToFind)
  }
}