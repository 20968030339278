import { RevealOperator } from "../reveal_operator";

/**
 * Reveal Operator that returns true if the many options from the formdata include the provided singluar value.
 * The operator does nicely handle checking both numeric and string versions of the data by first seeing if the value exists and if not
 * re-mapping the many values to string and checking again.
 */
export class Includes extends RevealOperator {
  sanitize() {
    if (Array.isArray(this.values)) {
      this.values = this.values[0]
    }
  }

  _valueCheck(formdata) {
    const valuesToSearch = this._manyFormValues(formdata);
    return valuesToSearch.some(v => v === this.values) || valuesToSearch.some(v => v === String(this.values))
  }
}