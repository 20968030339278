import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get values() {
    return { selector: String }
  }

  connect() {
    this.element.addEventListener('click', () => this.close())
  }

  close() {
    this.modal.close();
  }

  get modal() {
    return document.querySelector(this.selectorValue);
  }
}