import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['alert', 'submitter'];
  }

  static get outlets() {
    return ['dragula']
  }

  static get values() {
    return {
      url: String
    }
  }

  enableSamePageLinks() {
    document.querySelectorAll('.todo-sidebar .same-page-section-link').forEach((ele) => {
      if (ele.attributes['href-placeholder']) {
        ele.attributes['href-placeholder'].value

        ele.setAttribute('href', ele.attributes['href-placeholder'].value);
        ele.removeAttribute('href-placeholder');
        ele.disabled = false;
      }
    })
  }

  disableSamePageLinks() {
    document.querySelectorAll('.todo-sidebar .same-page-section-link').forEach( (ele) => {
      if (ele.href !== '#') {
        ele.setAttribute('href-placeholder', ele.href);
        ele.setAttribute('href', '#');
        ele.disabled = true;
      }
    })
  }

  submitterTargetConnected(ele) {
    ele.closest('form').addEventListener('submit', () => {
      this.submitterTarget.innerHTML = 'Saving...'
      this.submitterTarget.value = 'Saving...'
      this.submitterTarget.disabled = true;
    })
  }

  dragulaOutletConnected(dragula) {
    this.changes = false;
    this.submitterTarget.innerHTML = 'Save Changes';
    this.submitterTarget.value = 'Save Changes';
    this.submitterTarget.disabled = true;
    dragula.registerOnChangeCallback(() => this.handleChange() )
  }

  handleChange() {
    this.changes = true;
    this.displayAlert();
    this.setFormInput();
  }

  setFormInput() {
    this.submitterTarget.disabled = true;

    const form = this.submitterTarget.closest('form');
    const hierarchy = this.dragulaOutlet.fetchHierarchy();
    const body = JSON.stringify(hierarchy);
    const existing = form.querySelector('input[name="assignments_by_id"]')
    existing && existing.remove();
    const ele = document.createElement('input');
    ele.setAttribute('type', 'hidden');
    ele.setAttribute('value', body);
    ele.setAttribute('name', 'assignments_by_id');
    form.appendChild(ele);

    this.submitterTarget.disabled = false;
  }

  displayAlert() {
    this.alertTarget.classList.remove('d-none')
  }

  exit(event) {
    if (this.changes) {
      const modal = document.getElementById('leave-restructure-modal');
      modal.show();
    } else {
      const toggler = new CustomEvent('toggle-display:toggle', {bubbles: true})
      event.currentTarget.dispatchEvent(toggler)
    }
  }

  reset() {
    const form = this.submitterTarget.closest('form');

    // this is a bit of an ugly hack at the moment
    // since the ToC isn't live updating yet we want the reorder submission to redirect
    // the user back to the page. For some reason though, turbo isn't following the redirect though.
    // So when the form first loads, it is just a standard form. When the user wants to exit without saving,
    // we clear the form and submit it using turbo. This nicely puts the form back to the default state.
    // Later on below, we need to remove the data-turbo attribute again. Once the ToC is being live updated, this
    // will no longer be an issue and we can just handle the form using turbo. Redirects after reorder submission will
    // no longer be needed either.
    const existing = form.querySelector('input[name="assignments_by_id"]')
    existing && existing.remove();
    // submit the blank form - because it is a data-turbo=true form, turbo will process the response
    // and re-render the newly reset reorder frame
    this.submitterTarget.click();

    // this toggles the display back to the tabbed section preview
    const toggler = new CustomEvent('toggle-display:toggle', { bubbles: true });
    form.dispatchEvent(toggler);

    // finally, close the modal
    const modal = document.getElementById('leave-restructure-modal');
    modal.close();
  }
}
